import { Redirect, Route, Switch } from "react-router";
import { SystemConfigListPage } from "./SystemConfigListPage";

export function SystemConfigPage() {
  return (
    <Switch>
      <Redirect exact from="/app/system/config" to="/app/system/config/list" />
      <Route path="/app/system/config/list" component={SystemConfigListPage} />
    </Switch>
  )
}