import dayjs from 'dayjs';
import { useCallback, useMemo } from "react";
import { CommentDto } from "../../../dtos/comment/CommentDto";
import { CommentService } from '../../../services/CommentService';
import { FlexListQueryDto } from '../../AppFlexList';
import { AppTable, AppTableColumn } from "../../AppTable";
import { PlayerDisplay } from "../player/PlayerDisplay";

interface CommentListProps {
  roomId: string;
}

export function CommentList(props: CommentListProps) {
  const { roomId } = props;
  
  const columns = useMemo(() => {
    return [
      { title: 'T.gian', width: 200, render: c => dayjs(c.timestamp).format('DD/MM/YYYY HH:mm:ss') },
      { title: 'Người bình luận', width: 300, render: c => <PlayerDisplay playerAvatar={c.clientAvatar} playerName={c.clientName} /> },
      { title: 'Nội dung', render: c => c.content },
    ] as AppTableColumn<CommentDto>[];
  }, []);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return CommentService.query({
      ...queryDto,
      sorts: [
        { field: 'timestamp', descending: true }
      ],
      filters: {
        roomId,
        clientName: queryDto.searchTerm
      }
    });
  }, [roomId]);

  return (
    <AppTable 
      columns={columns}
      fetchData={fetchData}
    />
  )
}