import { SelectMode } from "../../enums/SelectMode";
import { AppRichDeviceFrame } from "../AppRichDeviceFrame";
import { AppInteractivePreview } from "../AppInteractivePreview";

interface PollDevicePreviewProps {
  title: string;
  options: string[];
  selectMode: SelectMode;
  overlay?: boolean;
}

export function PollDevicePreview(props: PollDevicePreviewProps) {
  return (
    <AppRichDeviceFrame>
      {props.overlay && (
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-gray-900 opacity-50"></div>
      )}
      <div className="self-end w-full px-4 pb-4 relative" style={{ zIndex: 2 }}>
        <AppInteractivePreview
          title={<b className="ml-2">Khảo sát</b>}
          content={props.title}
          options={props.options}
          selectMode={props.selectMode}
        />
      </div>
    </AppRichDeviceFrame>
  )
}