import React, { useCallback, useMemo, useState } from "react";
import { ResponseCode } from "../../config/ResponseConfig";
import { ResponseDto } from "../../dtos/common/ResponseDto";
import { CreateQuizGameBannerDto } from "../../dtos/quiz-game-banner/CreateQuizGameBannerDto";
import { QuizGameBannerDto } from "../../dtos/quiz-game-banner/QuizGameBannerDto";
import { UpdateQuizGameBannerDto } from "../../dtos/quiz-game-banner/UpdateQuizGameBannerDto";
import { UploadFileDto } from "../../dtos/upload-file/UploadFileDto";
import { UploadFileRequestDto } from "../../dtos/upload-file/UploadFileRequestDto";
import { QuizGameBannerType, QuizGameBannerTypeDisplay } from "../../enums/QuizGameBannerType";
import { ScreenMode, ScreenModeDisplay } from "../../enums/ScreenMode";
import { UploadFileFolder } from "../../enums/UploadFileFolder";
import { QuizGameBannerService } from "../../services/QuizGameBannerService";
import { UploadFileService } from "../../services/UploadFileService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { UploadFileInput } from "../upload-file/UploadFileInput";

interface IQuizGameBannerInputProps {
  label: string;
  bannerType: QuizGameBannerType;
  screenMode: ScreenMode;
  quizGameId: number;
  banner?: QuizGameBannerDto;
  onSuccess?: (banner: QuizGameBannerDto) => any;
}

export function QuizGameBannerInput(props: IQuizGameBannerInputProps) {
  const { label, bannerType, screenMode, quizGameId, onSuccess, banner: propsBanner } = props;
  const [banner, setBanner] = useState(propsBanner);

  const upload = useCallback(async (uf: UploadFileDto) => {
    if (uf.file) {
      const ufRequest = UploadFileRequestDto.ofUploadFileDto(uf, true);
      ufRequest.filename = undefined; // auto-generate filename
      ufRequest.displayName = `${quizGameId}. ${QuizGameBannerTypeDisplay[bannerType]} (${ScreenModeDisplay[screenMode]})`;
      ufRequest.folder = UploadFileFolder.QUIZ_GAME_BANNERS;
      return UploadFileService.upload(ufRequest);
    }
    return false;
  }, [quizGameId, bannerType, screenMode]);

  const upsertBanner = useCallback(async (uf: UploadFileDto) => {
    let upsertResponse: ResponseDto<QuizGameBannerDto>;
    if (banner) {
      const dto = new UpdateQuizGameBannerDto();
      dto.uploadFileId = uf.id;

      upsertResponse = await QuizGameBannerService.update(banner.id, dto);
    } else {
      const dto = new CreateQuizGameBannerDto();
      dto.type = bannerType;
      dto.screenMode = screenMode;
      dto.quizGameId = quizGameId;
      dto.uploadFileId = uf.id;

      upsertResponse = await QuizGameBannerService.create(dto);
    }

    if (upsertResponse.code === ResponseCode.OK.code) {
      NotificationUtils.success({
        message: <>{banner ? 'Tạo' : 'Cập nhật'} <b>{QuizGameBannerTypeDisplay[bannerType]} ({ScreenModeDisplay[screenMode]})</b> thành công!</>
      });
      setBanner(upsertResponse.body);
      onSuccess && onSuccess(upsertResponse.body);
    }
  }, [banner, bannerType, quizGameId, screenMode, onSuccess]);

  const deleteBanner = useCallback(async ()  => {
    if (banner) {
      const success = await QuizGameBannerService.delete(banner.id);
      if (success) {
        NotificationUtils.success({ message: <>Xoá <b>{QuizGameBannerTypeDisplay[banner.type]} ({ScreenModeDisplay[banner.screenMode]})</b> thành công!</> });
        setBanner(undefined);
      }
      return success;
    }
    return false;
  }, [banner]);

  const fileLabelRender = useCallback(() => {
    if (banner) {
      return `${QuizGameBannerTypeDisplay[banner.type]} (${ScreenModeDisplay[banner.screenMode]})`;
    }
    return `${QuizGameBannerTypeDisplay[bannerType]} (${ScreenModeDisplay[screenMode]})`;
  }, [banner, bannerType, screenMode]);

  const file = useMemo(() => banner?.uploadFile, [banner]);

  return (
    <UploadFileInput 
      label={label}
      upload={upload}
      onChange={upsertBanner}
      onDelete={deleteBanner}
      file={file}
      fileLabelRender={fileLabelRender}
      mime="image"
      displayHeight="auto"
      folder={UploadFileFolder.QUIZ_GAME_BANNERS}
    />
  )
}