import { createContext, useReducer } from "react";
import { AppGlobalStateDto } from "../dtos/store/AppGlobalStateDto";
import { AppReducer } from "./AppReducer";

const initialState = new AppGlobalStateDto();
export const AppContext = createContext(initialState);

export function AppStore(props: any) {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  return (
    <AppContext.Provider value={[state, dispatch] as any}>
      {props.children}
    </AppContext.Provider>
  );
}
