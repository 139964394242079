import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { PageDto } from "../dtos/common/PageDto";
import { QueryQuizDto } from "../dtos/quiz/QueryQuizDto";
import { QuizDto } from "../dtos/quiz/QuizDto";
import { UpsertQuizDto } from "../dtos/quiz/UpsertQuizDto";
import { HttpUtils } from "../utils/HttpUtils";

export class QuizService {
  
  static async query(queryDto: QueryQuizDto) {
    return (await HttpUtils.post<PageDto<QuizDto>>(Apis.quiz.query, queryDto)).body;
  }

  static async findById(id: number) {
    return (await HttpUtils.get<QuizDto>((Apis.quiz.findById(id)))).body;
  }

  static create(createDto: UpsertQuizDto) {
    return HttpUtils.post<QuizDto>(Apis.quiz.create, createDto);
  }

  static update(id: number, updateDto: UpsertQuizDto) {
    return HttpUtils.put<QuizDto>(Apis.quiz.update(id), updateDto);
  }

  static async publish(id: number) {
    return (await HttpUtils.post(Apis.quiz.publish(id))).code === ResponseCode.OK.code;
  }

  static async updateCorrectAnswers(quizIds: number[], correctAnswerIds: number[]) {
    return (await HttpUtils.post(Apis.quiz.updateCorrectAnswers, { quizIds, correctAnswerIds })).code === ResponseCode.OK.code;
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.quiz.toggle(id))).code === ResponseCode.OK.code;
  }
}