import { IsEmail, IsNotEmpty, IsString } from "class-validator";

export class LoginDto {
  @IsEmail(undefined, { message: 'Email không hợp lệ' })
  @IsString({ message: 'Vui lòng nhập email' })
  username: string;

  @IsNotEmpty({ message: 'Vui lòng nhập mật khẩu' })
  @IsString({ message: 'Vui lòng nhập mật khẩu' })
  password: string;
}