import axios, { AxiosRequestConfig } from "axios";
import { ResponseCode } from "../config/ResponseConfig";
import { ResponseDto } from "../dtos/common/ResponseDto";
import { NotificationUtils } from "./NotificationUtils";

export class HttpUtils {

  private static async perform<T = any>(config: AxiosRequestConfig, notifyIfError = true): Promise<ResponseDto<T>> {
    try {
      config.withCredentials = true; // important cors request
      
      const response = await axios(config);
      const data: ResponseDto<T> = response.data;
      if (data.code !== ResponseCode.OK.code) {
        throw new ResponseDto(data.code, data.body);
      }
      return data;
    } catch (err: any) {
      if (notifyIfError && (err instanceof ResponseDto)) {
        const message = err.body || 'Unknown Error';
        NotificationUtils.error({
          title: 'Có lỗi xảy ra',
          message: <><b>[{err.code}]</b> {message}</>
        });
        return err;
      } else {
        throw err;  
      }
    }
  }

  static get<T = any>(url: string, params: any = null, config: AxiosRequestConfig = {}, notifyIfError = true) {
    return this.perform<T>({ method: 'GET', url, params, ...config }, notifyIfError);
  }

  static post<T = any>(url: string, data: any = null, config: AxiosRequestConfig = {}, notifyIfError = true) {
    return this.perform<T>({ method: 'POST', url, data, ...config }, notifyIfError);
  }

  static put<T = any>(url: string, data: any = null, config: AxiosRequestConfig = {}, notifyIfError = true) {
    return this.perform<T>({ method: 'PUT', url, data, ...config }, notifyIfError);
  }

  static delete<T = any>(url: string, config: AxiosRequestConfig = {}, notifyIfError = true) {
    return this.perform<T>({ method: 'DELETE', url, ...config }, notifyIfError);
  }
}