import { IsNotEmpty, IsOptional, IsString, MaxLength } from "class-validator";

export class LivestreamProfileDto {
  @MaxLength(50, { message: 'Tên hiển thị quá dài' })
  @IsNotEmpty({ message: 'Tên hiển thị không được để trống' })
  @IsString({ message: 'Vui lòng nhập tên hiển thị' })
  username: string;

  @IsOptional()
  avatar?: string;
}