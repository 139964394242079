import { Button, Collapse } from "@mantine/core";
import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { HiPlusCircle } from "react-icons/hi";
import { MountedQuizDto } from "../../dtos/mounted-quiz/MountedQuizDto";
import { AppSideListItem } from "../AppSideListItem";
import { MountedQuizVerticalDnD } from "../mounted-quiz/MountedQuizVerticalDnD";

interface QuizGameSideListItemProps {
  title?: any;
  zIndex: number;
  mountedQuizzes: MountedQuizDto[];
  onDeleteMQ: (mq: MountedQuizDto) => any;
  onSelectMQ: (mq: MountedQuizDto) => any;
  onReorderMQ: (dropResult: DropResult) => any;
  onSelectQuizGame: () => any;
  createButton?: boolean;
  onCreate?: () => any;
  collapsable?: boolean;
  menu?: boolean;
  deletable?: boolean;
  onDelete?: () => any;
}

export function QuizGameSideListItem(props: QuizGameSideListItemProps) {
  const [collapse, setCollapse] = useState(false);
  return (
    <>
      <AppSideListItem 
        zIndex={props.zIndex}
        onClick={props.onSelectQuizGame}
        collapsable={props.collapsable}
        onCollapse={() => setCollapse(!collapse)}
        collapse={collapse}
        menu={props.menu}
        deletable={props.deletable}
        onDelete={props.onDelete}
      >
        {props.title || 'Bộ câu hỏi'} ({props.mountedQuizzes.length} câu)
      </AppSideListItem>
      <Collapse in={!collapse}>
        <div className={"p-4" + (props.createButton ? " border-b" : "")}>
          <MountedQuizVerticalDnD
            zIndex={props.zIndex}
            mountedQuizzes={props.mountedQuizzes}
            onDelete={props.onDeleteMQ}
            onSelect={props.onSelectMQ}
            onReorder={props.onReorderMQ}
          />
          {props.createButton && (
            <Button fullWidth variant="light" leftIcon={<HiPlusCircle />} onClick={props.onCreate}>
              Thêm câu hỏi
            </Button>
          )}
        </div>
      </Collapse>
    </>
  )
}