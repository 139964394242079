import dayjs from "dayjs";
import { QuizGameDto } from "../../dtos/quiz-game/QuizGameDto";
import { QuizModeEn } from "../../enums/QuizMode";

interface QuizGamePreviewProps {
  quizGame: QuizGameDto
}

export function QuizGamePreview(props: QuizGamePreviewProps) {
  return (
    <div className="text-gray-500 text-xs">
      <div className="mb-1"><b>Tổng số câu hỏi:</b> {props.quizGame.totalQuiz}</div>
      <div className="mb-1"><b>Chế độ chơi:</b> {QuizModeEn[props.quizGame.quizMode]}</div>
      <div className="mb-1"><b>Cập nhật lúc:</b> {dayjs(props.quizGame.updateDt).format('DD/MM/YYYY HH:mm')}</div>
      <div><b>Tạo lúc:</b> {dayjs(props.quizGame.createDt).format('DD/MM/YYYY HH:mm')}</div>
    </div>
  )
}