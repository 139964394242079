import { Button, Divider, Modal } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { UserDto } from "../../../dtos/user/UserDto";
import { useCompKey } from "../../../hooks/useCompKey";
import { UserService } from "../../../services/UserService";
import { FormatUtils } from "../../../utils/FormatUtils";
import { FlexListQueryDto } from "../../AppFlexList";
import { AppTable, AppTableColumn } from "../../AppTable";
import { AppToggle } from "../../AppToggle";
import { UpdateRoleForm } from "./UpdateRoleForm";
import { UpsertUserForm } from "./UpsertUserForm";

export function UserList() {
  const [create, setCreate] = useState(false);

  const tableKey = useCompKey();
  const columns = useMemo(() => {
    return [
      { title: 'ID', render: user => user.id },
      { title: 'Email', render: user => user.username },
      { title: 'Họ', render: user => user.firstName },
      { title: 'Tên', render: user => user.lastName },
      { title: 'App', render: user => user.appId },
      { title: 'Vai trò', width: 200, render: user => <UpdateRoleForm id={user.id} role={user.role} /> },
      { title: 'Trạng thái', width: 100, render: user => <AppToggle id={user.id} checked={!user.disabled} toggle={UserService.toggle} /> },
      { title: 'Cập nhật lúc', render: user => FormatUtils.dt(user.updateDt) },
    ] as AppTableColumn<UserDto>[];
  }, []);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return UserService.query({
      ...queryDto,
      sorts: [
        { field: 'updateDt', descending: true }
      ],
      filters: {
        username: queryDto.searchTerm
      }
    });
  }, []);

  const createBtn = useMemo(() => (
    <Button variant="gradient" onClick={() => setCreate(true)} leftIcon={<HiPlusCircle />}>
      Thêm tài khoản
    </Button>
  ), []);

  const handleCreateSuccess = useCallback(() => {
    setCreate(false);
    tableKey.renew();
  }, [tableKey]);

  return (
    <>
      <Modal opened={create} title="Thêm tài khoản" onClose={() => setCreate(false)}>
        <Divider my="lg" />
        <UpsertUserForm onSuccess={handleCreateSuccess} />
      </Modal>

      <AppTable 
        key={tableKey.key}
        fetchData={fetchData}
        columns={columns}
        actions={createBtn}
      />
    </>
  )
}