export enum StreamSourceType {
  YOUTUBE = 'YOUTUBE',
  ANT_MEDIA = 'ANT_MEDIA'
}

export const StreamSourceTypeDisplay = {
  [StreamSourceType.YOUTUBE]: 'Youtube',
  [StreamSourceType.ANT_MEDIA]: 'Ant Media'
}

export const StreamSourceTypeOptions = [
  { value: StreamSourceType.ANT_MEDIA, label: StreamSourceTypeDisplay[StreamSourceType.ANT_MEDIA] },
]