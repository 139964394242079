import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { QueryCommentDto } from "../dtos/comment/QueryCommentDto";
import { EventDto } from "../dtos/realtime/EventDto";
import { EventType } from "../enums/EventType";
import { HttpUtils } from "../utils/HttpUtils";
import { RealtimeService } from "./RealtimeService";

export class CommentService {

  static async block(roomId: string, commentId: string) {
    return (await HttpUtils.post(Apis.comment.block, { roomId, commentId })).code === ResponseCode.OK.code;
  }

  static sendComment(roomId: string, content: string) {
    const ws = RealtimeService.getWs(roomId);
    if (ws) {
      const eventDto = new EventDto(EventType.COMMENT, { content });
      ws.send(eventDto.toString());
    }
  }

  static getLastComments(roomId: string, size: number) {
    const ws = RealtimeService.getWs(roomId);
    if (ws) {
      const eventDto = new EventDto(EventType.GET_LAST_COMMENTS, { size });
      ws.send(eventDto.toString());
    }
  }

  static getLastBlockComments(roomId: string, size: number) {
    const ws = RealtimeService.getWs(roomId);
    if (ws) {
      const eventDto = new EventDto(EventType.GET_LAST_BLOCK_COMMENTS, { size });
      ws.send(eventDto.toString());
    }
  }

  static async query(queryDto: QueryCommentDto) {
    return (await HttpUtils.post(Apis.comment.query, queryDto)).body;
  }

  static async pin(roomId: string, commentId: string) {
    return (await HttpUtils.post(Apis.comment.pin, { roomId, commentId })).code === ResponseCode.OK.code;
  }

  static async unpin(roomId: string) {
    return (await HttpUtils.post(Apis.comment.unpin, { roomId })).code === ResponseCode.OK.code;
  }
}