import { Tab, Tabs } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { CommentList } from "../../../components/livestream/comment/CommentList";
import { InteractiveHistoryDetail } from "../../../components/livestream/interactive-history/InteractiveHistoryDetail";
import { PlayerList } from "../../../components/livestream/player/PlayerList";
import { PlayerLogList } from "../../../components/livestream/player/PlayerLogList";
import { useQuery } from "../../../hooks/useQuery";
import { ScenarioService } from "../../../services/ScenarioService";

export function InteractiveHistoryDetailPage() {
  const params: any = useParams();
  const scenarioId = params.scenarioId;

  const fetchScenrio = useCallback(() => {
    return ScenarioService.findById(scenarioId);
  }, [scenarioId]);

  const scenarioResp = useQuery(fetchScenrio);

  const breadcrumbs = useMemo(() => [
    { label: 'Livestream', link: '/app/livestream' },
    { label: 'Lịch sử vận hành', link: '/app/livestream/interactive/history/list' },
    { label: 'Chi tiết', link: '/app/livestream/interactive/history/detail/' + scenarioId }
  ], [scenarioId]);

  const scenario = scenarioResp.data;
  const roomId = scenario?.roomId as string;

  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />

      <Tabs>
        <Tab label="Danh sách tương tác">
          <div className="mt-4">
            <InteractiveHistoryDetail scenarioId={scenarioId} />
          </div>
        </Tab>
        <Tab label="Danh sách người chơi">
          <div className="mt-4">
            <PlayerList roomId={roomId} />
          </div>
        </Tab>
        <Tab label="Bình luận">
          <div className="mt-4">
            <CommentList roomId={roomId} />
          </div>
        </Tab>
        <Tab label="Log">
          <div className="mt-4">
            <PlayerLogList roomId={roomId} />
          </div>
        </Tab>
      </Tabs>
    </>
  );
}