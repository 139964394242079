import { IsNotEmpty, IsString, MaxLength } from "class-validator";

export class UpsertSystemConfigDto {
  @MaxLength(100, { message: "Tên cấu hình chỉ cho phép tối đa 100 kí tự" })
  @IsNotEmpty({ message: "Tên cấu hình không được để trống" })
  @IsString({ message: "Vui lòng nhập tên cấu hình" })
  name: string;

  @IsNotEmpty({ message: "Không được để trống giá trị" })
  @IsString({ message: "Vui lòng nhập giá trị" })
  value: string;
}