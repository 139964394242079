import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { OperateService } from "../../services/OperateService";

export function reduceOperateDisables(state: AppGlobalStateDto, operateDisables: any): AppGlobalStateDto {
  const newState = { ...state, operateDisables};
  const currRoomId = state.currRoomId;
  if (currRoomId) {
    OperateService.setOperateDisables(currRoomId, operateDisables);
    if (newState.currQuizItrId !== undefined && newState.currQuizGameItrId !== undefined && operateDisables[newState.currQuizItrId]) {
      const currQuizGameItr = newState.itrNodeMap[newState.currQuizGameItrId];
      if (currQuizGameItr?.children) {
        const currQuizItr = newState.itrNodeMap[newState.currQuizItrId];
        const nextQuiz = OperateService.findNextQuiz(currRoomId, currQuizGameItr.children, currQuizItr);
        if (nextQuiz) {
          newState.currQuizItrId = nextQuiz.id;
        }
      }
    }
  }

  return newState;
}