import { IoIosArrowForward } from "react-icons/io";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { PublishStatus } from "../../enums/PublishStatus";
import { useAppStore } from "../../store/useAppStore";
import { BaseInteraction } from "./BaseInteraction";

interface QuizGameInteractionProps {
  interaction: InteractionDto;
  onSelect: () => any;
  numOrder?: number;
}

export function QuizGameInteraction(props: QuizGameInteractionProps) {
  const { store } = useAppStore();
  const { currQuizGameItrId } = store;
  const { interaction: itr } = props;
  return (
    <BaseInteraction 
      onClick={props.onSelect}
      completed={itr.publishStatus === PublishStatus.FINISHED}
      publishing={itr.publishStatus === PublishStatus.PUBLISHING}
      current={currQuizGameItrId === itr.id}
    >
      <div className="flex items-center">
        <div>
          {props.numOrder || itr.numOrder}. {itr.quizGame?.name}
          <span className="ml-1">
            ({itr.quizGame?.totalQuiz} câu)
          </span>
        </div>
        <div className="ml-auto">
          <IoIosArrowForward />
        </div>
      </div>
    </BaseInteraction>
  )
}