import { ScenarioDto } from "../../dtos/scenario/ScenarioDto";
import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { PublishStatus } from "../../enums/PublishStatus";

export function reduceUpdateScenario(state: AppGlobalStateDto, scenario: ScenarioDto): AppGlobalStateDto {
  return { 
    ...state,
    isRoomOpened: state.isRoomOpened || scenario.publishStatus === PublishStatus.PUBLISHING,
    currScenario: scenario
  };
}