import { useMemo } from "react";
import { LivestreamProfileDto } from "../dtos/operate/LivestreamProfileDto";
import { OperateService } from "../services/OperateService";
import { useAppStore } from "../store/useAppStore";
import { S3Utils } from "../utils/S3Utils";
import { useSession } from "./useSession";

export function useLivestreamProfile() {
  const { store } = useAppStore();
  const { currRoomId } = store;
  const session = useSession();

  return useMemo(() => {
    if (!!currRoomId) {
      const dto = new LivestreamProfileDto();
      if (session.user) {
        dto.username = `${session.user.firstName} ${session.user.lastName}`;
        dto.avatar = S3Utils.toS3Url(session.user.avatar);
      }
      const profile = OperateService.getLivestreamProfile(currRoomId, String(session.user.id));
      if (!!profile) {
        dto.username = profile.username;
        dto.avatar = profile.avatar;
      }
      return dto;
    }
  }, [session, currRoomId]);
}