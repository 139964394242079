import { Redirect, Route, Switch } from "react-router";
import { AppAuthedRoute } from "../components/AppAuthedRoute";
import { AppHeader } from '../components/AppHeader';
import { AppSidebar } from '../components/AppSidebar';
import { ActionType } from "../enums/ActionType";
import { UserRole } from "../enums/UserRole";
import { useAppStore } from "../store/useAppStore";
import { InteractivePage } from "./interactive/InteractivePage";
import { LivestreamPage } from "./livestream/LivestreamPage";
import { OperateLiveStreamPage } from "./operate-livestream/OperateLiveStreamPage";
import { SettingPage } from "./setting/SettingPage";
import { SystemPage } from "./system/SystemPage";

const ADMIN_ROLES = [UserRole.ADMIN];

export function AppPage() {
  const { store, dispatch } = useAppStore(); 
  const { sbCollapse } = store;
  return (
    <>
      <div className={"fixed h-full w-52 " + (sbCollapse ? "transform -translate-x-48" : "")} style={{ zIndex: 2 }}>
        <AppSidebar collapse={sbCollapse} onCollapse={() => dispatch(ActionType.SET_SB_COLLAPSE, !sbCollapse)} />
      </div>
      <div className={sbCollapse ? "pl-4" : "pl-52"}>
        <AppHeader collapse={sbCollapse} />
        <div className="pt-14">
          <Switch>
            <Redirect exact from="/app" to="/app/interactive" />
            <Route path="/app/interactive" component={InteractivePage} />
            <Route path="/app/operate/livestream" component={OperateLiveStreamPage} />
            <Route path="/app/livestream" component={LivestreamPage} />
            <AppAuthedRoute roles={ADMIN_ROLES} path="/app/system" component={SystemPage} />
            <Route path="/app/setting" component={SettingPage} />
          </Switch>
        </div>
      </div>
    </>
  )
}