import dayjs from "dayjs";
import { PageDto } from "../dtos/common/PageDto";
import { QueryLogDto } from "../dtos/log/QueryLogDto";
import { EsService } from "./EsService";

export class LogService {

  private static readonly index = 
    `${process.env.REACT_APP_ES_INDEX_PREFIX || ''}-${process.env.REACT_APP_APP_ENV}-${dayjs().format('YYYY.MM')}.*,` + 
    `${process.env.REACT_APP_ES_INDEX_PREFIX || ''}-${process.env.REACT_APP_APP_ENV}-${dayjs().subtract(1, 'month').format('YYYY.MM')}.*`;

  static async query(queryDto: QueryLogDto) {
    const body: any = {
      from: (queryDto.paging.page - 1) * queryDto.paging.pageSize,
      size: queryDto.paging.pageSize,
      track_total_hits: true
    };

    body.sort = [];
    if (queryDto.sorts && queryDto.sorts.length > 0) {
      queryDto.sorts.forEach(sort => {
        body.sort.push({ [sort.field]: { order: sort.descending ? 'desc' : 'asc' } });
      });
    }

    if (queryDto.filters) {
      body.query = { bool: { filter: [] } };
      const { searchTerm, ...otherFilters } = queryDto.filters;
      if (searchTerm) {
        body.query.bool.filter.push({
          query_string: {
            query: searchTerm,
            fields: ['*']
          }
        });
      }

      if (otherFilters) {
        Object.keys(otherFilters).forEach((field: any) => {
          const value = (otherFilters as any)[field];
          if (value) {
            let filter: any;
            let termField = (v: any) => field + (typeof v === 'string' ? '.keyword' : '');

            if (Array.isArray(value)) {
              if (value.length > 0) {
                filter = { terms: { [termField(value[0])]: value } };
              }
            } else {
              filter = { term: { [termField(value)]: value } };
            }

            if (filter) {
              body.query.bool.filter.push(filter);
            }
          }
        });
      }
    }

    const response = await EsService.search(LogService.index, body);
    const pageDto = new PageDto();
    pageDto.number = queryDto.paging.page - 1;
    pageDto.size = queryDto.paging.pageSize;
    pageDto.totalElements = response.hits.total.value;
    pageDto.content = response.hits.hits.map((hit: any) => hit._source);
    return pageDto;
  }
}