import { Popover } from "@mantine/core";
import { useState } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";

interface AppQuestionTitleProps {
  title: any;
  children: any;
  zIndex?: number;
}

export function AppQuestionTitle(props: AppQuestionTitleProps) {
  const [preview, setPreview] = useState(false);
  return (
    <div className="flex items-center">
      <div className="mr-1 font-bold">{props.children}</div>
      <Popover
        withArrow
        color="dark"
        placement="center"
        position="bottom"
        trapFocus={false}
        opened={preview}
        zIndex={props.zIndex}
        target={
          <AiFillQuestionCircle 
            className="text-blue-400"
            onMouseEnter={() => setPreview(true)}
            onMouseLeave={() => setPreview(false)}
          />
        }
      >
        <div className="text-sm">{props.title}</div>
      </Popover>
    </div>
  )
}