import { Button, Divider, NumberInput, Radio, RadioGroup, Textarea } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { AiOutlineClockCircle, AiOutlineSave } from 'react-icons/ai';
import { QuizDto } from "../../dtos/quiz/QuizDto";
import { UpsertQuizDto } from "../../dtos/quiz/UpsertQuizDto";
import { SelectMode, SelectModeVi } from "../../enums/SelectMode";
import { useAppFormik } from "../../hooks/useAppFormik";
import { QuizService } from "../../services/QuizService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { RandomUtils } from "../../utils/RandomUtils";
import { UpsertQuizAnswerForm } from "./UpsertQuizAnswerForm";
import { useForceUpdate } from "@mantine/hooks";
import { BsLayoutSidebarInsetReverse } from 'react-icons/bs';
import { ResponseCode } from "../../config/ResponseConfig";
import { QuizDevicePreview } from "./QuizDevicePreview";
import { QuizMode, QuizModeEn } from "../../enums/QuizMode";

interface UpsertQuizFormProps {
  quizMode?: QuizMode;
  onSuccess?: (quiz: QuizDto) => any;
  onSubmitError?: () => any;
  quiz?: QuizDto | null;
  preview?: boolean;
  submitable?: boolean;
  elementRef?: any;
  template: boolean;
}

class AnswerRef {
  key: any;
  ref: {
    current?: any
  };

  constructor(key: any) {
    this.key = key;
    this.ref = { current: null };
  }
}

export function UpsertQuizForm(props: UpsertQuizFormProps) {
  const forceUpdate = useForceUpdate();
  const { onSuccess, elementRef, template, quizMode, onSubmitError, quiz: propsQuiz } = props;
  const [quiz, setQuiz] = useState(propsQuiz);
  const [preview, setPreview] = useState(props.preview);

  const [answerRefs, setAnswerRefs] = useState<AnswerRef[]>(() => {
    if (quiz) {
      return quiz.answers.map(answer => new AnswerRef(answer.id));
    }
    return [
      new AnswerRef(RandomUtils.uuid()),
      new AnswerRef(RandomUtils.uuid()),
      new AnswerRef(RandomUtils.uuid()),
      new AnswerRef(RandomUtils.uuid())
    ];
  });

  const initialValues = useMemo(() => {
    const upsertDto = new UpsertQuizDto();
    if (quiz) {
      upsertDto.content = quiz.content;
      upsertDto.selectMode = quiz.selectMode;
      upsertDto.answers = quiz.answers;
      upsertDto.duration = quiz.duration;
      upsertDto.delay = quiz.delay;
    }
    upsertDto.duration = upsertDto.duration || 10;
    upsertDto.selectMode = upsertDto.selectMode || SelectMode.SINGLE;
    upsertDto.delay = upsertDto.delay || 0;
    return upsertDto;
  }, [quiz]);

  const submit = useCallback(async (values: UpsertQuizDto, actions: FormikHelpers<UpsertQuizDto>) => {
    const promises = answerRefs.map(async ansRef => {
      await ansRef.ref.current.formik.handleSubmit();
      if (Object.keys(ansRef.ref.current.formik.errors).length === 0) {
        const ans = {...ansRef.ref.current.formik.values};
        if (typeof ansRef.key === 'number') {
          ans.id = ansRef.key;
        }
        return ans;
      }

      return null;
    });

    const upsertDto = {...values};
    upsertDto.answers = (await Promise.all(promises)).filter(ans => !!ans);

    if (upsertDto.answers.length < promises.length) {
      actions.setSubmitting(false);
      onSubmitError && onSubmitError();
      return;
    }

    if (quizMode && quizMode !== QuizMode.FOOTBALL) {
      if (upsertDto.answers.length < 2) {
        NotificationUtils.error({
          message: <>Câu hỏi của chế độ chơi <b>{QuizModeEn[quizMode]}</b> phải có ít nhất 2 câu trả lời</>
        });
        actions.setSubmitting(false);
        onSubmitError && onSubmitError();
        return;
      }

      const correctAnswers = upsertDto.answers.filter(ans => ans.correct);
      if (correctAnswers.length === 0) {
        NotificationUtils.error({
          message: 'Vui lòng chọn một đáp án đúng'
        });
        actions.setSubmitting(false);
        onSubmitError && onSubmitError();
        return;
      }

      if (upsertDto.selectMode === SelectMode.SINGLE && correctAnswers.length > 1) {
        NotificationUtils.error({
          message: <>Kiểu câu hỏi <b>{SelectModeVi[upsertDto.selectMode]}</b> chỉ cho phép duy nhất 1 đáp án đúng</>
        });
        actions.setSubmitting(false);
        onSubmitError && onSubmitError();
        return;
      }
    }

    upsertDto.template = template;

    let response;
    if (quiz) {
      response = await QuizService.update(quiz.id, upsertDto);
    } else {
      response = await QuizService.create(upsertDto);
    }

    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: "Lưu câu hỏi thành công" });
      setQuiz(response.body);
      
      onSuccess && onSuccess(response.body);
    }

    actions.setSubmitting(false);
  }, [onSuccess, quiz, answerRefs, template, quizMode, onSubmitError]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  const addAnswer = useCallback(() => {
    if (answerRefs.length === 4) {
      NotificationUtils.error({ message: 'Một câu hỏi chỉ cho phép tối đa 4 câu trả lời' });
      return;
    }

    setAnswerRefs(prev => {
      return [
        ...prev,
        new AnswerRef(RandomUtils.uuid())
      ];
    })
  }, [answerRefs.length]);

  const removeAnswer = useCallback((key: any) => {
    if (answerRefs.length === 2) {
      NotificationUtils.error({ message: 'Một câu hỏi phải có tối thiểu 2 đáp án' });
      return;
    }

    setAnswerRefs(prev => {
      return prev.filter(ans => ans.key !== key);
    });
  }, [answerRefs.length]);

  const clearCorrects = useCallback((skipIdx?: number) => {
    answerRefs.forEach((ansRef, idx) => {
      if (idx !== skipIdx) {
        ansRef.ref.current?.formik.setFieldValue('correct', false);
      }
    });
  }, [answerRefs]);

  const handleCorrectCheck = useCallback((checkedIx: number) => {
    if (formik.values.selectMode === SelectMode.SINGLE) {
      clearCorrects(checkedIx);
    }
  }, [formik, clearCorrects]);

  const handleSelectModeChange = useCallback((value: any) => {
    formik.handleChange('selectMode')(value);
    if (value === SelectMode.SINGLE) {
      clearCorrects();
    }
  }, [formik, clearCorrects]);

  useEffect(() => {
    if (elementRef) {
      elementRef.current = { formik };
    }
  }, [elementRef, formik]);

  return (
    <form className="relative w-full" onSubmit={e => {
      e.preventDefault();
      answerRefs.forEach(async ansRef => {
        await ansRef.ref.current.formik.handleSubmit();
      });
      formik.handleSubmit(e);
    }}>
      <div className="flex">
        <div className={"w-full relative " + (preview ? "mr-4" : "")}>
          <div 
            className="absolute top-2 right-0 text-sm flex items-start text-gray-500 hover:text-blue-400 cursor-pointer" 
            onClick={() => setPreview(v => !v)}
          >
            Preview <BsLayoutSidebarInsetReverse className="ml-1 text-xl" />
          </div>
          <div className="mb-6 flex items-center">
            <NumberInput
              required
              min={3}
              max={100000}
              label="Thời gian (giây)"
              placeholder="Thời gian..."
              className="mr-4"
              icon={<AiOutlineClockCircle />}
              onChange={value => formik.setFieldValue('duration', value)}
              onBlur={formik.handleBlur}
              value={formik.values.duration}
              error={formik.touched.duration && formik.errors.duration}
            />

            <NumberInput 
              required
              min={0}
              name="delay"
              label="Delay"
              placeholder="Delay..."
              className="w-28 mr-8"
              onChange={v => formik.setFieldValue('delay', v)}
              onBlur={formik.handleBlur}
              value={formik.values.delay}
              error={formik.touched.delay && formik.errors.delay}
            />

            {quizMode === QuizMode.KAHOOT && (
              <RadioGroup 
                required
                name="selectMode" 
                label="Kiểu câu hỏi"
                size="sm"
                onChange={handleSelectModeChange}
                onBlur={formik.handleBlur}
                value={formik.values.selectMode}
                error={formik.touched.selectMode && formik.errors.selectMode}
              >
                <Radio value={SelectMode.SINGLE} label={SelectModeVi[SelectMode.SINGLE]} />
                <Radio value={SelectMode.MULTIPLE} label={SelectModeVi[SelectMode.MULTIPLE]} />
              </RadioGroup>
            )}
          </div>

          <Textarea
            autosize
            minRows={2}
            maxRows={4}
            label={(
              <div className="flex">
                Nội dung câu hỏi <b className="text-red-400 ml-1">*</b>
                <div className="ml-auto text-gray-500 text-xs">{formik.values.content?.length || 0} / 255</div>
              </div>
            )}
            name="content"
            placeholder="Nội dung câu hỏi..."
            className="mb-4"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.content}
            error={formik.touched.content && formik.errors.content}
          />

          {answerRefs.map((answerRef, idx) => (
            <div className="w-full mb-4" key={answerRef.key}>
              <UpsertQuizAnswerForm 
                answer={quiz?.answers?.find(ans => ans.id === answerRef.key)}
                numOrder={idx + 1}
                onDelete={() => removeAnswer(answerRef.key)}
                elementRef={answerRef.ref}
                onContentChange={() => setTimeout(forceUpdate, 0)}
                onCorrectCheck={() => handleCorrectCheck(idx)}
                removable={answerRefs.length > 2}
              />
            </div>
          ))}

          {answerRefs.length < 4 && (
            <Button fullWidth variant="subtle" onClick={addAnswer} leftIcon={<HiPlusCircle />}>
              Thêm câu trả lời
            </Button>
          )}
          
          {props.submitable && (
            <>
              <Divider my="lg" />
              <Button type="submit" variant="gradient" leftIcon={<AiOutlineSave />} loading={formik.isSubmitting}>
                Lưu lại
              </Button>
            </>
          )}
        </div>
        <div className={"w-1/3 " + (preview ? "" : "hidden")}>
          <QuizDevicePreview 
            overlay={true}
            question={formik.values?.content || ''}
            selectMode={formik.values.selectMode}
            answers={answerRefs.map(ansRef => ansRef.ref.current?.formik.values.content || '')}
          />
        </div>
      </div>
    </form>
  )
}