import { Button, TextInput } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react"
import { ResponseCode } from "../../config/ResponseConfig";
import { ScenarioDto } from "../../dtos/scenario/ScenarioDto";
import { UpsertScenarioDto } from "../../dtos/scenario/UpsertScenarioDto";
import { useAppFormik } from "../../hooks/useAppFormik";
import { ScenarioService } from "../../services/ScenarioService";
import { NotificationUtils } from "../../utils/NotificationUtils";

interface UpsertScenarioFormProps {
  onSuccess: (scenario: ScenarioDto) => any;
  scenario?: ScenarioDto | null;
  projectId?: number;
  submitable?: boolean;
  elementRef?: { current: any };
}

export function UpsertScenarioForm(props: UpsertScenarioFormProps) {
  const { onSuccess, projectId, submitable, elementRef, scenario: propsScenario } = props;
  const [scenario, setScenario] = useState(propsScenario);

  const initialValues = useMemo(() => {
    const upsertDto = new UpsertScenarioDto();
    if (scenario) {
      upsertDto.name = scenario.name;
    }
    return upsertDto;
  }, [scenario]);

  const submit = useCallback(async (values: UpsertScenarioDto, actions: FormikHelpers<UpsertScenarioDto>) => {
    if (projectId) {
      let response;
      if (scenario) {
        response = await ScenarioService.update(scenario.id, values);
      } else {
        const isNameExisted = await ScenarioService.checkName(values.name, projectId);
        if (isNameExisted) {
          actions.setFieldError('name', 'Tên kịch bản đã tồn tại, vui lòng chọn tên khác');
          actions.setSubmitting(false);
          return;
        }
  
        response = await ScenarioService.create({ ...values, projectId });
      }
  
      if (response.code === ResponseCode.OK.code) {
        NotificationUtils.success({ message: "Lưu kịch bản thành công" });
        setScenario(response.body);
        
        onSuccess && onSuccess(response.body);
      }
  
      actions.setSubmitting(false);
    }
  }, [onSuccess, scenario, projectId]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  useEffect(() => {
    if (elementRef) {
      elementRef.current = { formik };
    }
  }, [elementRef, formik]);

  return (
    <form onSubmit={formik.handleSubmit} className="relative">
      <TextInput
        required
        label="Tên kịch bản"
        name="name"
        placeholder="Tên kịch bản..."
        className="w-full lg:w-1/3"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={formik.touched.name && formik.errors.name}
      />

      {submitable && (
        <Button variant="gradient" type="submit" className="mt-4" loading={formik.isSubmitting}>
          Lưu lại
        </Button>
      )}
    </form>
  )
}