import { Button } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { QuizGameLeaderboardFilters } from "../../../dtos/quiz-game-leaderboard/QueryQuizGameLeaderboardDto";
import { QuizGameLeaderboardDto } from "../../../dtos/quiz-game-leaderboard/QuizGameLeaderboardDto";
import { QuizGameDto } from "../../../dtos/quiz-game/QuizGameDto";
import { QuizMode } from "../../../enums/QuizMode";
import { QuizGameLeaderboardService } from "../../../services/QuizGameLeaderboardService";
import { FlexListQueryDto } from "../../AppFlexList";
import { AppTable, AppTableColumn } from "../../AppTable";
import { PlayerDisplay } from "../player/PlayerDisplay";

interface QuizGameResultProps {
  quizGame: QuizGameDto;
}

export function QuizGameResult(props: QuizGameResultProps) {
  const { quizGame } = props;
  const [filters, setFilters] = useState<QuizGameLeaderboardFilters | undefined>();

  const columns = useMemo(() => {
    const columns = [
      { title: 'Xếp hạng', render: row => row.rank },
      { title: 'ID', render: row => row.playerId },
      { title: 'Người chơi', render: row => <PlayerDisplay {...row} />}
    ] as AppTableColumn<QuizGameLeaderboardDto>[];

    if (quizGame.quizMode !== QuizMode.CONFETTI) {
      columns.push({ title: 'Điểm số', render: row => row.totalScore || 0 });
    }

    return columns;
  }, [quizGame]);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    const filters = {
      playerName: queryDto.searchTerm,
      quizGameId: quizGame.id
    };

    setFilters(filters);
    
    return QuizGameLeaderboardService.query({
      ...queryDto,
      sorts: [
        { field: 'rank', descending: false }
      ],
      filters
    })
  }, [quizGame]);

  const exportExcel = useCallback(() => {
    if (filters) {
      QuizGameLeaderboardService.exportExcel(filters);
    }
  }, [filters]);

  const actions = useMemo(() => (
    <Button variant="gradient" onClick={exportExcel}>Xuất Excel</Button>
  ), [exportExcel]);

  return (
    <>
      <AppTable
        columns={columns}
        fetchData={fetchData}
        actions={actions}
      />
    </>
  )
}