import { Redirect, Route, Switch } from "react-router";
import { SystemAppListPage } from "./SystemAppListPage";

export function SystemAppPage() {
  return (
    <Switch>
      <Redirect exact from="/app/system/app" to="/app/system/app/list" />
      <Route path="/app/system/app/list" component={SystemAppListPage} /> 
    </Switch>
  )
}