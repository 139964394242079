import { ActionIcon, TextInput, TextInputProps } from "@mantine/core";
import { useClipboard } from "@mantine/hooks";
import { AiOutlineCheck, AiOutlineCopy } from "react-icons/ai";

export function AppCopiableTextInput(props: TextInputProps) {
  const clipboard = useClipboard();
  return (
    <TextInput 
      {...props}
      rightSection={(
        <ActionIcon color={clipboard.copied ? 'teal' : 'gray'} variant="hover" onClick={() => clipboard.copy(props.value || props.defaultValue)}>
          {clipboard.copied ? <AiOutlineCheck /> : <AiOutlineCopy />}
        </ActionIcon>
      )}
    />
  )
}