import { Avatar, LoadingOverlay, TextInput, Tooltip } from "@mantine/core";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useEffect, useMemo, useState } from "react";
import { FiInbox, FiRefreshCcw } from "react-icons/fi";
import { AppConfig } from "../../../config/AppConfig";
import { EventType } from "../../../enums/EventType";
import { useQuery } from "../../../hooks/useQuery";
import { RealtimeService } from "../../../services/RealtimeService";
import { RoomService } from "../../../services/RoomService";
import { useAppStore } from "../../../store/useAppStore";
import { AppPagination } from "../../AppPagination";
import { OperateRoomNotExists } from "../OperateRoomNotExists";

const approx = require('approximate-number');

export function OperatePlayerList() {
  const { store } = useAppStore();
  const { currRoomId, isRoomOpened } = store;

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);

  const pageSize = AppConfig.paging.PLAYER_PAGE_SIZE;
  const fetchClientPage = useMemo(() => {
    if (currRoomId) {
      return () => RoomService.paginateClients(currRoomId, { page, pageSize });
    }
  }, [currRoomId, page, pageSize]);

  const clientPageResponse = useQuery(fetchClientPage);
  // const totalPage = Math.ceil(total / pageSize);

  useEffect(() => {
    if (currRoomId) {
      return RealtimeService.subscribe(currRoomId, eventDto => {
        switch (eventDto.eventType) {
          case EventType.ROOM_STATE:
            setTotal(prevTotal => {
              if (prevTotal !== eventDto.payload.viewerCount) {
                clientPageResponse.reFetch();
              }
              return eventDto.payload.viewerCount;
            });
            break;
        }
      });
    }
  }, [currRoomId, clientPageResponse]);

  if (!currRoomId || !isRoomOpened) {
    return <OperateRoomNotExists />
  }

  const maxTotal = Math.max(total, clientPageResponse.data?.total || 0);

  return (
    <div className="p-4 relative" style={{ height: 'calc(100% - 3.5rem)' }}>
      <LoadingOverlay visible={clientPageResponse.loading} />
      <div className="flex items-center">
        <h3 className="font-bold text-sm">
          Danh sách người chơi ({approx(maxTotal)})
        </h3>
        <FiRefreshCcw
          className="ml-2 text-blue-400 cursor-pointer" 
          onClick={() => clientPageResponse.reFetch()}
        />
      </div>

      <div className="flex items-center mt-4">
        <TextInput 
          readOnly
          icon={<MagnifyingGlassIcon />}
          placeholder="Tìm người chơi..."
          className="w-full"
        />
      </div>

      <div className="flex flex-wrap mt-4">
        {clientPageResponse.ready && clientPageResponse.data && clientPageResponse.data.content.length > 0 ? (
          <>
            {clientPageResponse.data.content.map((client, idx) => (
              <div key={idx} className={"w-1/2 " + (idx % 2 ? "pl-1" : "pr-1")}>
                <div className="flex items-center py-3 px-2 rounded-lg border bg-gray-50 mb-2 text-sm hover:bg-gray-200 cursor-pointer relative">
                  <div className="absolute">
                    <Avatar color="blue" radius="xl" size="sm" src={client.avatar} alt={client.name} />
                  </div>
                  <Tooltip className="w-full" withArrow position="bottom" label={client.name}>
                    <div className={"ml-8 overflow-hidden h-5 whitespace-nowrap " + (client.operator ? "text-red-400" : "")}>
                      {client.name}
                    </div>
                  </Tooltip>
                </div>
              </div>
            ))}

            <div className="mt-4 w-full flex justify-center">
              <AppPagination
                current={page}
                pageSize={pageSize}
                total={maxTotal}
                onChange={page => setPage(page)}
              />
            </div>
          </>
        ) : (
          <div className="pt-20 w-full flex flex-col items-center justify-center text-gray-400">
            <FiInbox className="text-6xl mb-2" />
            <div className="text-sm">Không có người chơi nào</div>
          </div>
        )}
      </div>
    </div>
  )
}