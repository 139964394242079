import Pagination, { PaginationProps } from 'rc-pagination';
import { useMemo } from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

interface ItemProps {
  children: any;
  active?: boolean;
  disabled?: boolean;
}

function Item(props: ItemProps) {
  return (
    <span className={"inline-flex items-center justify-center cursor-pointer p-2 w-8 h-8 border border-gray-200 hover:border-blue-400 hover:text-blue-400 rounded mr-2 bg-white" + 
      (props.active ? " border-blue-400 text-blue-400 " : '') +
      (props.disabled ? " bg-gray-200 cursor-not-allowed " : '')}
    >
      {props.children}
    </span>
  )
}

export function AppPagination(props: PaginationProps) {
  const { total, pageSize } = props;
  const totalPage = useMemo(() => {
    if (!total || !pageSize) return 0;
    return Math.ceil(total / pageSize);
  }, [total, pageSize]);

  return (
    <Pagination
      className="flex items-center"
      showLessItems={window.innerWidth <= 640}
      // showQuickJumper={false}
      itemRender={(current, type, element) => {
        switch (type) {
          case 'page':
            // if (current === totalPage && props.current !== current) {
            //   return null;
            // }
            return <Item active={current === props.current}>{current}</Item>
          case 'prev':
            return <Item disabled={props.current === 1}><IoIosArrowBack /></Item>
          case 'next':
            return <Item disabled={props.current === totalPage}><IoIosArrowForward /></Item>
          default:
            return element;
        }
      }}
      {...props} />
  )
}