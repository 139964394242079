import { ActionIcon, Badge, Button, LoadingOverlay, Popover, Tooltip } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineFile } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";
import { UploadFileDto } from "../../dtos/upload-file/UploadFileDto";

interface UploadFileDisplayProps {
  file: UploadFileDto;
  displayHeight?: any;
  labelRender?: (file: UploadFileDto) => any;
  onDelete?: (file: UploadFileDto) => any;
}

const fileMimeDisplays = {
  image: 'Ảnh',
  video: 'Video',
  file: 'Khác'
}

const fileBadgeColors = {
  image: 'blue',
  video: 'red',
  file: 'gray'
}

const fileSizes = {
  GB: 1024 * 1024 * 1024,
  MB: 1024 * 1024,
  KB: 1024
}

export function UploadFileDisplay(props: UploadFileDisplayProps) {
  const { file, labelRender, onDelete } = props;
  const displayHeight = props.displayHeight || '18vh';
  const [loading, setLoading] = useState(false);
  const [delConfirm, setDelConfirm] = useState(false);

  const fileMime = useMemo(() => {
    if (file.mime.startsWith('image')) {
      return 'image';
    }
    if (file.mime.startsWith('video')) {
      return 'video';
    }
    return 'file';
  }, [file]);

  const fileDisplay = useMemo(() => {
    switch (fileMime) {
      case 'image':
        return <img src={file.src} alt={file.displayName} className="w-full h-full rounded" />
      case 'video':
        return (
          <video style={{ objectFit: 'cover' }} className="w-full h-full rounded" controls onClick={e => e.stopPropagation()}>
            <source src={file.src} type={file.mime} />
          </video>
        )
      default:
        return <AiOutlineFile />
    }
  }, [file, fileMime]);

  const fileExt = useMemo(() => {
    return file.ext.toLocaleUpperCase();
  }, [file]);

  const fileSize = useMemo(() => {
    if (file.size >= fileSizes.GB) {
      return `${Math.round(file.size / fileSizes.GB)}GB`
    }
    if (file.size >= fileSizes.MB) {
      return `${Math.round(file.size / fileSizes.MB)}MB`;
    }
    return `${Math.round(file.size / fileSizes.KB)}KB`;
  }, [file]);

  const fileInfo = useMemo(() => {
    switch (fileMime) {
      case 'image':
        return `${fileExt} - ${file.width || 0} x ${file.height || 0} - ${fileSize}`;
      default:
        return `${fileExt} - ${fileSize}`;
    }
  }, [file, fileSize, fileExt, fileMime]);

  const fileLabel = useMemo(() => {
    if (labelRender) {
      return labelRender(file);
    }
    return file.displayName;
  }, [labelRender, file]);

  const handleDelete = useCallback(async () => {
    setDelConfirm(false);
    if (onDelete) {
      setLoading(true);
      await onDelete(file);
      setLoading(false);
    }
  }, [file, onDelete]);

  return (
    <div className="w-full relative">
      <LoadingOverlay visible={loading} />
      <Popover
        opened={delConfirm}
        onClose={() => setDelConfirm(false)}
        position="bottom"
        placement="center"
        className="absolute right-2 top-2"
        withArrow
        onClick={e => e.stopPropagation()}
        target={(
          <ActionIcon 
            radius="xl"
            color="red"
            variant="filled" 
            style={{ zIndex: 3 }}
            onClick={(e: any) => {
              e.stopPropagation();
              setDelConfirm(true);
            }}
          >
            <FiTrash2 />
          </ActionIcon>
        )}
      >
        <p className="text-sm mb-2">Bạn có chắc muốn xoá file này chứ?</p>
        <div className="flex justify-end">
          <Button compact color="gray" variant="default" className="mr-2" onClick={() => setDelConfirm(false)}>Huỷ</Button>
          <Button compact color="red" onClick={handleDelete}>Xoá</Button>
        </div>
      </Popover>
      <div className="w-full flex items-center justify-center border bg-gray-100" style={{ height: displayHeight }}>
        {fileDisplay}
      </div>
      <div className="mt-4 text-sm font-medium flex items-center">
        <div className={"mr-2 " + (fileMime === "video" ? "w-15" : "w-12")}>
          <Badge radius="sm" variant="filled" color={fileBadgeColors[fileMime]}>{fileMimeDisplays[fileMime]}</Badge>
        </div>
        <div className="overflow-hidden" style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          <Tooltip label={fileLabel}>
            {fileLabel}
          </Tooltip>
        </div>
      </div>
      <div className="mt-2 text-gray-400 text-sm">
        {fileInfo}
      </div>
    </div>
  )
}