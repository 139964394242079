import { EventDto } from "../../dtos/event/EventDto";
import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { EventState } from "../../enums/EventState";
import { OperateService } from "../../services/OperateService";

export function reduceSetEvent(state: AppGlobalStateDto, event: EventDto): AppGlobalStateDto {
  OperateService.setCurrRoomId(event.roomId)
  OperateService.setCurrEventId(event.roomId, event.id);
  return {
    ...state,
    isRoomOpened: state.isRoomOpened || event.state === EventState.LIVE,
    currRoomId: event.roomId,
    currEvent: event
  }
}