import { Button, PasswordInput, Select, TextInput } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useMemo } from "react";
import { ResponseCode } from "../../../config/ResponseConfig";
import { UpsertUserDto } from "../../../dtos/user/UpsertUserDto";
import { UserDto } from "../../../dtos/user/UserDto";
import { UserRole } from "../../../enums/UserRole";
import { useAppFormik } from "../../../hooks/useAppFormik";
import { useApps } from "../../../hooks/useApps";
import { UserService } from "../../../services/UserService";
import { NotificationUtils } from "../../../utils/NotificationUtils";
import { AppSelect } from "../app/AppSelect";

interface UpsertUserFormProps {
  onSuccess?: (user: UserDto) => any;
}

export function UpsertUserForm(props: UpsertUserFormProps) {
  const { onSuccess } = props;

  const initialValues = useMemo(() => {
    const upsertDto = new UpsertUserDto();
    upsertDto.role = UserRole.OPERATOR;
    return upsertDto;
  }, []);

  const submit = useCallback(async (values: UpsertUserDto, actions: FormikHelpers<UpsertUserDto>) => {
    if (values.role !== UserRole.ADMIN && !values.appId) {
      actions.setFieldError('appId', 'Vui lòng chọn một app');
      actions.setSubmitting(false);
      return;
    }

    const isNameExists = await UserService.checkUsername(values.username);
    if (isNameExists) {
      actions.setFieldError('username', 'Tên tài khoản đã tồn tại, vui lòng chọn tên khác');
      actions.setSubmitting(false);
      return;
    }

    values.appId = values.appId ? Number(values.appId) : undefined;

    const response = await UserService.create(values);
    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: 'Tạo tài khoản thành công' });
      onSuccess && onSuccess(response.body);
    }

    actions.setSubmitting(false);
  }, [onSuccess]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  const apps = useApps();

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextInput 
          required
          label="Email"
          name="username"
          placeholder="Email..."
          className="mb-4"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.username}
          error={formik.touched.username && formik.errors.username}
        />

        <PasswordInput
          required
          label="Mật khẩu"
          name="password"
          placeholder="Mật khẩu..."
          className="mb-4"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={formik.touched.password && formik.errors.password}
        />

        <TextInput
          required
          label="Họ người dùng"
          name="firstName"
          placeholder="Họ người dùng..."
          className="mb-4"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.firstName}
          error={formik.touched.firstName && formik.errors.firstName}
        />

        <TextInput
          required
          label="Tên người dùng"
          name="lastName"
          placeholder="Tên người dùng..."
          className="mb-4"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.lastName}
          error={formik.touched.lastName && formik.errors.lastName}
        />

        <div className={"mb-4 " + (formik.values.role === UserRole.ADMIN ? "hidden" : "")}>
          <AppSelect
            data={apps.data}
            loading={apps.loading}
            onChange={v => formik.setFieldValue('appId', Number(v))}
            onBlur={formik.handleBlur}
            value={String(formik.values.appId)}
            error={formik.touched.appId && formik.errors.appId}
          />
        </div>

        <Select
          required
          label="Vai trò"
          name="role"
          placeholder="Vai trò..."
          className="mb-4"
          onChange={role => formik.setFieldValue('role', role)}
          onBlur={formik.handleBlur}
          value={formik.values.role}
          error={formik.touched.role && formik.errors.role}
          data={[
            { label: 'Vận hành viên', value: UserRole.OPERATOR },
            { label: 'Admin', value: UserRole.ADMIN }
          ]}
        />

        <Button fullWidth type="submit" className="mt-4" loading={formik.isSubmitting}>
          Tạo tài khoản
        </Button>
      </form>
    </>
  )
}