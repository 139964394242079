import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { PageDto } from "../dtos/common/PageDto";
import { UploadFileRequestDto } from "../dtos/upload-file/UploadFileRequestDto";
import { QueryUploadFileDto } from "../dtos/upload-file/QueryUploadFileDto";
import { UploadFileDto } from "../dtos/upload-file/UploadFileDto";
import { HttpUtils } from "../utils/HttpUtils";
import { S3Utils } from "../utils/S3Utils";

export class UploadFileService {

  static async query(dto: QueryUploadFileDto) {
    const { body } = await HttpUtils.post<PageDto<UploadFileDto>>(Apis.uploadFile.query, dto);
    body.content = body.content.map(uf => {
      uf.src = UploadFileService.makeSrc(uf);
      return uf;
    })
    return body;
  }

  static makeSrc(uf: UploadFileDto | UploadFileRequestDto) {
    return S3Utils.toS3Url(`${uf.folder}/${uf.filename}`);
  }

  static async upload(dto: UploadFileRequestDto) {
    return (await HttpUtils.post(Apis.uploadFile.upload, dto.toFormData())).code === ResponseCode.OK.code;
  }

  static async delete(id: number) {
    return (await HttpUtils.delete(Apis.uploadFile.delete(id))).code === ResponseCode.OK.code;
  }
}