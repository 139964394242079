import { Redirect, Route, Switch } from "react-router";
import { EventCalendarPage } from "./event-calendar/EventCalendarPage";
import { InteractiveHistoryPage } from "./interactive-history/InteractiveHistoryPage";

export function LivestreamPage() {
  return (
    <div className="p-5">
      <Switch>
        <Redirect exact from="/app/livestream" to="/app/livestream/interactive/history" />
        <Route path="/app/livestream/interactive/history" component={InteractiveHistoryPage} />
        <Route path="/app/livestream/event-calendar" component={EventCalendarPage} />
      </Switch>
    </div>
  )
}