import { Modal } from "@mantine/core";
import { LogDetail } from "./LogDetail";

interface LogDetailModalProps {
  log: any;
  opened: boolean;
  onClose: () => any;
}

export function LogDetailModal(props: LogDetailModalProps) {
  return (
    <Modal title="Chi tiết log" opened={props.opened} onClose={props.onClose} overflow="inside" size="50%">
      <div className="border-t pt-4">
        {props.log && <LogDetail log={props.log} />}
      </div>
    </Modal>
  )
}