import { AppActionDto } from "../dtos/store/AppActionDto";
import { AppGlobalStateDto } from "../dtos/store/AppGlobalStateDto";
import { ActionType } from "../enums/ActionType";
import { reduceAutoPublishGap } from "./reducer/reduceAutoPublishGap";
import { reduceItrNodes } from "./reducer/reduceItrNodes";
import { reduceSetCurrQuizGameItrId } from "./reducer/reduceSetCurrQuizGameItrId";
import { reduceNewScenario } from "./reducer/reduceNewScenario";
import { reduceOperateDisables } from "./reducer/reduceOperateDisables";
import { reduceUpdateItrNode } from "./reducer/reduceUpdateItrNode";
import { reduceUpdateScenario } from "./reducer/reduceUpdateScenario";
import { reduceSetEvent } from "./reducer/reduceSetEvent";
import { reduceSetLivestreamProfile } from "./reducer/reduceSetLivestreamProfile";
import { reduceCloseRoom } from "./reducer/reduceCloseRoom";
import { reduceRemoveScenario } from "./reducer/reduceRemoveScenario";
import { reduceSetHTVGoLivestream } from "./reducer/reduceSetHTVGoLivestream";

export function AppReducer(state: AppGlobalStateDto, action: AppActionDto): AppGlobalStateDto {
  switch (action.type) {
    case ActionType.SET_SESSION:
      return { ...state, session: { ...state.session, ...action.payload } };
    case ActionType.SET_SB_COLLAPSE:
      return { ...state, sbCollapse: action.payload  };
    case ActionType.NEW_SCENARIO:
      return reduceNewScenario(state, action.payload);
    case ActionType.UPDATE_SCENARIO:
      return reduceUpdateScenario(state, action.payload);
    case ActionType.REMOVE_SCENARIO:
      return reduceRemoveScenario(state);
    case ActionType.SET_ITR_NODES:
      return reduceItrNodes(state, action.payload);
    case ActionType.UPDATE_ITR_NODE:
      return reduceUpdateItrNode(state, action.payload);
    case ActionType.SET_CURR_POLL_ITR_ID:
      return { ...state, currPollItrId: action.payload };
    case ActionType.SET_CURR_QUIZ_GAME_ITR_ID:
      return reduceSetCurrQuizGameItrId(state, action.payload);
    case ActionType.SET_CURR_QUIZ_ITR_ID:
      return { ...state, currQuizItrId: action.payload };
    case ActionType.SET_CLOCK_TICK:
      return { ...state, clockTick: action.payload };
    case ActionType.SET_AUTO_PUBLISH:
      return { ...state, autoPublish: action.payload };
    case ActionType.SET_AUTO_PUBLISH_COUNTDOWN:
      return { ...state, autoPublishCountDown: action.payload };
    case ActionType.SET_AUTO_END_QUIZ_GAME:
      return { ...state, autoEndQuizGame: action.payload };
    case ActionType.SET_OPERATE_DISALES:
      return reduceOperateDisables(state, action.payload);
    case ActionType.SET_AUTO_PUBLISH_GAP:
      return reduceAutoPublishGap(state, action.payload);
    case ActionType.SET_CONNECTION_STATUS:
      return { ...state, connectionStatus: action.payload };
    case ActionType.SET_CURR_EVENT:
      return reduceSetEvent(state, action.payload);
    case ActionType.SET_CURR_HTVGO_LIVESTREAM:
      return reduceSetHTVGoLivestream(state, action.payload);
    case ActionType.SET_LIVESTREAM_PROFILE:
      return reduceSetLivestreamProfile(state, action.payload);
    case ActionType.SET_CURR_ROOM_ID:
      return { ...state, currRoomId: action.payload };
    case ActionType.SET_IS_ROOM_OPENED:
      return { ...state, isRoomOpened: action.payload };
    case ActionType.CLOSE_ROOM:
      return reduceCloseRoom(state);
    case ActionType.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
