import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from "react-beautiful-dnd";

interface AppVerticalDnDProps<T = any> {
  items: T[];
  itemId: (item: T) => string;
  itemRenderer: (item: T, index: number) => any;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => any;
  droppableId: string;
}

export function AppVerticalDnD<T = any>(props: AppVerticalDnDProps<T>) {
  return (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <Droppable droppableId={props.droppableId}>
        {(provided, snapshot) => (
          <div 
            {...provided.droppableProps} 
            ref={provided.innerRef}
          >
            {props.items.map((item, idx) => (
              <Draggable 
                key={props.itemId(item)}
                draggableId={props.itemId(item)}
                index={idx}
              >
                {(provided, snapshot) => (
                  <div 
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      userSelect: "none",
                      ...provided.draggableProps.style
                    }}
                  >
                    {props.itemRenderer(item, idx)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}