import { QuizDto } from "../../dtos/quiz/QuizDto";
import { FiCheckSquare } from 'react-icons/fi';
import dayjs from "dayjs";
import { SelectModeVi } from "../../enums/SelectMode";

interface QuizPreviewProps {
  quiz: QuizDto;
}

export function QuizPreview(props: QuizPreviewProps) {
  return (
    <div className="flex flex-wrap max-w-2xl">
      <div className="w-full text-sm font-bold">{props.quiz.content}</div>
      <div className="w-full text-gray-500 text-sm mt-2 mb-4">
        Kiểu câu hỏi: {SelectModeVi[props.quiz.selectMode]}
      </div>
      {props.quiz.answers.map(ans => (
        <div className="w-1/2 pr-2 mb-2">
          <div className={"border rounded p-3 text-sm flex items-center " + (ans.correct ? "border-green-400" : "")}>
            {ans.correct && <FiCheckSquare className="text-green-600 mr-1" />}
            {ans.content}
          </div>
        </div>
      ))}
      <div className="w-full flex mt-4 text-xs">
        {/* <Divider my="sm" /> */}
        <div className="mr-4"><b>Cập nhật lúc:</b> {dayjs(props.quiz.updateDt).format('DD/MM/YYYY HH:mm')}</div>
        <div><b>Tạo lúc:</b> {dayjs(props.quiz.createDt).format('DD/MM/YYYY HH:mm')}</div>
      </div>
    </div>
  )
}