import { Button, Popover, SharedPopperProps } from "@mantine/core";
import { useCallback, useState } from "react";

interface AppConfirmPopoverProps extends SharedPopperProps {
  title?: any;
  onConfirm?: () => any;
  onClose?: () => any;
  children: any;
}

export function AppConfirmPopover(props: AppConfirmPopoverProps) {
  const { title, onConfirm, onClose, children, ...popperProps } = props;
  const [opened, setOpened] = useState(false);

  const close = useCallback(() => {
    setOpened(false);
    onClose && onClose();
  }, [onClose]);

  return (
    <Popover
      opened={opened}
      onClose={close}
      target={children}
      position="bottom"
      {...popperProps}
    >
      <div>
        <div className="mb-4">{title}</div>
        <div className="flex items-center justify-end">
          <Button className="mr-2" variant="outline" color="gray" onClick={close}>Huỷ</Button>
          <Button onClick={onConfirm}>Đồng ý</Button>
        </div>
      </div>
    </Popover>
  )
}