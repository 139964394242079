import { IsNotEmpty, IsString, MaxLength, MinLength } from "class-validator";

export class ChangePasswordDto {
  @IsNotEmpty({ message: "Vui lòng nhập mật khẩu" })
  @IsString({ message: "Vui lòng nhập mật khẩu" })
  password: string;

  @MaxLength(100, { message: "Mật khẩu mới không được vượt quá 100 kí tự" })
  @MinLength(8, { message: "Mật khẩu mới phải có ít nhất 8 kí tự" })
  @IsString({ message: "Vui lòng nhập mật khẩu mới" })
  newPassword: string;
}