import { useCallback, useEffect, useMemo, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { MountedQuizDto } from "../dtos/mounted-quiz/MountedQuizDto";
import { QuizGameDto } from "../dtos/quiz-game/QuizGameDto";
import { QuizGameEditorTab } from "../enums/QuizGameEditorTab";
import { QuizMode } from "../enums/QuizMode";
import { MountedQuizService } from "../services/MountedQuizService";
import { NotificationUtils } from "../utils/NotificationUtils";
import { ICompKey, useCompKey } from "./useCompKey";
import { IQueryResponse, useQuery } from "./useQuery";

export interface IQuizGameEditor {
  tab: QuizGameEditorTab;
  setTab: React.Dispatch<React.SetStateAction<QuizGameEditorTab>>;
  quizGame?: QuizGameDto | null;
  setQuizGame: React.Dispatch<React.SetStateAction<QuizGameDto | null | undefined>>;
  updateMQ?: MountedQuizDto | null;
  setUpdateMQ: React.Dispatch<React.SetStateAction<MountedQuizDto | null>>;
  upsertMQFormKey: ICompKey;
  upsertQGFormKey: ICompKey;
  mountedQuizzes: MountedQuizDto[];
  setMountedQuizzes: React.Dispatch<React.SetStateAction<MountedQuizDto[]>>;
  findMQ: (quizId: number) => { index: number, mountedQuiz: MountedQuizDto } | null;
  handleSelectMQ: (mq: MountedQuizDto) => void;
  handleDeleteMQ: (mq: MountedQuizDto) => Promise<void>;
  handleUpdateMQ: (mq: MountedQuizDto) => void;
  handleReorderMQ: (dropResult: DropResult) => Promise<void>;
  handleUpsertQuizGame: (quizGame: QuizGameDto, update: boolean) => void;
  handleMountQuiz: (mq: MountedQuizDto, newQuiz?: boolean) => void;
  fetchMQ?: () => Promise<MountedQuizDto[]>;
  validateQuizMode: (quizMode: QuizMode) => MountedQuizDto[];
  fethMQResponse: IQueryResponse<MountedQuizDto[]>;
  reordering: boolean;
  nextNumOrder: number;
}

interface UseQuizGameEditorProps {
  quizGame?: QuizGameDto | null;
  onMount?: (mq: MountedQuizDto) => any;
  onDeleteMQ?: (mq: MountedQuizDto) => any;
  onUpdateMQ?: (mq: MountedQuizDto) => any;
  onUpsertQuizGame?: (quizGame: QuizGameDto, update: boolean) => any;
  onReorderMQ?: (src: MountedQuizDto, dest: MountedQuizDto) => any;
}

export function useQuizGameEdtior(props: UseQuizGameEditorProps): IQuizGameEditor {
  const { 
    quizGame: propsQuizGame, onMount, onDeleteMQ, 
    onUpdateMQ, onUpsertQuizGame, onReorderMQ 
  } = props;

  const [tab, setTab] = useState(QuizGameEditorTab.UPDATE_QUIZ_GAME);
  const [quizGame, setQuizGame] = useState(propsQuizGame);
  const [updateMQ, setUpdateMQ] = useState<MountedQuizDto | null>(null);
  const [mountedQuizzes, setMountedQuizzes] = useState<MountedQuizDto[]>([]);
  const [reordering, setReordering] = useState(false);
  
  const upsertMQFormKey = useCompKey();
  const upsertQGFormKey = useCompKey();

  const quizGameId = quizGame?.id;
  const fetchMQ = useMemo(() => {
    return quizGameId ? () => MountedQuizService.findByQuizGameId(quizGameId) : undefined;
  }, [quizGameId]);

  const fethMQResponse = useQuery(fetchMQ);
  useEffect(() => {
    if (!fethMQResponse.loading) {
      setMountedQuizzes(fethMQResponse.data || []);
    }
  }, [fethMQResponse]);

  useEffect(() => {
    setQuizGame(propsQuizGame);
  }, [propsQuizGame]);

  const findMQ = useCallback((id: number) => {
    const index = mountedQuizzes.findIndex(mq => mq.id === id);
    if (index >= 0) {
      return { index, mountedQuiz: mountedQuizzes[index] };
    }
    return null;
  }, [mountedQuizzes]);

  const handleDeleteMQ = useCallback(async (mq: MountedQuizDto) => {
    const existsMQ = findMQ(mq.id);
    if (!!existsMQ) {
      const success = await MountedQuizService.toggle(mq.id);
      if (success) {
        NotificationUtils.success({ message: 'Xoá câu hỏi thành công' });
        setMountedQuizzes(prev => prev.filter(item => item.id !== mq.id));

        if (updateMQ?.id === mq.id) {
          setTab(QuizGameEditorTab.UPDATE_QUIZ_GAME);
          setUpdateMQ(null);  
        }

        onDeleteMQ && onDeleteMQ(mq);
      }
    }
  }, [findMQ, onDeleteMQ, updateMQ]);

  const handleSelectMQ = useCallback((mq: MountedQuizDto) => {
    setTab(QuizGameEditorTab.UPDATE_MOUNTED_QUIZ);
    setUpdateMQ(mq);
    upsertMQFormKey.renew();
  }, [upsertMQFormKey]);

  const handleUpdateMQ = useCallback(async (mq: MountedQuizDto) => {
    const existsMQ = findMQ(mq.id);
    if (!!existsMQ) {
      setMountedQuizzes(prev => {
        prev[existsMQ.index] = mq;
        return [...prev];
      });

      onUpdateMQ && onUpdateMQ(mq);
    }
  }, [findMQ, onUpdateMQ]);

  const handleUpsertQuizGame = useCallback((newQuizGame: QuizGameDto, update: boolean) => {
    setQuizGame(newQuizGame);
    onUpsertQuizGame && onUpsertQuizGame(newQuizGame, update);
  }, [onUpsertQuizGame]);

  const handleReorderMQ = useCallback(async (dropResult: DropResult) => {
    if (dropResult.destination) {
      const src = mountedQuizzes[dropResult.source.index];
      const dest = mountedQuizzes[dropResult.destination.index];
      if (src.id !== dest.id) {
        setReordering(true);
        const requestDto = {
          updates: [
            { id: src.id, numOrder: dest.numOrder },
            { id: dest.id, numOrder: src.numOrder }
          ]
        };
  
        const success = await MountedQuizService.updateNumOrder(requestDto);
        if (success) {
          const srcNumOrder = src.numOrder;
          src.numOrder = dest.numOrder;
          dest.numOrder = srcNumOrder;
          mountedQuizzes[dropResult.source.index] = dest;
          mountedQuizzes[dropResult.destination.index] = src;
          setMountedQuizzes([...mountedQuizzes]);
          onReorderMQ && onReorderMQ(src, dest);
        }
        setReordering(false);
      }
    }
  }, [mountedQuizzes, onReorderMQ]);

  const handleMountQuiz = useCallback(async (mq: MountedQuizDto, newQuiz?: boolean) => {
    const existsMQ = findMQ(mq.id);
    if (!!existsMQ) {
      setMountedQuizzes(prev => {
        prev[existsMQ.index] = mq;
        return [...prev];
      });

      onUpdateMQ && onUpdateMQ(mq);
    } else {
      setMountedQuizzes(prev => [...prev, mq]);
      onMount && onMount(mq);
    }

    if (newQuiz) {
      handleSelectMQ(mq);
    }
  }, [onMount, onUpdateMQ, findMQ, handleSelectMQ]);

  const validateQuizMode = useCallback((quizMode: QuizMode) => {
    if (quizGame?.quizMode === QuizMode.FOOTBALL && quizMode !== quizGame.quizMode) {
      const invalidQuizzes: MountedQuizDto[] = [];
      mountedQuizzes.forEach((mq) => {
        const isInvalid = !mq.quiz || mq.quiz.answers.length < 2 || mq.quiz.correctAnswerCount === 0;
        if (isInvalid) {
          invalidQuizzes.push(mq);
        }
      });

      return invalidQuizzes;
    }
    return [];
  }, [quizGame, mountedQuizzes]);

  return useMemo(() => ({
    tab,
    setTab,
    quizGame,
    setQuizGame,
    updateMQ,
    setUpdateMQ,
    upsertMQFormKey,
    upsertQGFormKey,
    mountedQuizzes,
    setMountedQuizzes,
    findMQ,
    handleSelectMQ,
    handleDeleteMQ,
    handleUpdateMQ,
    handleReorderMQ,
    handleUpsertQuizGame,
    handleMountQuiz,
    fetchMQ,
    fethMQResponse,
    nextNumOrder: mountedQuizzes.length + 1,
    reordering,
    validateQuizMode
  }), [
    tab,
    setTab,
    quizGame,
    setQuizGame,
    updateMQ,
    setUpdateMQ,
    upsertMQFormKey,
    upsertQGFormKey,
    mountedQuizzes,
    setMountedQuizzes,
    findMQ,
    handleSelectMQ,
    handleDeleteMQ,
    handleUpdateMQ,
    handleReorderMQ,
    handleUpsertQuizGame,
    handleMountQuiz,
    fetchMQ,
    fethMQResponse,
    reordering,
    validateQuizMode
  ]);
}