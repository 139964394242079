import { Loader, Select, SelectProps } from "@mantine/core";
import { useMemo } from "react";
import { FiBox } from "react-icons/fi";
import { AppDto } from "../../../dtos/app/AppDto";

interface AppSelectProps extends SelectProps {
  data: any;
  loading?: boolean;
}

export function AppSelect(props: AppSelectProps) {
  const { data: apps, loading, ...selectProps } = props;
  const data = useMemo(() => {
    if (apps) {
      return apps.map((app: AppDto) => ({ label: app.name, value: String(app.id) }));
    }
    return [];
  }, [apps]);

  return (
    <Select 
      label="App"
      placeholder="App..."
      icon={loading ? <Loader size="sm" /> : <FiBox />}
      readOnly={loading}
      {...selectProps}
      data={data}
    />
  )
}