import { useCallback, useMemo } from "react";
import { QuizDto } from "../../../dtos/quiz/QuizDto";
import { useQuery } from "../../../hooks/useQuery";
import { QuizResultService } from "../../../services/QuizResultService";
import { AppInteractivePreview } from "../../AppInteractivePreview";
import { AppLoading } from '../../AppLoading';

interface QuizResultProps {
  quiz: QuizDto;
  numOrder?: number;
}

export function QuizResult(props: QuizResultProps) {
  const { quiz, numOrder } = props;
  const { id: quizId } = quiz;

  const fetchQuizResult = useCallback(() => {
    return QuizResultService.findByQuizId(quizId);
  }, [quizId]);

  const quizResultResp = useQuery(fetchQuizResult);

  const previewProps: any = useMemo(() => {
    if (quizResultResp.data) {
      return {
        title: `Câu hỏi ${numOrder || ''}`,
        content: quiz.content,
        selectMode: quiz.selectMode,
        options: quiz.answers.map(ans => ans.content),
        corrects: quiz.answers.map(ans => ans.correct),
        selections: quiz.answers.map(ans => {
          return quizResultResp.data?.find(rs => rs.quizAnswerId === ans.id)?.selections || 0;
        })
      }
    }
    return {};
  }, [quiz, quizResultResp, numOrder]);

  if (!quizResultResp.ready) {
    return <AppLoading />
  }

  return (
    <AppInteractivePreview {...previewProps}/>
  );
}