import { Button, TextInput } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useMemo, useState } from "react"
import { ResponseCode } from "../../config/ResponseConfig";
import { ProjectDto } from "../../dtos/project/ProjectDto";
import { UpsertProjectDto } from "../../dtos/project/UpsertProjectDto";
import { useAppFormik } from "../../hooks/useAppFormik";
import { ProjectService } from "../../services/ProjectService";
import { NotificationUtils } from "../../utils/NotificationUtils";

interface UpsertProjectFormProps {
  onSuccess?: (project: ProjectDto) => any;
  project?: ProjectDto | null;
}

export function UpsertProjectForm(props: UpsertProjectFormProps) {
  const { onSuccess, project: propsProject } = props;
  const [project, setProject] = useState(propsProject);

  const initialValues = useMemo(() => {
    const upsertDto = new UpsertProjectDto();
    if (project) {
      upsertDto.name = project.name;
    }
    return upsertDto;
  }, [project]);

  const submit = useCallback(async (values: UpsertProjectDto, actions: FormikHelpers<UpsertProjectDto>) => {
    let response;
    if (project) {
      response = await ProjectService.update(project.id, values);
    } else {
      const isNameExisted = await ProjectService.checkName(values.name);
      if (isNameExisted) {
        actions.setFieldError('name', 'Tên dự án đã tồn tại, vui lòng chọn tên khác');
        actions.setSubmitting(false);
        return;
      }
      
      response = await ProjectService.create(values);
    }

    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: "Lưu dự án thành công" });
      setProject(response.body);

      onSuccess && onSuccess(response.body);
    }

    actions.setSubmitting(false);
  }, [onSuccess, project]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  return (
    <form onSubmit={formik.handleSubmit} className="relative">
      <TextInput
        required
        label="Tên dự án"
        name="name"
        placeholder="Tên dự án..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={formik.touched.name && formik.errors.name}
      />

      <Button fullWidth type="submit" className="mt-4" loading={formik.isSubmitting}>
        {project ? "Cập nhật" : "Thêm"}
      </Button>
    </form>
  )
}