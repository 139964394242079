import dayjs from "dayjs";

export class FormatUtils {
  
  static dt(dt?: any) {
    return dt ? dayjs(dt).format('DD/MM/YYYY HH:mm:ss') : '';
  }

  static dt2(dt?: any) {
    return dt ? dayjs(dt).format('DD/MM/YYYY HH:mm') : '';
  }

  static date(date?: any) {
    return date ? dayjs(date).format('DD/MM/YYYY') : '';
  }

  static time(time?: any) {
    return time ? dayjs(time).format('HH:mm:ss') : '';
  }

  static currency(num: number) {
    return isNaN(num) ? '' : (''+num).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}