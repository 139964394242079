import { Tab, Tabs } from "@mantine/core";
import { useState } from "react";
import { OperateInteractive } from "./interactive/OperateInteractive";
import { OperateComment } from "./comment/OperateComment";
import { OperateStreamSource } from "./stream/OperateStreamSource";

export function OperateLiveStream() {
  const [tab, setTab] = useState(1);
  return (
    <>
      <div className="p-4">
        <Tabs grow active={tab} onTabChange={setTab}>
          <Tab label="T.tin chung"></Tab>
          <Tab label="Tương tác"></Tab>
          <Tab label="Bình luận"></Tab>
          <Tab label="Stream">
            <OperateStreamSource />
          </Tab>
        </Tabs>
      </div>

      <div className={(tab === 1 || tab === 2) ? "" : "hidden"} style={{ height: 'calc(100% - 72px)' }}>
        <div className={tab === 1 ? "block h-full" : "hidden"}>
          <OperateInteractive />
        </div>

        <div className={tab === 2 ? 'block h-full' : 'hidden'}>
          <OperateComment />
        </div>
      </div>
    </>
  )
}