import { Badge } from "@mantine/core";

interface AppDeviceFrameProps {
  device: string;
  scale: number;
  children: any;
}

export const deviceFrames = {
  "iPhone 5/SE": { width: 320, height: 568 },
  "iPhone X": { width: 375, height: 812 },
  "Pixel 2 XL": { width: 411, height: 823 },
  "Galaxy 5": { width: 360, height: 640 },
};

export function AppDeviceFrame(props: AppDeviceFrameProps) {
  const device = (deviceFrames as any)[props.device];
  const width = device.width;
  const height = device.height;
  return (
    <div 
      className="flex rounded-lg border-4 border-gray-900 relative" 
      style={{ width, height, transform: `scale(${props.scale})`, transformOrigin: 'top center' }}
    >
      <div className="absolute top-4 left-0 right-0 flex justify-center" style={{ zIndex: 2 }}>
        <Badge color="gray">{width} x {height}</Badge>
      </div>
      {props.children}
    </div>
  )
}