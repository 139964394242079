import { Button, Modal } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { PageDto } from "../../../dtos/common/PageDto";
import { InteractionDto } from "../../../dtos/interaction/InteractionDto";
import { QuizDto } from "../../../dtos/quiz/QuizDto";
import { InteractionType } from "../../../enums/InteractionType";
import { PublishStatusDisplay } from "../../../enums/PublishStatus";
import { QuizMode } from "../../../enums/QuizMode";
import { InteractionService } from "../../../services/InteractionService";
import { FormatUtils } from "../../../utils/FormatUtils";
import { AppTable, AppTableColumn } from "../../AppTable";
import { UpdateQuizCorrectAnswersModal } from "../../quiz/UpdateQuizCorrectAnswersModal";
import { QuizResult } from "./QuizResult";

interface QuizResultListProps {
  quizGameId: number;
  quizMode: QuizMode;
}

export function QuizResultList(props: QuizResultListProps) {
  const { quizGameId, quizMode } = props;
  const [detail, setDetail] = useState<InteractionDto | undefined>();
  const [quizzes, setQuizzes] = useState<QuizDto[]>([]);
  const [updateCorrectAnswers, setUpdateCorrectAnswers] = useState(false);

  const columns = useMemo(() => {
    return [
      { title: 'STT', render: itr => itr.mountedQuiz?.numOrder },
      { title: 'Nội dung', render: itr => itr.mountedQuiz?.quiz?.content },
      { title: 'Trạng thái', render: itr => PublishStatusDisplay[itr.publishStatus] },
      { title: 'Bắt đầu', render: itr => <div className="w-40">{FormatUtils.dt(itr.publishDt)}</div> },
      { title: 'Kết thúc', render: itr => <div className="w-40">{FormatUtils.dt(itr.finishDt)}</div> },
      {
        title: 'Chi tiết',
        render: itr => (
          <Button compact onClick={() => setDetail(itr)}>Chi tiết</Button>
        )
      }
    ] as AppTableColumn<InteractionDto>[];
  }, []);

  const fetchData = useCallback(async () => {
    const itrList = await InteractionService.find({
      mqQuizGameId: quizGameId,
      interactionTypes: [InteractionType.QUIZ]
    });

    setQuizzes(itrList.map(itr => itr.mountedQuiz?.quiz as QuizDto));

    return PageDto.onePage(itrList);
  }, [quizGameId]);

  const actions = useMemo(() => {
    if (quizMode === QuizMode.FOOTBALL) {
      return <Button variant="gradient" onClick={() => setUpdateCorrectAnswers(true)}>Cập nhật đáp án</Button>
    }
    return null;
  }, [quizMode]);

  return (
    <>
      <Modal opened={!!detail} title="Kết quả câu hỏi" onClose={() => setDetail(undefined)}>
        {detail && <QuizResult numOrder={detail.numOrder} quiz={detail.mountedQuiz?.quiz as QuizDto} />}
      </Modal>

      <UpdateQuizCorrectAnswersModal 
        opened={updateCorrectAnswers}
        onClose={() => setUpdateCorrectAnswers(false)}
        quizGameId={quizGameId}
        quizzes={quizzes}
      />

      <AppTable
        columns={columns}
        fetchData={fetchData}
        actions={actions}
      />
    </>
  )
}