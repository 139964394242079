import { Breadcrumbs } from "@mantine/core";
import { Link } from "react-router-dom";
import { AiOutlineHome } from 'react-icons/ai';

interface BreadcrumbItem {
  label: any;
  link: string;
}

interface AppBreadcrumbsProps {
  items: BreadcrumbItem[];
  className?: string;
}

export function AppBreadcrumbs(props: AppBreadcrumbsProps) {
  return (
    <div className={props.className || "mb-6"}>
      <Breadcrumbs>
        <Link to="/"><AiOutlineHome className="text-blue-600 hover:text-blue-400" /></Link>
        {props.items.map((item) => (
          <Link key={item.link} to={item.link}>
            <span className="text-blue-600 hover:text-blue-400 cursor-pointer text-sm">{item.label}</span>
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  )  
}