export class PageDto<T> {
  number: number;
  size: number;
  totalElements: number;
  content: T[];

  static onePage<T>(content: T[]) {
    const pageDto = new PageDto<T>();
    pageDto.number = 0;
    pageDto.size = content.length;
    pageDto.totalElements = content.length;
    pageDto.content = content;
    return pageDto;
  }
}