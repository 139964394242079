import { useCallback, useMemo } from "react";
import { useRouteMatch } from "react-router";
import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { AppLoading } from "../../../components/AppLoading";
import { InteractiveHistoryList } from "../../../components/livestream/interactive-history/InteractiveHistoryList";
import { useQuery } from "../../../hooks/useQuery";
import { EventCalendarService } from "../../../services/EventCalendarService";

export function EventCalendarDetailPage() {
  const match = useRouteMatch();
  const { id } = match.params as any;

  const fetchEventCalendar = useCallback(() => {
    return EventCalendarService.findById(id);
  }, [id]);

  const ecResponse = useQuery(fetchEventCalendar);
  const breadcrumbs = useMemo(() => {
    return [
      { label: 'Livestream', link: '/app/livestream' },
      { label: 'Lịch sự kiện', link: '/app/livestream/event-calendar/list' },
      { label: 'Chi tiết', link: `/app/livestream/event-calendar/${id}` }
    ];
  }, [id]);

  if (!ecResponse.ready || !ecResponse.data) {
    return <AppLoading />
  }

  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <InteractiveHistoryList roomId={ecResponse.data.roomId} />
    </>
  )
}