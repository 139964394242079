import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { SystemConfigDto } from "../dtos/system-config/SystemConfigDto";
import { UpsertSystemConfigDto } from "../dtos/system-config/UpsertSystemConfigDto";
import { HttpUtils } from "../utils/HttpUtils";

export class SystemConfigService {

  static async findAll() {
    return (await HttpUtils.get<SystemConfigDto[]>(Apis.systemCofnig.findAll)).body;
  }

  static async findByName(name: string) {
    return (await HttpUtils.get<SystemConfigDto>(Apis.systemCofnig.findByName, { name })).body;
  }

  static upsert(dto: UpsertSystemConfigDto) {
    return HttpUtils.post<SystemConfigDto>(Apis.systemCofnig.upsert, dto);
  }

  static async delete(id: number) {
    return (await HttpUtils.delete(Apis.systemCofnig.delete(id))).code === ResponseCode.OK.code;
  }
}