import { IsNotEmpty, IsNumber, IsOptional, IsString, Max, MaxLength, Min } from "class-validator";
import { IsNotBlank } from "../../decorators/validations/IsNotBlankDecorator";
import { QuizMode } from "../../enums/QuizMode";

export class UpsertQuizGameDto {
  @MaxLength(50, { message: 'Tên bộ câu hỏi chỉ cho phép tối đa 50 kí tự' })
  @IsNotBlank({ message: 'Tên bộ câu hỏi không hợp lệ' })
  @IsNotEmpty({ message: 'Vui lòng nhập tên bộ câu hỏi' })
  @IsString({ message: 'Vui lòng nhập tên bộ câu hỏi' })
  name: string;

  @Min(1, { message: "Phải có ít nhất 1 người chiến thắng" })
  @IsNumber({}, { message: "Vui lòng nhập số người chiến thắng" })
  winRange: number = 20;

  @Min(0, { message: 'Delay phải lớn hơn hoặc bằng 0' })
  @Max(600, { message: 'Delay không được quá 600' })
  @IsNumber({}, { message: 'Vui lòng nhập delay' })
  delay: number = 0;

  @IsString({ message: 'Vui chọn chế độ chơi' })
  quizMode: QuizMode;

  @IsOptional()
  template?: boolean;
}