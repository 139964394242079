import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { ChangeLogList } from "../../../components/system/changelog/ChangeLogList";

const breadcrumbs = [
  { label: 'Hệ thống', link: '/app/system' },
  { label: 'Change log', link: '/app/system/changelog/list' }
];

export function ChangeLogListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <ChangeLogList />
    </>
  )
}