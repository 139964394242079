import { Button } from "@mantine/core";
import { AppConfirmPopover } from "./AppConfirmPopover";

interface AppActionButtonsProps {
  onEdit?: () => any;
  onDelete?: () => any;
  editBtnLabel?: any;
  deleteBtnLabel?: any;
}

export function AppActionButtons(props: AppActionButtonsProps) {
  return (
    <div className="flex items-center">
      {props.onEdit && <Button compact className="mr-2" onClick={props.onEdit}>{props.editBtnLabel || 'Sửa'}</Button>}
      {props.onDelete && (
        <AppConfirmPopover title="Bạn có chắc muốn xoá dòng này không?" onConfirm={props.onDelete}>
          <Button compact color="red">{props.deleteBtnLabel || 'Xoá'}</Button>
        </AppConfirmPopover>
      )}
    </div>
  )
}