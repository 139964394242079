import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { AppDto } from "../dtos/app/AppDto";
import { MiniAppDto } from "../dtos/app/MiniAppDto";
import { QueryAppDto } from "../dtos/app/QueryAppDto";
import { UpsertAppDto } from "../dtos/app/UpsertAppDto";
import { PageDto } from "../dtos/common/PageDto";
import { HttpUtils } from "../utils/HttpUtils";

export class AppService {

  static async findAll() {
    return (await HttpUtils.get<MiniAppDto[]>(Apis.app.findAll)).body;
  }

  static async query(queryDto: QueryAppDto) {
    return (await HttpUtils.post<PageDto<AppDto>>(Apis.app.query, queryDto)).body;
  }

  static async checkName(name: string) {
    return (await HttpUtils.get(Apis.app.checkName, { name })).body;
  }

  static create(createDto: UpsertAppDto) {
    return HttpUtils.post(Apis.app.create, createDto);
  }

  static update(id: number, updateDto: UpsertAppDto) {
    return HttpUtils.put(Apis.app.update(id), updateDto);
  }

  static regenKeyPair(id: number) {
    return HttpUtils.post(Apis.app.regenKeyPair(id));
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.app.toggle(id))).code === ResponseCode.OK.code;
  }
}