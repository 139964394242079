import { QuizDto } from "../../dtos/quiz/QuizDto";
import { AppInteractivePreview } from "../AppInteractivePreview";

interface QuizLogDetailProps {
  log: any;
}

export function QuizLogDetail(props: QuizLogDetailProps) {
  const { log } = props;
  const quiz: QuizDto = log.quiz;
  return (
    <>
      <p className="mt-0 mb-4 text-sm">Trả lời ở giây thứ: <b>{log.at_second}</b></p>
      <AppInteractivePreview
        title={`Câu hỏi thứ ${(quiz as any).numOrder}`}
        content={quiz.content}
        selectMode={quiz.selectMode}
        options={quiz.answers.map(ans => ans.content)}
        corrects={quiz.answers.map(ans => ans.correct)}
        checked={quiz.answers.map(ans => log.answerIds.includes(ans.id))}
      />
    </>
  )
}