import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { ClientPageDto } from "../dtos/client/ClientPageDto";
import { PagingDto } from "../dtos/common/PagingDto";
import { OpenRoomDto } from "../dtos/room/OpenRoomDto";
import { RoomDto } from "../dtos/room/RoomDto";
import { ShowNotificationDto } from "../dtos/room/ShowNotificationDto";
import { HttpUtils } from "../utils/HttpUtils";

export class RoomService {

  static async isRoomOpened(roomId: string) {
    return (await HttpUtils.get<boolean>(Apis.room.isRoomOpened(roomId))).body;
  }

  static async getRoomInfo(roomId: string) {
    return (await HttpUtils.get<RoomDto>(Apis.room.getRoomInfo(roomId))).body;
  }

  static async paginateClients(roomId: string, pagingDto: PagingDto) {
    return (await HttpUtils.get<ClientPageDto>(Apis.room.paginateClients(roomId), pagingDto)).body;
  }

  static async openRoom(dto: OpenRoomDto) {
    return (await HttpUtils.post(Apis.room.openRoom, dto)).code === ResponseCode.OK.code;
  }

  static async closeRoom(roomId: string) {
    return (await HttpUtils.post(Apis.room.closeRoom(roomId))).code === ResponseCode.OK.code;
  }

  static async updateMaxConnection(roomId: string, maxConnection: number) {
    return (await HttpUtils.put(Apis.room.updateMaxConnection(roomId), { maxConnection })).code === ResponseCode.OK.code;
  }

  static async showNotification(roomId: string, dto: ShowNotificationDto) {
    return (await HttpUtils.post(Apis.room.showNotification(roomId), dto)).code === ResponseCode.OK.code;
  }

  static async hideNotification(roomId: string) {
    return (await HttpUtils.post(Apis.room.hideNotification(roomId))).code === ResponseCode.OK.code;
  }
}