import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { OperateService } from "../../services/OperateService";

export function reduceSetCurrQuizGameItrId(state: AppGlobalStateDto, quizGameItrId: number): AppGlobalStateDto {
  const { itrNodeMap } = state;
  const roomId = state.currScenario?.roomId;
  const quizGameItr = itrNodeMap[quizGameItrId];
  if (!!quizGameItr && !!quizGameItr.quizGame && roomId) {
    OperateService.setLeaderboardSize(roomId, quizGameItr.quizGame.winRange);
  }
  return {
    ...state,
    currQuizGameItrId: quizGameItrId
  };
}