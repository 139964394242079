import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { QuizListPage } from "./QuizListPage";

export function QuizPage(props: RouteComponentProps) {
  return (
    <>
      <Switch>
        <Redirect exact from="/app/interactive/quiz" to="/app/interactive/quiz/list" />
        <Route path="/app/interactive/quiz/list" component={QuizListPage} />
      </Switch>
    </>
  )
}