import { UploadFileFolder } from "../../enums/UploadFileFolder";
import { UploadFileDto } from "./UploadFileDto";
import path from 'path';
import { RandomUtils } from "../../utils/RandomUtils";

export class UploadFileRequestDto {
  file: File;
  folder: UploadFileFolder;
  filename?: string;
  displayName?: string;
  width?: number = 0;
  height?: number = 0;
  size?: number = 0;
  persist?: boolean = false;

  toFormData() {
    if (!this.filename) {
      this.filename = `${RandomUtils.uuid()}${path.extname(this.file.name)}`;
    }
    
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('folder', this.folder.toString());
    formData.append('filename', this.filename);
    formData.append('displayName', this.displayName || path.basename(this.filename));
    formData.append('persist', (this.persist || false).toString());
    formData.append('width', (this.width || 0).toString());
    formData.append('height', (this.height || 0).toString());
    formData.append('size', (this.size || 0).toString());
    return formData;
  }

  static ofUploadFileDto(uf: UploadFileDto, persist: boolean = false) {
    const dto = new UploadFileRequestDto();
    if (uf.file) {
      dto.file = uf.file;
    }
    dto.folder = uf.folder as any;
    dto.filename = uf.filename;
    dto.displayName = uf.displayName;
    dto.width = uf.width;
    dto.height = uf.height;
    dto.size = uf.size;
    dto.persist = persist;
    return dto;
  }
}