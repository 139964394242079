import { AppConfig } from "../../config/AppConfig";
import { ConnectionStatus } from "../../enums/ConnectionStatus";
import { OperateService } from "../../services/OperateService";
import { Session } from "../auth/Session";
import { EventDto } from "../event/EventDto";
import { HTVGoLivestreamDetailDto } from "../htvgo/livestream/HTVGoLivestreamDetailDto";
import { InteractionNodeDto } from "../interaction/InteractionNodeDto";
import { LivestreamProfileDto } from "../operate/LivestreamProfileDto";
import { ScenarioDto } from "../scenario/ScenarioDto";

export class AppGlobalStateDto {
  session: Session = new Session();
  sbCollapse: boolean = false;
  currRoomId?: string = OperateService.getCurrRoomId();
  currEvent?: EventDto;
  currHTVGoLivestream?: HTVGoLivestreamDetailDto;
  currScenario?: ScenarioDto;
  itrNodes: InteractionNodeDto[] = [];
  itrNodeMap: {[key: number]: InteractionNodeDto} = {};
  currQuizGameItrId?: number;
  currQuizItrId?: number;
  currPollItrId?: number;
  clockTick?: number;
  autoPublish?: boolean;
  autoEndQuizGame?: boolean;
  autoPublishCountDown?: number;
  autoPublishGap: number = AppConfig.realtime.MIN_AUTO_PUBLISH_GAP;
  operateDisables: any = {};
  connectionStatus: ConnectionStatus = ConnectionStatus.IDLE;
  livestreamProfile?: LivestreamProfileDto;
  isRoomOpened?: boolean = false;
}