import { ActionIcon, Avatar } from "@mantine/core";
import { useCallback } from "react";
import { RealtimeCommentDto } from "../../../dtos/comment/RealtimeCommentDto";
import { CommentService } from "../../../services/CommentService";
import { NotificationUtils } from "../../../utils/NotificationUtils";
import { MdBlock } from 'react-icons/md';
import { AiFillPushpin } from 'react-icons/ai';
import dayjs from "dayjs";

interface CommentItemProps {
  comment: RealtimeCommentDto;
  isBlock?: boolean;
  isPin?: boolean;
}

export function CommentItem(props: CommentItemProps) {
  const { comment } = props;
  const { roomId, commentId } = comment;

  const blockComment = useCallback(async () => {
    const success = await CommentService.block(roomId, commentId);
    if (success) {
      NotificationUtils.success({ message: 'Khoá bình luận thành công' });
    }
  }, [roomId, commentId]);

  const pinComment = useCallback(async () => {
    const success = await CommentService.pin(roomId, commentId);
    if (success) {
      NotificationUtils.success({ message: 'Ghim bình luận thành công' });
    }
  }, [roomId, commentId]);

  const unpinComment = useCallback(async () => {
    const success = await CommentService.unpin(roomId);
    if (success) {
      NotificationUtils.success({ message: 'Bỏ ghim bình luận thành công' });
    }
  }, [roomId]);

  const renderTime = useCallback((ts: number) => {
    const d = dayjs(ts);
    if (d.isSame(dayjs(), 'date')) {
      return d.format('HH:mm');
    }
    return d.format('DD/MM HH:mm');
  }, []);

  return (
    <div className={"flex items-start px-2 py-1 " + (props.isPin ? '' : 'border rounded-lg hover:shadow hover:bg-gray-50')}>
      <Avatar radius="xl" color="blue" size="md" className="mr-2" src={comment.clientInfo.avatar} alt={comment.clientInfo.name} />
      <div className="w-full flex items-center">
        <div className="text-sm w-full">
          <div className="flex items-center">
            <span className={"font-medium " + (comment.clientInfo.operator ? "text-red-600" : "")}>{comment.clientInfo.name}</span>
            <span className="ml-1 text-gray-400 text-xs">· {renderTime(comment.timestamp)}</span>
          </div>
          <div className="text-gray-600">{comment.content}</div>
        </div>
        {!props.isBlock && (
          <div className="ml-auto flex items-center">
            {props.isPin ? (
              <ActionIcon variant="filled" color="red" onClick={unpinComment}>
                <AiFillPushpin className="text-xl" />
              </ActionIcon>
            ) : (
              <ActionIcon variant="filled" color="blue" onClick={pinComment}>
                <AiFillPushpin className="text-xl" />
              </ActionIcon>
            )}
            {!props.isPin && (
              <ActionIcon variant="filled" color="red" className="ml-2" onClick={blockComment}>
                <MdBlock className="text-xl" />
              </ActionIcon>
            )}
          </div>
        )}
      </div>
    </div>
  )
}