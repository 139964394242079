import { Button, Divider } from "@mantine/core";
import { useCallback, useRef, useState } from "react";
import { ResponseCode } from "../../config/ResponseConfig";
import { ModifyMountedQuizDto } from "../../dtos/mounted-quiz/ModifyMountedQuizDto";
import { MountedQuizDto } from "../../dtos/mounted-quiz/MountedQuizDto";
import { QuizDto } from "../../dtos/quiz/QuizDto";
import { QuizMode } from "../../enums/QuizMode";
import { useCompKey } from "../../hooks/useCompKey";
import { MountedQuizService } from "../../services/MountedQuizService";
import { UpsertQuizForm } from "../quiz/UpsertQuizForm";
import { UpsertMountedQuizForm } from "./UpsertMountedQuizForm";

interface MountAndUpsertQuizFormProps {
  zIndex: number;
  quizGameId: number;
  quizMode: QuizMode;
  onSuccess: (mq: MountedQuizDto, newQuiz?: boolean) => any;
  mountedQuiz?: MountedQuizDto | null;
  numOrder: number;
  template: boolean;
}

export function MountAndUpsertQuizForm(props: MountAndUpsertQuizFormProps) {
  const { quizGameId, onSuccess, numOrder, template, mountedQuiz: propsMountedQuiz } = props;
  const [submitting, setSubmitting] = useState(false);
  const [mountedQuiz, setMountedQuiz] = useState(propsMountedQuiz);

  const upsertMQKey = useCompKey();
  const upsertMQRef = useRef<any>(null);
  const upsertQuizRef = useRef<any>(null);
  const modifyDtoRef = useRef<ModifyMountedQuizDto>(new ModifyMountedQuizDto());

  const submit = useCallback(() => {
    setSubmitting(true);
    upsertMQRef.current.formik.handleSubmit();
  }, []);

  const handleValidatedMQ = useCallback((modifyDto: ModifyMountedQuizDto) => {
    modifyDtoRef.current.maxScore = modifyDto.maxScore;
    modifyDtoRef.current.scoreCondition = modifyDto.scoreCondition;
    upsertQuizRef.current.formik.handleSubmit();
  }, []);

  const handleCreateQuizSuccess = useCallback(async (quiz: QuizDto) => {
    let response;
    if (mountedQuiz) {
      response = await MountedQuizService.update(mountedQuiz.id, modifyDtoRef.current);
    } else {
      response = await MountedQuizService.create({
        ...modifyDtoRef.current,
        template,
        numOrder,
        quizGameId,
        quizId: quiz.id,
      });
    }

    if (response.code === ResponseCode.OK.code) {
      setMountedQuiz(response.body);
      upsertMQKey.renew();

      onSuccess && onSuccess(response.body, true);
    }

    setSubmitting(false);
  }, [
    quizGameId, onSuccess, numOrder, mountedQuiz, 
    template, upsertMQKey
  ]);

  const handleCreateQuizError = useCallback(() => {
    setSubmitting(false);
  }, []);

  return (
    <>
      <UpsertMountedQuizForm
        key={upsertMQKey.key}
        quizMode={props.quizMode}
        mountedQuiz={mountedQuiz}
        onValidated={handleValidatedMQ}
        elementRef={upsertMQRef}
        vertical={false}
      />
      <UpsertQuizForm
        quizMode={props.quizMode}
        preview={true}
        quiz={mountedQuiz?.quiz}
        template={template}
        onSuccess={handleCreateQuizSuccess}
        onSubmitError={handleCreateQuizError}
        submitable={false}
        elementRef={upsertQuizRef}
      />
      <Divider my="lg" />
      <Button variant="gradient" onClick={submit} loading={submitting}>
        Lưu lại
      </Button>
    </>
  )
}