import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { ProjectDetailPage } from "./ProjectDetailPage";
import { ProjectListPage } from "./ProjectListPage";

export function ProjectPage(props: RouteComponentProps) {
  return (
    <>
      <Switch>
        <Redirect exact from="/app/interactive/project" to="/app/interactive/project/list" />
        <Route path="/app/interactive/project/list" component={ProjectListPage} />
        <Route path="/app/interactive/project/detail/:id" component={ProjectDetailPage} />
      </Switch>
    </>
  )
}