import { useCallback, useMemo } from "react";
import { PlayerDto } from "../../../dtos/player/PlayerDto";
import { PlayerService } from "../../../services/PlayerService";
import { FlexListQueryDto } from "../../AppFlexList";
import { AppTable, AppTableColumn } from "../../AppTable";
import { PlayerDisplay } from "./PlayerDisplay";

interface PlayerListProps {
  roomId: string;
}

export function PlayerList(props: PlayerListProps) {
  const { roomId } = props;

  const columns = useMemo(() => {
    return [
      { title: 'ID', render: p => p.playerId },
      { title: 'Người chơi', render: p => <PlayerDisplay {...p} /> },
      { title: 'App', render: p => p.appId }
    ] as AppTableColumn<PlayerDto>[];
  }, []);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return PlayerService.query({
      ...queryDto,
      sorts: [
        { field: 'playerId', descending: false }
      ],
      filters: {
        roomId: roomId,
        playerName: queryDto.searchTerm
      }
    })
  }, [roomId]);

  return (
    <AppTable
      columns={columns}
      fetchData={fetchData}
    />
  )
}