import { useCallback, useMemo } from "react";
import { ActionType } from "../enums/ActionType";
import { useAppStore } from "../store/useAppStore";

export function useOperateDisables() {
  const { store, dispatch } = useAppStore();
  const { operateDisables } = store;

  const isOperateDisabled = useCallback((id: number) => {
    return !!operateDisables[id];
  }, [operateDisables]);

  const toggleOperate = useCallback((id: number) => {
    operateDisables[id] = !operateDisables[id];
    dispatch(ActionType.SET_OPERATE_DISALES, {...operateDisables});
  }, [operateDisables, dispatch]);

  return useMemo(() => ({
    disabledMap: operateDisables,
    isOperateDisabled,
    toggleOperate
  }), [
    operateDisables,
    isOperateDisabled,
    toggleOperate
  ])
}