import { Avatar } from "@mantine/core";

interface PlayerDisplayProps {
  playerName: string;
  playerAvatar: string;
}

export function PlayerDisplay(props: PlayerDisplayProps) {
  const { playerAvatar, playerName } = props;
  return (
    <div className="flex items-center">
      <Avatar color="blue" radius="xl" size="sm" src={playerAvatar} />
      <div className="ml-2">{playerName}</div>
    </div>
  )
}