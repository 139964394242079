import { useState } from "react";
import { Link } from "react-router-dom";
import { AppNavLink } from "./AppNavLink";
import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';

interface AppSidebarItemProps {
  title: string;
  link: string;
  path: string;
  nested: any[];
}

const sidebarItems: AppSidebarItemProps[] = [
  {
    title: 'Interactive',
    link: '/app/interactive',
    path: '/app/interactive/(.*)',
    nested: [
      {
        title: 'Dự án',
        link: '/app/interactive/project',
        path: '/app/interactive/project/(.*)?',
      },
      {
        title: 'Bộ câu hỏi',
        link: '/app/interactive/quiz-game',
        path: '/app/interactive/quiz-game/(.*)?',
      },
      {
        title: 'Câu hỏi',
        link: '/app/interactive/quiz',
        path: '/app/interactive/quiz/(.*)?',
      },
      {
        title: 'Khảo sát',
        link: '/app/interactive/poll',
        path: '/app/interactive/poll/(.*)?',
      }
    ]
  },
  {
    title: 'Livestream',
    link: '/app/livestream',
    path: '/app/livestream/(.*)',
    nested: [
      // {
      //   title: 'Lịch sự kiện',
      //   link: '/app/livestream/event-calendar',
      //   path: '/app/livestream/event-calendar/(.*)?'
      // },
      {
        title: 'Lịch sử vận hành',
        link: '/app/livestream/interactive/history',
        path: '/app/livestream/interactive/history/(.*)?'
      }
    ]
  },
  {
    title: 'Hệ thống',
    link: '/app/system',
    path: '/app/system/(.*)',
    nested: [
      {
        title: 'Tài khoản',
        link: '/app/system/user',
        path: '/app/system/user/(.*)?'
      },
      {
        title: 'App tích hợp',
        link: '/app/system/app',
        path: '/app/system/app/(.*)?'
      },
      {
        title: 'Cấu hình',
        link: '/app/system/config',
        path: '/app/system/config/(.*)?'
      },
      {
        title: 'Change log',
        link: '/app/system/changelog',
        path: '/app/system/changelog/(.*)?'
      }
    ]
  }
];

function AppSidebarLeafItem(props: AppSidebarItemProps) {
  return (
    <AppNavLink
      to={props.link}
      path={props.path}
      className="flex w-full px-8 py-2 rounded-lg my-2 text-white hover:bg-blue-700 text-sm"
      activeClassName="bg-blue-700"
    >
      {props.title}
    </AppNavLink>
  )
}

function AppSidebarItem(props: AppSidebarItemProps) {
  const [collapse, setCollapse] = useState(false);

  if (!props.nested) {
    return <AppSidebarLeafItem {...props} />
  }

  return (
    <div className="w-full cursor-pointer" onClick={() => setCollapse(v => !v)}>
      <div className="flex items-center py-4 px-4 border-t border-b border-gray-400 relative">
        <Link 
          to={props.link}
          className="text-white text-sm"
          onClick={e => e.stopPropagation()}
        >
          {props.title}
        </Link>
        <div className="ml-auto">
          {collapse && <IoIosArrowForward className="text-white" />}
          {!collapse && <IoIosArrowDown className="text-white" />}
        </div>
      </div>
      <div 
        className={"overflow-hidden transition-all " + (collapse ? "h-0" : "p-2")} 
        onClick={e => e.stopPropagation()}
      >
        {props.nested.map((itemProps, idx) => (
          <AppSidebarLeafItem key={idx} {...itemProps} />
        ))}
      </div>
    </div>
  )
}

interface AppSidebarProps {
  collapse: boolean;
  onCollapse: () => any;
}

export function AppSidebar(props: AppSidebarProps) {
  return (
    <div className="h-full w-full top-0 left-0 bg-blue-900 itr-hover:wrapper relative">
      <div className="h-full overflow-y-auto overflow-x-hidden">
        <Link to="/" className="h-14 px-4 w-full flex items-center font-bold text-white bg-blue-800">
          Interactive CMS
        </Link>
        {sidebarItems.map((itemProps, idx) => (
          <AppSidebarItem {...itemProps} key={idx} />
        ))}
      </div>
      <div 
        className="absolute w-8 h-8 hidden itr-hover:flex items-center justify-center rounded-full bg-white border border-blue-900 cursor-pointer"
        style={{ top: '45%', right: -10, zIndex: 2 }}
        onClick={props.onCollapse}
      >
        {props.collapse ? <IoIosArrowForward /> : <IoIosArrowBack />}
      </div>
    </div>
  )
}