import { TextInput } from "@mantine/core";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { AppConfig } from "../config/AppConfig";
import { PageDto } from "../dtos/common/PageDto";
import { PagingDto } from "../dtos/common/PagingDto";
import { QueryDto } from "../dtos/common/QueryDto";
import { useQuery } from "../hooks/useQuery";
import { FormatUtils } from "../utils/FormatUtils";
import { QueryUtils } from "../utils/QueryUtils";
import { AppLoading } from "./AppLoading";
import { AppPagination } from "./AppPagination";

export class FlexListQueryDto extends QueryDto {
  searchTerm?: string;
  filters?: any;
}

export interface AppFlexListProps<T> {
  hideSearch?: boolean;
  hidePagination?: boolean;
  fetchData: (queryDto: FlexListQueryDto) => Promise<PageDto<T>>;
  children: (data: PageDto<any>) => any;
  actions?: any;
}

export function AppFlexList<T = any>(props: AppFlexListProps<T>) {
  const location = useLocation();
  const queryDto = useMemo(() => {
    return QueryUtils.parse(location.search);
  }, [location.search]);

  const [page, setPage] = useState(queryDto.paging?.page || 1);
  const [pageSize, setPageSize] = useState(queryDto.paging?.pageSize || AppConfig.paging.DEFAULT_PAGE_SIZE);
  // eslint-disable-next-line 
  const [filters, setFilters] = useState<any>(queryDto.filters);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  const searchRef = useRef<any>(null);

  useEffect(() => {
    if (queryDto) {
      setPage((prev: number) => queryDto.paging?.page || prev);
      setPageSize((prev: number) => queryDto.paging?.pageSize || prev);
      setFilters(queryDto.filters);
      setSearchTerm(queryDto.searchTerm || '');
    }
  }, [queryDto]);

  const makeQueryDto = useCallback(() => {
    const queryDto = new FlexListQueryDto();
    queryDto.paging = new PagingDto();
    queryDto.paging.page = page;
    queryDto.paging.pageSize = pageSize;
    if (searchTerm) {
      queryDto.searchTerm = searchTerm;
    }
    if (filters) {
      queryDto.filters = filters;
    }
    return queryDto;
  }, [page, pageSize, filters, searchTerm]);

  const { fetchData: propsFetchData } = props;
  const fetchData = useCallback(() => {
    const queryDto = makeQueryDto();
    return propsFetchData(queryDto);
  }, [propsFetchData, makeQueryDto]);

  const response = useQuery(fetchData);

  const query = useCallback((overrides?: any) => {
    const queryDto = makeQueryDto();
    history.push(location.pathname + "?" + QueryUtils.stringify({
      ...queryDto,
      ...overrides
    }));
  }, [history, location, makeQueryDto]);

  return (
    <>
      {(!props.hideSearch || !props.hidePagination || props.actions) && (
        <div className="flex items-center mb-6">
          {!props.hideSearch && (
            <form onSubmit={(evt: any) => {
              evt.preventDefault();
              query({ searchTerm: searchRef.current.value, page: 1 });
            }}>
              <TextInput 
                type="search"
                icon={<MagnifyingGlassIcon />} 
                placeholder="Tìm kiếm..."
                className="w-64 mr-4 hidden sm:block"
                defaultValue={queryDto?.searchTerm}
                ref={searchRef}
              />
              <button className="hidden" type="submit"></button>
            </form>
          )}

          {props.actions}
          
          {!props.hidePagination && (
            <div className="ml-auto flex items-center">
              <div className="text-sm mr-4">Tổng cộng: <b>{FormatUtils.currency(response.data?.totalElements || 0)}</b> (phần tử)</div>
              <AppPagination
                total={response.data?.totalElements || 0}
                current={(response.data?.number || 0) + 1}
                pageSize={response.data?.size || 0}
                onChange={(page, pageSize) => {
                  query({ paging: { page, pageSize } });
                }} />
            </div>
          )}
        </div>
      )}

      {!response.ready && (
        <AppLoading />
      )}
      
      {response.ready && (
        <div>
          {response.data && props.children(response.data)}
        </div>
      )}
    </>
  )
}