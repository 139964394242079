export class FileUtils {

  static makeUrl(file: File) {
    return URL.createObjectURL(file);
  }

  static getWidthHeight(src: string) {
    return new Promise<any>(resolve => {
      const img = new Image();
      img.src = src;
      img.onload = function () {
        resolve({ width: img.width, height: img.height });
      }
    });
  }

  static ext(filename: string) {
    return filename.split('.').pop() || '';
  }
}