import { useCallback, useMemo, useState } from "react";
import { LogType, LogTypeDisplay } from "../../../enums/LogType";
import { LogService } from "../../../services/LogService";
import { FormatUtils } from "../../../utils/FormatUtils";
import { FlexListQueryDto } from "../../AppFlexList";
import { AppTable, AppTableColumn } from "../../AppTable";
import { LogTargetDisplay } from "../../../enums/LogTarget";
import { LogActionDisplay } from "../../../enums/LogAction";
import { LogActorTypeDisplay } from "../../../enums/LogActorType";
import { Button } from "@mantine/core";
import { LogDetailModal } from "../../log/LogDetailModal";
import { AppQuestionTitle } from "../../AppQuestionTitle";

export function ChangeLogList() {
  const [detail, setDetail] = useState<any>();
  const columns = useMemo(() => {
    return [
      { title: 'T.gian', render: row => <div className="w-44">{FormatUtils.dt(row['@timestamp'])}</div> },
      { title: <AppQuestionTitle title="log_type">Loại log</AppQuestionTitle>, render: row => (LogTypeDisplay as any)[row.log_type] },
      { title: <AppQuestionTitle title="actor_type">Tác nhân</AppQuestionTitle>, render: row => (LogActorTypeDisplay as any)[row.actor_type] },
      { title: <AppQuestionTitle title="actor_id">ID tác nhân</AppQuestionTitle>, render: row => row.actor_id },
      { title: <AppQuestionTitle title="action">H.động</AppQuestionTitle>, render: row => <div className="w-32">{(LogActionDisplay as any)[row.action]}</div> },
      { title: <AppQuestionTitle title="target_type">Đ.tượng</AppQuestionTitle>, render: row => <div className="w-32">{(LogTargetDisplay as any)[row.target_type]}</div> },
      { title: <AppQuestionTitle title="target_id">ID đ.tượng</AppQuestionTitle>, width: 120, render: row => row.target_id },
      { 
        title: 'Chi tiết',
        render: row => <Button compact onClick={() => setDetail(row)}>Chi tiết</Button>
      }
    ] as AppTableColumn<any>[];
  }, []);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return LogService.query({
      ...queryDto,
      sorts: [
        { field: '@timestamp', descending: true }
      ],
      filters: {
        searchTerm: queryDto.searchTerm,
        log_type: [LogType.CHANGE_LOG, LogType.OPERATION_LOG]
      }
    })
  }, []);

  return (
    <>
      <LogDetailModal opened={!!detail} onClose={() => setDetail(undefined)} log={detail} />
      <AppTable
        fetchData={fetchData}
        columns={columns}
      />
    </>
  );
}