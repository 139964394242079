import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { PageDto } from "../dtos/common/PageDto";
import { PollDto } from "../dtos/poll/PollDto";
import { QueryPollDto } from "../dtos/poll/QueryPollDto";
import { UpsertPollDto } from "../dtos/poll/UpsertPollDto";
import { HttpUtils } from "../utils/HttpUtils";

export class PollService {
  
  static async query(queryDto: QueryPollDto) {
    return (await HttpUtils.post<PageDto<PollDto>>(Apis.poll.query, queryDto)).body;
  }

  static async findById(id: number) {
    return (await HttpUtils.get<PollDto>(Apis.poll.findById(id))).body;
  }

  static create(createDto: UpsertPollDto) {
    return HttpUtils.post(Apis.poll.create, createDto);
  }

  static update(id: number, updateDto: UpsertPollDto) {
    return HttpUtils.put(Apis.poll.update(id), updateDto);
  }

  static async publish(id: number) {
    return (await HttpUtils.post(Apis.poll.publish(id))).code === ResponseCode.OK.code; 
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.poll.toggle(id))).code === ResponseCode.OK.code;
  }
}