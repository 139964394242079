export const AppConfig = {
  auth: {
    token: {
      ACCESS_TOKEN_KEY: 'interactive_atk',
      REFRESH_TOKEN_KEY: 'interactive_rtk'
    },
    REMEMBER_ME: 'interactive_rmb'
  },
  paging: {
    DEFAULT_PAGE_SIZE: 50,
    PLAYER_PAGE_SIZE: 10,
    LEADERBOARD_PAGE_SIZE: 10
  },
  realtime: {
    ID_PREFIX: '___CMS_',
    APP_ID: String(process.env.REACT_APP_APP_ID),
    PUBLIC_KEY: String(process.env.REACT_APP_PUBLIC_KEY),
    MIN_AUTO_PUBLISH_GAP: 10,
    MAX_AUTO_PUBLISH_GAP: 600,
    MAX_RETRY: 10
  },
  comment: {
    BUFFER_SIZE: 100
  },
  s3: {
    URL: process.env.REACT_APP_S3_URL
  },
  notification3rd: {
    ENABLE: process.env.REACT_APP_NOTIFICATION_ENABLE === 'true'
  },
  uploadFile: {
    MAX_SIZE: 100 * 1024 * 1024, // MB
  },
  HTVGo: {
    ENABLE: process.env.REACT_APP_ENABLE_HTVGO_MODE === 'true'
  }
}