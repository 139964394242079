import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { EventCalendarList } from "../../../components/livestream/event-calendar/EventCalendarList";

const breadcrumbs = [
  { label: 'Livestream', link: '/app/livestream' },
  { label: 'Lịch sự kiện', link: '/app/livestream/event-calendar/list' }
]

export function EventCalendarListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <EventCalendarList />
    </>
  )
}