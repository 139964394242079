import { Button, Divider, NumberInput, Radio, RadioGroup, Textarea } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { AiOutlineClockCircle, AiOutlineSave } from 'react-icons/ai';
import { PollDto } from "../../dtos/poll/PollDto";
import { UpsertPollDto } from "../../dtos/poll/UpsertPollDto";
import { SelectMode, SelectModeVi } from "../../enums/SelectMode";
import { useAppFormik } from "../../hooks/useAppFormik";
import { PollService } from "../../services/PollService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { RandomUtils } from "../../utils/RandomUtils";
import { UpsertPollOptionForm } from "./UpsertPollOptionForm";
import { BsLayoutSidebarInsetReverse } from "react-icons/bs";
import { useForceUpdate } from "@mantine/hooks";
import { ResponseCode } from "../../config/ResponseConfig";
import { PollDevicePreview } from "./PollDevicePreview";

interface UpsertPollFormProps {
  onSuccess?: (poll: PollDto) => any;
  onSubmitError?: () => any;
  preview?: boolean;
  poll?: PollDto | null;
  submitable?: boolean;
  elementRef?: any;
  template: boolean;
}

class OptionRef {
  key: any;
  ref: {
    current?: any
  };

  constructor(key: any) {
    this.key = key;
    this.ref = { current: null };
  }
}

export function UpsertPollForm(props: UpsertPollFormProps) {
  const { onSuccess, elementRef, template, onSubmitError, poll: propsPoll } = props;
  const forceUpdate = useForceUpdate();
  const [poll, setPoll] = useState(propsPoll);
  const [preview, setPreview] = useState(props.preview);

  const [optionRefs, setOptionRefs] = useState<OptionRef[]>(() => {
    if (poll) {
      return poll.pollOptions.map(option => new OptionRef(option.id));
    }
    return [
      new OptionRef(RandomUtils.uuid()),
      new OptionRef(RandomUtils.uuid()),
      new OptionRef(RandomUtils.uuid()),
      new OptionRef(RandomUtils.uuid())
    ];
  });

  const initialValues = useMemo(() => {
    const upsertDto = new UpsertPollDto();
    if (poll) {
      upsertDto.title = poll.title;
      upsertDto.selectMode = poll.selectMode;
      upsertDto.options = poll.pollOptions;
      upsertDto.duration = poll.duration;
      upsertDto.delay = poll.delay;
    }
    upsertDto.duration = upsertDto.duration || 10;
    upsertDto.selectMode = upsertDto.selectMode || SelectMode.SINGLE;
    upsertDto.delay = upsertDto.delay || 0;
    return upsertDto;
  }, [poll]);

  const submit = useCallback(async (values: UpsertPollDto, actions: FormikHelpers<UpsertPollDto>) => {
    const promises = optionRefs.map(async optRef => {
      await optRef.ref.current.formik.handleSubmit();
      if (Object.keys(optRef.ref.current.formik.errors).length === 0) {
        const opt = {...optRef.ref.current.formik.values};
        if (typeof optRef.key === 'number') {
          opt.id = optRef.key;
        }
        return opt;
      }

      return null;
    });

    const upsertDto = {...values};
    upsertDto.options = (await Promise.all(promises)).filter(opt => !!opt);

    if (upsertDto.options.length < promises.length) {
      actions.setSubmitting(false);
      onSubmitError && onSubmitError();
      return;
    }

    upsertDto.template = template;

    let response;
    if (poll) {
      response = await PollService.update(poll.id, upsertDto);
    } else {
      response = await PollService.create(upsertDto);
    }

    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: "Lưu khảo sát thành công" });
      setPoll(response.body);
      
      onSuccess && onSuccess(response.body);
    }

    actions.setSubmitting(false);
  }, [onSuccess, poll, optionRefs, template, onSubmitError]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  const addOption = useCallback(() => {
    if (optionRefs.length === 4) {
      NotificationUtils.error({ message: "Một khảo sát chỉ cho phép tối đa 4 câu trả lời" });
      return;
    }

    setOptionRefs(prev => {
      return [
        ...prev,
        new OptionRef(RandomUtils.uuid())
      ];
    })
  }, [optionRefs.length]);

  const removeOption = useCallback((key: any) => {
    if (optionRefs.length === 2) {
      NotificationUtils.error({ message: "Một khảo sát phải có ít nhất 2 câu trả lời" });
      return;
    }

    setOptionRefs(prev => {
      return prev.filter(opt => opt.key !== key);
    });
  }, [optionRefs.length]);

  useEffect(() => {
    if (elementRef) {
      elementRef.current = { formik };
    }
  }, [elementRef, formik]);

  return (
    <form className="relative w-full" onSubmit={e => {
      e.preventDefault();
      optionRefs.forEach(async optRef => {
        await optRef.ref.current.formik.handleSubmit();
      });
      formik.handleSubmit(e);
    }}>
      <div className="flex">
        <div className={"w-full relative " + (preview ? "mr-4" : "")}>
          <div 
            className="absolute top-2 right-0 text-sm flex items-center text-gray-500 hover:text-blue-400 cursor-pointer" 
            onClick={() => setPreview(v => !v)}
          >
            Preview <BsLayoutSidebarInsetReverse className="ml-1 text-xl" />
          </div>
          <div className="mb-6 flex items-center">
            <NumberInput
              required
              min={3}
              max={100000}
              label="Thời gian (giây)"
              placeholder="Thời gian..."
              className="mr-4"
              icon={<AiOutlineClockCircle />}
              onChange={value => formik.setFieldValue('duration', value)}
              onBlur={formik.handleBlur}
              value={formik.values.duration}
              error={formik.touched.duration && formik.errors.duration}
            />
            <NumberInput 
              required
              min={0}
              name="delay"
              label="Delay"
              placeholder="Delay..."
              className="w-28 mr-8"
              onChange={v => formik.setFieldValue('delay', v)}
              onBlur={formik.handleBlur}
              value={formik.values.delay}
              error={formik.touched.delay && formik.errors.delay}
            />
            <RadioGroup 
              required
              name="selectMode" 
              label="Kiểu khảo sát"
              size="sm"
              onChange={value => formik.handleChange('selectMode')(value)}
              onBlur={formik.handleBlur}
              value={formik.values.selectMode}
              error={formik.touched.selectMode && formik.errors.selectMode}
            >
              <Radio value={SelectMode.SINGLE} label={SelectModeVi[SelectMode.SINGLE]} />
              <Radio value={SelectMode.MULTIPLE} label={SelectModeVi[SelectMode.MULTIPLE]} />
            </RadioGroup>
          </div>

          <Textarea
            autosize
            minRows={2}
            maxRows={4}
            label={(
              <div className="flex">
                Nội dung khảo sát <b className="text-red-400 ml-1">*</b>
                <div className="ml-auto text-gray-500 text-xs">{formik.values.title?.length || 0} / 255</div>
              </div>
            )}
            name="title"
            placeholder="Nội dung khảo sát..."
            className="mb-4"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
            error={formik.touched.title && formik.errors.title}
          />

          {optionRefs.map((optionRef, idx) => (
            <div className="w-full mb-4" key={optionRef.key}>
              <UpsertPollOptionForm 
                option={poll?.pollOptions?.find(opt => opt.id === optionRef.key)}
                numOrder={idx + 1}
                onDelete={() => removeOption(optionRef.key)}
                elementRef={optionRef.ref}
                onChange={() => setTimeout(forceUpdate, 0)}
                removable={optionRefs.length > 2}
              />
            </div>
          ))}

          {optionRefs.length < 4 && (
            <Button fullWidth variant="subtle" onClick={addOption} leftIcon={<HiPlusCircle />}>
              Thêm câu trả lời
            </Button>
          )}
          
          {props.submitable && (
            <>
              <Divider my="lg" />
              <Button type="submit" variant="gradient" leftIcon={<AiOutlineSave />} loading={formik.isSubmitting}>
                Lưu lại
              </Button>
            </>
          )}
        </div>
        <div className={"w-1/3 " + (preview ? "" : "hidden")}>
          <PollDevicePreview 
            overlay={true}
            title={formik.values?.title || ''}
            selectMode={formik.values.selectMode}
            options={optionRefs.map(optRef => optRef.ref.current?.formik.values.content)}
          />
        </div>
      </div>
    </form>
  )
}