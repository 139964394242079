import { Button } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { ScenarioDto } from "../../../dtos/scenario/ScenarioDto";
import { PublishStatus } from "../../../enums/PublishStatus";
import { ScenarioService } from "../../../services/ScenarioService";
import { FormatUtils } from "../../../utils/FormatUtils";
import { FlexListQueryDto } from "../../AppFlexList";
import { AppTable, AppTableColumn } from "../../AppTable";

interface InteractiveHistoryListProps {
  roomId?: string | null;
}

export function InteractiveHistoryList(props: InteractiveHistoryListProps) {
  const { roomId } = props;
  const columns = useMemo(() => {
    return [
      { title: 'ID', render: s => s.id },
      { title: 'Tên kịch bản', render: s => s.name },
      { title: 'Room ID', render: s => s.roomId },
      { title: 'Bắt đầu', render: s => FormatUtils.dt(s.publishDt) },
      { title: 'Kết thúc', render: s => FormatUtils.dt(s.finishDt) },
      { 
        title: 'Chi tiết', 
        render: s => (
          <Link to={`/app/livestream/interactive/history/detail/${s.id}`}>
            <Button compact>Chi tiết</Button>
          </Link>
        )
      }
    ] as AppTableColumn<ScenarioDto>[];
  }, []);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    const filters: any = {
      name: queryDto.searchTerm,
      publishStatus: PublishStatus.FINISHED
    };

    if (roomId) {
      filters.roomId = roomId;
    }

    return ScenarioService.query({
      ...queryDto,
      sorts: [
        { field: 'finishDt', descending: true }
      ],
      filters
    });
  }, [roomId]);

  return (
    <AppTable 
      fetchData={fetchData}
      columns={columns}
    />
  );
}