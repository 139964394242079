import { Loader, Menu, MenuItem } from "@mantine/core";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoIosSend } from "react-icons/io";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BiErrorCircle } from 'react-icons/bi';
import { BsPlayFill } from "react-icons/bs";
import { MinusCircledIcon, PlusCircledIcon } from "@radix-ui/react-icons";
import { AppRingCountDown } from "../AppRingCountDown";
import { CgMediaLive } from "react-icons/cg";

interface BaseInteractionProps {
  menu?: boolean;
  publishable?: boolean;
  allowDisable?: boolean;
  onClick?: () => any;
  onPublish?: () => any;
  onDisable?: () => any;
  error?: boolean;
  completed?: boolean;
  disabled?: boolean;
  loading?: boolean;
  current?: boolean;
  countdown?: number;
  countdownPercent?: number;
  children: any;
  publishing?: boolean;
}

export function BaseInteraction(props: BaseInteractionProps) {
  return (
    <div 
      className={
        "py-4 px-1 border-b text-sm itr-hover:wrapper flex items-start"
        + (props.loading || props.disabled || props.completed || props.error ? 
          " text-gray-300 cursor-not-allowed" : " hover:bg-gray-100 cursor-pointer")
        + (props.current && !props.publishing ? " border-blue-400 text-blue-400" : "")
        + (props.publishing ? " border-red-400 text-red-400": "")
      }
      onClick={props.onClick}
    >
      <div className={"w-full flex items-center " + (props.menu ? "mr-1" : "")}>
        {props.current && (
          props.countdown ? (
            <AppRingCountDown
              size={30}
              thickness={2}
              className="mr-2"
              countdown={props.countdown}
              percent={props.countdownPercent}
            />
          ) : (
            <BsPlayFill className="mr-1 text-xl" />
          )
        )}
        <div>{props.children}</div>
      </div>

      {(props.loading || props.completed || props.error || props.publishing) ? (
        <div className="ml-auto mt-1">
          {props.loading && <Loader size="sm" />}
          {props.completed && <AiOutlineCheckCircle className="text-green-400" />}
          {props.error && <BiErrorCircle className="text-red-400" />}
          {props.publishing && <CgMediaLive className="text-red-400" />}
        </div>
      ) : props.menu && (
        <div className="ml-auto">
          <Menu 
            trigger="hover"
            placement="end"
            delay={200}
            control={<span><HiOutlineDotsVertical /></span>}
            controlRefProp="ref"
          >
            {!props.disabled && props.publishable && (
              <MenuItem
                icon={<IoIosSend />}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  props.onPublish && props.onPublish();
                }}
              >
                Publish
              </MenuItem>
            )}

            {props.allowDisable && (
              <MenuItem
                color={props.disabled ? "blue" : "red"}
                icon={props.disabled ? <PlusCircledIcon /> : <MinusCircledIcon />}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  props.onDisable && props.onDisable();
                }}
              >
                {props.disabled ? "Kích hoạt" : "Vô hiệu hoá"}
              </MenuItem>
            )}
          </Menu>
        </div>
      )}
    </div>
  )
}