import { Apis } from "../config/ApiConfig";
import { AppConfig } from "../config/AppConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { ChangePasswordDto } from "../dtos/auth/ChangePasswordDto";
import { JwtResponseDto } from "../dtos/auth/JwtResponseDto";
import { LoginDto } from "../dtos/auth/LoginDto";
import { UserDto } from "../dtos/user/UserDto";
import { HttpUtils } from "../utils/HttpUtils";

export class AuthService {

  static get accessToken() {
    return localStorage.getItem(AppConfig.auth.token.ACCESS_TOKEN_KEY);
  }

  static get refreshToken() {
    return localStorage.getItem(AppConfig.auth.token.REFRESH_TOKEN_KEY);
  }

  static get isRememberMe() {
    return localStorage.getItem(AppConfig.auth.REMEMBER_ME) === '1' && !!this.refreshToken;
  }

  static get isAuthed() {
    return !!this.accessToken;
  }

  private static saveTokens(response: JwtResponseDto) {
    localStorage.setItem(AppConfig.auth.token.ACCESS_TOKEN_KEY, response.accessToken);
    localStorage.setItem(AppConfig.auth.token.REFRESH_TOKEN_KEY, response.refreshToken);
  }

  static setRememberMe(remember: boolean) {
    if (remember) {
      localStorage.setItem(AppConfig.auth.REMEMBER_ME, '1');
    } else {
      localStorage.removeItem(AppConfig.auth.REMEMBER_ME);
    }
  }

  static clearTokens() {
    localStorage.removeItem(AppConfig.auth.token.ACCESS_TOKEN_KEY);
    localStorage.removeItem(AppConfig.auth.token.REFRESH_TOKEN_KEY);
  }

  static async login(dto: LoginDto) {
    const { code, body } = await HttpUtils.post(Apis.auth.login, dto);
    if (code === ResponseCode.OK.code) {
      this.saveTokens(body);
      return true;
    }
    return false;
  }

  static async logout(callApi = true) {
    if (callApi) {
      const { code } = await HttpUtils.post(Apis.auth.logout);
      if (code !== ResponseCode.OK.code) {
        return false;
      }
    }
    this.clearTokens();
    this.setRememberMe(false);
    return true;
  }

  static async refreshAccessToken() {
    const refreshToken = this.refreshToken;
    if (!refreshToken) {
      return false;
    }

    try {
      const { body } = await HttpUtils.post(Apis.auth.refreshToken, { refreshToken }, {}, false);
      this.saveTokens(body);
      return true;
    } catch (err: any) {
      if (
        err.code === ResponseCode.REFRESH_TOKEN_NOT_FOUND.code ||
        err.code === ResponseCode.REFRESH_TOKEN_EXPIRED.code
      ) {
        await this.logout(false);
      }
      return false;
    }
  }

  static async getUser(): Promise<UserDto | null> {
    let user: any = null;
    if (this.isAuthed) {
      user = await this.getMe();
    } else if (this.isRememberMe) {
      if (await this.refreshAccessToken()) {
        user = await this.getMe();
      }
    }
    return user;
  }

  private static async getMe(): Promise<UserDto | null> {
    try {
      return (await HttpUtils.get(Apis.auth.getMe, {}, {}, false)).body;
    } catch (err: any) {
      if (err.code === ResponseCode.UNAUTHORIZED.code) {
        return null;
      }
      throw err;
    }
  }

  static async changePassword(dto: ChangePasswordDto) {
    return (await HttpUtils.put(Apis.auth.changePassword, {
      password: dto.password,
      newPassword: dto.newPassword
    })).code === ResponseCode.OK.code;
  }
}