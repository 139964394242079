import { PagingDto } from './PagingDto';

class Sort {
  field: string;
  descending: boolean;
}

export class QueryDto {
  paging: PagingDto;
  sorts: Sort[];
}