import { Badge } from "@mantine/core";
import { CgMediaLive } from 'react-icons/cg';
import { FaCheckCircle } from 'react-icons/fa';

export enum PublishStatus {
  PUBLISHING = 'PUBLISHING',
  FINISHED = 'FINISHED',
  IDLE = 'IDLE'
}

export const PublishStatusDisplay = {
  [PublishStatus.PUBLISHING]: <Badge color="red">Live</Badge>,
  [PublishStatus.FINISHED]: <Badge color="green">Hoàn thành</Badge>,
  [PublishStatus.IDLE]: <Badge color="dark">Chưa publish</Badge>
}

export const PublishStatusIcon = {
  [PublishStatus.PUBLISHING]: <CgMediaLive className="text-red-400" />,
  [PublishStatus.FINISHED]: <FaCheckCircle className="text-green-400" />,
  [PublishStatus.IDLE]: ''
}