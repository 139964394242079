import { LogType } from "../../enums/LogType";
import { DynamicLogDetail } from "./DynamicLogDetail";
import { PlayerLogDetail } from "./PlayerLogDetail";

interface LogDetailProps {
  log: any;
}

export function LogDetail(props: LogDetailProps) {
  const { log } = props;
  switch (log.log_type) {
    case LogType.PLAYER_LOG:
      return <PlayerLogDetail log={log} />
    default:
      return <DynamicLogDetail log={log} />
  }
}