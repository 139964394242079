import { useCallback, useState } from "react";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { ActionType } from "../../enums/ActionType";
import { PublishStatus } from "../../enums/PublishStatus";
import { useOperateDisables } from "../../hooks/useOperateDisables";
import { QuizService } from "../../services/QuizService";
import { useAppStore } from "../../store/useAppStore";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { BaseInteraction } from "./BaseInteraction";

interface QuizInteractionProps {
  interaction: InteractionDto;
  onSelect?: () => any;
  numOrder?: number;
}

export function QuizInteraction(props: QuizInteractionProps) {
  const { interaction: itr } = props;
  const { store, dispatch } = useAppStore();
  const { 
    clockTick, autoPublishCountDown, autoPublishGap, 
    itrNodeMap, currQuizItrId, isRoomOpened, currRoomId,
    currQuizGameItrId
  } = store;
  const operateDisables = useOperateDisables();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handlePublish = useCallback(async () => {
    setLoading(true);
    setError(false);
    const success = await QuizService.publish(itr.id);
    if (success) {
      NotificationUtils.success({ message: 'Publish câu hỏi thành công' });
      dispatch(ActionType.SET_CURR_QUIZ_ITR_ID, itr.id);
      dispatch(ActionType.UPDATE_ITR_NODE, {
        id: itr.id,
        publishStatus: PublishStatus.PUBLISHING
      });
    } else {
      setError(true);
    }
    setLoading(false);
  }, [itr, dispatch]);

  const handleDisable = useCallback(() => {
    operateDisables.toggleOperate(itr.id);
  }, [operateDisables, itr]);

  const currQuizItr = currQuizItrId !== undefined ? itrNodeMap[currQuizItrId] : null;

  return (
    <BaseInteraction 
      menu={itr.publishStatus === PublishStatus.IDLE}
      allowDisable={!clockTick || currQuizItr?.id !== itr.id}
      publishable={!clockTick && isRoomOpened && !!currRoomId}
      disabled={!currQuizGameItrId || operateDisables.isOperateDisabled(itr.id)}
      current={currQuizItr?.id === itr.id}
      publishing={itr.publishStatus === PublishStatus.PUBLISHING}
      completed={itr.publishStatus === PublishStatus.FINISHED}
      loading={loading}
      error={error}
      onClick={props.onSelect}
      onPublish={handlePublish}
      onDisable={handleDisable}
      countdown={autoPublishCountDown}
      countdownPercent={Math.round((autoPublishCountDown || 0) / autoPublishGap * 100)}
    >
      {props.numOrder || itr.mountedQuiz?.numOrder}. {itr.mountedQuiz?.quiz?.content}
    </BaseInteraction>
  )
}