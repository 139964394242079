import { useEffect } from "react";
import { OpenConnectionInfoDto } from "../dtos/realtime/OpenConnectionInfoDto";
import { ActionType } from "../enums/ActionType";
import { ConnectionStatus } from "../enums/ConnectionStatus";
import { EventType } from "../enums/EventType";
import { OperateService } from "../services/OperateService";
import { RealtimeService } from "../services/RealtimeService";
import { RoomService } from "../services/RoomService";
import { useAppStore } from "../store/useAppStore";

export function useRealtime(dto?: OpenConnectionInfoDto) {
  const { dispatch } = useAppStore();
  useEffect(() => {
    (async () => {
      if (!!dto) {
        const isOpened = await RoomService.isRoomOpened(dto.roomId);
        if (!isOpened) {
          OperateService.cleanRoom(dto.roomId);
          window.location.reload();
          return;
        }

        RealtimeService.open(dto);
        const unsubcribe = RealtimeService.subscribe(dto.roomId, e => {
          switch (e.eventType) {
            case EventType.CONNECTED:
              dispatch(ActionType.SET_CONNECTION_STATUS, ConnectionStatus.CONNECTED);
              break;
            case EventType.CONNECTING:
              dispatch(ActionType.SET_CONNECTION_STATUS, ConnectionStatus.CONNECTING);
              break;
            case EventType.DISCONNECTED:
              dispatch(ActionType.SET_CONNECTION_STATUS, ConnectionStatus.DISCONNECTED);
              break;
          }
        });
  
        return () => {
          RealtimeService.close(dto.roomId);
          unsubcribe();
          dispatch(ActionType.SET_CONNECTION_STATUS, ConnectionStatus.IDLE);
        };
      }
    })()
  }, [dto, dispatch]);
}