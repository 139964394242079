import { Select } from "@mantine/core";
import { useState } from "react";
import { StreamSourceProtocol, StreamSourceProtocolOptions } from "../../../enums/StreamSourceProtocol";
import { StreamSourceType, StreamSourceTypeOptions } from "../../../enums/StreamSourceType";
import { AmsService } from "../../../services/AmsService";
import { useAppStore } from "../../../store/useAppStore";
import { AppCopiableTextInput } from "../../AppCopiableTextInput";
import { OperateRoomNotExists } from "../OperateRoomNotExists";

export function OperateStreamSource() {
  const { store } = useAppStore();
  const { currRoomId, isRoomOpened } = store;
  const [type, setType] = useState<StreamSourceType>(StreamSourceType.ANT_MEDIA);
  const [protocol, setProtocol] = useState<StreamSourceProtocol>(StreamSourceProtocol.WEB_RTC);

  if (!currRoomId || !isRoomOpened) {
    return <OperateRoomNotExists />
  }

  return (
    <div className="px-4 h-full">
      <Select 
        required
        label="Nguồn stream"
        placeholder="Chọn nguồn stream..."
        className="w-full mb-4"
        data={StreamSourceTypeOptions}
        value={type}
        onChange={v => setType(v as any)}
      />

      <Select 
        required
        label="Protocol"
        placeholder="Chọn protocol..."
        className="w-full mb-4"
        data={StreamSourceProtocolOptions}
        value={protocol}
        onChange={v => setProtocol(v as any)}
      />

      <div className="rounded border bg-gray-50 p-3">

        {protocol === StreamSourceProtocol.RTMP && (
          <>
            <AppCopiableTextInput 
              readOnly
              label="Server"
              className="w-full mb-4"
              defaultValue={AmsService.rtmpUrl(currRoomId)}
            />

            <AppCopiableTextInput 
              readOnly
              label="Stream Key"
              className="w-full"
              defaultValue={currRoomId}
            />
          </>
        )}

        {protocol === StreamSourceProtocol.WEB_RTC && (
          <>
            <AppCopiableTextInput 
              readOnly
              label="Server"
              className="w-full mb-4"
              defaultValue={AmsService.webRtcUrl()} 
            />

            <AppCopiableTextInput 
              readOnly
              label="Stream ID"
              className="w-full"
              defaultValue={currRoomId} 
            />
          </>
        )}
      </div>

    </div>
  )
}