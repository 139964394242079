import { useCallback, useMemo } from "react";
import { PollDto } from "../../../dtos/poll/PollDto";
import { useQuery } from "../../../hooks/useQuery";
import { PollResultService } from "../../../services/PollResultService";
import { AppInteractivePreview } from "../../AppInteractivePreview";
import { AppLoading } from '../../AppLoading';

interface PollResultProps {
  poll: PollDto;
  numOrder?: number;
}

export function PollResult(props: PollResultProps) {
  const { poll, numOrder } = props;
  const { id: pollId } = poll;

  const fetchPollResult = useCallback(() => {
    return PollResultService.findByPollId(pollId);
  }, [pollId]);

  const pollResultResp = useQuery(fetchPollResult);

  const previewProps: any = useMemo(() => {
    if (pollResultResp.data) {
      return {
        title: `Khảo sát ${numOrder || ''}`,
        content: poll.title,
        options: poll.pollOptions.map(opt => opt.content),
        selectMode: poll.selectMode,
        selections: poll.pollOptions.map(opt => {
          return pollResultResp.data?.find(rs => rs.pollOptionId === opt.id)?.selections || 0;
        })
      }
    }
    return {};
  }, [poll, pollResultResp, numOrder]);

  if (!pollResultResp.ready) {
    return <AppLoading />
  }

  return (
    <AppInteractivePreview {...previewProps}/>
  );
}