import { Tab, Tabs } from "@mantine/core";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { MountAndUpsertPollForm } from "./MountAndUpsertPollForm";
import { MountPollFromLibraryForm } from "./MountPollFromLibraryForm";

interface MountPollFormProps {
  zIndex: number;
  scenarioId: number;
  onMount: (itr: InteractionDto) => any;
  numOrder: number;
}

export function MountPollForm(props: MountPollFormProps) {
  return (
    <Tabs>
      <Tab label="Thư viện">
        <div className="mt-4">
          <MountPollFromLibraryForm {...props} />
        </div>
      </Tab>
      <Tab label="Tạo khảo sát mới">
        <div className="mt-4">
          <MountAndUpsertPollForm
            zIndex={props.zIndex}
            scenarioId={props.scenarioId}
            onSuccess={props.onMount}
            numOrder={props.numOrder}
          />
        </div>
      </Tab>
    </Tabs>
  )
}