import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { PageDto } from "../dtos/common/PageDto";
import { LeaderboardPagingDto } from "../dtos/quiz-game/LeaderboardPagingDto";
import { QueryQuizGameDto } from "../dtos/quiz-game/QueryQuizGameDto";
import { QuizGameDto } from "../dtos/quiz-game/QuizGameDto";
import { QuizLeaderboardDto } from "../dtos/quiz-game/QuizLeaderboardDto";
import { UpsertQuizGameDto } from "../dtos/quiz-game/UpsertQuizGameDto";
import { HttpUtils } from "../utils/HttpUtils";

export class QuizGameService {
  
  static async query(queryDto: QueryQuizGameDto) {
    return (await HttpUtils.post<PageDto<QuizGameDto>>(Apis.quizGame.query, queryDto)).body;
  }

  static async findById(id: number) {
    return (await HttpUtils.get<QuizGameDto>(Apis.quizGame.findById(id))).body;
  }

  static async checkName(name: string) {
    return (await HttpUtils.get(Apis.quizGame.checkName, { name })).body;
  }

  static create(createDto: UpsertQuizGameDto) {
    return HttpUtils.post(Apis.quizGame.create, createDto);
  }

  static update(id: number, updateDto: UpsertQuizGameDto) {
    return HttpUtils.put(Apis.quizGame.update(id), updateDto);
  }

  static async publish(id: number) {
    return (await HttpUtils.post(Apis.quizGame.publish(id), { winRange: 20 })).code === ResponseCode.OK.code;
  }

  static async publishLeaderboard(id: number, dto: LeaderboardPagingDto) {
    return (await HttpUtils.post(Apis.quizGame.publishLeaderboard(id), dto)).code === ResponseCode.OK.code;
  }

  static async publishResult(id: number) {
    return (await HttpUtils.post(Apis.quizGame.publishResult(id))).code === ResponseCode.OK.code;
  }

  static async publishChange(id: number) {
    return (await HttpUtils.post(Apis.quizGame.publishChange(id))).code === ResponseCode.OK.code;
  }

  static async paginateLeaderboard(id: number, dto: LeaderboardPagingDto) {
    return (await HttpUtils.get<QuizLeaderboardDto>(Apis.quizGame.paginateLeaderboard(id), dto)).body;
  }

  static async finish(id: number) {
    return (await HttpUtils.post(Apis.quizGame.finish(id))).code === ResponseCode.OK.code;
  }

  static async updateFootballLeaderboard(quizGameId: number, triggerWorker: boolean) {
    const api = Apis.quizGame.updateFootballLeaderboard(quizGameId);
    return (await HttpUtils.post(api, null, { params: { triggerWorker } })).code === ResponseCode.OK.code;
  }

  static async triggerLeaderboardWorker(quizGameId: number, roomId: string) {
    const api = Apis.quizGame.triggerLeaderboardWorker;
    return (await HttpUtils.post(api, null, { params: { quizGameId, roomId } })).code === ResponseCode.OK.code;
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.quizGame.toggle(id))).code === ResponseCode.OK.code;
  }
}