import { Redirect, Route, Switch } from "react-router";
import { InteractiveHistoryDetailPage } from "./InteractiveHistoryDetailPage";
import { InteractiveHistoryListPage } from "./InteractiveHistoryListPage";
import { QuizGameResultPage } from "./QuizGameResultPage";

export function InteractiveHistoryPage() {
  return (
    <Switch>
      <Redirect exact from="/app/livestream/interactive/history" to="/app/livestream/interactive/history/list" />
      <Route path="/app/livestream/interactive/history/list" component={InteractiveHistoryListPage} />
      <Route path="/app/livestream/interactive/history/detail/:scenarioId" component={InteractiveHistoryDetailPage} />
      <Route path="/app/livestream/interactive/history/quiz-game/:quizGameId" component={QuizGameResultPage} />
    </Switch>
  )
}