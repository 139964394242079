import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { InteractionNodeDto } from "../../dtos/interaction/InteractionNodeDto";
import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { InteractionType } from "../../enums/InteractionType";
import { PublishStatus } from "../../enums/PublishStatus";
import { OperateService } from "../../services/OperateService";

export function reduceItrNodes(state: AppGlobalStateDto, interactions: InteractionDto[]): AppGlobalStateDto {
  const mqGroups = interactions.reduce((group: any, itr) => {
    if (itr.interactionType === InteractionType.QUIZ && itr.mountedQuiz) {
      if (Array.isArray(group[itr.mountedQuiz.quizGameId])) {
        group[itr.mountedQuiz.quizGameId].push(itr);
      } else {
        group[itr.mountedQuiz.quizGameId] = [itr];
      }
    }
    return group;
  }, {});

  const allItrNodes = interactions
    .map(itr => {
      const node = {...itr} as InteractionNodeDto;
      if (itr.interactionType === InteractionType.QUIZ_GAME) {
        node.children = mqGroups[itr.quizGameId]
          .sort((a: InteractionNodeDto, b: InteractionNodeDto) => {
            return (a.mountedQuiz?.numOrder || 0) - (b.mountedQuiz?.numOrder || 0);
          });
        return node;
      }
      return node;
    });

  const itrNodeMap = allItrNodes
    .reduce((map: any, itr: InteractionNodeDto) => {
      map[itr.id] = itr;
      return map;
    }, {});

  const itrNodes = allItrNodes.filter(itr =>
    itr.interactionType === InteractionType.POLL || 
    itr.interactionType === InteractionType.QUIZ_GAME
  );

  const newState = {
    ...state,
    itrNodeMap,
    itrNodes
  };
  
  if (newState.currQuizGameItrId === undefined) {
    const publishingQuizGameItr = itrNodes.find(itr => 
      itr.interactionType === InteractionType.QUIZ_GAME && 
      itr.publishStatus === PublishStatus.PUBLISHING
    );

    if (publishingQuizGameItr) {
      newState.currQuizGameItrId = publishingQuizGameItr.id;
    }
  }
  
  if (
    (newState.currQuizItrId === undefined || newState.operateDisables[newState.currQuizItrId]) 
    && newState.currQuizGameItrId !== undefined 
    && newState.currRoomId
  ) {
    const quizGameItr = itrNodeMap[newState.currQuizGameItrId];
    if (quizGameItr?.children) {
      const nextQuiz = OperateService.findNextQuiz(
        newState.currRoomId,
        quizGameItr.children, 
        newState.currQuizItrId !== undefined ? newState.itrNodeMap[newState.currQuizItrId] : undefined
      );
      
      if (nextQuiz) {
        newState.currQuizItrId = nextQuiz.id;
      }
    }
  }

  return newState;
}