import { Button, TextInput } from "@mantine/core";
import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { useCallback, useMemo } from "react"
import { ShowNotificationDto } from "../../../dtos/room/ShowNotificationDto";
import { UploadFileRequestDto } from "../../../dtos/upload-file/UploadFileRequestDto";
import { UploadFileFolder } from "../../../enums/UploadFileFolder";
import { useAppFormik } from "../../../hooks/useAppFormik";
import { RoomService } from "../../../services/RoomService";
import { UploadFileService } from "../../../services/UploadFileService";
import { useAppStore } from "../../../store/useAppStore";
import { FileUtils } from "../../../utils/FileUtils";
import { NotificationUtils } from "../../../utils/NotificationUtils";
import { AppUploader } from "../../AppUploader";

interface IShowNotificationFormProps {
  onSuccess?: () => any;
}

export function ShowNotificationForm(props: IShowNotificationFormProps) {
  const { onSuccess } = props;
  const { store } = useAppStore();
  const { currRoomId } = store;

  const initialValues = useMemo(() => {
    const dto = new ShowNotificationDto();
    return dto;
  }, []);

  const submit = useCallback(async (dto: ShowNotificationDto, actions: FormikHelpers<ShowNotificationDto>) => {
    if (currRoomId) {
      const ext = FileUtils.ext(dto.imageFile.name);
      const ufRequest = new UploadFileRequestDto();
      ufRequest.file = dto.imageFile;
      ufRequest.folder = UploadFileFolder.LIVESTREAM_NOTIFICATIONS;
      ufRequest.filename = `${dayjs().format('DD-MM-YYYY')}/${currRoomId}.${ext}`;
      ufRequest.persist = false;

      const success = await UploadFileService.upload(ufRequest);
      if (success) {
        delete dto.imageFile;
        delete dto.ttlInSeconds;
        delete dto.expireAt;

        dto.image = UploadFileService.makeSrc(ufRequest);
        const success = await RoomService.showNotification(currRoomId, dto);
        if (success) {
          NotificationUtils.success({ message: 'Hiện thông báo thành công' });
          onSuccess && onSuccess();
        }
      }
    }

    actions.setSubmitting(false);
  }, [currRoomId, onSuccess]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <TextInput 
        required
        name="description"
        label="Nội dung"
        placeholder="Nhập nội dung..."
        className="w-full mb-2"
        value={formik.values.description}
        onChange={formik.handleChange}
        error={formik.touched.description && formik.errors.description}
      />

      <div className="w-full mb-2">
        <label className="text-sm">Hình ảnh <b className="text-red-400">*</b></label>
        <div className="mt-1">
          <AppUploader 
            maxSize={31457280}
            onDrop={file => formik.setFieldValue('imageFile', file)}
            onBlur={() => formik.setFieldTouched('imageFile', true)}
          />
        </div>
        <p className="text-sm text-red-400">{formik.touched.imageFile && formik.errors.imageFile}</p>
      </div>

      <Button fullWidth type="submit" loading={formik.isSubmitting}>Hiện thông báo</Button>
    </form>
  )
}