import { Checkbox, CloseButton, Textarea } from "@mantine/core";
import { useCallback, useEffect, useMemo } from "react";
import { UpsertQuizAnswerDto } from "../../dtos/quiz/UpsertQuizDto";
import { useAppFormik } from "../../hooks/useAppFormik";

interface UpsertQuizAnswerFormProps {
  answer?: UpsertQuizAnswerDto;
  elementRef: any;
  numOrder: number;
  onDelete: () => any;
  onCorrectCheck: () => any;
  onContentChange: () => any;
  removable?: boolean; 
}

export function UpsertQuizAnswerForm(props: UpsertQuizAnswerFormProps) {
  const { answer, elementRef, onContentChange } = props;
  const initialValues = useMemo(() => {
    const upsertDto = new UpsertQuizAnswerDto();
    if (answer) {
      upsertDto.content = answer.content;
      upsertDto.correct = answer.correct;
    }
    return upsertDto;
  }, [answer]);

  const submit = useCallback(() => {}, []);
  const formik = useAppFormik({ initialValues, onSubmit: submit });

  useEffect(() => {
    elementRef.current = { formik }
    onContentChange && onContentChange();
  }, [elementRef, formik, onContentChange]);

  const isError = formik.touched.content && !!formik.errors.content;

  return (
    <>
      <div className={"border rounded " + (isError ? "border-red-400" : "border-gray-300")}>
        <div className="w-full rounded-t bg-gray-100 flex items-center px-2 text-sm h-8">
          <div>
            Câu trả lời {props.numOrder} <b className="text-red-400">*</b> 
            <span className="text-xs ml-2">({formik.values.content?.length || 0} / 100)</span>
          </div>
          {props.removable && (
            <div className="ml-auto">
              <CloseButton color="gray" onClick={props.onDelete} />
            </div>
          )}
        </div>
        <div className="w-full flex">
          <div className="w-10 flex items-start justify-center pt-3">
            <Checkbox
              name="correct"
              checked={formik.values.correct}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                if (e.target.checked) {
                  props.onCorrectCheck && props.onCorrectCheck();
                }
              }}
            />
          </div>
          <div className="w-full">
            <Textarea
              required
              name="content"
              variant="unstyled"
              placeholder="Nội dung câu trả lời..."
              value={formik.values.content}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                props.onContentChange && props.onContentChange();
              }}
            />
          </div>
        </div>
      </div>
      {isError && (
        <div className="text-red-400 text-sm mt-1">{formik.errors.content}</div>
      )}
    </>
  )
}