import { Link } from "react-router-dom";
import { RiVideoAddFill, RiNotification2Fill } from 'react-icons/ri';
import { AppHeaderUserMenu } from "./AppHeaderUserMenu";
import { useSession } from "../hooks/useSession";

interface AppHeaderProps {
  collapse?: boolean;
}

export function AppHeader(props: AppHeaderProps) {
  const session = useSession();
  return (
    <div 
      className={"fixed left-0 top-0 w-full border bg-white shadow flex items-center h-14 " + (props.collapse ? "pl-4" : "pl-52")}
      style={{ zIndex: 1 }}
    >
      <div className="ml-auto flex items-center">
        <Link to="/app/operate/livestream" className="mr-4">
          <RiVideoAddFill className="text-gray-300 text-3xl hover:text-blue-400" />
        </Link>
        <RiNotification2Fill className="mr-4 text-gray-300 text-2xl hover:text-blue-400" />
        <AppHeaderUserMenu user={session.user} />
      </div>
    </div>
  )
}