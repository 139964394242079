import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { AppBreadcrumbs } from "../../components/AppBreadCrumbs";
import { AppLoading } from "../../components/AppLoading";
import { OperateLiveStream } from "../../components/operate-livestream/OperateLiveStream";
import { OperateLiveStreamPlayer } from "../../components/operate-livestream/OperateLiveStreamPlayer";
import { OpenConnectionInfoDto } from "../../dtos/realtime/OpenConnectionInfoDto";
import { ActionType } from "../../enums/ActionType";
import { useLivestreamProfile } from "../../hooks/useLivestreamProfile";
import { useRealtime } from "../../hooks/useRealtime";
import { useSession } from "../../hooks/useSession";
import { EventService } from "../../services/EventService";
import { HTVGoService } from "../../services/HTVGoService";
import { OperateService } from "../../services/OperateService";
import { RoomService } from "../../services/RoomService";
import { useAppStore } from "../../store/useAppStore";
import { QsUtils } from "../../utils/QsUtils";

const breadcrumbs = [
  { label: 'Vận hành livestream', link: '/app/operate/livestream' },
];

export function OperateLiveStreamPage() {
  const location = useLocation();
  const session = useSession();
  const { search } = location;
  const { store, dispatch } = useAppStore();
  const { isRoomOpened, currRoomId, livestreamProfile } = store;
  const [loading, setLoading] = useState(0);

  const params = useMemo(() => QsUtils.parse(search), [search]);
  
  let eventIdParam = params.eventId ? Number(params.eventId) : 0;
  let htvgoLivestreamIdParam = params.htvgoLivestreamId ? Number(params.htvgoLivestreamId) : 0;

  eventIdParam = isNaN(eventIdParam) ? 0 : eventIdParam;
  htvgoLivestreamIdParam = isNaN(htvgoLivestreamIdParam) ? 0 : htvgoLivestreamIdParam;

  useEffect(() => {
    async function fetchIsRoomOpened() {
      if (currRoomId) {
        dispatch(ActionType.SET_IS_ROOM_OPENED, await RoomService.isRoomOpened(currRoomId));
      }
    }
    fetchIsRoomOpened();
  }, [currRoomId, dispatch]);

  useEffect(() => {
    let eventId = eventIdParam;
    if (!eventId) {
      if (!!currRoomId) {
        eventId = OperateService.getCurrEventId(currRoomId);
      }
    }

    async function fetchEvent() {
      setLoading(v => v + 1);
      if (eventId) {
        const event = await EventService.findById(eventId);
        if (!!event) {
          dispatch(ActionType.SET_CURR_EVENT, event);
        }
      }
      setLoading(v => v - 1);
    }

    async function fetchHTVGoLivestream() {
      setLoading(v => v + 1);
      if (htvgoLivestreamIdParam) {
        const htvGoLivestream = await HTVGoService.getLivestreamDetail(htvgoLivestreamIdParam);
        if (!!htvGoLivestream) {
          dispatch(ActionType.SET_CURR_HTVGO_LIVESTREAM, htvGoLivestream);
        }
      }
      setLoading(v => v - 1);
    }

    fetchEvent();
    fetchHTVGoLivestream();
  }, [eventIdParam, htvgoLivestreamIdParam, currRoomId, dispatch]);

  const storedLiveStreamProfile = useLivestreamProfile();
  useEffect(() => {
    if (storedLiveStreamProfile) {
      dispatch(ActionType.SET_LIVESTREAM_PROFILE, storedLiveStreamProfile);
    }
  }, [storedLiveStreamProfile, dispatch]);

  const openConnectionInfo = useMemo(() => {
    console.log(isRoomOpened, currRoomId, livestreamProfile, session.user);
    if (isRoomOpened && currRoomId && livestreamProfile && session.user) {
      const dto = new OpenConnectionInfoDto();
      dto.id = String(session.user.id);
      dto.avatar = livestreamProfile.avatar;
      dto.name = livestreamProfile.username;
      dto.forceNew = true;
      dto.roomId = currRoomId;
      return dto;
    }
  }, [isRoomOpened, currRoomId, livestreamProfile, session]);
  
  useRealtime(openConnectionInfo);

  if (loading > 0 && !session.user) {
    return <AppLoading />
  }

  return (
    <div className="w-full bg-gray-100 p-4 relative" style={{ height: 'calc(100vh - 56px)' }}>
      <div className="mb-2 flex items-center">
        <AppBreadcrumbs className="mb-2" items={breadcrumbs} />
      </div>
      <div className="flex" style={{ height: 'calc(100% - 2rem)' }}>
        <div className="w-7/12 h-full bg-white shadow border rounded-lg mr-2">
          <OperateLiveStreamPlayer />
        </div>
        <div className="w-5/12 h-full bg-white shadow border rounded-lg ml-2">
          <OperateLiveStream />
        </div>
      </div>
    </div>
  )
}