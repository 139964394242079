export const API_PREFIX = '/api/v1';
export const EVENT_API_PREFIX = '/event/api/v1';
export const HTVGO_API_PREFIX = '/htvgo/api/v1';

export const Apis = {
  auth: {
    login: API_PREFIX + '/auth/login',
    logout: API_PREFIX + '/auth/logout',
    refreshToken: API_PREFIX + '/auth/refresh-access-token',
    changePassword: API_PREFIX + '/auth/change-password',
    getMe: API_PREFIX + '/auth/me'
  },
  project: {
    query: API_PREFIX + '/project/query',
    checkName: API_PREFIX + '/project/check-name',
    create: API_PREFIX + '/project',
    update: (id: number) => API_PREFIX + `/project/${id}`,
    toggle: (id: number) => API_PREFIX + `/project/toggle/${id}`
  },
  scenario: {
    query: API_PREFIX + '/scenario/query',
    findById: (id: number) => API_PREFIX + `/scenario/${id}`,
    findByEventId: (eventId: number) => API_PREFIX + `/scenario/find-by-event-id/${eventId}`,
    findPublishing: API_PREFIX + '/scenario/find-publishing',
    checkName: API_PREFIX + '/scenario/check-name',
    create: API_PREFIX + '/scenario',
    update: (id: number) => API_PREFIX + `/scenario/${id}`,
    toggle: (id: number) => API_PREFIX + `/scenario/toggle/${id}`,
    publish: (id: number) => API_PREFIX + `/scenario/publish/${id}`,
    finish: (id: number) => API_PREFIX + `/scenario/finish/${id}`
  },
  quizGame: {
    query: API_PREFIX + '/quiz-game/query',
    findById: (id: number) => API_PREFIX + `/quiz-game/${id}`,
    checkName: API_PREFIX + '/quiz-game/check-name',
    create: API_PREFIX + '/quiz-game',
    update: (id: number) => API_PREFIX + `/quiz-game/${id}`,
    toggle: (id: number) => API_PREFIX + `/quiz-game/toggle/${id}`,
    publish: (interactionId: number) => API_PREFIX + `/quiz-game/publish/${interactionId}`,
    finish: (interactionId: number) => API_PREFIX + `/quiz-game/finish/${interactionId}`,
    publishLeaderboard: (interactionId: number) => API_PREFIX + `/quiz-game/publish-leaderboard/${interactionId}`,
    publishResult: (interactionId: number) => API_PREFIX + `/quiz-game/publish-result/${interactionId}`,
    publishChange: (interactionId: number) => API_PREFIX + `/quiz-game/publish-change/${interactionId}`,
    paginateLeaderboard: (interactionId: number) => API_PREFIX + `/quiz-game/paginate-leaderboard/${interactionId}`,
    updateFootballLeaderboard: (quizGameId: number) => API_PREFIX + `/quiz-game/football-game/update-leaderboard/${quizGameId}`,
    triggerLeaderboardWorker: API_PREFIX + '/quiz-game/worker/leaderboard/trigger'
  },
  quiz: {
    query: API_PREFIX + '/quiz/query',
    findById: (id: number) => API_PREFIX + `/quiz/${id}`,
    create: API_PREFIX + '/quiz',
    update: (id: number) => API_PREFIX + `/quiz/${id}`,
    toggle: (id: number) => API_PREFIX + `/quiz/toggle/${id}`,
    publish: (interactionId: number) => API_PREFIX + `/quiz/publish/${interactionId}`,
    updateCorrectAnswers: API_PREFIX + '/quiz/update-correct-answers'
  },
  mountedQuiz: {
    findByQuizGameId: API_PREFIX + '/mounted-quizzes',
    create: API_PREFIX + '/mounted-quiz',
    update: (id: number) => API_PREFIX + `/mounted-quiz/${id}`,
    updateNumOrder: API_PREFIX + `/mounted-quiz/num-order`,
    toggle: (id: number) => API_PREFIX + `/mounted-quiz/toggle/${id}`
  },
  poll: {
    query: API_PREFIX + '/poll/query',
    findById: (id: number) => API_PREFIX + `/poll/${id}`,
    create: API_PREFIX + '/poll',
    update: (id: number) => API_PREFIX + `/poll/${id}`,
    toggle: (id: number) => API_PREFIX + `/poll/toggle/${id}`,
    publish: (interactionId: number) => API_PREFIX + `/poll/publish/${interactionId}`,
  },
  interaction: {
    find: API_PREFIX + '/interaction/find',
    findById: (id: number) => API_PREFIX + `/interaction/${id}`,
    getDetail: (id: number) => API_PREFIX + `/interaction/${id}`,
    create: API_PREFIX + '/interaction',
    update: (id: number) => API_PREFIX + `/interaction/${id}`,
    updateNumOrder: API_PREFIX + '/interaction/num-order',
    toggle: (id: number) => API_PREFIX + `/interaction/toggle/${id}`
  },
  user: {
    query: API_PREFIX + '/user/query',
    create: API_PREFIX + '/user',
    checkUsername: API_PREFIX + '/user/check-username',
    update: (id: number) => API_PREFIX + `/user/${id}`,
    // updateRole: (id: number) => API_PREFIX + `/user/update-role/${id}`,
    updateAvatar: API_PREFIX + '/user/update-avatar',
    updateProfile: API_PREFIX + '/user/update-profile',
    toggle: (id: number) => API_PREFIX + `/user/toggle/${id}`
  },
  room: {
    isRoomOpened: (roomId: string) => API_PREFIX + `/room/is-opened/${roomId}`,
    getRoomInfo: (roomId: string) => API_PREFIX + `/room/${roomId}`,
    paginateClients: (roomId: string) => API_PREFIX + `/room/clients/${roomId}`,
    openRoom: API_PREFIX + '/room/open',
    closeRoom: (roomId: string) => API_PREFIX + `/room/close/${roomId}`,
    updateMaxConnection: (roomId: string) => API_PREFIX + `/room/update-max-connection/${roomId}`,
    showNotification: (roomId: string) => API_PREFIX + `/room/show-notification/${roomId}`,
    hideNotification: (roomId: string) => API_PREFIX + `/room/hide-notification/${roomId}`
  },
  realtime: {
    local: `ws://localhost:6600`,
    dev: `wss://realtime.interactive.skysocial.club`,
    'rv-prod': 'wss://realtime.interactive.rongvangvietnam.vn',
    'aws-load-test': 'wss://realtime.interactive.phamdzung.com',
    htvgo: 'wss://realtime.interactive.htvgo.net'
  },
  event: {
    findById: (id: number) => EVENT_API_PREFIX + `/event/find-by-id/${id}`,
    updateState: (id: number) => EVENT_API_PREFIX + `/event/update-state/${id}`
  },
  systemCofnig: {
    findAll: API_PREFIX + '/system-config/all',
    findByName: API_PREFIX + '/system-config/find-by-name',
    upsert: API_PREFIX + '/system-config/upsert',
    delete: (id: number) => API_PREFIX + `/system-config/delete/${id}`
  },
  app: {
    findAll: API_PREFIX + '/app/find-all',
    query: API_PREFIX + '/app/query',
    checkName: API_PREFIX + '/app/check-name',
    create: API_PREFIX + '/app',
    update: (id: number) => API_PREFIX + `/app/${id}`,
    regenKeyPair: (id: number) => API_PREFIX + `/app/regen-key-pair/${id}`,
    toggle: (id: number) => API_PREFIX + `/app/toggle/${id}`
  },
  eventCalendar: {
    query: API_PREFIX + '/event-calendar/query',
    findById: (id: number) => API_PREFIX + `/event-calendar/${id}`,
    create: API_PREFIX + '/event-calendar',
    update: (id: number) => API_PREFIX + `/event-calendar/${id}`,
    toggle: (id: number) => API_PREFIX + `/event-calendar/toggle/${id}`
  },
  quizGameLeaderboard: {
    query: API_PREFIX + '/quiz-game-leaderboard/query',
    exportExcel: API_PREFIX + '/quiz-game-leaderboard/export-excel'
  },
  quizResult: {
    findByQuizId: API_PREFIX + '/quiz-result/find-by-quiz-id'
  },
  pollResult: {
    findByPollId: API_PREFIX + '/poll-result/find-by-poll-id'
  },
  player: {
    query: API_PREFIX + '/player/query'
  },
  comment: {
    block: API_PREFIX + '/comment/block',
    query: API_PREFIX + '/comment/query',
    pin: API_PREFIX + '/comment/pin',
    unpin: API_PREFIX + '/comment/unpin'
  },
  uploadFile: {
    upload: API_PREFIX + '/upload-file',
    delete: (id: number) => API_PREFIX + `/upload-file/${id}`,
    query: API_PREFIX + '/upload-file/query'
  },
  notification3rd: {
    notifyEventLive:  API_PREFIX + '/notification-3rd/notify-event-live',
  },
  quizGameBanner: {
    findByQuizGameId: (quizGameId: number) => API_PREFIX + '/quiz-game-banner/find-by-quiz-game-id/' + quizGameId,
    create: API_PREFIX + '/quiz-game-banner',
    update: (id: number) => API_PREFIX + `/quiz-game-banner/${id}`,
    delete: (id: number) => API_PREFIX + `/quiz-game-banner/${id}`
  },
  HTVGo: {
    livestream: {
      getDetail: HTVGO_API_PREFIX + '/events/detail',
      updateStatus: HTVGO_API_PREFIX + '/events/interactive'
    }
  }
}

export const unAuthedApis = new Set<string>();
unAuthedApis.add(Apis.auth.login);
unAuthedApis.add(Apis.auth.refreshToken);