import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { InteractiveHistoryList } from "../../../components/livestream/interactive-history/InteractiveHistoryList";

const breadcrumbs = [
  { label: 'Livestream', link: '/app/livestream' },
  { label: 'Lịch sử vận hành', link: '/app/livestream/interactive/history/list' }
];

export function InteractiveHistoryListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <InteractiveHistoryList />
    </>
  )
}