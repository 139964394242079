import { IsDate, IsNumber, IsString, MaxLength, MinLength } from "class-validator";
import dayjs from "dayjs";
import { DtUtils } from "../../utils/DtUtils";
import { UpsertEventCalendarDto } from "./UpsertEventCalendarDto";

export class UpsertEventCalendarFormDto {
  @MaxLength(255, { message: 'Tên sự kiện chỉ cho phép tối đa 255 kí tự' })
  @MinLength(2, { message: 'Tên sự kiện phải có ít nhất 2 kí tự' })
  @IsString({ message: 'Tên sự kiện là bắt đầu' })
  name: string;

  @IsDate({ message: 'Ngày bắt đầu là bắt buộc' })
  startDate: Date;

  @IsDate({ message: 'Giờ bắt đầu là bắt buộc' })
  startTime: Date;

  @IsDate({ message: 'Ngày kết thúc là bắt buộc' })
  endDate: Date;

  @IsDate({ message: 'Giờ kết thúc là bắt buộc' })
  endTime: Date;

  @IsNumber({}, { message: 'Vui lòng chọn một app' })
  appId: number;

  toUpsertDto() {
    const upsertDto = new UpsertEventCalendarDto();
    upsertDto.appId = this.appId;
    upsertDto.name = this.name;
    upsertDto.startDt = DtUtils.mergeDt(this.startDate, this.startTime);
    upsertDto.endDt = DtUtils.mergeDt(this.endDate, this.endTime);
    return upsertDto;
  }

  checkDate() {
    const startDt = DtUtils.mergeDt(this.startDate, this.startTime);
    const endDt = DtUtils.mergeDt(this.endDate, this.endTime);
    return dayjs(endDt).isAfter(dayjs(startDt));
  }
}