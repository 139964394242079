import { Tab } from "@mantine/core";
import { useState } from "react";
import { useAppStore } from "../../../store/useAppStore";
import { AppRingCountDown } from "../../AppRingCountDown";
import { AppRoundedTabs } from "../../AppRoundedTabs";
import { OperateInteractiveSettings } from "./OperateInteractiveSettings";
import { OperateLeadboard } from "./OperateLeaderboard";
import { OperatePlayerList } from "./OperatePlayerList";
import { OperateScenario } from "./OperateScenario";

export function OperateInteractive() {
  const [tab, setTab] = useState(0);
  const { store } = useAppStore();
  const { autoPublishCountDown, autoPublishGap, itrNodeMap, currQuizItrId } = store;
  const currQuizItr = currQuizItrId !== undefined ? itrNodeMap[currQuizItrId] : null;

  return (
    <>
      {tab !== 0 && !!autoPublishCountDown && currQuizItr && (
        <div className="absolute top-3 right-0 rounded-l-xl bg-white border border-blue-400">
          <div className="w-80 h-8 whitespace-nowrap overflow-hidden flex items-center pl-2 text-sm">
            <AppRingCountDown
              size={28}
              thickness={1}
              textSize="xs"
              className="mr-2"
              countdown={autoPublishCountDown}
              percent={Math.round((autoPublishCountDown || 0) / autoPublishGap * 100)}
            />
            {currQuizItr.mountedQuiz?.quiz?.content}
          </div>
        </div>
      )}

      <div className="px-4">
        <AppRoundedTabs grow active={tab} onTabChange={setTab}>
          <Tab label="Kịch bản"></Tab>
          <Tab label="Người chơi"></Tab>
          <Tab label="Bảng điểm"></Tab>
          <Tab label="Cài đặt"></Tab>
        </AppRoundedTabs>
      </div>

      <div style={{ height: 'calc(100% - 40px)' }}>
        <div className={tab === 0 ? "block h-full" : "hidden"}>
          <OperateScenario />
        </div>

        {tab === 1 && (
          <OperatePlayerList />
        )}

        {tab === 2 && (
          <OperateLeadboard />
        )}

        {tab === 3 && (
          <div className="block h-full overflow-y-auto">
            <OperateInteractiveSettings />
          </div>
        )}
      </div>
    </>
  )
}