import dayjs from "dayjs";

export class DtUtils {

  static mergeDt(date: Date, time: Date) {
    const dateStr = dayjs(date).format('DD/MM/YYYY') + ' ' + dayjs(time).format('HH:mm:ss');
    return dayjs(dateStr, 'DD/MM/YYYY HH:mm:ss').toDate();
  }

  static excludePastDate(date: Date) {
    return dayjs(date).isBefore(dayjs(), 'date');
  }
}