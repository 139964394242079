import { AppConfig } from "../config/AppConfig";
import queryString from 'querystring';

export class QueryUtils {
  
  static parse(qs: string) {
    const params = queryString.parse(qs.replace('?', ''));
    const queryDto: any = {};
    if (params.page) {
      queryDto.paging = { 
        page: Number(params.page),
        pageSize: AppConfig.paging.DEFAULT_PAGE_SIZE
      }
    }
    if (params.pageSize) {
      queryDto.paging.pageSize = Number(params.pageSize);
    }
    if (params.searchTerm) {
      queryDto.searchTerm = params.searchTerm;
    }

    Object.entries(params).forEach(([key, value]) => {
      if (key.startsWith("flt-")) {
        if (!queryDto.filters) {
          queryDto.filters = {};
        }

        let typedValue: any;
        if (key.startsWith('flt-b')) {
          key = key.replace('flt-b', '');
          typedValue = value === 'true';
        }

        if (key.startsWith('flt-n')) {
          key = key.replace('flt-n', '');
          typedValue = Number(value);
        }

        if (key.startsWith('flt-s')) {
          key = key.replace('flt-s', '');
          typedValue = value;
        }

        if (typedValue) {
          queryDto.filters[key] = value;
        }
      }
    });

    return queryDto;
  }

  static stringify(queryDto: any) {
    let params: any = {
      page: queryDto.paging.page,
      pageSize: queryDto.paging.pageSize,
    };
    
    if (queryDto.searchTerm) {
      params.searchTerm = queryDto.searchTerm
    }

    if (queryDto.filters) {
      for (const key in queryDto.filters) {
        let t = 's';
        if (typeof queryDto.filters[key] === 'number') {
          t = 'n';
        }
        if (typeof queryDto.filters[key] === 'boolean') {
          t = 'b';
        }
        params[`flt-${t}-${key}`] = queryDto.filters[key];
      }
    }

    return queryString.stringify(params);
  }
}