import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { NotificationsProvider } from '@mantine/notifications';
import { BrowserRouter } from 'react-router-dom';

import './styles/tailwind.min.css';
import 'react-complex-tree/lib/style.css';
import './styles/index.css';

import { App } from './App';
import { AppStore } from './store/AppStore';
import { AxiosUtils } from './utils/AxiosUtils';
import { LogService } from './services/LogService';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

// eslint-disable-next-line
Date.prototype.toJSON = function () {
  return dayjs(this).format('YYYY-MM-DDTHH:mm:ss');
}

AxiosUtils.initAxiosInterceptors();

(window as any).queryLog = LogService.query;

ReactDOM.render(
  <BrowserRouter>
    <NotificationsProvider>
      <AppStore>
        <App />
      </AppStore>
    </NotificationsProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
