import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { QuizList } from "../../../components/quiz/QuizList";

const breadcrumbs = [
  { label: 'Tương tác', link: '/app/interactive' },
  { label: 'Câu hỏi', link: '/app/interactive/quiz/list' }
]

export function QuizListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} /> 
      <QuizList zIndex={3} creatable={true} />
    </>
  )
}