import { CloseButton, Textarea } from "@mantine/core";
import { useCallback, useEffect, useMemo } from "react";
import { UpsertPollOptionDto } from "../../dtos/poll/UpsertPollDto";
import { useAppFormik } from "../../hooks/useAppFormik";

interface UpsertPollOptionFormProps {
  option?: UpsertPollOptionDto;
  elementRef: any;
  numOrder: number;
  onDelete: () => any;
  onChange: () => any;
  removable?: boolean;
}

export function UpsertPollOptionForm(props: UpsertPollOptionFormProps) {
  const { option, elementRef, onChange } = props;
  const initialValues = useMemo(() => {
    const upsertDto = new UpsertPollOptionDto();
    if (option) {
      upsertDto.content = option.content;
    }
    return upsertDto;
  }, [option]);

  const submit = useCallback(() => {}, []);
  const formik = useAppFormik({ initialValues, onSubmit: submit });
  const handleChange = useCallback((e: any) => {
    formik.handleChange(e);
    onChange && onChange();
  }, [onChange, formik]);

  useEffect(() => {
    elementRef.current = { formik }
    onChange && onChange();
  }, [elementRef, formik, onChange]);

  const isError =formik.touched.content && !!formik.errors.content;

  return (
    <>
      <div className={"border rounded  " + (isError ? "border-red-400" : "border-gray-300")}>
        <div className="w-full rounded-t bg-gray-100 flex items-center px-2 text-sm h-8">
          <div>
            Câu trả lời {props.numOrder} <b className="text-red-400">*</b> 
            <span className="text-xs ml-2">({formik.values.content?.length || 0} / 100)</span>
          </div>
          {props.removable && (
            <div className="ml-auto">
              <CloseButton color="gray" onClick={props.onDelete} />
            </div>
          )}
        </div>

        <div className="px-3 py-1">
          <Textarea
            required
            name="content"
            variant="unstyled"
            placeholder="Nội dung câu trả lời..."
            onChange={handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.content}
          />
        </div>

      </div>
      {isError && (
        <div className="text-red-400 text-sm mt-1">{formik.errors.content}</div>
      )}
    </>
  )
}