import axios, { AxiosRequestConfig } from "axios";
import { Apis } from "../config/ApiConfig";
import { HTVGoResponseDto } from "../dtos/htvgo/HTVGoResponseDto";
import { HTVGoLivestreamDetailDto } from "../dtos/htvgo/livestream/HTVGoLivestreamDetailDto";
import { HTVGoLivestreamStatus } from "../enums/HTVGoLivestreamStatus";
import { NotificationUtils } from "../utils/NotificationUtils";

export class HTVGoService {

  static getLivestreamDetail(id: number) {
    return this.request<HTVGoLivestreamDetailDto>({
      method: 'GET',
      url: Apis.HTVGo.livestream.getDetail,
      params: { id }
    });
  }

  static async updateLivestreamStatus(id: number, status: HTVGoLivestreamStatus) {
    return this.request({
      method: 'POST',
      url: Apis.HTVGo.livestream.updateStatus,
      data: {
        id, status
      }
    });
  }

  static async startLivestream(dto: HTVGoLivestreamDetailDto) {
    // let error = '';
    // if (!dto.isConfirm) {
    //   error = 'Vui lòng xác nhận livestream trước khi bắt đầu';
    // }
    // if (!dto.isCompleted) {
    //   error = 'Không thể bắt đầu livestream đã kết thúc';
    // }
    // if (dto.isRunning) {
    //   error = 'Livestream đã được bắt đầu';
    // }

    // if (error) {
    //   NotificationUtils.error({
    //     title: 'Không thể bắt đầu livestream',
    //     message: error
    //   });
    //   return;
    // }

    return this.updateLivestreamStatus(dto.id, HTVGoLivestreamStatus.BROADCAST);
  }

  static async stopLivestream(dto: HTVGoLivestreamDetailDto) {
    // let error = '';
    // if (!dto.isRunning) {
    //   error = 'Không thể kết thúc livestream chưa bắt đầu';
    // }
    // if (dto.isCompleted) {
    //   error = 'Livestream đã kết thúc';
    // }

    // if (error) {
    //   NotificationUtils.error({
    //     title: 'Không thể kết thúc livestream',
    //     message: error
    //   });
    //   return;
    // }

    return this.updateLivestreamStatus(dto.id, HTVGoLivestreamStatus.STOP);
  }

  static async request<R>(config: AxiosRequestConfig) {
    const resp = await axios(config);
    const data: HTVGoResponseDto<R> = resp.data;

    if (!data.success) {
      NotificationUtils.error({ title: 'Có lỗi xảy ra', message: data.message || 'Không xác định' });
    }

    return data.data;
  }
}