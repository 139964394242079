export enum LogAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  REORDER = 'REORDER',
  DELETE = 'DELETE',
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  PUBLISH = 'PUBLISH',
  AUTO_PUBLISH = 'AUTO_PUBLISH',
  FINISH = 'FINISH',
  AUTO_FINISH = 'AUTO_FINISH',
  PAUSE = 'PAUSE',
  RESUME = 'RESUME',
  SELECT = 'SELECT',
  PUBLISH_LEADERBOARD = 'PUBLISH_LEADERBOARD',
  CONNECT = 'CONNECT',
  RECONNECT = 'RECONNECT',
  DISCONNECT = 'DISCONNECT',
  ANSWER = 'ANSWER'
}

export const LogActionDisplay = {
  [LogAction.CREATE]: 'Tạo mới',
  [LogAction.UPDATE]: 'Cập nhật',
  [LogAction.REORDER]: 'Đổi thứ tự',
  [LogAction.DELETE]: 'Xoá',
  [LogAction.ENABLE]: 'Kích hoạt',
  [LogAction.DISABLE]: 'Vô hiệu hoá',
  [LogAction.PUBLISH]: 'Publish',
  [LogAction.AUTO_PUBLISH]: 'Tự động publish',
  [LogAction.FINISH]: 'Kết thúc',
  [LogAction.AUTO_FINISH]: 'Tự động kết thúc',
  [LogAction.PAUSE]: 'Pause',
  [LogAction.RESUME]: 'Resume',
  [LogAction.SELECT]: 'Select',
  [LogAction.PUBLISH_LEADERBOARD]: 'Publish BXH',
  [LogAction.CONNECT]: 'Connect',
  [LogAction.DISCONNECT]: 'Disconnect',
  [LogAction.ANSWER]: 'Trả lời'
}