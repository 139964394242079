import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { unAuthedApis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { AuthService } from "../services/AuthService";

export class AxiosUtils {

  static redirectToLogin() {
    window.location.replace('/login?redirect=' + encodeURIComponent(window.location.href));
  }

  static initAxiosInterceptors() {
    axios.interceptors.request.use((req: AxiosRequestConfig) => {
      if (!unAuthedApis.has(req.url as string)) {
        const accessToken = AuthService.accessToken;
        if (!accessToken) {
          this.redirectToLogin();
        } else {
          req.headers.Authorization = accessToken;
        }
      }
      return req;
    });

    axios.interceptors.response.use(
      async resp => {
        if (!unAuthedApis.has(resp.config.url as string)) {
          if (resp.data.code === ResponseCode.UNAUTHORIZED.code) {
            const success = await AuthService.refreshAccessToken();
            if (success) {
              if (typeof resp.config.data === 'string') {
                resp.config.data = JSON.parse(resp.config.data);
              }
              return axios.request(resp.config);
            }

            if (window.location.pathname !== '/login') {
              // redirect to login page
              this.redirectToLogin();
  
              // wait for redirect
              await new Promise((resolve) => {
                setTimeout(resolve, 3000);
              });
            }
          }
        }

        return resp;
      },
      (err: AxiosError) => Promise.reject(err)
    );
  }

}