import { Button } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import { ProjectDto } from "../../dtos/project/ProjectDto";
import { ScenarioDto } from "../../dtos/scenario/ScenarioDto";
import { PublishStatus } from "../../enums/PublishStatus";
import { AppFixedModal } from "../AppFixedModal";
import { ProjectList } from "../project/ProjectList";
import { ScenarioList, scenarioListFolderClassNames } from "./ScenarioList";

interface ScenarioSelectModalProps {
  zIndex: number;
  onClose: () => any;
  onSelect: (scenario: ScenarioDto) => any;
}

export function ScenarioSelectModal(props: ScenarioSelectModalProps) {
  const [step, setStep] = useState(1);
  const [project, setProject] = useState<ProjectDto | null>(null);
  const [scenario, setScenario] = useState<ScenarioDto | null>(null);

  const selectProject = useCallback((project: ProjectDto) => {
    setProject(project);
    setStep(2);
  }, []);

  const projectListFolderOverrides = useMemo(() => ({
    onSelect: selectProject
  }), [selectProject]);

  const selectScenario = useCallback((scenario: ScenarioDto) => {
    if (scenario.publishStatus !== PublishStatus.FINISHED) {
      setScenario(scenario);
      setStep(3);
    }
  }, []);

  const scenarioListFolderOverrides = useMemo(() => ({
    folderClassName: (item: ScenarioDto) => {
      if (item.id === scenario?.id) {
        return (item.id === scenario?.id ? "border-blue-400 text-blue-400" : "");
      }
      return scenarioListFolderClassNames[item.publishStatus];
    },
    onSelect: selectScenario
  }), [scenario, selectScenario]);

  return (
    <AppFixedModal 
      title="Chọn kịch bản"
      zIndex={props.zIndex}
      onClose={props.onClose}
    >
      <div className="p-4">
        <Stepper activeStep={step} className="mb-6">
          <Step label="Chọn dự án" onClick={() => {
            setStep(1);
            setProject(null);
            setScenario(null);
          }} />
          <Step label="Chọn kịch bản" />
          <Step label="Hoàn thành" />
        </Stepper>

        {step === 1 && (
          <ProjectList 
            zIndex={props.zIndex + 1}
            creatable={true}
            folderOverrides={projectListFolderOverrides}
          />
        )}

        {step >= 2 && project && (
          <ScenarioList
            projectId={project?.id}
            zIndex={props.zIndex + 1}
            creatable={true}
            folderOverrides={scenarioListFolderOverrides}
          />
        )}

        {step === 3 && scenario && (
          <Button variant="gradient" onClick={() => props.onSelect(scenario)}>
            Đồng ý
          </Button>
        )}
      </div>
    </AppFixedModal>
  )
}