import { Button } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { PollDto } from "../../dtos/poll/PollDto";
import { useCompKey } from "../../hooks/useCompKey";
import { PollService } from "../../services/PollService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { AppConfirmModal } from "../AppConfirmModal";
import { AppFixedModal } from "../AppFixedModal";
import { AppFlexFolders, AppFlexFoldersProps } from "../AppFlexFolders";
import { FlexListQueryDto } from "../AppFlexList";
import { PollPreview } from "./PollPreview";
import { UpsertPollForm } from "./UpsertPollForm";

interface PollListProps {
  zIndex: number;
  folderOverrides?: Partial<AppFlexFoldersProps<PollDto>>;
  onCreateSuccess?: (poll: PollDto) => any;
  onUpdateSuccess?: (poll: PollDto) => any;
  creatable?: boolean;
}

export function PollList(props: PollListProps) {
  const { zIndex, folderOverrides, creatable, onCreateSuccess, onUpdateSuccess } = props;
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState<PollDto | null>(null);
  const [del, setDel] = useState<PollDto | null>(null);
  const compKey = useCompKey();

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return PollService.query({
      ...queryDto,
      sorts: [
        { field: 'updateDt', descending: true }
      ],
      filters: {
        title: queryDto.searchTerm,
        disabled: false
      }
    })
  }, []);

  const deletePoll = useCallback(async (poll: PollDto) => {
    const success = await PollService.toggle(poll.id);
    if (success) {
      NotificationUtils.success({ message: <>Xoá khảo sát <b>{poll.title} thành công!</b></> });
      setDel(null);
      compKey.renew();
    }
  }, [compKey]);

  const handleUpsertPollSuccess = useCallback((poll: PollDto) => {
    if (create) {
      onCreateSuccess && onCreateSuccess(poll);
    } else {
      onUpdateSuccess && onUpdateSuccess(poll);
    }
  }, [onCreateSuccess, onUpdateSuccess, create]);

  const actions = useMemo(() => {
    if (!creatable) {
      return null; 
    }
    return (
      <Button variant="gradient" onClick={() => setCreate(true)} leftIcon={<HiPlusCircle />}>
        Thêm khảo sát
      </Button>
    );
  }, [creatable]);

  return (
    <>
      {(create || !!update) && (
        <AppFixedModal
          title={(create ? "Thêm" : "Cập nhật") + " khảo sát"}
          zIndex={props.zIndex}
          onClose={() => {
            setCreate(false);
            setUpdate(null);
            
            setTimeout(() => {
              compKey.renew();
            }, 0);
          }}
        >
          <div className="p-4">
            <UpsertPollForm 
              template={true}
              submitable={true} 
              preview={true} 
              poll={update} 
              onSuccess={handleUpsertPollSuccess}
            />
          </div>
        </AppFixedModal>
      )}

      <AppConfirmModal 
        opened={!!del} 
        onClose={() => setDel(null)}
        onConfirm={() => del && deletePoll(del)}
      >
        Bạn có chắc muốn xoá khảo sát <b>{del?.title}</b> chứ?
      </AppConfirmModal>

      <AppFlexFolders
        key={compKey.key}
        fetchData={fetchData as any}
        folderWrapperClassName="w-full md:w-1/2 mb-4 pr-4 text-sm"
        folderLabelRenderer={(poll: PollDto) => poll.title}
        folderPreviewRenderer={(poll: PollDto) => (
          <PollPreview poll={poll} />
        )}
        actions={actions}
        onSelect={setUpdate}
        onUpdate={setUpdate}
        onDelete={setDel}
        zIndex={zIndex + 1}
        {...folderOverrides}
      />
    </>
  )
}