import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { PageDto } from "../dtos/common/PageDto";
import { QueryUserDto } from "../dtos/user/QueryUserDto";
import { UpdateProfileDto } from "../dtos/user/UpdateProfileDto";
import { UpdateUserDto } from "../dtos/user/UpdateUserDto";
import { UpsertUserDto } from "../dtos/user/UpsertUserDto";
import { UserDto } from "../dtos/user/UserDto";
import { HttpUtils } from "../utils/HttpUtils";

export class UserService {
  
  static async query(queryDto: QueryUserDto) {
    return (await HttpUtils.post<PageDto<UserDto>>(Apis.user.query, queryDto)).body;
  }

  static async checkUsername(username: string) {
    return (await HttpUtils.get(Apis.user.checkUsername, { username })).body;
  }

  static create(createDto: UpsertUserDto) {
    return HttpUtils.post(Apis.user.create, createDto);
  }

  static async update(id: number, updateDto: UpdateUserDto) {
    return (await HttpUtils.put(Apis.user.update(id), updateDto)).code === ResponseCode.OK.code;
  }

  static async updateProfile(updateDto: UpdateProfileDto) {
    return (await HttpUtils.put(Apis.user.updateProfile, updateDto)).code === ResponseCode.OK.code;
  }

  static async updateAvatar(file: any) {
    const formData = new FormData();
    formData.append('avatar', file);
    return HttpUtils.post(Apis.user.updateAvatar, formData);
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.user.toggle(id))).code === ResponseCode.OK.code;
  }
}