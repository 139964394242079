import { IsNotEmpty, IsNumber, IsObject, IsOptional, IsString, Max, MaxLength, Min } from "class-validator";

export class ShowNotificationDto {

  @MaxLength(100, { message: 'Nội dung chỉ được tối đa 100 kí tự' })
  @IsNotEmpty({ message: 'Vui lòng không bỏ trống nội dung' })
  @IsString({ message: 'Vui lòng nhập nội dung' })
  description: string;
  
  @IsOptional()
  image: string;

  @IsOptional()
  expireAt?: number;

  @Max(3600, { message: 'Thời gian tồn tại tối đa là 1 giờ' })
  @Min(30, { message: 'Thời gian tồn tại tối thiểu là 30 giây' })
  @IsNumber({}, { message: 'Thời gian tồn tại không hợp lệ' })
  @IsOptional()
  ttlInSeconds?: number;

  @IsObject({ message: 'Vui lòng chọn một hình ảnh' })
  imageFile?: any;
}