import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { QuizGameList } from "../../../components/quiz-game/QuizGameList";

const breadcrumbs = [
  { label: 'Tương tác', link: '/app/interactive' },
  { label: 'Bộ câu hỏi', link: '/app/interactive/quiz-game/list' }
]

export function QuizGameListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <QuizGameList zIndex={3} creatable={true} />
    </>
  )
}