import { useMemo } from "react";
import { RouteComponentProps } from "react-router";
import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { ScenarioList } from "../../../components/scenario/ScenarioList";

export function ProjectDetailPage(props: RouteComponentProps) {
  const { id } = props.match.params as any;
  const { pathname } = props.location;
  const state: any = props.location.state;

  const breadcrumbs = useMemo(() => {
    const items: any[] = [
      { label: 'Tương tác', link: '/app/interactive' },
      { label: 'Dự án', link: '/app/interactive/project/list' }
    ];
    if (state) {
      items.push({ label: state.projectName, link: pathname });
    } else {
      items.push({ label: id, link: pathname });
    }
    return items;
  }, [id, state, pathname]);

  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <ScenarioList zIndex={3} projectId={id} creatable />
    </>
  )
}