import { Modal } from "@mantine/core";
import { useCallback, useEffect, useState } from "react";
import { FiPlusSquare } from "react-icons/fi";
import { UploadFileDto } from "../../dtos/upload-file/UploadFileDto";
import { UploadFileDisplay } from "./UploadFileDisplay";
import { UploadFilesSelect } from "./UploadFilesSelect";

interface UploadFileInputProps {
  file?: UploadFileDto;
  onChange?: (file: UploadFileDto) => any;
  onDelete?: (file: UploadFileDto) => any;
  upload: (file: UploadFileDto) => any;
  label: any;
  mime?: 'image' | 'video' | 'file';
  maxSize?: number;
  fileLabelRender?: (file: UploadFileDto) => any;
  displayHeight?: any;
  folder?: string;
}

export function UploadFileInput(props: UploadFileInputProps) {
  const { file: propsFile, label, mime, onChange, onDelete, maxSize, upload, folder } = props;

  const [select, setSelect] = useState(false);
  const [file, setFile] = useState<UploadFileDto | undefined>(propsFile);

  const handleFileChange = useCallback((file: UploadFileDto) => {
    setFile(file);
    setSelect(false);
    onChange && onChange(file);
  }, [onChange]);

  useEffect(() => {
    setFile(propsFile);
  }, [propsFile]);

  const handleDelete = useCallback(async (uf: UploadFileDto) => {
    if (onDelete) {
      const success = await onDelete(uf);
      if (success) {
        setFile(undefined);
      }
    }
  }, [onDelete]);

  return (
    <>
      <Modal
        size="80%"
        opened={select}
        onClose={() => setSelect(false)}
        title={label}
        overflow="inside"
      >
        <UploadFilesSelect folder={folder} upload={upload} maxSize={maxSize} mime={mime} onChange={handleFileChange} onCancel={() => setSelect(false)} />
      </Modal>

      <div className="w-full border rounded p-3 bg-gray-700 text-white hover:bg-gray-900 cursor-pointer" onClick={() => setSelect(true)}>
        {file ? (
          <UploadFileDisplay onDelete={handleDelete} file={file} displayHeight={props.displayHeight || "30vh"} labelRender={props.fileLabelRender} />
        ) : (
          <div className="flex items-center text-sm">
            <FiPlusSquare className="mr-2" /> {label}
          </div>
        )}
      </div>
    </>
  )
}