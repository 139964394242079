import { Button, Divider, Modal } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { AppDto } from "../../../dtos/app/AppDto";
import { useCompKey } from "../../../hooks/useCompKey";
import { AppService } from "../../../services/AppService";
import { FormatUtils } from "../../../utils/FormatUtils";
import { AppActionButtons } from "../../AppActionButtons";
import { FlexListQueryDto } from "../../AppFlexList";
import { AppTable, AppTableColumn } from "../../AppTable";
import { AppToggle } from "../../AppToggle";
import { UpsertAppForm } from "./UpsertAppForm";

export function AppList() {
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState<AppDto | undefined>();

  const tableKey = useCompKey();
  const columns = useMemo(() => {
    return [
      { title: 'ID', render: app => app.id },
      { title: 'Tên app', render: app => app.name },
      { title: 'Cập nhật lúc', render: app => FormatUtils.dt(app.updateDt) },
      { title: 'Trạng thái', render: app => <AppToggle id={app.id} checked={!app.disabled} toggle={AppService.toggle} /> },
      { title: 'Hành động', render: app => <AppActionButtons editBtnLabel="Chi tiết" onEdit={() => setUpdate(app)} /> }
    ] as AppTableColumn<AppDto>[];
  }, []);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return AppService.query({
      ...queryDto,
      sorts: [
        { field: 'updateDt', descending: true }
      ],
      filters: {
        name: queryDto.searchTerm
      }
    })
  }, []);

  const createBtn = useMemo(() => (
    <Button variant="gradient" onClick={() => setCreate(true)} leftIcon={<HiPlusCircle />}>
      Thêm app
    </Button>
  ), []);

  const handleAppChange = useCallback(() => {
    setCreate(false);
    setUpdate(undefined);
    tableKey.renew();
  }, [tableKey]);

  return (
    <>
      <Modal 
        opened={create || !!update} 
        title={create ? "Thêm app" : "Cập nhật app"}
        onClose={() => {
          setCreate(false);
          setUpdate(undefined);
        }}
      >
        <Divider my="lg" />
        <UpsertAppForm app={update} onChange={handleAppChange} />
      </Modal>

      <AppTable 
        key={tableKey.key}
        fetchData={fetchData}
        columns={columns}
        actions={createBtn}
      />
    </>
  )
}