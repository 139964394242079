import { useEffect } from "react";
import { ActionType } from "../enums/ActionType";
import { AuthService } from "../services/AuthService";
import { useAppStore } from "../store/useAppStore";

export function useSession() {
  const { store, dispatch } = useAppStore();
  const { session } = store;

  useEffect(() => {
    if (!session.loading && !session.ready) {
      dispatch(ActionType.SET_SESSION, {
        loading: true,
        ready: false,
      });

      (async () => {
        dispatch(ActionType.SET_SESSION, {
          loading: false,
          ready: true,
          user: await AuthService.getUser()
        });
      })();
    }
  }, [dispatch, session.loading, session.ready]);

  return session;
}