export class EsService {

  static doRequest(method: string, index: string, path: string, body: any) {
    return fetch(`${process.env.REACT_APP_ES_HOST}/${index}/${path}`, {
      method,
      headers: {
        'Authorization': 'Basic ' + process.env.REACT_APP_ES_TOKEN,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    });
  }

  static search(index: string, body: any) {
    return this.doRequest('POST', index, '_search', body).then(res => res.json());
  }
}