import { Avatar, Divider, Menu, MenuItem, Paper } from "@mantine/core";
import { ExitIcon, GearIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { UserDto } from "../dtos/user/UserDto";
import { AuthService } from "../services/AuthService";
import { S3Utils } from "../utils/S3Utils";

interface AppHeaderUserMenuProps {
  user?: UserDto;
}

export function AppHeaderUserMenu(props: AppHeaderUserMenuProps) {
  const { user } = props;
  const logout = useCallback(() => {
    (async () => {
      const success = await AuthService.logout();
      if (success) {
        window.location.reload();
      }
    })();
  }, []);

  return (
    <Menu 
      trigger="hover"
      placement="end"
      delay={500}
      control={(
        <Paper>
          <div className="flex items-center hover:text-blue-400 text-sm">
            <Avatar radius="xl" className="mr-2" src={S3Utils.toS3Url(user?.avatar)} alt="Avatar" color="blue" />
            <div className="mr-1">{user?.lastName}</div>
            <TriangleDownIcon />
          </div>
        </Paper>
      )}
    >
      <MenuItem icon={<GearIcon />} component={Link} to="/app/setting">Cài đặt</MenuItem>
      <Divider />
      <MenuItem icon={<ExitIcon />} color="red" onClick={logout}>Đăng xuất</MenuItem>
    </Menu>
  )
}