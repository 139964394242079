import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { PageDto } from "../dtos/common/PageDto";
import { EventCalendarDto } from "../dtos/event-calendar/EventCalendarDto";
import { QueryEventCalendarDto } from "../dtos/event-calendar/QueryEventCalendarDto";
import { UpsertEventCalendarDto } from "../dtos/event-calendar/UpsertEventCalendarDto";
import { HttpUtils } from "../utils/HttpUtils";

export class EventCalendarService {

  static async query(queryDto: QueryEventCalendarDto) {
    return (await HttpUtils.post<PageDto<EventCalendarDto>>(Apis.eventCalendar.query, queryDto)).body;
  }

  static async findById(id: number) {
    return (await HttpUtils.get<EventCalendarDto>(Apis.eventCalendar.findById(id))).body;
  }

  static create(dto: UpsertEventCalendarDto) {
    return HttpUtils.post(Apis.eventCalendar.create, dto);
  }

  static update(id: number, dto: UpsertEventCalendarDto) {
    return HttpUtils.put(Apis.eventCalendar.update(id), dto);
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.eventCalendar.toggle(id))).code === ResponseCode.OK.code;
  }
}