import { HTVGoLivestreamDetailDto } from "../../dtos/htvgo/livestream/HTVGoLivestreamDetailDto";
import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { OperateService } from "../../services/OperateService";

export function reduceSetHTVGoLivestream(state: AppGlobalStateDto, livestream: HTVGoLivestreamDetailDto): AppGlobalStateDto {
  OperateService.setCurrRoomId(livestream.streamKey);
  return {
    ...state,
    isRoomOpened: state.isRoomOpened || livestream.isRunning,
    currRoomId: livestream.streamKey,
    currHTVGoLivestream: livestream
  }
}