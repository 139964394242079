import { Redirect, Route, Switch } from "react-router";
import { UserListPage } from "./UserListPage";

export function UserPage() {
  return (
    <Switch>
      <Redirect exact from="/app/system/user" to="/app/system/user/list" />
      <Route path="/app/system/user/list" component={UserListPage} />
    </Switch>
  )
}