import { useNotifications } from "@mantine/notifications";
import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AppAuthedRoute } from "./components/AppAuthedRoute";
import { AppFlashScreen } from "./components/AppFlashScreen";
import { useSession } from "./hooks/useSession";
import { AppPage } from "./pages/AppPage";
import { LoginPage } from "./pages/auth/LoginPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { NotificationUtils } from "./utils/NotificationUtils";

export function App() {
  const notifications = useNotifications();
  
  useEffect(() => {
    NotificationUtils.init(notifications);
  }, [notifications]);

  const session = useSession();

  if (!session.ready) {
    return <AppFlashScreen />;
  }

  return (
    <Switch>
      <Redirect path="/" exact to={session.user ? "/app" : "/login"} />
      <Route path="/login" component={LoginPage} />
      <AppAuthedRoute path="/app" component={AppPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}