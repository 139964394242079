import { Avatar, Button, LoadingOverlay, Select, Table, Checkbox, NumberInput } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FiInbox, FiRefreshCcw } from "react-icons/fi";
import { AppConfig } from "../../../config/AppConfig";
import { InteractionType } from "../../../enums/InteractionType";
import { PublishStatus } from "../../../enums/PublishStatus";
import { useQuery } from "../../../hooks/useQuery";
import { QuizGameService } from "../../../services/QuizGameService";
import { useAppStore } from "../../../store/useAppStore";
import { AppPagination } from "../../AppPagination";
import { OperateRoomNotExists } from "../OperateRoomNotExists";
import { QuizMode } from '../../../enums/QuizMode';
import { NotificationUtils } from "../../../utils/NotificationUtils";
import { OperateService } from "../../../services/OperateService";

const approx = require('approximate-number');

export function OperateLeadboard() {
  const { store } = useAppStore();
  const { currQuizGameItrId, itrNodeMap, itrNodes, currRoomId, isRoomOpened } = store;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(AppConfig.paging.LEADERBOARD_PAGE_SIZE);
  const [selectedQGItrId, setSelectedQGItrId] = useState(currQuizGameItrId);
  const [full, setFull] = useState(false);

  const quizGameId = selectedQGItrId;
  const quizGameItr = quizGameId !== undefined ? itrNodeMap[quizGameId] : null;
  // const isConfetti = quizGameItr?.quizGame?.quizMode === QuizMode.CONFETTI;

  useEffect(() => {
    if (currRoomId) {
      setPageSize(OperateService.getLeaderboardSize(currRoomId));
    }
  }, [currRoomId]);

  const fetchLeaderboard = useMemo(() => {
    if (quizGameId && currRoomId) {
      return () => QuizGameService.paginateLeaderboard(quizGameId, { page, pageSize, full });
    }
  }, [quizGameId, page, pageSize, currRoomId, full]);
  
  const resp = useQuery(fetchLeaderboard);

  const publishLeaderboard = useCallback(async () => {
    if (quizGameId !== undefined && !!currRoomId) {
      const success = await QuizGameService.publishLeaderboard(quizGameId, { page, full, pageSize });
      if (success) {
        NotificationUtils.success({ message: 'Publish bảng điểm thành công' });
      }
    }
  }, [currRoomId, quizGameId, page, pageSize, full]);

  const qgSelectData = useMemo(() => {
    return itrNodes
      .filter(itr => itr.interactionType === InteractionType.QUIZ_GAME && itr.publishStatus !== PublishStatus.IDLE)
      .map(itr => ({ value: String(itr.id), label: itr.quizGame?.name }));
  }, [itrNodes]);

  useEffect(() => {
    if (currQuizGameItrId) {
      setSelectedQGItrId(currQuizGameItrId);
    }
  }, [currQuizGameItrId]);

  if (!currRoomId || !isRoomOpened) {
    return <OperateRoomNotExists />
  }

  const total = resp.data?.total || 0;
  const isConfetti = quizGameItr?.quizGame?.quizMode === QuizMode.CONFETTI;

  return (
    <>
      <div className="p-4 relative overflow-auto" style={{ height: 'calc(100% - 3.5rem)' }}>
        <LoadingOverlay visible={resp.loading} />
        <div className="flex items-center">
          <h3 className="text-sm font-bold">Bảng điểm {total > 0 && `(${approx(total)})`}</h3>
          {currRoomId && (
            <FiRefreshCcw 
              className="ml-2 text-blue-400 cursor-pointer"
              onClick={() => resp.reFetch()}
            />
          )}
        </div>

        <div className="flex items-center mt-4">
          {qgSelectData && qgSelectData.length > 0 && (
            <Select 
              required
              label="Bộ câu hỏi"
              placeholder="Chọn bộ câu hỏi..."
              className="w-1/2 mr-4"
              data={qgSelectData}
              value={String(selectedQGItrId)}
              onChange={v => setSelectedQGItrId(Number(v))}
            />
          )}

          <NumberInput 
            required
            label="Số dòng hiển thị"
            placeholder="Số dòng hiển thị..."
            className="w-1/2"
            min={3}
            max={50}
            value={pageSize}
            onChange={v => setPageSize(Math.min(Math.max(v || 0, 3), 50))}
          />
        </div>

        <div className="mt-4">
          {resp.ready && resp.data && resp.data.content?.length > 0 ? (
            <>
              <Table striped highlightOnHover>
                <thead>
                  <tr>
                    <th className="text-left text-sm px-2">Hạng</th>
                    <th className="text-left text-sm px-2">Người chơi</th>
                    <th className="text-left text-sm px-2">Điểm</th>
                  </tr>
                </thead>
                <tbody>
                  {resp.data.content.map((row, idx) => (
                    <tr key={idx}>
                      <td>{row.rank || ((page - 1) * pageSize + idx + 1)}</td>
                      <td>
                        <div className="flex items-center">
                          <Avatar color="blue" size="sm" radius="xl" src={row.clientInfo.avatar} alt={row.clientInfo.name} />
                          <div className="ml-2 font-medium">{row.clientInfo.name}</div>
                        </div>
                      </td>
                      <td>{row.totalScore}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="mt-4 flex justify-center">
                <AppPagination
                  current={page}
                  pageSize={pageSize}
                  total={resp.data.total}
                  onChange={page => setPage(page)}
                  showLessItems={true}
                />
              </div>
            </>
          ) : (
            <div className="pt-20 w-full flex flex-col items-center justify-center text-gray-400">
              <FiInbox className="text-6xl mb-2" />
              <div className="text-sm">Bảng điểm rỗng</div>
            </div>
          )}
        </div>
      </div>

      <div className="w-full bg-gray-100 px-4 h-14 flex items-center">
        <div className="ml-auto">
          {currRoomId && quizGameItr && (
            <div className="flex items-center">
              {isConfetti && (
                <Checkbox className="mr-4" label="Đầy đủ" checked={full} onClick={() => setFull(v => !v)} />
              )}
              <Button onClick={publishLeaderboard}>Hiện bảng điểm</Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}