import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { UpdateNumOrderRequestDto } from "../dtos/common/UpdateNumOrderDto";
import { CreateInteractionDto } from "../dtos/interaction/CreateInteractionDto";
import { InteractionDto } from "../dtos/interaction/InteractionDto";
import { InteractionFiltersDto } from "../dtos/interaction/InteractionFiltersDto";
import { ModifyInteractionDto } from "../dtos/interaction/ModifyInteractionDto";
import { HttpUtils } from "../utils/HttpUtils";

export class InteractionService {
  
  static async find(filters: InteractionFiltersDto) {
    return (await HttpUtils.post<InteractionDto[]>(Apis.interaction.find, filters)).body;
  }

  static async findById(id: number) {
    return (await HttpUtils.get<InteractionDto>(Apis.interaction.findById(id))).body;
  }

  static create(createDto: CreateInteractionDto) {
    return HttpUtils.post<InteractionDto>(Apis.interaction.create, createDto);
  }

  static update(id: number, updateDto: ModifyInteractionDto) {
    return HttpUtils.put<InteractionDto>(Apis.interaction.update(id), updateDto);
  }

  static async updateNumOrder(requestDto: UpdateNumOrderRequestDto) {
    return (await HttpUtils.put(Apis.interaction.updateNumOrder, requestDto)).code === ResponseCode.OK.code;
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.interaction.toggle(id))).code === ResponseCode.OK.code;
  }
}