import { LoadingOverlay, Tab, Tabs } from "@mantine/core";
import { QuizGameDto } from "../../dtos/quiz-game/QuizGameDto";
import { QuizGameEditorTab } from "../../enums/QuizGameEditorTab";
import { IQuizGameEditor, useQuizGameEdtior } from "../../hooks/useQuizGameEditor";
import { MountAndUpsertQuizForm } from "../mounted-quiz/MountAndUpsertQuizForm";
import { MountQuizForm } from "../mounted-quiz/MountQuizForm";
import { UpsertQuizGameBannerForm } from "../quiz-game-banner/UpsertQuizGameBannerForm";
import { QuizGameSideListItem } from "./QuizGameSideListItem";
import { UpsertQuizGameForm } from "./UpsertQuizGameForm";

interface QuizGameEditorComponentProps {
  zIndex: number;
  editor: IQuizGameEditor;
}

export function QuizGameEditorSideList(props: QuizGameEditorComponentProps) {
  const { editor } = props;
  return (
    <>
      <LoadingOverlay visible={(!!editor.fetchMQ && editor.fethMQResponse.loading) || editor.reordering} />
      <QuizGameSideListItem
        zIndex={props.zIndex}
        title={editor.quizGame?.name}
        createButton={!!editor.quizGame}
        mountedQuizzes={editor.mountedQuizzes}
        onDeleteMQ={editor.handleDeleteMQ}
        onSelectMQ={editor.handleSelectMQ}
        onReorderMQ={editor.handleReorderMQ}
        onCreate={() => editor.setTab(QuizGameEditorTab.MOUNT_QUIZ)}
        onSelectQuizGame={() => editor.setTab(QuizGameEditorTab.UPDATE_QUIZ_GAME)}
      />
    </>
  )
}

interface QuizGameEditorBodyProps extends QuizGameEditorComponentProps {
  template: boolean;
}

export function QuizGameEditorBody(props: QuizGameEditorBodyProps) {
  const { editor } = props;
  return (
    <>
      {editor.tab === QuizGameEditorTab.UPDATE_QUIZ_GAME && (
        <Tabs>
          <Tab label="Thông tin bộ câu hỏi">
            <UpsertQuizGameForm
              template={props.template}
              key={editor.upsertQGFormKey.key}
              quizGame={editor.quizGame}
              onSuccess={editor.handleUpsertQuizGame}
              quizModeValidator={editor.validateQuizMode}
            />
          </Tab>
          {editor.quizGame && (
            <Tab label="Hình ảnh">
              <UpsertQuizGameBannerForm quizGame={editor.quizGame} />
            </Tab>
          )}
        </Tabs>
      )}

      {editor.tab === QuizGameEditorTab.UPDATE_MOUNTED_QUIZ && (
        <MountAndUpsertQuizForm 
          template={props.template}
          key={editor.upsertMQFormKey.key}
          zIndex={props.zIndex + 1}
          quizGameId={editor.quizGame?.id as any}
          quizMode={editor.quizGame?.quizMode as any}
          mountedQuiz={editor.updateMQ}
          onSuccess={editor.handleUpdateMQ}
          numOrder={editor.nextNumOrder}
        />
      )}

      {editor.tab === QuizGameEditorTab.MOUNT_QUIZ && (
        <MountQuizForm 
          template={props.template}
          zIndex={props.zIndex + 1}
          quizGameId={editor.quizGame?.id as any}
          quizMode={editor.quizGame?.quizMode as any}
          onMount={editor.handleMountQuiz}
          numOrder={editor.nextNumOrder}
        />
      )}
    </>
  )
}

interface QuizGameEditorProps {
  zIndex: number;
  template: boolean;
  quizGame?: QuizGameDto | null;
}

export function QuizGameEditor(props: QuizGameEditorProps) {
  const editor = useQuizGameEdtior({
    quizGame: props.quizGame
  });

  return (
    <div className="flex" style={{ height: 'calc(100% - 40px)' }}>
      <div className="w-1/4 border-r relative h-full overflow-auto">
        <QuizGameEditorSideList zIndex={props.zIndex} editor={editor} />
      </div>
      <div className="w-3/4 p-4 h-full overflow-auto">
        <QuizGameEditorBody template={props.template} zIndex={props.zIndex + 1} editor={editor} />
      </div>
    </div>
  )
}