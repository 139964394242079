import { Button, Center, LoadingOverlay, PasswordInput, Text, TextInput } from "@mantine/core";
import { EnvelopeClosedIcon, LockClosedIcon } from "@radix-ui/react-icons";
import { FormikHelpers } from "formik";
import { useMemo } from "react";
import { useCallback } from "react";
import { LoginDto } from "../../dtos/auth/LoginDto";
import { useAppFormik } from "../../hooks/useAppFormik";
import { AuthService } from "../../services/AuthService";

interface LoginFormProps {
  onSuccess: () => any;
}

export function LoginForm(props: LoginFormProps) {
  const { onSuccess } = props;
  
  const initialValues = useMemo(() => new LoginDto(), []);
  const submit = useCallback(async (values: LoginDto, actions: FormikHelpers<LoginDto>) => {
    const success = await AuthService.login(values);
    if (success) {
      onSuccess && onSuccess();
    }
    actions.setSubmitting(false);
  }, [onSuccess]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit,
  });

  return (
    <form onSubmit={formik.handleSubmit} className="relative w-full">
      <LoadingOverlay visible={formik.isSubmitting} />

      <TextInput
        required
        label="Email"
        name="username"
        placeholder="Email..."
        icon={<EnvelopeClosedIcon />}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.username && formik.errors.username}
      />
      
      <PasswordInput
        required
        label="Mật khẩu"
        name="password"
        placeholder="Mật khẩu..."
        className="mt-4"
        icon={<LockClosedIcon />}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && formik.errors.password}
      />

      <Button fullWidth type="submit" className="mt-4">Đăng nhập</Button>

      <Center className="mt-4">
        <Text size="sm" className="flex items-center">
          Quên mật khẩu?
          <span className="ml-1 font-semibold text-blue-400 cursor-pointer">Lấy lại</span>
        </Text>
      </Center>
    </form>
  )
}