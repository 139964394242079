import { Menu, MenuItem } from "@mantine/core";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { RiDeleteBin6Line, RiEditBoxLine } from "react-icons/ri";

interface AppSideListItemProps {
  zIndex: number;
  collapsable?: boolean;
  collapse?: boolean;
  onCollapse?: () => any;
  onClick?: () => any;
  menu?: boolean;
  updatable?: boolean;
  deletable?: boolean;
  onUpdate?: () => any;
  onDelete?: () => any;
  children: any;
}

export function AppSideListItem(props: AppSideListItemProps) {
  return (
    <div 
      className="flex items-center text-sm border-t border-b p-4" 
      onClick={props.collapsable ? props.onCollapse : undefined}
    >
      <div 
        className="cursor-pointer hover:text-blue-400" 
        onClick={e => {
          e.stopPropagation();
          props.onClick && props.onClick();
        }}
      >
        {props.children}
      </div>
      <div className="ml-auto cursor-pointer hover:text-blue-400">
        {props.menu && (
          <Menu 
            trigger="hover"
            placement="center"
            zIndex={props.zIndex}
            delay={200}
            control={<span><HiOutlineDotsVertical /></span>}
            controlRefProp="ref"
          >
            {props.updatable && (
              <MenuItem
                icon={<RiEditBoxLine />}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  props.onUpdate && props.onUpdate();
                }}
              >
                Cập nhật
              </MenuItem>
            )}
            {props.deletable && (
              <MenuItem
                color="red"
                icon={<RiDeleteBin6Line />}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  props.onDelete && props.onDelete()
                }}
              >
                Xoá
              </MenuItem>
            )}
          </Menu>
        )}
      </div>
    </div>
  )
}