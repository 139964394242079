import { Redirect, Route, Switch } from "react-router";
import { SystemAppPage } from "./app/SystemAppPage";
import { ChangeLogPage } from "./changelog/ChangeLogPage";
import { SystemConfigPage } from "./config/SystemConfigPage";
import { UserPage } from "./user/UserPage";

export function SystemPage() {
  return (
    <div className="p-5">
      <Switch>
        <Redirect exact from="/app/system" to="/app/system/user" />
        <Route path="/app/system/user" component={UserPage} />
        <Route path="/app/system/config" component={SystemConfigPage} />
        <Route path="/app/system/app" component={SystemAppPage} />
        <Route path="/app/system/changelog" component={ChangeLogPage} />
      </Switch>
    </div>
  )
}