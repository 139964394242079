import { Button, Divider } from "@mantine/core";
import { useCallback, useRef, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import { ResponseCode } from "../../config/ResponseConfig";
import { CreateInteractionDto } from "../../dtos/interaction/CreateInteractionDto";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { PollDto } from "../../dtos/poll/PollDto";
import { InteractionType } from "../../enums/InteractionType";
import { InteractionService } from "../../services/InteractionService";
import { UpsertPollForm } from "./UpsertPollForm";

interface MountAndUpsertPollFormProps {
  zIndex: number;
  scenarioId: number;
  interaction?: InteractionDto | null;
  onSuccess: (itr: InteractionDto) => any;
  numOrder: number;
}

export function MountAndUpsertPollForm(props: MountAndUpsertPollFormProps) {
  const { numOrder, scenarioId, onSuccess, interaction: propsInteraction } = props;
  const [interaction, setInteraction] = useState(propsInteraction);
  const [submitting, setSubmitting] = useState(false);

  const upsertPollRef = useRef<any>(null);
  const handleUpsertPollSuccess = useCallback(async (poll: PollDto) => {
    if (interaction) {
      // TODO: update interaction's fields
      const newInteraction = { ...interaction, poll };
      setInteraction(newInteraction);
      onSuccess && onSuccess(newInteraction);
    } else {
      const createDto = new CreateInteractionDto();
      createDto.interactionType = InteractionType.POLL;
      createDto.numOrder = numOrder;
      createDto.pollId = poll.id;
      createDto.scenarioId = scenarioId;
      
      const response = await InteractionService.create(createDto);
      if (response.code === ResponseCode.OK.code) {
        setInteraction(response.body);
        onSuccess && onSuccess(response.body);
      }
    }

    setSubmitting(false);
  }, [numOrder, onSuccess, interaction, scenarioId]);

  const submit = useCallback(() => {
    setSubmitting(true);
    upsertPollRef.current?.formik.handleSubmit();
  }, []);

  return (
    <>
      <UpsertPollForm
        template={false}
        submitable={false}
        preview={true}
        poll={interaction?.poll}
        elementRef={upsertPollRef}
        onSuccess={handleUpsertPollSuccess}
      />
      <Divider my="lg" />
      <Button 
        type="submit" 
        variant="gradient" 
        leftIcon={<AiOutlineSave />} 
        loading={submitting} 
        onClick={submit}
      >
        Lưu lại
      </Button>
    </>
  )
}