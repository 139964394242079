import { Button, Modal } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillInfoCircle, AiFillWarning } from "react-icons/ai";

interface ModalProps {
  zIndex?: number;
  icon?: any;
  title?: string;
  message?: string;
  cancelBtnLabel?: string;
  okBtnLabel?: string;
  cancelBtn?: boolean;
  okBtn?: boolean;
  onOk?: () => any;
  onCancel?: () => any;
  onClose?: () => any;
}

export function useModal() {
  const [modalProps, setModalProps] = useState<ModalProps>();

  const show = useCallback((props: ModalProps) => {
    setModalProps(props);
  }, []);

  const hide = useCallback(() => {
    if (modalProps) {
      modalProps.onClose && modalProps.onClose();
      setModalProps(undefined);
    }
  }, [modalProps]);

  const onCancel = modalProps?.onCancel;
  const onOk = modalProps?.onOk;

  const handleCancel = useCallback(() => {
    onCancel && onCancel();
    hide();
  }, [onCancel, hide]);

  const handleOk = useCallback(() => {
    onOk && onOk();
    hide();
  }, [onOk, hide]);

  const modal = useMemo(() => {
    return (
      <Modal 
        withCloseButton={false}
        opened={!!modalProps}
        onClose={hide}
        zIndex={modalProps?.zIndex}
      >
        <div className="flex items-start mb-4 relative">
          <div className="absolute top-0 left-0">
            {modalProps?.icon}
          </div>
          <div className="ml-16">
            {modalProps?.title && <div className="mb-2 font-bold">{modalProps.title}</div>}
            {modalProps?.message && <div>{modalProps.message}</div>}
          </div>
        </div>
        <div className="flex justify-end">
          {(modalProps?.onCancel || modalProps?.cancelBtn) && (
            <Button color="gray" variant="outline" onClick={handleCancel}>
              {modalProps.cancelBtnLabel || 'Huỷ bỏ'}
            </Button>
          )}
          {(modalProps?.onOk || modalProps?.okBtn) && (
            <Button onClick={handleOk} className="ml-2">{modalProps.okBtnLabel || 'Đồng ý'}</Button>
          )}
        </div>
      </Modal>
    )
  }, [modalProps, handleOk, handleCancel, hide]);

  return useMemo(() => ({
    show,
    hide,
    info: (props: ModalProps) => show({ ...props, icon: <AiFillInfoCircle className="text-blue-400" style={{ fontSize: '3rem' }} />  }),
    warn: (props: ModalProps) => show({ ...props, icon: <AiFillWarning className="text-yellow-400" style={{ fontSize: '3rem' }} />  }),
    error: (props: ModalProps) => show({ ...props, icon: <AiFillCloseCircle className="text-red-400" style={{ fontSize: '3rem' }} /> }),
    success: (props: ModalProps) => show({ ...props, icon: <AiFillCheckCircle className="text-green-400" style={{ fontSize: '3rem' }} /> }),
    modal
  }), [
    show,
    hide,
    modal,
  ])
}