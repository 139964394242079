import { useCallback, useMemo, useState } from "react";
import { DropzoneOptions, useDropzone,  } from 'react-dropzone';
import { FileUtils } from "../utils/FileUtils";

export interface IAppUploaderProps extends DropzoneOptions {
  onBlur?: () => any;
  onDrop?: (files: any[]) => any;
}

export function AppUploader(props: IAppUploaderProps) {
  const { onDrop, onBlur, ...dropzoneOptions } = props;
  const [imageSrc, setImageSrc] = useState('');

  const handleDrop = useCallback(async (files: any) => {
    const file = files[0];
    if (file.type.includes('image')) {
      setImageSrc(await FileUtils.makeUrl(file));
    }
    onDrop && onDrop(file);
  }, [onDrop]);

  const dropzone = useDropzone({
    multiple: false,
    onDrop: handleDrop,
    onDragLeave: onBlur,
    onDropRejected: onBlur,
    onFileDialogCancel: onBlur,
    ...dropzoneOptions
  });

  const className = useMemo(() => {
    let className = 'flex items-center justify-center border-2 border-dashed rounded cursor-pointer p-8 ';
    let makeUpClassName = 'border-gray-400 bg-gray-50 text-gray-400';
    if (dropzone.isFocused) {
      makeUpClassName = 'border-blue-400 bg-blue-50 text-blue-400';
    }
    if (dropzone.isDragAccept) {
      makeUpClassName = 'border-green-400 bg-green-50 text-green-400';
    }
    if (dropzone.isDragReject) {
      makeUpClassName = 'border-red-400 bg-red-50 text-red-400';
    }
    return className + makeUpClassName;
  }, [dropzone]);

  return (
    <>
      {imageSrc && (
        <div className="w-full mb-1">
          <img className="w-full max-h-96 rounded" src={imageSrc} alt="Upload preview" />
        </div>
      )}

      <div {...dropzone.getRootProps({ className })}>
        <input {...dropzone.getInputProps()} />
        <p className="text-center text-sm">Kéo thả hoặc nhấn <u className="text-blue-400">vào đây</u> để upload file</p>
      </div>
    </>
  )
}