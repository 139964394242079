import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { PageDto } from "../dtos/common/PageDto";
import { UpsertProjectDto } from "../dtos/project/UpsertProjectDto";
import { PublishScenarioDto } from "../dtos/scenario/PublishScenarioDto";
import { QueryScenarioDto } from "../dtos/scenario/QueryScenarioDto";
import { ScenarioDto } from "../dtos/scenario/ScenarioDto";
import { UpsertScenarioDto } from "../dtos/scenario/UpsertScenarioDto";
import { HttpUtils } from "../utils/HttpUtils";

export class ScenarioService {

  static async query(queryDto: QueryScenarioDto) {
    return (await HttpUtils.post<PageDto<ScenarioDto>>(Apis.scenario.query, queryDto)).body;
  }

  static async findById(id: number) {
    return (await HttpUtils.get<ScenarioDto>(Apis.scenario.findById(id))).body;
  }
  
  static async findByEventId(eventId: number) {
    return (await HttpUtils.get<ScenarioDto>(Apis.scenario.findByEventId(eventId))).body;
  }

  static async findPublishing(roomId: string) {
    return (await HttpUtils.get(Apis.scenario.findPublishing, { roomId })).body;
  }

  static async checkName(name: string, projectId: number) {
    return (await HttpUtils.get(Apis.scenario.checkName, { name, projectId })).body;
  }

  static create(createDto: UpsertScenarioDto) {
    return HttpUtils.post(Apis.scenario.create, createDto);
  }

  static update(id: number, updateDto: UpsertProjectDto) {
    return HttpUtils.put(Apis.scenario.update(id), updateDto);
  }

  static async publish(id: number, dto: PublishScenarioDto) {
    return (await HttpUtils.post(Apis.scenario.publish(id), dto)).code === ResponseCode.OK.code;
  }

  static async finish(id: number) {
    return (await HttpUtils.post(Apis.scenario.finish(id))).code === ResponseCode.OK.code;
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.scenario.toggle(id))).code === ResponseCode.OK.code;
  }
}