import { PollDto } from "../../dtos/poll/PollDto";
import { AppInteractivePreview } from "../AppInteractivePreview";

interface PollLogDetailProps {
  log: any;
}

export function PollLogDetail(props: PollLogDetailProps) {
  const { log } = props;
  const poll: PollDto = log.poll;

  return (
    <AppInteractivePreview
      title={`Khảo sát thứ ${(poll as any).numOrder}`}
      content={poll.title}
      selectMode={poll.selectMode}
      options={poll.pollOptions.map(opt => opt.content)}
      checked={poll.pollOptions.map(opt => log.answerIds.includes(opt.id))}
    />
  )
}