import { validate, ValidationError } from "class-validator";

export class ValidateUtils {

  static async validate(values: any) {
    const errors = await validate(values, {
      forbidUnknownValues: true
    });

    return errors.reduce((errorMap: any, error: ValidationError) => {
      errorMap[error.property] = Object.values(error.constraints as any)[0];
      return errorMap;
    }, {});
  }
}