import { Tab, Tabs } from "@mantine/core";
import { useMemo } from "react";
import { Redirect, Route, RouteComponentProps, Switch, useHistory } from "react-router";
import { AppBreadcrumbs } from "../../components/AppBreadCrumbs";
import { ChangePasswordPage } from "./ChangePasswordPage";
import { ProfilePage } from "./ProfilePage";

const paths = [
  "/app/setting/profile",
  "/app/setting/change-password"
];

const path2Idx: any = paths.reduce((map: any, path, idx) => {
  map[path] = idx;
  return map;
}, {});

export function SettingPage(props: RouteComponentProps) {
  const history = useHistory();
  const { pathname } = props.location;
  const breadcrums = useMemo(() => {
    const breadcrums = [{ label: "Cài đặt", link: "/app/setting" }];
    switch (pathname) {
      case paths[0]: breadcrums.push({ label: "Tài khoản", link: pathname }); break;
      case paths[1]: breadcrums.push({ label: "Đổi mật khẩu", link: pathname }); break;
      default:
        break;
    }
    return breadcrums;
  }, [pathname]);
  
  return (
    <div className="p-5">
      <AppBreadcrumbs items={breadcrums} />

      <Tabs 
        active={path2Idx[props.location.pathname]}
        onTabChange={idx => history.push(paths[idx])}
      >
        <Tab label="Tài khoản" />
        <Tab label="Đổi mật khẩu" />
      </Tabs>

      <div className="px-3 mt-6">
        <Switch>
          <Redirect exact from="/app/setting" to="/app/setting/profile" />
          <Route path="/app/setting/profile" component={ProfilePage} />
          <Route path="/app/setting/change-password" component={ChangePasswordPage} />
        </Switch>
      </div>
    </div>
  )
}