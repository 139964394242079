import { useCallback, useContext, useMemo } from "react";
import { AppGlobalStateDto } from "../dtos/store/AppGlobalStateDto";
import { ActionType } from "../enums/ActionType";
import { AppContext } from "./AppStore";

type Dispatcher = (type: ActionType, payload?: any) => void;

interface IAppStore {
  store: AppGlobalStateDto,
  dispatch: Dispatcher
}

export function useAppStore(): IAppStore {
  const [globalState, dispatch] = useContext(AppContext) as any;
  const dispatcher = useCallback(
    (type: ActionType, payload: any) => {
      dispatch({ type, payload });
    },
    [dispatch]
  );

  return useMemo(() => ({
    store: globalState,
    dispatch: dispatcher
  }), [globalState, dispatcher]);
}
