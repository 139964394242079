import { Button, Divider, Modal } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { ResponseCode } from "../../config/ResponseConfig";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { PollDto } from "../../dtos/poll/PollDto";
import { InteractionType } from "../../enums/InteractionType";
import { InteractionService } from "../../services/InteractionService";
import { PollList } from "./PollList";

interface MountPollFromLibraryFormProps {
  scenarioId: number;
  zIndex: number;
  onMount: (interaction: InteractionDto) => any;
  numOrder: number;
}

export function MountPollFromLibraryForm(props: MountPollFromLibraryFormProps) {
  const { onMount, numOrder, scenarioId } = props;
  const [poll, setPoll] = useState<PollDto | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const quizFolderOverrides = useMemo(() => ({
    onSelect: setPoll
  }), []);

  const submit = useCallback(async () => {
    if (poll) {
      const response = await InteractionService.create({
        interactionType: InteractionType.POLL,
        numOrder,
        scenarioId,
        pollId: poll.id
      });

      if (response.code === ResponseCode.OK.code) {
        onMount && onMount(response.body);
        setPoll(null);
      }
      
      setSubmitting(false);
    }
  }, [poll, onMount, scenarioId, numOrder]);

  return (
    <>
      {poll && (
        <Modal
          opened
          zIndex={props.zIndex + 1}
          title="Thêm khảo sát"
          onClose={() => setPoll(null)}
        >
          <Divider my="lg" />
          <div className="text-sm mb-4">
            Thêm khảo sát <b>"{poll.title}"</b> vào kịch bản?
          </div>
          <Button fullWidth variant="gradient" onClick={submit} loading={submitting}>
            Đồng ý
          </Button>
        </Modal>
      )}

      <PollList 
        creatable={false}
        zIndex={props.zIndex}
        folderOverrides={quizFolderOverrides}
      />
    </>
  );
}