import { Badge } from "@mantine/core";

export enum LogType {
  CHANGE_LOG = 'CHANGE_LOG',
  OPERATION_LOG = 'OPERATION_LOG',
  PLAYER_LOG = 'PLAYER_LOG'
}

export const LogTypeDisplay = {
  [LogType.CHANGE_LOG]: <Badge color="teal">Thay đổi</Badge>,
  [LogType.OPERATION_LOG]: <Badge color="red">Vận hành</Badge>,
  [LogType.PLAYER_LOG]: <Badge color="blue">Realtime</Badge>
}