import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { SystemConfigList } from "../../../components/system/config/SystemConfigList";

const breadcrumbs = [
  { label: 'Hệ thống', link: '/app/system' },
  { label: 'Cấu hình hệ thống', link: '/app/system/config/list' }
];

export function SystemConfigListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <SystemConfigList />
    </>
  )
}