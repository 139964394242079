import { Button, Divider, Modal } from "@mantine/core";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { useHistory } from "react-router";
import { ProjectDto } from "../../dtos/project/ProjectDto";
import { PublishStatusIcon, PublishStatus } from "../../enums/PublishStatus";
import { useCompKey } from "../../hooks/useCompKey";
import { ProjectService } from "../../services/ProjectService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { AppConfirmModal } from "../AppConfirmModal";
import { AppFlexFolders, AppFlexFoldersProps } from "../AppFlexFolders";
import { FlexListQueryDto } from "../AppFlexList";
import { UpsertProjectForm } from "./UpsertProjectForm";

interface ProjectListProps {
  zIndex: number;
  creatable?: boolean;
  folderOverrides?: Partial<AppFlexFoldersProps<ProjectDto>>;
}

export function ProjectList(props: ProjectListProps) {
  const { zIndex, creatable, folderOverrides } = props;
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState<ProjectDto | null>(null);
  const [del, setDel] = useState<ProjectDto | null>(null);
  const history = useHistory();
  const compKey = useCompKey();

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return ProjectService.query({
      ...queryDto,
      sorts: [
        { field: 'updateDt', descending: true }
      ],
      filters: {
        name: queryDto.searchTerm,
        disabled: false
      }
    })
  }, []);

  const deleteProject = useCallback(async (project: ProjectDto) => {
    const success = await ProjectService.toggle(project.id);
    if (success) {
      NotificationUtils.success({ message: <>Xoá dự án <b>{project.name} thành công!</b></> });
      setDel(null);
      compKey.renew();
    }
  }, [compKey]);

  const actions = useMemo(() => {
    if (creatable) {
      return (
        <Button variant="gradient" onClick={() => setCreate(true)} leftIcon={<HiPlusCircle />}>
          Thêm dự án
        </Button>
      );
    }
    return null;
  }, [creatable]);

  return (
    <>
      <Modal
        zIndex={zIndex}
        title={create ? "Thêm dự án mới" : "Cập nhật dự án"}
        opened={create || !!update}
        onClose={() => {
          setCreate(false);
          setUpdate(null);
        }}
      >
        <Divider my="lg" />
        <UpsertProjectForm 
          project={update}
          onSuccess={() => {
            setCreate(false);
            setUpdate(null);
            compKey.renew();
          }} 
        />
      </Modal>

      <AppConfirmModal 
        opened={!!del} 
        onClose={() => setDel(null)}
        onConfirm={() => del && deleteProject(del)}
      >
        Bạn có chắc muốn xoá dự án <b>{del?.name}</b> chứ?
      </AppConfirmModal>

      <AppFlexFolders
        zIndex={zIndex + 1}
        key={compKey.key}
        fetchData={fetchData as any}
        folderWrapperClassName="w-1/4 mb-4 pr-4 text-sm"
        folderClassName={(project: ProjectDto) => project.publishingScenario > 0 ? 'border-red-400 bg-red-50' : ''}
        folderLabelRenderer={(project: ProjectDto) => (
          <div className="flex items-center">
            {project.publishingScenario > 0 && (
              <div className="mr-1 flex items-center text-sm text-red-400">
                {PublishStatusIcon[PublishStatus.PUBLISHING]}
              </div>
            )}
            {project.name}
          </div>
        )}
        folderPreviewRenderer={(project: ProjectDto) => (
          <div className="text-gray-500 text-xs">
            <div className="mb-1"><b>Cập nhật lúc:</b> {dayjs(project.updateDt).format('DD/MM/YYYY HH:mm')}</div>
            <div><b>Tạo lúc:</b> {dayjs(project.createDt).format('DD/MM/YYYY HH:mm')}</div>
          </div>
        )}
        actions={actions}
        onUpdate={setUpdate}
        onDelete={setDel}
        onSelect={(project) => history.push({
          pathname: `/app/interactive/project/detail/${project.id}`,
          state: { projectName: project.name }
        })}
        {...folderOverrides}
      />
    </>
  )
}