import { ScenarioDto } from "../../dtos/scenario/ScenarioDto";
import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { PublishStatus } from "../../enums/PublishStatus";
import { OperateService } from "../../services/OperateService";
import { RandomUtils } from "../../utils/RandomUtils";
import { clearScenarioState } from "./clearScenarioState";

export function reduceNewScenario(state: AppGlobalStateDto, newScenario: ScenarioDto): AppGlobalStateDto {
  newScenario.roomId = newScenario.roomId || state.currRoomId || RandomUtils.uuid();
  const newState: AppGlobalStateDto = {
    ...clearScenarioState(state),
    isRoomOpened: state.isRoomOpened || newScenario.publishStatus === PublishStatus.PUBLISHING,
    currEvent: state.currEvent || newScenario.currEvent,
    currRoomId: newScenario.roomId,
    currScenario: newScenario
  };

  if (newScenario.roomId) {
    OperateService.setCurrRoomId(newScenario.roomId);
    OperateService.setCurrScenarioId(newScenario.roomId, newScenario.id);
    newState.operateDisables = OperateService.getOperateDisables(newScenario.roomId);
  }

  return newState;
}