import { Button, Checkbox, Radio, RadioGroup } from "@mantine/core";
import { FormikHelpers, useFormik } from "formik";
import { useCallback, useMemo } from "react";
import { QuizDto } from "../../dtos/quiz/QuizDto";
import { QuizGameService } from "../../services/QuizGameService";
import { QuizService } from "../../services/QuizService";
import { NotificationUtils } from "../../utils/NotificationUtils";

interface UpdateQuizCorrectAnswersFormProps {
  quizGameId: number;
  quizzes: QuizDto[];
  onSuccess?: (correctAnswerIds: number[]) => any;
}

export function UpdateQuizCorrectAnswersForm(props: UpdateQuizCorrectAnswersFormProps) {
  const { quizGameId, quizzes, onSuccess } = props;
  const initialValues = useMemo(() => {
    return quizzes.reduce((values: any, quiz) => {
      const correctAns = quiz.answers.find(ans => ans.correct);
      if (correctAns) {
        values[String(quiz.id)] = String(correctAns.id);
      }
      return values;
    }, {});
  }, [quizzes]);

  const submit = useCallback(async (values: any, actions: FormikHelpers<any>) => {
    const { calcResult } = values;
    delete values.calcResult;

    let correctAnswerIds = Object.values(values).filter(v => !!v).map(v => Number(v));
    let quizIds = quizzes.filter(quiz => quiz.answers.some(a => correctAnswerIds.includes(a.id))).map(q => q.id);
    let success = await QuizService.updateCorrectAnswers(quizIds, correctAnswerIds);

    if (success) {
      NotificationUtils.success({ message: 'Lưu lại đáp án thành công' });
      if (calcResult) {
        success = await QuizGameService.updateFootballLeaderboard(quizGameId, true);
        NotificationUtils.success({ message: 'Tính toán kết quả thành công' });
      }
      onSuccess && onSuccess(correctAnswerIds);
    }

    actions.setSubmitting(false);
  }, [quizGameId, quizzes, onSuccess]);

  const formik = useFormik({
    initialValues,
    onSubmit: submit
  });

  // const disabledCalcResult = useMemo(() => {
  //   const { calcResult, ...values } = formik.values;
  //   return Object.values(values).length < quizzes.length;
  // }, [formik, quizzes]);

  return (
    <form onSubmit={formik.handleSubmit}>
      {quizzes.map(quiz => (
        <div className="mb-4 w-full">
          <RadioGroup 
            key={quiz.id}
            name={String(quiz.id)} 
            label={(
              <div className="bg-gray-100 p-1 rounded">{quiz.content}</div>
            )}
            value={formik.values[quiz.id]}
            onChange={formik.handleChange(String(quiz.id))}
          >
            {quiz.answers.map(ans => (
              <Radio 
                key={ans.id}
                className="w-full"
                value={String(ans.id)}
                label={<span className="text-sm">{ans.content}</span>}
              />
            ))}
          </RadioGroup>
        </div>
      ))}

      <div className="flex justify-end items-center mt-4">
        <Checkbox 
          name="calcResult" 
          label="Tính toán kết quả"
          // disabled={disabledCalcResult}
          checked={formik.values.calcResult}
          onBlur={formik.handleBlur}
          onChange={() => formik.setFieldValue('calcResult', !formik.values.calcResult)}
        />

        <Button className="ml-4" type="submit" variant="gradient" loading={formik.isSubmitting}>
          Lưu lại
        </Button>
      </div>
    </form>
  )
}