export enum StreamSourceProtocol {
  RTMP = 'RTMP',
  WEB_RTC = 'WEB_RTC'
};

export const StreamSourceProtocolDisplay = {
  [StreamSourceProtocol.RTMP]: 'RTMP',
  [StreamSourceProtocol.WEB_RTC]: 'WebRTC'
};

export const StreamSourceProtocolOptions = [
  { value: StreamSourceProtocol.RTMP, label: StreamSourceProtocolDisplay[StreamSourceProtocol.RTMP] },
  { value: StreamSourceProtocol.WEB_RTC, label: StreamSourceProtocolDisplay[StreamSourceProtocol.WEB_RTC] }
]