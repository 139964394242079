export enum EventType {
  ERROR = "0000",
  CLOCK_TICK = "0001",
  DELAY_COUNT_DOWN = "0002",
  CLIENT_CONFLICT = "0003",

  // Room Event
  OPEN_ROOM = "1000",
  JOIN_ROOM = "1001",
  LEAVE_ROOM = "1002",
  CLOSE_ROOM = "1003",
  ROOM_STATE = "1004",
  CLIENT_INFO = "1005",
  PAGINATE_CLIENTS = "1006",
  CLIENT_PAGE = "1007",

  // Quiz Event
  PUBLISH_QUIZ_GAME = "2001",
  PUBLISH_QUIZ = "2002",
  END_QUIZ_GAME = "2003",
  END_QUIZ = "2004",
  SELECT_QUIZ_ANSWER = "2005",
  QUIZ_ANSWER_SELECTIONS = "2006",
  QUIZ_LEADERBOARD = "2007",
  PAGINATE_QUIZ_LEADERBOARD = "2008",
  CONFETTI_QUIZ_STATE = "2009",
  KAHOOT_QUIZ_STATE = "2010",
  SELECTED_QUIZ_ANSWERS = "2011",
  SHOW_QUIZ_GAME_RESULT = "2012",

  // Poll Event
  PUBLISH_POLL = "3001",
  END_POLL = "3002",
  SELECT_POLL_OPTION = "3003",
  POLL_OPTION_SELECTIONS = "3004",
  SELECTED_POLL_OPTIONS = "3005",

  // Comment
  COMMENT = "5001",
  LAST_COMMENTS= "5002",
  BLOCK_COMMENT= "5003",
  LAST_BLOCK_COMMENTS = "5004",
  GET_LAST_COMMENTS = "5005",
  GET_LAST_BLOCK_COMMENTS = "5006",
  PIN_COMMENT = "5007",
  UNPIN_COMMENT = "5008",

  // Reaction
  REACT= "6001",
  REACTIONS= "6002",

  // Banner
  SHOW_NOTIFICATION = "7000",
  HIDE_NOTIFICATION = "7001",

  // Custom
  CONNECTED = "CONNECTED",
  CONNECTING = "CONNECTING",
  DISCONNECTED = "DISCONNECTED",
}