export class StorageUtils {

  private readonly provider: any;

  constructor(provider: any) {
    this.provider = provider;
  }

  setJSON(key: string, data: any) {
    this.set(key, JSON.stringify(data));
  }

  set(key: string, data: any) {
    this.provider.setItem(key, data);
  }

  getJSON(key: string, defaultValue: any = null) {
    const raw = this.get(key);
    return raw ? JSON.parse(raw) : defaultValue;
  }

  get(key: string, defaultValue: any = null) {
    return this.provider.getItem(key) || defaultValue;
  }

  remove(key: string) {
    return this.provider.removeItem(key);
  }

  clearByPrefix(prefix: string) {
    this.clear(key => key.startsWith(prefix));
  }

  clear(match: (key: string) => boolean) {
    for (let i = 0; i < this.provider.length; i++) {
      const key = this.provider.key(i);
      if (!key) {
        break;
      }
      if (match(key)) {
        this.remove(key);
      }
    }
  }
}