import { LivestreamProfileDto } from "../../dtos/operate/LivestreamProfileDto";
import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { OperateService } from "../../services/OperateService";

export function reduceSetLivestreamProfile(state: AppGlobalStateDto, dto: LivestreamProfileDto): AppGlobalStateDto {
  const userId = state.session.user.id;
  const roomId = state.currRoomId;

  if (roomId && userId) {
    OperateService.setLivestreamProfile(roomId, userId, dto);
  }

  return {
    ...state,
    livestreamProfile: dto
  };
}