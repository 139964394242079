import { IsEmail, IsOptional, IsString, MaxLength, MinLength } from "class-validator";
import { UserRole } from "../../enums/UserRole";
import { UpdateProfileDto } from "./UpdateProfileDto";

export class UpsertUserDto extends UpdateProfileDto {
  @MaxLength(100, { message: "Email chỉ cho phép tối đa 100 kí tư" })
  @MinLength(4, { message: "Email phải có ít nhất 4 kí tự" })
  @IsEmail({}, { message: 'Email không đúng định dạng' })
  @IsString({ message: "Vui lòng nhập email" })
  username: string;

  @MaxLength(255, { message: "Mật khẩu chỉ cho phép tối đa 255 kí tự" })
  @MinLength(8, { message: "Mật khẩu phải có ít nhất 8 kí tự" })
  @IsString({ message: "Vui lòng nhập mật khẩu cho tài khoản" })
  password: string;
  role: UserRole;

  @IsOptional()
  appId?: number;
}