import { Button, Modal } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { PageDto } from "../../../dtos/common/PageDto";
import { InteractionDto } from "../../../dtos/interaction/InteractionDto";
import { PollDto } from "../../../dtos/poll/PollDto";
import { InteractionType, InteractionTypeDisplay } from "../../../enums/InteractionType";
import { PublishStatus, PublishStatusDisplay } from "../../../enums/PublishStatus";
import { InteractionService } from "../../../services/InteractionService";
import { FormatUtils } from "../../../utils/FormatUtils";
import { AppTable, AppTableColumn } from "../../AppTable";
import { PollResult } from "../poll-result/PollResult";

interface ScenarioHistoryDetailProps {
  scenarioId: number;
}

export function InteractiveHistoryDetail(props: ScenarioHistoryDetailProps) {
  const { scenarioId } = props;
  const [pollItr, setPollItr] = useState<InteractionDto | undefined>();

  const columns = useMemo(() => {
    return [
      { title: 'ID', render: itr => itr.id },
      { title: 'Tên tương tác', render: itr => itr.quizGame ? itr.quizGame.name : itr.poll?.title },
      { title: 'Loại tương tác', render: itr => InteractionTypeDisplay[itr.interactionType] },
      { title: 'Trạng thái', render: itr => PublishStatusDisplay[itr.publishStatus] },
      { title: 'Bắt đầu', render: itr => FormatUtils.dt(itr.publishDt) },
      { title: 'Kết thúc', render: itr => FormatUtils.dt(itr.finishDt) },
      {
        title: 'Chi tiết',
        render: itr => itr.publishStatus === PublishStatus.FINISHED && (
          itr.interactionType === InteractionType.QUIZ_GAME ? (
            <Link to={`/app/livestream/interactive/history/quiz-game/${itr.id}`}>
              <Button compact>Chi tiết</Button>
            </Link>
          ) : (
            <Button compact onClick={() => setPollItr(itr)}>Chi tiết</Button>
          )
        )
      }
    ] as AppTableColumn<InteractionDto>[];
  }, []);

  const fetchData = useCallback(async () => {
    const interactions = await InteractionService.find({
      scenarioId,
      interactionTypes: [
        InteractionType.QUIZ_GAME,
        InteractionType.POLL
      ]
    });

    return PageDto.onePage(interactions);
  }, [scenarioId]);

  return (
    <>
      <Modal opened={!!pollItr} title="Kết quả khảo sát" onClose={() => setPollItr(undefined)}>
        {pollItr && <PollResult numOrder={pollItr?.numOrder} poll={pollItr?.poll as PollDto} />}
      </Modal>

      <AppTable
        hideSearch
        hidePagination
        columns={columns}
        fetchData={fetchData}
      />
    </>
  );
}