import { Switch } from "@mantine/core";
import { useCallback, useState } from "react";
import { NotificationUtils } from "../utils/NotificationUtils";

interface AppToggleProps {
  id: number;
  checked: boolean;
  toggle: (id: number) => Promise<boolean>;
  onSucces?: (id: number, checked: boolean) => any;
}

export function AppToggle(props: AppToggleProps) {
  const { id, toggle, onSucces } = props;
  const [checked, setChecked] = useState(props.checked);

  const handleChange = useCallback(async (checked: boolean) => {
    const success = await toggle(id);
    if (success) {
      NotificationUtils.success({ message: `${checked ? "Kích hoạt" : "Vô hiệu hoá"} thành công` });
      onSucces && onSucces(id, checked);
      setChecked(checked);
    }
  }, [id, toggle, onSucces]);

  return (
    <Switch
      checked={checked}
      onChange={e => handleChange(e.target.checked)}
    />
  )
}