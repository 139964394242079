import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { CreateQuizGameBannerDto } from "../dtos/quiz-game-banner/CreateQuizGameBannerDto";
import { QuizGameBannerDto } from "../dtos/quiz-game-banner/QuizGameBannerDto";
import { UpdateQuizGameBannerDto } from "../dtos/quiz-game-banner/UpdateQuizGameBannerDto";
import { HttpUtils } from "../utils/HttpUtils";

export class QuizGameBannerService {

  static async findByQuizGameId(quizGameId: number) {
    return (await HttpUtils.get<QuizGameBannerDto[]>(Apis.quizGameBanner.findByQuizGameId(quizGameId))).body;
  }

  static create(dto: CreateQuizGameBannerDto) {
    return HttpUtils.post<QuizGameBannerDto>(Apis.quizGameBanner.create, dto);
  }

  static async update(id: number, dto: UpdateQuizGameBannerDto) {
    return (await HttpUtils.put<QuizGameBannerDto>(Apis.quizGameBanner.update(id), dto));
  }

  static async delete(id: number) {
    return (await HttpUtils.delete(Apis.quizGameBanner.delete(id))).code === ResponseCode.OK.code;
  }
}