import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";

export function clearScenarioState(state: AppGlobalStateDto): AppGlobalStateDto {
  return {
    ...state,
    currScenario: undefined,
    itrNodes: [],
    itrNodeMap: {},
    clockTick: 0,
    autoPublish: false,
    autoEndQuizGame: false,
    autoPublishCountDown: undefined,
    currQuizGameItrId: undefined,
    currQuizItrId: undefined,
    currPollItrId: undefined
  }
}