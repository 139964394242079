import {registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface} from 'class-validator';

export function IsNotBlank(validationOptions?: ValidationOptions) {
    return (object: any, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            validator: IsNotBlankConstraint,
        });
    };
}

@ValidatorConstraint({name: 'IsNotBlank'})
export class IsNotBlankConstraint implements ValidatorConstraintInterface {

    validate(value: any) {
      return typeof value === 'string' && value.trim().length > 0;
    }

}