import { Button } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { FiInbox } from "react-icons/fi";
import { useCompKey } from "../../hooks/useCompKey";
import { AppFlexList, FlexListQueryDto } from "../AppFlexList";
import { UploadFileDropZone } from "./UploadFileDropZone";
import { UploadFileDisplay } from "./UploadFileDisplay";
import { UploadFileDto } from "../../dtos/upload-file/UploadFileDto";
import { UploadFileService } from "../../services/UploadFileService";

interface UploadFileSelectProps {
  upload?: (file: UploadFileDto) => boolean;
  mime?: 'image' | 'video' | 'file';
  maxSize?: number;
  onChange?: (file: UploadFileDto) => any;
  onCancel?: () => any;
  folder?: string;
}

const accepts = {
  image: ['image/jpg', 'image/jpeg', 'image/png'],
  video: ['video/mp4'],
  file: undefined
}

export function UploadFilesSelect(props: UploadFileSelectProps) {
  const { onChange, onCancel, mime, maxSize, folder, upload: doUpload } = props;
  const [selected, setSelected] = useState<UploadFileDto>();
  const [upload, setUpload] = useState(false);
  const compKey = useCompKey();

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    let { filters, searchTerm } = queryDto;
    delete queryDto.searchTerm;
    delete queryDto.filters;

    if (!filters) {
      filters = {};
    }

    if (!!searchTerm) {
      filters.basename = searchTerm;
    }

    return UploadFileService.query({
      ...queryDto,
      filters: {
        ...filters,
        folder
      },
      sorts: [
        { field: 'id', descending: true }
      ]
    });
  }, [folder]);

  const actions = useMemo(() => (
    !!doUpload ? <Button leftIcon={<AiOutlineUpload />} onClick={() => setUpload(true)}>Tải lên</Button> : null
  ), [doUpload]);

  const select = useCallback(() => {
    if (selected) {
      onChange && onChange(selected);
    }
  }, [onChange, selected]);

  const handleUpload = useCallback(async (uf: UploadFileDto) => {
    if (doUpload) {
      const success = await doUpload(uf);
      if (success) {
        compKey.renew();
      }
    }
    setUpload(false);
  }, [compKey, doUpload]);

  const handleDelete = useCallback(async (uf: UploadFileDto) => {
    const success = await UploadFileService.delete(uf.id);
    if (success) {
      compKey.renew();
    }
  }, [compKey]);

  return (
    <>
      {upload && (
        <div>
          <UploadFileDropZone
            onUpload={handleUpload}
            onClose={() => setUpload(false)}
            accept={mime ? accepts[mime] : undefined}
            maxSize={maxSize}
          />
        </div>
      )}

      <div className={"relative mb-20 " + (upload ? "hidden" : "")}>
        <div>
          <AppFlexList<UploadFileDto>
            key={compKey.key}
            fetchData={fetchData}
            actions={actions}
          >
            {data => data.content.length > 0 ? (
              <div className="flex flex-wrap">
                {data.content.map(file => (
                  <div 
                    className={"w-full md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 p-4 cursor-pointer " + (file.id === selected?.id ? "rounded bg-blue-100 " : "")}
                    onClick={() => setSelected(file)}
                  >
                    <UploadFileDisplay onDelete={handleDelete} file={file} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="pt-20 w-full flex flex-col items-center justify-center text-gray-400">
                <FiInbox className="text-6xl mb-2" />
                <div className="text-sm">Danh sách rỗng</div>
              </div>
            )}
          </AppFlexList>
        </div>
        <div className="fixed right-0 left-0 bottom-0 bg-gray-100 w-full flex items-center justify-end py-4 px-8">
          <Button className="mr-4" variant="outline" color="gray" onClick={onCancel}>Huỷ bỏ</Button>
          <Button disabled={!selected} onClick={select}>Đồng ý</Button>
        </div>
      </div>
    </>
  )
}