import { InteractionType } from "../../enums/InteractionType";
import { ModifyInteractionDto } from "./ModifyInteractionDto";

export class CreateInteractionDto extends ModifyInteractionDto {
  numOrder: number;
  interactionType: InteractionType;
  scenarioId: number;
  quizGameId?: number;
  pollId?: number;
  mountedQuizId?: number;
}