import { Button, Divider, Modal } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { ResponseCode } from "../../config/ResponseConfig";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { QuizGameDto } from "../../dtos/quiz-game/QuizGameDto";
import { InteractionType } from "../../enums/InteractionType";
import { InteractionService } from "../../services/InteractionService";
import { QuizGameList } from "./QuizGameList";

interface MountQuizGameFromLibraryFormProps {
  scenarioId: number;
  zIndex: number;
  onMount: (interaction: InteractionDto) => any;
  numOrder: number;
}

export function MountQuizGameFromLibraryForm(props: MountQuizGameFromLibraryFormProps) {
  const { onMount, numOrder, scenarioId } = props;
  const [quizGame, setQuizGame] = useState<QuizGameDto | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const quizFolderOverrides = useMemo(() => ({
    onSelect: setQuizGame
  }), []);

  const submit = useCallback(async () => {
    if (quizGame) {
      const response = await InteractionService.create({
        interactionType: InteractionType.QUIZ_GAME,
        numOrder,
        scenarioId,
        quizGameId: quizGame.id
      });

      if (response.code === ResponseCode.OK.code) {
        onMount && onMount(response.body);
        setQuizGame(null);
      }
      
      setSubmitting(false);
    }
  }, [quizGame, onMount, scenarioId, numOrder]);

  return (
    <>
      {quizGame && (
        <Modal
          opened
          zIndex={props.zIndex + 1}
          title="Thêm bộ câu hỏi"
          onClose={() => setQuizGame(null)}
        >
          <Divider my="lg" />
          <div className="text-sm mb-4">
            Thêm bộ câu hỏi <b>"{quizGame.name}"</b> vào kịch bản?
          </div>
          <Button fullWidth variant="gradient" onClick={submit} loading={submitting}>
            Đồng ý
          </Button>
        </Modal>
      )}

      <QuizGameList 
        creatable={false}
        zIndex={props.zIndex}
        folderOverrides={quizFolderOverrides}
      />
    </>
  );
}