import { Button } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { QuizDto } from "../../dtos/quiz/QuizDto";
import { useCompKey } from "../../hooks/useCompKey";
import { QuizService } from "../../services/QuizService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { AppConfirmModal } from "../AppConfirmModal";
import { AppFixedModal } from "../AppFixedModal";
import { AppFlexFolders, AppFlexFoldersProps } from "../AppFlexFolders";
import { FlexListQueryDto } from "../AppFlexList";
import { QuizPreview } from "./QuizPreview";
import { UpsertQuizForm } from "./UpsertQuizForm";

interface QuizListProps {
  zIndex: number;
  folderOverrides?: Partial<AppFlexFoldersProps<QuizDto>>;
  onCreateSuccess?: (quiz: QuizDto) => any;
  onUpdateSuccess?: (quiz: QuizDto) => any;
  creatable?: boolean;
  customFilters?: any;
}

export function QuizList(props: QuizListProps) {
  const { zIndex, folderOverrides, creatable, onCreateSuccess, onUpdateSuccess, customFilters } = props;
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState<QuizDto | null>(null);
  const [del, setDel] = useState<QuizDto | null>(null);
  const compKey = useCompKey();

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return QuizService.query({
      ...queryDto,
      sorts: [
        { field: 'updateDt', descending: true }
      ],
      filters: {
        content: queryDto.searchTerm,
        disabled: false,
        ...customFilters
      }
    })
  }, [customFilters]);

  const deleteQuiz = useCallback(async (quiz: QuizDto) => {
    const success = await QuizService.toggle(quiz.id);
    if (success) {
      NotificationUtils.success({ message: <>Xoá câu hỏi <b>{quiz.content} thành công!</b></> });
      setDel(null);
      compKey.renew();
    }
  }, [compKey]);

  const handleUpsertSuccess = useCallback((quiz: QuizDto) => {
    if (create) {
      onCreateSuccess && onCreateSuccess(quiz);
    } else {
      onUpdateSuccess && onUpdateSuccess(quiz);
    }
    compKey.renew();
  }, [onCreateSuccess, onUpdateSuccess, compKey, create]);

  const actions = useMemo(() => {
    if (!creatable) {
      return null; 
    }
    return (
      <Button variant="gradient" onClick={() => setCreate(true)} leftIcon={<HiPlusCircle />}>
        Thêm câu hỏi
      </Button>
    );
  }, [creatable]);

  return (
    <>
      {(create || !!update) && (
        <AppFixedModal
          title={(create ? "Thêm" : "Cập nhật") + " câu hỏi"}
          zIndex={zIndex}
          onClose={() => {
            setCreate(false);
            setUpdate(null);
            
            setTimeout(() => {
              compKey.renew();
            }, 0);
          }}
        >
          <div className="p-4">
            <UpsertQuizForm 
              template={true}
              preview={true}
              submitable={true}
              quiz={update} 
              onSuccess={handleUpsertSuccess}
            />
          </div>
        </AppFixedModal>
      )}

      <AppConfirmModal 
        opened={!!del} 
        onClose={() => setDel(null)}
        onConfirm={() => del && deleteQuiz(del)}
      >
        Bạn có chắc muốn xoá câu hỏi <b>{del?.content}</b> chứ?
      </AppConfirmModal>

      <AppFlexFolders<QuizDto>
        key={compKey.key}
        fetchData={fetchData}
        folderWrapperClassName="w-full md:w-1/2 mb-4 pr-4 text-sm"
        folderLabelRenderer={(quiz: QuizDto) => quiz.content}
        folderPreviewRenderer={(quiz: QuizDto) => (
          <QuizPreview quiz={quiz} />
        )}
        actions={actions}
        onUpdate={setUpdate}
        onSelect={setUpdate}
        onDelete={setDel}
        zIndex={zIndex + 1}
        {...folderOverrides}
      />
    </>
  )
}