import { LogTarget } from "../../enums/LogTarget";
import { DynamicLogDetail } from "./DynamicLogDetail";
import { PollLogDetail } from "./PollLogDetail";
import { QuizLogDetail } from "./QuizLogDetail";

interface PlayerLogDetailProps {
  log: any;
}

export function PlayerLogDetail(props: PlayerLogDetailProps) {
  const { log } = props;
  switch (log.target_type) {
    case LogTarget.QUIZ:
      return <QuizLogDetail log={log} />
    case LogTarget.POLL:
      return <PollLogDetail log={log} />
    default:
      return <DynamicLogDetail log={log} />;
  }
}