import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { UserList } from "../../../components/system/user/UserList";

const breadcrumbs = [
  { label: 'Hệ thống', link: '/app/system' },
  { label: 'Tài khoản', link: '/app/system/user/list' }
];

export function UserListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <UserList />
    </>
  )
}