import { Button, NumberInput, Radio, RadioGroup, TextInput } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ResponseCode } from "../../config/ResponseConfig";
import { MountedQuizDto } from "../../dtos/mounted-quiz/MountedQuizDto";
import { QuizGameDto } from "../../dtos/quiz-game/QuizGameDto";
import { UpsertQuizGameDto } from "../../dtos/quiz-game/UpsertQuizGameDto";
import { QuizMode, QuizModeEn } from "../../enums/QuizMode";
import { useAppFormik } from "../../hooks/useAppFormik";
import { QuizGameService } from "../../services/QuizGameService";
import { NotificationUtils } from "../../utils/NotificationUtils";

interface UpsertQuizGameFormProps {
  elementRef?: any;
  template: boolean;
  quizGame?: QuizGameDto | null;
  onSuccess?: (quizGame: QuizGameDto, update: boolean) => any;
  quizModeValidator?: (quizMode: QuizMode) => MountedQuizDto[];
}

export function UpsertQuizGameForm(props: UpsertQuizGameFormProps) {
  const { elementRef, onSuccess, template, quizModeValidator, quizGame: propsQuizGame } = props;
  const [quizGame, setQuizGame] = useState(propsQuizGame);

  const initialValues = useMemo(() => {
    const upsertDto = new UpsertQuizGameDto();
    if (quizGame) {
      upsertDto.name = quizGame.name;
      upsertDto.quizMode = quizGame.quizMode;
      upsertDto.winRange = quizGame.winRange;
      upsertDto.delay = quizGame.delay;
    }
    upsertDto.quizMode = upsertDto.quizMode || QuizMode.KAHOOT;
    upsertDto.winRange = upsertDto.winRange || 20;
    upsertDto.delay = upsertDto.delay || 0;
    return upsertDto;
  }, [quizGame]);

  const submit = useCallback(async (values: UpsertQuizGameDto, actions: FormikHelpers<UpsertQuizGameDto>) => {
    if (quizModeValidator) {
      const invalidQuizzes = quizModeValidator(values.quizMode);
      if (invalidQuizzes.length > 0) {
        const invalidQuizzesStr = invalidQuizzes.map(mq => mq.numOrder).join(', ');
        NotificationUtils.error({
          message: <>Vui lòng thêm câu trả lời, đáp án đúng cho{invalidQuizzes.length > 1 ? ' các' : ''} câu hỏi <b>{invalidQuizzesStr}</b> để có thể đổi sang chế độ chơi <b>{QuizModeEn[values.quizMode]}</b></>,
          autoClose: 10000
        });
        actions.setSubmitting(false);
        return;
      }
    }

    values.template = template;

    let response;
    if (quizGame) {
      response = await QuizGameService.update(quizGame.id, values);
    } else {
      const isNameExisted = await QuizGameService.checkName(values.name);
      if (isNameExisted) {
        actions.setFieldError('name', 'Tên bộ câu hỏi đã tồn tại, vui lòng chọn tên khác');
        actions.setSubmitting(false);
        return;
      }

      response = await QuizGameService.create(values);
    }

    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: 'Lưu bộ câu hỏi thành công' });
      setQuizGame(response.body);
      
      onSuccess && onSuccess(response.body, !!quizGame);
    }

    actions.setSubmitting(false);
  }, [quizGame, onSuccess, template, quizModeValidator]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  useEffect(() => {
    if (elementRef) {
      elementRef.current = { formik };
    }
  }, [elementRef, formik]);

  return (
    <form 
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset} 
    >
      <div className="w-full flex items-start mb-4">
        <div className="w-72 mr-4">
          <TextInput
            required
            name="name"
            label="Tên bộ câu hỏi"
            placeholder="Tên bộ câu hỏi..."
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            error={formik.touched.name && formik.errors.name}
          />
        </div>

        <RadioGroup
          required
          name="selectMode" 
          label="Chế độ chơi"
          size="sm"
          onChange={value => formik.handleChange('quizMode')(value)}
          onBlur={formik.handleBlur}
          value={formik.values.quizMode}
          error={formik.touched.quizMode && formik.errors.quizMode}
        >
          <Radio value={QuizMode.KAHOOT} label={QuizModeEn[QuizMode.KAHOOT]} />
          <Radio value={QuizMode.CONFETTI} label={QuizModeEn[QuizMode.CONFETTI]} />
          <Radio value={QuizMode.FOOTBALL} label={QuizModeEn[QuizMode.FOOTBALL]} />
        </RadioGroup>
      </div>

      <div className="flex items-start mb-4">
        <NumberInput 
          required
          min={1}
          name="winRange"
          label="Số người chiến thắng"
          placeholder="Số người chiến thắng..."
          className="w-44 mr-4"
          onChange={v => formik.setFieldValue('winRange', v)}
          onBlur={formik.handleBlur}
          value={formik.values.winRange}
          error={formik.touched.winRange && formik.errors.winRange}
        />

        {/* <NumberInput 
          required
          min={0}
          name="delay"
          label="Delay"
          placeholder="Delay..."
          className="w-28 mr-8"
          onChange={v => formik.setFieldValue('delay', v)}
          onBlur={formik.handleBlur}
          value={formik.values.delay}
          error={formik.touched.delay && formik.errors.delay}
        /> */}
      </div>

      <Button type="submit" variant="gradient" loading={formik.isSubmitting}>Lưu lại</Button>
    </form>
  )
}