import { useCallback, useMemo, useState } from "react";
import { EventCalendarDto } from "../../../dtos/event-calendar/EventCalendarDto";
import { AppTable, AppTableColumn } from "../../AppTable";
import { FormatUtils } from '../../../utils/FormatUtils';
import { EventCalendarStatusDisplay } from "../../../enums/EventCalendarStatus";
import { Link } from "react-router-dom";
import { Button, Divider, Modal } from "@mantine/core";
import { useSession } from "../../../hooks/useSession";
import { QueryEventCalendarDto } from "../../../dtos/event-calendar/QueryEventCalendarDto";
import { FlexListQueryDto } from "../../AppFlexList";
import { UserRole } from '../../../enums/UserRole';
import { EventCalendarService } from "../../../services/EventCalendarService";
import { useCompKey } from "../../../hooks/useCompKey";
import { UpsertEventCalendarForm } from "./UpsertEventCalendarForm";
import { HiPlusCircle } from "react-icons/hi";

export function EventCalendarList() {
  const { user } = useSession();
  const compKey = useCompKey();
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState<EventCalendarDto | undefined>();

  const columns = useMemo(() => {
    const columns = [
      { title: 'ID', render: e => e.id },
      { title: 'Tên', render: e => e.name },
      { title: 'Room', render: e => e.roomId },
      { title: 'Trạng thái', render: e => EventCalendarStatusDisplay[e.status] },
      { title: 'Bắt đầu', render: e => FormatUtils.dt2(e.startDt) },
      { title: 'Kết thúc', render: e => FormatUtils.dt2(e.endDt) },
      {
        title: 'Lịch sử vận hành',
        render: e => (
          <Link to={`/app/livestream/event-calendar/detail/${e.id}`}>
            <Button compact>Chi tiết</Button>
          </Link>
        )
      },
      {
        title: 'Hành động',
        render: e => (
          <div className="flex items-center">
            <Button className="mr-2" compact onClick={() => setUpdate(e)}>Sửa</Button>
            <Link to={`/app/operate/livestream?roomId=${e.roomId}`}>
              <Button compact color="red">Vận hành</Button>
            </Link>
          </div>
        )
      }
    ] as AppTableColumn<EventCalendarDto>[];

    if (user.role !== UserRole.ADMIN) {
      columns.splice(-1, 0, { title: 'App', render: e => e.appId });
    }

    return columns;
  }, [user]);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    const queryEventCalendarDto: QueryEventCalendarDto = {
      ...queryDto,
      sorts: [
        { field: 'endDt', descending: true }
      ],
      filters: {
        name: queryDto.searchTerm
      }
    };

    if (user.role !== UserRole.ADMIN) {
      queryEventCalendarDto.filters.appId = user.appId;
    }

    return EventCalendarService.query(queryEventCalendarDto);
  }, [user]);

  const createBtn = useMemo(() => (
    <Button variant="gradient" onClick={() => setCreate(true)} leftIcon={<HiPlusCircle />}>
      Thêm lịch sự kiện
    </Button>
  ), []);

  const handleCreateSuccess = useCallback(() => {
    setCreate(false);
    setUpdate(undefined);
    compKey.renew();
  }, [compKey]);

  return (
    <>
      <Modal 
        opened={create || !!update}
        title={(create ? "Thêm" : "Cập nhật")+ " lịch sự kiện"} 
        zIndex={3}
        onClose={() => {
          setCreate(false);
          setUpdate(undefined);
        }}>
        <Divider my="lg" />
        <UpsertEventCalendarForm eventCalendar={update} onSuccess={handleCreateSuccess} />
      </Modal>

      <AppTable 
        key={compKey.key}
        fetchData={fetchData}
        columns={columns}
        actions={createBtn}
      />
    </>
  )
}