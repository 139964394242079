import { Table, TableProps } from '@mantine/core';
import { FiInbox } from 'react-icons/fi';
import { PageDto } from '../dtos/common/PageDto';
import { useCompKey } from '../hooks/useCompKey';
import { AppFlexList, FlexListQueryDto } from './AppFlexList';

export interface AppTableColumn<T> {
  title: string;
  render: (item: T, items: T[], refresh: () => any) => any;
  className?: string;
  style?: any;
  width?: any;
}

export interface AppTableProps<T> extends TableProps {
  hideSearch?: boolean;
  hidePagination?: boolean;
  columns: AppTableColumn<T>[];
  fetchData: (queryDto: FlexListQueryDto) => Promise<PageDto<T>>;
  rowModifier?: (row: T) => any;
  actions?: any;
}

export function AppTable<T>(props: AppTableProps<T>) {
  const compKey = useCompKey();
  const { columns, fetchData, actions, hideSearch, hidePagination, rowModifier, ...tableProps } = props;
  return (
    <AppFlexList<T> 
      key={compKey.key}
      fetchData={fetchData}
      actions={actions}
      hidePagination={hidePagination}
      hideSearch={hideSearch}
    >
      {data => (
        data.content.length > 0 ? (
          <Table {...tableProps}>
            <thead>
              <tr>
                {columns.map((col, idx) => (
                  <th key={idx}>{col.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.content.map((row, rowIdx) => (
                <tr key={rowIdx} className="hover:bg-gray-50" {...(rowModifier ? rowModifier(row) : undefined)}>
                  {columns.map((col, colIdx) => (
                    <td key={colIdx} width={col.width} className={col.className} style={col.style}>
                      {col.render(row, data.content, compKey.renew)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        )  : (
          <div className="pt-20 w-full flex flex-col items-center justify-center text-gray-400">
            <FiInbox className="text-6xl mb-2" />
            <div className="text-sm">Danh sách rỗng</div>
          </div>
        )
      )}
    </AppFlexList>
  )
}