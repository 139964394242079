import { Button } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { LogActionDisplay } from "../../../enums/LogAction";
import { LogActorTypeDisplay } from "../../../enums/LogActorType";
import { LogTargetDisplay } from "../../../enums/LogTarget";
import { LogType, LogTypeDisplay } from "../../../enums/LogType";
import { LogService } from "../../../services/LogService";
import { FormatUtils } from "../../../utils/FormatUtils";
import { FlexListQueryDto } from "../../AppFlexList";
import { AppQuestionTitle } from "../../AppQuestionTitle";
import { AppTable, AppTableColumn } from "../../AppTable";
import { LogDetailModal } from "../../log/LogDetailModal";

interface PlayerLogListProps {
  roomId: string;
}

export function PlayerLogList(props: PlayerLogListProps) {
  const { roomId } = props;
  const [detail, setDetail] = useState<any>();

  const columns = useMemo(() => {
    return [
      { title: 'T.gian', render: row => FormatUtils.dt(row['@timestamp']) },
      { title: <AppQuestionTitle title="log_type">Loại log</AppQuestionTitle>, render: row => (LogTypeDisplay as any)[row.log_type] },
      { title: <AppQuestionTitle title="actor_type">Tác nhân</AppQuestionTitle>, render: row => (LogActorTypeDisplay as any)[row.actor_type] },
      { title: <AppQuestionTitle title="actor_id">ID tác nhân</AppQuestionTitle>, render: row => row.actor_id },
      { title: <AppQuestionTitle title="action">H.động</AppQuestionTitle>, render: row => (LogActionDisplay as any)[row.action] },
      { title: <AppQuestionTitle title="target_type">Đ.tượng</AppQuestionTitle>, render: row => (LogTargetDisplay as any)[row.target_type] },
      { title: <AppQuestionTitle title="target_id">ID đ.tượng</AppQuestionTitle>, render: row => row.target_id },
      { 
        title: 'Chi tiết',
        render: row => <Button compact onClick={() => setDetail(row)}>Chi tiết</Button>
      }
    ] as AppTableColumn<any>[];
  }, []);

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return LogService.query({
      ...queryDto,
      sorts: [
        { field: '@timestamp', descending: false }
      ],
      filters: {
        searchTerm: queryDto.searchTerm,
        room_id: roomId,
        log_type: [LogType.OPERATION_LOG, LogType.PLAYER_LOG]
      }
    });
  }, [roomId]);

  const rowModifier = useCallback((row: any) => {
    if (row.log_type === LogType.OPERATION_LOG) {
      return { className: 'bg-red-100' };
    }
  }, []);

  return (
    <>
      <LogDetailModal log={detail} opened={!!detail} onClose={() => setDetail(undefined)} />
      <AppTable
        columns={columns}
        fetchData={fetchData}
        rowModifier={rowModifier}
      />
    </>
  );
}