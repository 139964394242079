export enum InteractionType {
  QUIZ_GAME = 'QUIZ_GAME',
  QUIZ = 'QUIZ',
  POLL = 'POLL'
}

export const InteractionTypeDisplay = {
  [InteractionType.QUIZ_GAME]: 'Quiz game',
  [InteractionType.QUIZ]: 'Câu hỏi',
  [InteractionType.POLL]: 'Khảo sát'
}