import { Image } from "@mantine/core";
import warningImage from '../images/undraw_warning.svg';

export function AppNotAllowed() {
  return (
    <div className="flex items-center justify-center pt-12">
      <div className="w-64">
        <Image src={warningImage} alt="Access denied" />
      </div>
      <p className="mt-8 text-xl text-red-400 font-bold">Dừng lại...</p>
      <p className="mt-2">Bạn không có quyền truy cập vào trang này</p>
    </div>
  );
}
