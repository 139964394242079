import { Tab, Tabs } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router";
import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { AppLoading } from "../../../components/AppLoading";
import { QuizGameLogList } from "../../../components/livestream/quiz-game-result/QuizGameLogList";
import { QuizGameResult } from "../../../components/livestream/quiz-game-result/QuizGameResult";
import { QuizResultList } from "../../../components/livestream/quiz-result/QuizResultList";
import { QuizGameDto } from "../../../dtos/quiz-game/QuizGameDto";
import { QuizModeEn } from "../../../enums/QuizMode";
import { useQuery } from "../../../hooks/useQuery";
import { InteractionService } from "../../../services/InteractionService";
import { FormatUtils } from "../../../utils/FormatUtils";

export function QuizGameResultPage() {
  const params: any = useParams();
  const quizGameItrId = params.quizGameId;

  const breadcrumbs = useMemo(() => [
    { label: 'Livestream', link: '/app/livestream' },
    { label: 'Lịch sử vận hành', link: '/app/livestream/interactive/history/list' },
    { label: 'Lịch sử quiz game', link: '/app/livestream/interactive/history/quiz-game/' + quizGameItrId }
  ], [quizGameItrId]);

  const fetchQuizGameItr = useCallback(() => {
    return InteractionService.findById(quizGameItrId);
  }, [quizGameItrId]);

  const itrResp = useQuery(fetchQuizGameItr);
  if (!itrResp.ready || !itrResp.data) {
    return <AppLoading />
  }

  const itr = itrResp.data;
  const quizGame = itrResp.data.quizGame as QuizGameDto;

  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />

      <div className="border rounded p-2 flex justify-between text-xs mb-4">
        <div>Quiz game: <b>{quizGame.name}</b></div>
        <div>Chế độ chơi: <b>{QuizModeEn[quizGame.quizMode]}</b></div>
        <div>Bắt đầu: <b>{FormatUtils.dt(itr.publishDt)}</b></div>
        <div>Kết thúc: <b>{FormatUtils.dt(itr.finishDt)}</b></div>
      </div>

      <Tabs>
        <Tab label="Kết quả">
          <div className="mt-4">
            <QuizGameResult quizGame={quizGame} />
          </div>
        </Tab>
        <Tab label="Câu hỏi">
          <div className="mt-2">
            <QuizResultList quizGameId={quizGame.id} quizMode={quizGame.quizMode} />
          </div>
        </Tab>
        <Tab label="Log">
          <div className="mt-4">
            <QuizGameLogList quizGameId={quizGame.id} />
          </div>
        </Tab>
      </Tabs>
    </>
  )
}