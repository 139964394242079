import { Button, Divider, Modal } from "@mantine/core";
import { useCallback, useMemo, useRef, useState } from "react";
import { ResponseCode } from "../../config/ResponseConfig";
import { ModifyMountedQuizDto } from "../../dtos/mounted-quiz/ModifyMountedQuizDto";
import { MountedQuizDto } from "../../dtos/mounted-quiz/MountedQuizDto";
import { QuizDto } from "../../dtos/quiz/QuizDto";
import { QuizMode } from "../../enums/QuizMode";
import { MountedQuizService } from "../../services/MountedQuizService";
import { QuizList } from "../quiz/QuizList";
import { UpsertMountedQuizForm } from "./UpsertMountedQuizForm";

interface MountQuizFromLibraryFormProps {
  quizMode: QuizMode;
  quizGameId: number;
  zIndex: number;
  onMount: (mq: MountedQuizDto) => any;
  numOrder: number;
  template: boolean;
}

export function MountQuizFromLibraryForm(props: MountQuizFromLibraryFormProps) {
  const { onMount, quizGameId, numOrder, template, quizMode } = props;
  const [quiz, setQuiz] = useState<QuizDto | null>(null);
  const [submitting, setSubmitting] = useState(false);
  
  const upsertMQRef = useRef<any>(null);
  const quizFolderOverrides = useMemo(() => ({
    onSelect: setQuiz
  }), []);

  const handleValidatedMQ = useCallback(async (modifyDto: ModifyMountedQuizDto) => {
    if (quiz) {
      const response = await MountedQuizService.create({
        ...modifyDto,
        template,
        quizGameId,
        quizId: quiz.id,
        numOrder
      });

      if (response.code === ResponseCode.OK.code) {
        onMount && onMount(response.body);
        setQuiz(null);
      }
      
      setSubmitting(false);
    }
  }, [quiz, onMount, quizGameId, numOrder, template]);

  const submit = useCallback(() => {
    setSubmitting(true);
    upsertMQRef.current.formik.handleSubmit();
  }, []);

  const customFilters = useMemo(() => {
    if (quizMode !== QuizMode.FOOTBALL) {
      return { correctAnswerCountGte: 1 };
    }
    return undefined;
  }, [quizMode]);

  return (
    <>
      {quiz && (
        <Modal
          opened
          zIndex={props.zIndex + 1}
          title="Thêm câu hỏi"
          onClose={() => setQuiz(null)}
        >
          <Divider my="lg" />
          <div className="text-sm mb-4">
            Thêm câu hỏi <b>"{quiz.content}"</b> vào bộ câu hỏi?
          </div>
          <UpsertMountedQuizForm
            onValidated={handleValidatedMQ}
            elementRef={upsertMQRef}
            quizMode={props.quizMode}
            vertical={true}
          />
          <Button fullWidth variant="gradient" onClick={submit} loading={submitting}>
            Đồng ý
          </Button>
        </Modal>
      )}

      <QuizList 
        creatable={false}
        zIndex={props.zIndex}
        folderOverrides={quizFolderOverrides}
        customFilters={customFilters}
      />
    </>
  );
}