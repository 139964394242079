import { useCallback, useMemo, useState } from "react";
import { RandomUtils } from '../utils/RandomUtils';

export interface ICompKey {
  key: string;
  renew: () => void;
}

export function useCompKey(): ICompKey {
  const [key, setKey] = useState(RandomUtils.uuid());
  const renew = useCallback(() => {
    setKey(RandomUtils.uuid());
  }, []);
  return useMemo(() => ({ key, renew }), [key, renew]);
}
