import { NumberInput, Radio, RadioGroup } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo } from "react";
import { AiOutlineStar } from "react-icons/ai";
import { ModifyMountedQuizDto } from "../../dtos/mounted-quiz/ModifyMountedQuizDto";
import { MountedQuizDto } from "../../dtos/mounted-quiz/MountedQuizDto";
import { QuizMode } from "../../enums/QuizMode";
import { QuizScoreCondition, QuizScoreConditionVi } from "../../enums/QuizScoreCondition";
import { useAppFormik } from "../../hooks/useAppFormik";

interface UpsertMountedQuizFormProps {
  quizMode: QuizMode;
  vertical?: boolean;
  mountedQuiz?: MountedQuizDto | null;
  onValidated?: (modifyDto: ModifyMountedQuizDto) => any;
  elementRef?: any;
}

export function UpsertMountedQuizForm(props: UpsertMountedQuizFormProps) {
  const { mountedQuiz, onValidated, elementRef } = props;
  const initialValues = useMemo(() => {
    const modifyDto = new ModifyMountedQuizDto();
    if (mountedQuiz) {
      modifyDto.maxScore = mountedQuiz.maxScore;
      modifyDto.scoreCondition = mountedQuiz.scoreCondition;
    }
    modifyDto.maxScore = modifyDto.maxScore || 1000;
    modifyDto.scoreCondition = modifyDto.scoreCondition || QuizScoreCondition.ALL;
    return modifyDto;
  }, [mountedQuiz]);

  const submit = useCallback(async (values: ModifyMountedQuizDto, actions: FormikHelpers<ModifyMountedQuizDto>) => {
    onValidated && onValidated(values);
    actions.setSubmitting(false);
  }, [onValidated]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  useEffect(() => {
    if (elementRef) {
      elementRef.current = { formik };
    }
  }, [elementRef, formik]);

  const inputClassName = props.vertical ? "w-full mb-6" : "mr-10 mb-4";

  return (
    <form 
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset} 
      className={"flex relative" + (props.vertical ? " flex-wrap" : "")}
    >
      {props.quizMode !== QuizMode.CONFETTI && (
        <NumberInput
          required
          min={100}
          max={100000}
          label="Tổng điểm"
          placeholder="Tổng điểm..."
          className={inputClassName}
          icon={<AiOutlineStar />}
          onChange={value => formik.setFieldValue('maxScore', value)}
          onBlur={formik.handleBlur}
          value={formik.values.maxScore}
          error={formik.touched.maxScore && formik.errors.maxScore}
        />
      )}

      {props.quizMode === QuizMode.KAHOOT && (
        <div className={inputClassName}>
          <RadioGroup
            required
            size="sm"
            name="selectMode" 
            label="Tính điểm khi"
            onChange={value => formik.handleChange('scoreCondition')(value)}
            onBlur={formik.handleBlur}
            value={formik.values.scoreCondition}
            error={formik.touched.scoreCondition && formik.errors.scoreCondition}
          >
            <Radio value={QuizScoreCondition.ALL} label={QuizScoreConditionVi[QuizScoreCondition.ALL]} />
            <Radio value={QuizScoreCondition.PARTIAL} label={QuizScoreConditionVi[QuizScoreCondition.PARTIAL]} />
          </RadioGroup>
        </div>
      )}
    </form>
  )
}