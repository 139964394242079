import { ActionIcon, Button, LoadingOverlay, Modal } from "@mantine/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FiInbox } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { RiEditBoxLine } from "react-icons/ri";
import { EndPollDto } from "../../dtos/poll/EndPollDto";
import { EndQuizDto } from "../../dtos/quiz/EndQuizDto";
import { ActionType } from "../../enums/ActionType";
import { EventType } from "../../enums/EventType";
import { InteractionType } from "../../enums/InteractionType";
import { PublishStatus } from "../../enums/PublishStatus";
import { QuizMode } from "../../enums/QuizMode";
import { useModal } from "../../hooks/useModal";
import { useQuery } from "../../hooks/useQuery";
import { InteractionService } from "../../services/InteractionService";
import { OperateService } from "../../services/OperateService";
import { QuizGameService } from "../../services/QuizGameService";
import { QuizService } from "../../services/QuizService";
import { RealtimeService } from "../../services/RealtimeService";
import { RoomService } from "../../services/RoomService";
import { ScenarioService } from "../../services/ScenarioService";
import { useAppStore } from "../../store/useAppStore";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { UpdateQuizCorrectAnswersModal } from "../quiz/UpdateQuizCorrectAnswersModal";
import { PollInteraction } from "./PollInteraction";
import { QuizGameInteraction } from "./QuizGameInteraction";
import { QuizInteraction } from "./QuizInteraction";
import { DelayCountDownDto } from "../../dtos/realtime/DelayCountDownDto";
import { ShowNotificationForm } from "../operate-livestream/interactive/ShowNotificationForm";
import { AppConfig } from "../../config/AppConfig";
import { Notification3rdService } from "../../services/Notification3rdService";
import { HTVGoService } from "../../services/HTVGoService";

export function OperateInteraction() {
  const { store, dispatch } = useAppStore();
  const { 
    clockTick, autoPublish, 
    currQuizGameItrId, currQuizItrId, itrNodeMap, itrNodes,
    currScenario, currRoomId, isRoomOpened,
    currEvent, currHTVGoLivestream
  } = store;
  
  const autoPublishItvRef = useRef<any>();
  
  const scenarioId = currScenario?.id;
  const fetchItr = useMemo(() => {
    return scenarioId ? () => InteractionService.find({ scenarioId }) : undefined;
  }, [scenarioId]);

  const itrResponse = useQuery(fetchItr);
  const [selectedQGId, setSelectedQGId] = useState<number | undefined>(currQuizGameItrId);
  const [loading, setLoading] = useState(false);
  const [delay, setDelay] = useState(0);
  const [updateCorrectAnswers, setUpdateCorrectAnswers] = useState(false);

  const [isNotificationShowing, setIsNotificationShowing] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  
  const confirmModal = useModal();

  useEffect(() => {
    if (itrResponse.ready && itrResponse.data) {
      dispatch(ActionType.SET_ITR_NODES, itrResponse.data);
    }
  }, [itrResponse, dispatch]);

  const startScenario = useCallback(async () => {
    if (currRoomId && currScenario) {
      setLoading(true);

      let success = true;
      let isRoomOpened = await RoomService.isRoomOpened(currRoomId);
      if (!isRoomOpened) {
        success = await RoomService.openRoom({
          roomId: currRoomId,
          eventId: currEvent?.id,
          maxConnection: await OperateService.getMaxConnection()
        });

        if (success && AppConfig.notification3rd.ENABLE && !!currEvent?.id) {
          await Notification3rdService.notifyEventLive(currEvent.id);
        }

        if (success && AppConfig.HTVGo.ENABLE && !!currHTVGoLivestream) {
          await HTVGoService.startLivestream(currHTVGoLivestream);
        }
      }

      if (success) {
        success = await ScenarioService.publish(currScenario.id, {
          roomId: currRoomId,
          eventId: currEvent?.id
        });

        if (success) {
          NotificationUtils.success({ message: 'Bắt đầu kịch bản thành công' });
          dispatch(ActionType.UPDATE_SCENARIO, {
            ...currScenario,
            publishStatus: PublishStatus.PUBLISHING
          });
        }
      }

      setLoading(false);
      return success;
    }

    return false;
  }, [currScenario, currRoomId, currEvent, currHTVGoLivestream, dispatch]);

  const finishScenario = useCallback(async () => {
    let success = false;
    if (currScenario) {
      setLoading(true);
      success = await ScenarioService.finish(currScenario.id);
      if (success) {
        NotificationUtils.success({ message: 'Kết thúc kịch bản thành công' });
        dispatch(ActionType.REMOVE_SCENARIO);
      }
      setLoading(false);
    }
    return success;
  }, [currScenario, dispatch]);

  const closeRoom = useCallback(async () => {
    if (currRoomId) {
      setLoading(true);
      const success = await RoomService.closeRoom(currRoomId);
      if (success) {
        if (AppConfig.HTVGo.ENABLE && !!currHTVGoLivestream) {
          await HTVGoService.stopLivestream(currHTVGoLivestream);
        }

        NotificationUtils.success({ message: 'Đóng room thành công' });
        dispatch(ActionType.CLOSE_ROOM);

        window.confirm(`Room ${currRoomId} đã kết thúc!`);
        window.location.pathname = `/app/livestream/interactive/history/list`;
      }
      setLoading(false);
    }
  }, [currRoomId, currHTVGoLivestream, dispatch]);

  const startQuizGame = useCallback(async () => {
    if (currScenario && selectedQGId !== undefined) {
      let success = true;
      if (currScenario.publishStatus !== PublishStatus.PUBLISHING) {
        success = await startScenario();
      }

      setLoading(true);
      if (success) {
        success = await QuizGameService.publish(selectedQGId);
      }

      if (success) {
        NotificationUtils.success({ message: 'Bắt đầu game thành công' });
        
        dispatch(ActionType.SET_CURR_QUIZ_GAME_ITR_ID, selectedQGId);
        dispatch(ActionType.UPDATE_ITR_NODE, {
          id: selectedQGId,
          publishStatus: PublishStatus.PUBLISHING
        });
      }
      setLoading(false);
    }
  }, [currScenario, selectedQGId, dispatch, startScenario]);

  const finishQuizGame = useCallback(async () => {
    if (currScenario && currQuizGameItrId !== undefined) {
      setLoading(true);
      const doFinishQuizGame = async () => {
        const success = await QuizGameService.finish(currQuizGameItrId);
        if (success) {
          NotificationUtils.success({ message: 'Kết thúc game thành công' });
          dispatch(ActionType.SET_CURR_QUIZ_GAME_ITR_ID, undefined);
          dispatch(ActionType.SET_CURR_QUIZ_ITR_ID, undefined);
          dispatch(ActionType.UPDATE_ITR_NODE, {
            id: currQuizGameItrId,
            publishStatus: PublishStatus.FINISHED
          });
        }
      }

      if (currQuizItrId !== undefined && !!clockTick) {
        confirmModal.warn({
          title: 'Cẩn thận khi kết thúc game',
          message: 'Một câu hỏi đang được diễn ra, bạn có chắc muốn kết thúc game chứ?',
          onOk: async () => {
            await doFinishQuizGame();
            confirmModal.hide();
          }
        });
      } else {
        await doFinishQuizGame();
      }
      setLoading(false);
    }
  }, [currScenario, clockTick, currQuizGameItrId, currQuizItrId, dispatch, confirmModal]);

  const publishNextQuiz = useCallback(async () => {
    if (currQuizItrId !== undefined) {
      const success = await QuizService.publish(currQuizItrId);
      if (success) {
        NotificationUtils.success({ message: 'Publish câu hỏi thành công' });
        dispatch(ActionType.UPDATE_ITR_NODE, {
          id: currQuizItrId,
          publishStatus: PublishStatus.PUBLISHING
        });
      }
    }
  }, [currQuizItrId, dispatch]);

  const clearAutoPublishItv = useCallback(() => {
    if (autoPublishItvRef.current) {
      dispatch(ActionType.SET_AUTO_PUBLISH_COUNTDOWN, undefined);
      clearInterval(autoPublishItvRef.current);
    }
  }, [dispatch]);

  const restartAutoPublishCountDown = useCallback(() => {
    if (currRoomId) {
      clearAutoPublishItv();
      let countdown = OperateService.getAutoPublishGap(currRoomId);
      autoPublishItvRef.current = setInterval(() => {
        dispatch(ActionType.SET_AUTO_PUBLISH_COUNTDOWN, --countdown);
        if (countdown === 0) {
          publishNextQuiz();
        }
      }, 1000);
    }
  }, [currRoomId, clearAutoPublishItv, dispatch, publishNextQuiz]);

  const publishResult = useCallback(async () => {
    if (currQuizGameItrId !== undefined) {
      const success = await QuizGameService.publishResult(currQuizGameItrId);
      if (success) {
        NotificationUtils.success({ message: 'Publish kết quả của người chơi thành công' });
      }
    }
  }, [currQuizGameItrId]);

  const handleEndQuiz = useCallback((dto: EndQuizDto) => {
    if (currRoomId && currQuizGameItrId !== undefined) {
      const quizGameItr = itrNodeMap[currQuizGameItrId];
      if (quizGameItr?.children) {
        const quizItr = quizGameItr.children.find(itr => itr.id === dto.interactionId);
        if (quizItr) {
          dispatch(ActionType.UPDATE_ITR_NODE, {
            id: quizItr.id,
            publishStatus: PublishStatus.FINISHED
          });

          const nextQuiz = OperateService.findNextQuiz(currRoomId, quizGameItr.children, quizItr);
          if (nextQuiz) {
            dispatch(ActionType.SET_CURR_QUIZ_ITR_ID, nextQuiz.id);
          } else {
            dispatch(ActionType.SET_CURR_QUIZ_ITR_ID, undefined);
          }
        }
      }
    }
  }, [currRoomId, currQuizGameItrId, itrNodeMap,dispatch]);

  const handleEndPoll = useCallback((dto: EndPollDto) => {
    dispatch(ActionType.SET_CURR_POLL_ITR_ID, undefined);
    dispatch(ActionType.UPDATE_ITR_NODE, {
      id: dto.interactionId,
      publishStatus: PublishStatus.FINISHED
    });
  }, [dispatch]);

  const handleDelayCountDown = useCallback((dto: DelayCountDownDto) => {
    setDelay(dto.atSecond);
  }, []);

  const hideNotification = useCallback(async () => {
    if (currRoomId) {
      setLoading(true);
      const success = await RoomService.hideNotification(currRoomId);
      if (success) {
        NotificationUtils.success({ message: 'Ẩn thông báo thành công' });
      }
      setLoading(false);
    }
  }, [currRoomId]);

  useEffect(() => {
    if (currRoomId) {
      return RealtimeService.subscribe(currRoomId, eventDto => {
        switch (eventDto.eventType) {
          case EventType.END_QUIZ:
            handleEndQuiz(eventDto.payload);
            break;
          case EventType.END_POLL:
            handleEndPoll(eventDto.payload);
            break;
          case EventType.DELAY_COUNT_DOWN:
            handleDelayCountDown(eventDto.payload);
            break;
          case EventType.PUBLISH_QUIZ_GAME:
            dispatch(ActionType.SET_CURR_QUIZ_GAME_ITR_ID, eventDto.payload.interactionId);
            break;
          case EventType.PUBLISH_QUIZ:
            dispatch(ActionType.SET_CURR_QUIZ_ITR_ID, eventDto.payload.interactionId);
            break;
          case EventType.PUBLISH_POLL:
            dispatch(ActionType.SET_CURR_POLL_ITR_ID, eventDto.payload.interactionId);
            break;
          case EventType.SHOW_NOTIFICATION:
            setIsNotificationShowing(true);
            break;
          case EventType.HIDE_NOTIFICATION:
            setIsNotificationShowing(false);
            break;
        }
      });
    }
  }, [
    currRoomId, handleEndPoll,
    handleEndQuiz, handleDelayCountDown, dispatch
  ]);

  useEffect(() => {
    if (autoPublish && !clockTick && currQuizItrId !== undefined) {
      restartAutoPublishCountDown();
    }
    return clearAutoPublishItv;
  }, [
    clockTick, autoPublish, currQuizItrId, 
    restartAutoPublishCountDown, clearAutoPublishItv
  ]);

  const quizGameItr = selectedQGId !== undefined ? itrNodeMap[selectedQGId] : null;
  const handleUpdateCorrectAnswersSuccess = useCallback(async (correctAnswerIds: number[]) => {
    if (quizGameItr) {
      quizGameItr.children?.forEach(itr => {
        if (itr.mountedQuiz?.quiz) {
          itr.mountedQuiz.quiz.answers.forEach(ans => {
            ans.correct = correctAnswerIds.includes(ans.id);
          });
        }
      })
    }
  }, [quizGameItr]);

  const publishingItr = useMemo(() => {
    return itrNodes.find(itr => itr.publishStatus === PublishStatus.PUBLISHING);
  }, [itrNodes]);
  
  return (
    <>
      {updateCorrectAnswers && quizGameItr && (
        <UpdateQuizCorrectAnswersModal 
          opened={true}
          quizGameId={quizGameItr.quizGameId}
          quizzes={quizGameItr.children?.map(itr => itr.mountedQuiz?.quiz) as any}
          onClose={() => setUpdateCorrectAnswers(false)}
          onSuccess={handleUpdateCorrectAnswersSuccess}
        />
      )}

      {showNotification && (
        <Modal title="Hiện thông báo" opened={true} onClose={() => setShowNotification(false)}>
          <ShowNotificationForm onSuccess={() => setShowNotification(false)} />
        </Modal>
      )}

      <div className="relative h-full">
        <LoadingOverlay visible={!!fetchItr && itrResponse.loading} />

        {confirmModal.modal}

        <div className="px-5" style={{ height: 'calc(100% - 3.5rem)' }}>
          {!itrResponse.ready ? (
            <div className="pt-20 w-full flex flex-col items-center justify-center text-gray-400">
              <FiInbox className="text-6xl mb-2" />
              <div className="text-sm">Không có tương tác nào</div>
            </div>
          ) : (
            <div className="overflow-auto h-full">
              {quizGameItr && (
                <div className="mt-2 flex items-center">
                  <div className="text-sm itr-hover:wrapper">
                    <div 
                      className="items-center text-blue-400 itr-hover:flex hidden cursor-pointer"
                      onClick={() => setSelectedQGId(undefined)}
                    >
                      <IoIosArrowBack className="mr-1" style={{ marginLeft: '-5px' }} />
                      <span>Danh sách tương tác</span>
                    </div>
                    <div className="itr-hover:hidden block">
                      <b className="text-sm">{quizGameItr.quizGame?.name}</b> 
                      <span className="text-gray-400 ml-1 text-sm">
                        ({quizGameItr.quizGame?.totalQuiz} câu)
                      </span>
                    </div>
                  </div>
                  {quizGameItr.quizGame?.quizMode === QuizMode.FOOTBALL && 
                    quizGameItr.publishStatus !== PublishStatus.IDLE && (
                    <ActionIcon color="blue" onClick={() => setUpdateCorrectAnswers(true)} className="ml-1">
                      <RiEditBoxLine />
                    </ActionIcon>
                  )}
                </div>
              )}
              
              {(quizGameItr?.children || itrNodes).map((itr, idx) => {
                switch (itr.interactionType) {
                  case InteractionType.QUIZ_GAME:
                    return (
                      <QuizGameInteraction key={itr.id} interaction={itr} numOrder={idx + 1} onSelect={() => setSelectedQGId(itr.id)} />
                    );
                  case InteractionType.QUIZ:
                    return (
                      <QuizInteraction key={itr.id} interaction={itr} numOrder={idx + 1} />
                    );
                  case InteractionType.POLL:
                    return (
                      <PollInteraction key={itr.id} interaction={itr} numOrder={idx + 1} />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          )}
        </div>

        <div className="w-full bg-gray-100 px-4 h-14 flex items-center">
          <div className="ml-auto">
            {!!currRoomId && isRoomOpened && !clockTick && !delay && (
              isNotificationShowing ? (
                <Button size="xs" className="mr-2" onClick={hideNotification} loading={loading}>Ẩn thông báo</Button>
              ): (
                <Button size="xs" className="mr-2" onClick={() => setShowNotification(true)} loading={loading}>Hiện thông báo</Button>
              )
            )}

            {selectedQGId !== undefined ? (
              currQuizGameItrId !== undefined ? (
                currQuizGameItrId === selectedQGId && (
                  <>
                    <Button size="xs" color="red" onClick={finishQuizGame} loading={loading} className="mr-2">
                      Kết thúc game
                    </Button>
                    {currQuizItrId !== undefined ? (
                      <Button size="xs" disabled={!!clockTick || !!delay} onClick={publishNextQuiz} loading={loading}>
                        Câu hỏi tiếp theo {!!clockTick && `(${clockTick}s)`}
                      </Button>
                    ) : (
                      <Button size="xs" onClick={publishResult}>
                        Hiện kết quả
                      </Button>
                    )}
                  </>
                )
              ) : (
                quizGameItr?.publishStatus === PublishStatus.IDLE && (
                  <Button size="xs" onClick={startQuizGame} loading={loading}>
                    Bắt đầu game
                  </Button>
                )
              )
            ) : (
              currScenario?.publishStatus === PublishStatus.PUBLISHING ? (
                !publishingItr && (
                  <Button size="xs" color="red" onClick={finishScenario} loading={loading}>
                    Kết thúc kịch bản
                  </Button>
                )
              ) : (
                <>
                  {!!currRoomId && isRoomOpened && !currScenario && (
                    <Button size="xs" color="red" onClick={closeRoom} loading={loading}>
                      Đóng room
                    </Button>
                  )}

                  {currScenario?.publishStatus === PublishStatus.IDLE && (
                    <Button size="xs" onClick={startScenario} loading={loading}>
                      Bắt đầu kịch bản
                    </Button>
                  )}
                </>
              )
            )}
          </div>
        </div>
      </div>
    </>
  )
}