import { Select } from "@mantine/core";
import { useCallback, useState } from "react";
import { UserRole } from "../../../enums/UserRole";
import { UserService } from "../../../services/UserService";
import { NotificationUtils } from "../../../utils/NotificationUtils";

interface UpdateRoleFormProps {
  role: UserRole;
  id: number;
  onSuccess?: (id: number, role: UserRole) => any;
}

export function UpdateRoleForm(props: UpdateRoleFormProps) {
  const { id, onSuccess } = props;
  const [role, setRole] = useState(props.role);
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(async (newRole: any) => {
    setLoading(true);
    if (role !== newRole) {
      const success = await UserService.update(id, { role: newRole });
      if (success) {
        NotificationUtils.success({ message: 'Cập nhật vai trò thành công' });
        onSuccess && onSuccess(id, newRole);
        setRole(newRole);
      }
    }
    setLoading(false);
  }, [id, role, onSuccess]);

  return (
    <Select
      value={role}
      onChange={handleChange}
      disabled={loading}
      placeholder="Vai trò..."
      data={[
        { label: 'Vận hành viên', value: UserRole.OPERATOR },
        { label: 'Admin', value: UserRole.ADMIN }
      ]}
    />
  )
}