import { NotificationProps, NotificationsContextProps } from "@mantine/notifications/lib/types";
import { CheckIcon, Cross2Icon, ExclamationTriangleIcon, InfoCircledIcon } from "@radix-ui/react-icons";

export class NotificationUtils {

  private static notifications: NotificationsContextProps;
  static show: (props: NotificationProps) => string;
  static hide: (id: string) => void;
  static update: (id: string, props: NotificationProps) => void;
  static clean: () => void;

  static init(notifications: NotificationsContextProps) {
    this.notifications = notifications;
    this.show = this.notifications.showNotification;
    this.hide = this.notifications.hideNotification;
    this.update = this.notifications.updateNotification;
    this.clean = this.notifications.clean;
  }

  static success(props: NotificationProps) {
    this.show({
      icon: <CheckIcon />,
      color: 'teal',
      autoClose: 5000,
      ...props
    });
  }

  static error(props: NotificationProps) {
    this.show({
      color: 'red',
      icon: <Cross2Icon />,
      ...props
    });
  }

  static warn(props: NotificationProps) {
    this.show({
      color: 'yellow',
      icon: <ExclamationTriangleIcon />,
      ...props
    });
  }

  static info(props: NotificationProps) {
    this.show({
      color: 'blue',
      icon: <InfoCircledIcon />,
      ...props
    });
  }
}