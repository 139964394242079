// import { Avatar } from "@mantine/core";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HiOutlineVolumeOff, HiOutlineVolumeUp } from "react-icons/hi";
import { PollDto } from "../../dtos/poll/PollDto";
import { QuizDto } from "../../dtos/quiz/QuizDto";
import { EventType } from "../../enums/EventType";
import { RealtimeService } from "../../services/RealtimeService";
import { useAppStore } from "../../store/useAppStore";
import { AppInteractivePreview, AppInteractivePreviewProps } from "../AppInteractivePreview";

import { ActionType } from "../../enums/ActionType";
import { QuizMode } from "../../enums/QuizMode";
import { AppRingCountDown } from "../AppRingCountDown";
import { QuizLeaderboardDto } from "../../dtos/quiz-game/QuizLeaderboardDto";
import { AppQuizLeaderboardPreview } from "../AppQuizLeaderboardPreview";
import { EventDto } from "../../dtos/realtime/EventDto";
import { ConnectionStatus } from "../../enums/ConnectionStatus";
import { FiRefreshCcw } from "react-icons/fi";
import { ActionIcon, Avatar } from "@mantine/core";
import Youtube from 'react-youtube';
import { DelayCountDownDto } from "../../dtos/realtime/DelayCountDownDto";
import { TargetTypeDisplay } from "../../enums/TargetType";
import { useSession } from "../../hooks/useSession";
import { S3Utils } from "../../utils/S3Utils";
import { OperateRoomNotExists } from "./OperateRoomNotExists";
import { ShowNotificationDto } from "../../dtos/room/ShowNotificationDto";
import { IoMdNotificationsOutline } from "react-icons/io";
import { AmsService } from "../../services/AmsService";
import { StreamSourceType } from "../../enums/StreamSourceType";

const connectionColors = {
  [ConnectionStatus.IDLE]: 'bg-gray-400',
  [ConnectionStatus.CONNECTED]: 'bg-green-400',
  [ConnectionStatus.CONNECTING]: 'bg-yellow-400',
  [ConnectionStatus.DISCONNECTED]: 'bg-red-400'
};

export function OperateLiveStreamPlayer() {
  const session = useSession();
  const { store, dispatch } = useAppStore();
  const { 
    clockTick, currScenario, currRoomId, currEvent, currQuizGameItrId, 
    itrNodeMap, livestreamProfile, isRoomOpened, currHTVGoLivestream
  } = store;

  const [viewerCount, setViewerCount] = useState(0);
  const [interactivePreview, setInteractivePreview] = useState<Partial<AppInteractivePreviewProps> | undefined>();
  const [selections, setSelections] = useState<number[]>();
  const [corrects, setCorrects] = useState<boolean[]>();
  const [passedQuizCount, setPassedQuizCount] = useState(0);
  const [leaderboard, setLeaderboard] = useState<QuizLeaderboardDto>();
  const [delayDto, setDelayDto] = useState<DelayCountDownDto>();

  const [showNotification, setShowNotification] = useState(true);
  const [notificationDto, setNotificationDto] = useState<ShowNotificationDto>();
  const [mute, setMute] = useState(false);
  const youtubeRef = useRef<any>(null);

  const pollRef = useRef<PollDto>();
  const quizRef = useRef<QuizDto>();

  const quizGameItr = currQuizGameItrId !== undefined ? itrNodeMap[currQuizGameItrId] : null;
  const isConfetti = quizGameItr?.quizGame?.quizMode === QuizMode.CONFETTI;

  useEffect(() => {
    if (currRoomId) {
      return RealtimeService.subscribe(currRoomId, eventDto => {
        switch (eventDto.eventType) {
          case EventType.PUBLISH_QUIZ:
            const quiz = eventDto.payload;
            quizRef.current = quiz;

            const quizItr = itrNodeMap[quiz.interactionId];
            dispatch(ActionType.SET_CLOCK_TICK, quiz.duration);
            setCorrects(quizItr?.mountedQuiz?.quiz?.answers.map(ans => ans.correct) || undefined);
            setLeaderboard(undefined);
            setSelections(undefined);
            setPassedQuizCount(quiz.passedQuizCount);
            setInteractivePreview({
              content: quiz.content,
              options: quiz.answers.map((ans: any) => ans.content),
              selectMode: quiz.selectMode
            });
            break;
          case EventType.PUBLISH_POLL:
            const poll = eventDto.payload;
            pollRef.current = poll;
            setSelections(undefined);
            setLeaderboard(undefined);
            setCorrects(undefined);
            setInteractivePreview({
              title: <b className="pl-2">Khảo sát</b>,
              content: poll.title,
              options: poll.pollOptions.map((opt: any) => opt.content),
              selectMode: poll.selectMode
            });
            break;
          case EventType.END_QUIZ:
            const endQuizDto = eventDto.payload;
            if (endQuizDto.quizMode === QuizMode.CONFETTI) {
              setCorrects(quizRef.current?.answers.map(ans => ans.id === endQuizDto.confetti.correctAnswerId));
            } else if (endQuizDto.quizMode === QuizMode.KAHOOT) {
              setCorrects(quizRef.current?.answers.map(ans => endQuizDto.kahoot.correctAnswerIds.includes(ans.id)));
              setSelections(quizRef.current?.answers.map(ans => endQuizDto.answerSelections[ans.id]));
            } else if (endQuizDto.quizMode === QuizMode.FOOTBALL) {
              setSelections(quizRef.current?.answers.map(ans => endQuizDto.answerSelections[ans.id]));
            }
            break;
          case EventType.END_QUIZ_GAME:
            setLeaderboard(undefined);
            setInteractivePreview(undefined);
            quizRef.current = undefined;
            pollRef.current = undefined;
            break;
          case EventType.END_POLL:
            setInteractivePreview(undefined);
            pollRef.current = undefined;
            break;
          case EventType.QUIZ_ANSWER_SELECTIONS:
            const { answerSelections } = eventDto.payload;
            if (quizRef.current) {
              setSelections(quizRef.current?.answers.map(ans => answerSelections[ans.id] || 0));
            }
            break;
          case EventType.POLL_OPTION_SELECTIONS:
            const { optionSelections } = eventDto.payload;
            if (pollRef.current) {
              setSelections(pollRef.current?.pollOptions.map(opt => optionSelections[opt.id] || 0));
            }
            break;
          case EventType.ROOM_STATE:
            setViewerCount(eventDto.payload.viewerCount)
            break;
          case EventType.CLOCK_TICK:
            dispatch(ActionType.SET_CLOCK_TICK, eventDto.payload.atSecond);
            break;
          case EventType.QUIZ_LEADERBOARD:
            setLeaderboard(eventDto.payload);
            break;
          case EventType.PUBLISH_QUIZ_GAME:
            setPassedQuizCount(eventDto.payload.passedQuizCount);
            break;
          case EventType.DELAY_COUNT_DOWN:
            setDelayDto(eventDto.payload.atSecond > 0 ? eventDto.payload : undefined);
            break;
          case EventType.SHOW_NOTIFICATION:
            setNotificationDto(eventDto.payload);
            break;
          case EventType.HIDE_NOTIFICATION:
            setNotificationDto(undefined);
            break;
        }
      });
    }
  }, [currRoomId, itrNodeMap, dispatch]);

  const paginateLeaderboard = useCallback((page: number) => {
    if (currRoomId && leaderboard) {
      const ws = RealtimeService.getWs(currRoomId);
      if (ws) {
        const eventDto = new EventDto(EventType.PAGINATE_QUIZ_LEADERBOARD, { page, pageSize: leaderboard.pageSize });
        ws.send(eventDto.toString());
      }
    }
  }, [leaderboard, currRoomId]);

  const handleYoutubeReady = useCallback((e: any) => {
    youtubeRef.current = e.target;
    setTimeout(() => {
      youtubeRef.current.playVideo();
    }, 3000);
  }, []);

  const toggleNotification = useCallback(() => {
    setShowNotification(v => !v);
  }, []);

  const youtubeVideoId = useMemo(() => {
    if (currEvent && currEvent.streamSrcType === StreamSourceType.YOUTUBE && currEvent.streamSrc) {
      const url = new URL(currEvent.streamSrc);
      return url.searchParams.get('v');
    }
  }, [currEvent]);

  useEffect(() => {
    if (!!youtubeRef.current) {
      if (mute) {
        youtubeRef.current.mute();
      } else {
        youtubeRef.current.unMute();
      }
    }
  }, [mute]);

  const title = useMemo(() => {
    let pre = '';
    let scenarioName = currScenario?.name || 'Chưa có kịch bản';
    if (currEvent) {
      pre = currEvent.caption;
    } else if (currHTVGoLivestream) {
      pre = currHTVGoLivestream.title;
    }

    if (pre) {
      return <><b>[{pre}]</b> {scenarioName}</>
    }

    return <b>{scenarioName}</b>;
  }, [currEvent, currHTVGoLivestream, currScenario]);

  const roomState = useMemo(() => {
    if (currRoomId && isRoomOpened) {
      return (
        <div className="flex items-center">
          <div className="rounded bg-red-600 text-white px-2 h-6 flex items-center text-sm mr-2">
            LIVE
          </div>
          <div className="rounded-xl bg-gray-600 text-white px-2 h-6 flex items-center opacity-50 text-xs">
            <EyeOpenIcon className="mr-2" /> {viewerCount || 0}
          </div>
        </div>
      );
    }
  }, [viewerCount, currRoomId, isRoomOpened]);

  const amsPlayUrl = useMemo(() => {
    if (currEvent && currEvent.streamSrcType === StreamSourceType.ANT_MEDIA && currEvent.streamSrc) {
      return AmsService.playUrl(currEvent.streamSrc);
    } else if (currHTVGoLivestream) {
      return AmsService.playUrl(currHTVGoLivestream.streamKey);
    }
    if (currRoomId) {
      return AmsService.playUrl(currRoomId);
    }
  }, [currRoomId, currHTVGoLivestream, currEvent]);

  return (
    <div className="flex flex-col h-full">
      <div className="w-full flex justify-center rounded-t-lg relative" style={{ height: 'calc(100% - 3.5rem)' }}>
        {currRoomId && (
          <div className="w-full absolute flex items-center left-0 top-4 px-4" style={{ zIndex: 2 }}>
            {roomState}
            <div className="ml-auto">
              <div className="relative">
                {notificationDto && (
                  <div className="rounded-full w-2 h-2 bg-red-500 absolute" style={{ zIndex: 2, top: 0, left: 0 }}></div>
                )}
                <ActionIcon color="gray" variant="filled" radius="xl" onClick={toggleNotification} className="opacity-50">
                  <IoMdNotificationsOutline />
                </ActionIcon>
              </div>
            </div>
          </div>
        )}

        <div className="h-full flex bg-transparent relative" style={{ minWidth: '20vw' }}>
          {notificationDto && (
            <div className={"absolute top-14 w-full px-4 " + (showNotification ? '' : 'hidden')} style={{ zIndex: 2 }}>
              <div className="rounded bg-white">
                <img src={notificationDto.image} alt="Notification preview" className="w-full max-h-64 rounded-t" />
                <div className="p-3 text-sm">{notificationDto.description}</div>
              </div>
            </div>
          )}

          {(interactivePreview || leaderboard) && (
            <div className="absolute self-end w-full px-4 pb-4" style={{ zIndex: 2 }}>
              {interactivePreview && !leaderboard && (
                <AppInteractivePreview 
                  {...interactivePreview as any}
                  selections={selections}
                  corrects={corrects}
                  title={interactivePreview.title || (
                    quizRef.current && quizGameItr && (
                      <>
                        <AppRingCountDown 
                          size={40}
                          thickness={2}
                          className="mr-1"
                          countdown={clockTick}
                          percent={Math.round((clockTick || 0) / quizRef.current.duration * 100)}
                        />
                        <div className="font-medium">Câu {passedQuizCount} / {quizGameItr.quizGame?.totalQuiz || 0}</div>
                      </>
                    )
                  )}
                />
              )}

              {leaderboard && (
                <AppQuizLeaderboardPreview 
                  title={(
                    <div className="text-center font-medium pl-2 text-sm">
                      {isConfetti ? "Người thắng cuộc" : "Bảng điểm"}
                    </div>
                  )}
                  showScore={!isConfetti}
                  leaderboard={leaderboard}
                  onPageChange={paginateLeaderboard}
                />
              )}
            </div>
          )}

          {(!currRoomId || !isRoomOpened) && (
            <OperateRoomNotExists />
          )}
        </div>
        
        {delayDto && (
          <div className="absolute top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center opacity-75 bg-gray-900 text-white" style={{ zIndex: 2 }}>
            <div className="p-4 rounded-full border border-white w-16 h-16 flex items-center justify-center">
              {delayDto.atSecond}
            </div>
            <div className="text-center mt-4">{TargetTypeDisplay[delayDto.targetType]} tiếp theo sắp bắt đầu...</div>
          </div>
        )}

        {youtubeVideoId && (
          <Youtube 
            videoId={youtubeVideoId}
            className="absolute left-0 right-0 top-0 bottom-0 h-full w-full"
            onReady={handleYoutubeReady}
            opts={{ playerVars: { autoplay: 1, controls: 0, disablekb: 1, iv_load_policy: 3, modestbranding: 1 } }}
          />
        )}

        {!youtubeVideoId && amsPlayUrl && (isRoomOpened || currEvent?.streamSrcType === StreamSourceType.ANT_MEDIA) && (
          <iframe src={amsPlayUrl} className="absolute w-full h-full" title="AMS Player" />
        )}
      </div>
      <div className="w-full bg-gray-100 px-4 h-14 flex items-center">
        <Avatar 
          radius="xl"
          color="blue"
          alt="Operator avatar" 
          src={livestreamProfile?.avatar || S3Utils.toS3Url(session.user.avatar)} 
        />
        <div className="ml-3">
          <h2 className="text-md text-red-600">{title}</h2>
          <p className="text-sm font-medium">{livestreamProfile?.username || `${session.user.firstName} ${session.user.lastName}`}</p>
        </div>
        <div className="ml-auto flex items-center">
          {youtubeVideoId && (
            <div className="mr-5">
              {mute ? (
                <ActionIcon color="red" variant="hover">
                  <HiOutlineVolumeOff className="text-xl" onClick={() => setMute(false)} />
                </ActionIcon>
              ) : (
                <ActionIcon>
                  <HiOutlineVolumeUp className="text-xl" onClick={() => setMute(true)} />
                </ActionIcon>
              )}
            </div>
          )}
          <div className={"h-2 w-2 rounded-full mr-2 " + (connectionColors[store.connectionStatus])}></div>
          <div className="text-sm flex items-center">
            {store.connectionStatus}
            {store.connectionStatus === ConnectionStatus.DISCONNECTED && (
              <ActionIcon color="blue" className="ml-2">
                <FiRefreshCcw
                  className="text-blue-400 cursor-pointer" 
                  onClick={() => window.location.reload()}
                />
              </ActionIcon>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}