import { Paper } from "@mantine/core";
import { useCallback, useEffect, useMemo } from "react"
import { useHistory, useLocation } from "react-router";
import { AppFlashScreen } from "../../components/AppFlashScreen";
import { LoginForm } from "../../components/auth/LoginForm";
import { useSession } from "../../hooks/useSession";
import qs from 'querystring';

export function LoginPage() {
  const session = useSession();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;

  const redirect = useMemo(() => {
    const params = qs.parse(search.replace('?', ''));
    return params.redirect ? String(params.redirect) : undefined;
  }, [search]);
  
  useEffect(() => {
    if (session.ready && session.user) {
      history.push('/app');
    }
  }, [session.ready, session.user, history]);

  const handleLoginSuccess = useCallback(() => {
    if (redirect) {
      window.location.assign(redirect);
    } else {
      window.location.pathname = '/app';
    }
  }, [redirect]);

  if (!session.ready) {
    return <AppFlashScreen />
  }

  return (
    <div className="fixed left-0 right-0 top-0 bottom-0 flex items-center justify-center bg-gray-100">
      <Paper className="w-96 bg-white" shadow="xs" p="lg">
        <h2 className="text-center text-xl text-orange mb-4 font-medium mt-0">Đăng nhập</h2>
        <LoginForm onSuccess={handleLoginSuccess} />
      </Paper>
    </div>
  )
}