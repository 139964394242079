import { Divider, Modal } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { PageDto } from "../../../dtos/common/PageDto";
import { SystemConfigDto } from "../../../dtos/system-config/SystemConfigDto";
import { useCompKey } from "../../../hooks/useCompKey";
import { SystemConfigService } from "../../../services/SystemConfigService";
import { FormatUtils } from "../../../utils/FormatUtils";
import { AppActionButtons } from "../../AppActionButtons";
import { AppTable, AppTableColumn } from "../../AppTable";
import { UpsertSystemConfigForm } from "./UpsertSystemConfigForm";

export function SystemConfigList() {
  const [edit, setEdit] = useState<SystemConfigDto | undefined>();
  
  const tableKey = useCompKey();
  const columns = useMemo(() => {
    return [
      { title: 'Tên cấu hình', render: sc => sc.name },
      { title: 'Giá trị', render: sc => sc.value },
      { title: 'Cập nhật lúc', render: sc => FormatUtils.dt(sc.updateDt) },
      { title: 'Hành động', render: sc => <AppActionButtons onEdit={() => setEdit(sc)} /> }
    ] as AppTableColumn<SystemConfigDto>[];
  }, []);

  const handleEditSuccess = useCallback(() => {
    setEdit(undefined);
    tableKey.renew();
  }, [tableKey]);

  const fetchData = useCallback(async () => {
    const systemConfigs = await SystemConfigService.findAll();
    return PageDto.onePage(systemConfigs);
  }, []);

  return (
    <>
      <Modal opened={!!edit} title="Cập nhật cấu hình" onClose={() => setEdit(undefined)}>
        <Divider my="lg" />
        <UpsertSystemConfigForm systemConfig={edit} onSuccess={handleEditSuccess} />
      </Modal>

      <AppTable 
        key={tableKey.key}
        hideSearch
        hidePagination
        fetchData={fetchData}
        columns={columns}
      />
    </>
  )
}