import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { ProjectList } from "../../../components/project/ProjectList";

const breadcrumbs = [
  { label: 'Tương tác', link: '/app/interactive' },
  { label: 'Dự án', link: '/app/interactive/project/list' }
];

export function ProjectListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <ProjectList zIndex={3} creatable />
    </>
  )
}