import { Menu, MenuItem, Popover } from "@mantine/core";
import { useState } from "react";
import { AppFlexList, FlexListQueryDto } from "./AppFlexList";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { RiEditBoxLine, RiDeleteBin6Line } from "react-icons/ri";
import { EyeOpenIcon } from "@radix-ui/react-icons";
import { PageDto } from "../dtos/common/PageDto";

export interface AppFlexFoldersProps<T> {
  fetchData: (queryDto: FlexListQueryDto) => Promise<PageDto<T>>;
  onSelect?: (item: T) => any;
  onDelete?: (item: T) => any;
  onUpdate?: (item: T) => any;
  folderLabelRenderer: (item: T) => any;
  folderPreviewRenderer?: (item: T) => any;
  folderClassName?: string | ((item: T) => string);
  folderWrapperClassName?: string | ((item: T) => string);
  folderMenu?: boolean | ((items: T) => boolean);
  folderUpdatable?: boolean;
  folderDeletable?: boolean;
  folderCustomMenuItems?: any;
  elementRef?: any;
  actions?: any;
  zIndex: number;
}

export interface AppFolderProps<T> {
  onSelect?: (item: T) => any;
  onDelete?: (item: T) => any;
  onUpdate?: (item: T) => any;
  previewRenderer?: (item: T) => any;
  item: T;
  zIndex: number;
  className?: string;
  menu?: boolean;
  updatable?: boolean;
  deletable?: boolean;
  customMenuItems?: any;
  children: any;
}

export function AppFolder<T = any>(props: AppFolderProps<T>) {
  const [preview, setPreview] = useState(false);

  return (
    <div
      onClick={() => props.onSelect && props.onSelect(props.item)}
      className={
        "flex items-center cursor-pointer p-3 border rounded-lg bg-white hover:border-blue-400 w-full " +
        props.className
      }
    >
      <div className="overflow-hidden h-5 whitespace-nowrap w-full mr-4">
        {props.children}
      </div>
      <div className="ml-auto flex items-center">
        {props.previewRenderer && (
          <Popover
            withArrow
            placement="center"
            position="bottom"
            trapFocus={false}
            opened={preview}
            zIndex={props.zIndex}
            target={
              <EyeOpenIcon
                onMouseEnter={() => setPreview(true)}
                onMouseLeave={() => setPreview(false)}
              />
            }
          >
            {props.previewRenderer(props.item)}
          </Popover>
        )}

        {props.menu && (
          <Menu 
            trigger="hover"
            placement="center"
            className="ml-2"
            zIndex={props.zIndex}
            delay={200}
            control={<span><HiOutlineDotsVertical /></span>}
            controlRefProp="ref"
          >
            {props.customMenuItems}
            {props.updatable && (
              <MenuItem
                icon={<RiEditBoxLine />}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  props.onUpdate && props.onUpdate(props.item);
                }}
              >
                Chi tiết
              </MenuItem>
            )}
            {props.deletable && (
              <MenuItem
                color="red"
                icon={<RiDeleteBin6Line />}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  props.onDelete && props.onDelete(props.item)
                }}
              >
                Xoá
              </MenuItem>
            )}
          </Menu>
        )}
      </div>
    </div>
  );
}

export function AppFlexFolders<T = any>(props: AppFlexFoldersProps<T>) {
  return (
    <AppFlexList<T>
      fetchData={props.fetchData} 
      actions={props.actions}
    >
      {(data) => (
        <div className="flex flex-wrap">
          {data.content.map((item, idx) => (
            <div 
              key={idx}
              className={typeof props.folderWrapperClassName === 'function' ? 
                props.folderWrapperClassName(item) : 
                props.folderWrapperClassName}
            >
              <AppFolder<T>
                item={item}
                zIndex={props.zIndex}
                previewRenderer={props.folderPreviewRenderer}
                className={typeof props.folderClassName === 'function' ? 
                  props.folderClassName(item) : 
                  props.folderClassName}
                onDelete={props.onDelete}
                onUpdate={props.onUpdate}
                onSelect={props.onSelect}
                menu={typeof props.folderMenu === 'function' ? props.folderMenu(item) : 
                  (typeof props.folderMenu === 'boolean' ? props.folderMenu : true)}
                updatable={typeof props.folderUpdatable === 'boolean' ? props.folderUpdatable : true}
                deletable={typeof props.folderDeletable === 'boolean' ? props.folderDeletable : true}
                customMenuItems={props.folderCustomMenuItems}
              >
                {props.folderLabelRenderer(item)}
              </AppFolder>
            </div>
          ))}
        </div>
      )}
    </AppFlexList>
  );
}
