import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { AppList } from "../../../components/system/app/AppList";

const breadcrumbs = [
  { label: 'Hệ thống', link: '/app/system' },
  { label: 'App tích hợp', link: '/app/system/app/list' }
]

export function SystemAppListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} />
      <AppList />
    </>
  )
}