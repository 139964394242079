import { Button, NumberInput, Switch } from "@mantine/core";
import { useEffect, useState } from "react";
import { AppConfig } from "../../../config/AppConfig";
import { ActionType } from "../../../enums/ActionType";
import { OperateService } from "../../../services/OperateService";
import { useAppStore } from "../../../store/useAppStore";
import { OperateRoomNotExists } from "../OperateRoomNotExists";
import { OperateLivestreamProfileForm } from "./OperateLivestreamProfileForm";
import { AppCopiableTextInput } from "../../AppCopiableTextInput";

interface ResetSaveButtonProps {
  disabled?: boolean;
  onReset: () => any;
  onSave: () => any;
}

function ResetSaveButton(props: ResetSaveButtonProps) {
  return (
    <>
      <Button 
        disabled={props.disabled}
        variant="subtle"
        className="mx-2"
        color="red" 
        onClick={props.onReset}
      >
        Reset
      </Button>
      <Button
        disabled={props.disabled}
        variant="subtle"
        onClick={props.onSave}
      >
        Lưu lại
      </Button>
    </>
  )
}

export function OperateInteractiveSettings() {
  const { store, dispatch } = useAppStore();
  const { currRoomId, isRoomOpened, livestreamProfile } = store;

  const [autoPublishGap, setAutoPublishGap] = useState(store.autoPublishGap);
  const [leaderboardSize, setLeaderboardSize] = useState(() => {
    return currRoomId ? OperateService.getLeaderboardSize(currRoomId) : AppConfig.paging.LEADERBOARD_PAGE_SIZE;
  });
  const [oriAutoPublishGap, setOriPublishGap] = useState(autoPublishGap);
  const [oriLeaderboardSize, setOriLeaderboardSize] = useState(leaderboardSize);

  useEffect(() => {
    if (currRoomId) {
      setLeaderboardSize(OperateService.getLeaderboardSize(currRoomId));
    } else {
      setLeaderboardSize(AppConfig.paging.LEADERBOARD_PAGE_SIZE);
    }
  }, [currRoomId]);

  if (!currRoomId || !isRoomOpened) {
    return (
      <OperateRoomNotExists />
    )
  }

  return (
    <div className="p-4">
      <h3 className="text-sm font-bold">Room ID</h3>
      <AppCopiableTextInput defaultValue={currRoomId} className="mt-3 w-full" />

      <h3 className="mt-8 text-sm font-bold">Thông tin kết nối</h3>
      {livestreamProfile && (
        <div className="mt-3">
          <OperateLivestreamProfileForm initialValues={livestreamProfile}  />
        </div>
      )}

      <h3 className="mt-8 text-sm font-bold">Tự động chuyển câu hỏi</h3>
      <Switch 
        label="Bật tự động chuyển câu hỏi"
        className="my-3"
        checked={store.autoPublish}
        onChange={() => dispatch(ActionType.SET_AUTO_PUBLISH, !store.autoPublish)}
      />
      {false && <Switch 
        label="Tự động publish bảng điểm và kết thúc game sau khi đã publish tất cả câu hỏi"
        className="my-3"
        checked={store.autoEndQuizGame}
        onChange={() => dispatch(ActionType.SET_AUTO_END_QUIZ_GAME, !store.autoEndQuizGame)}
      />}
      <div className="flex items-center">
        <div className="mr-4 text-sm">Thời gian chờ giữa 2 câu hỏi</div>
        <NumberInput 
          disabled={!!store.autoPublishCountDown}
          min={AppConfig.realtime.MIN_AUTO_PUBLISH_GAP}
          max={AppConfig.realtime.MAX_AUTO_PUBLISH_GAP}
          className="w-24"
          value={autoPublishGap}
          onChange={v => setAutoPublishGap(v || 0)}
        />
        {!store.autoPublishCountDown && 
          oriAutoPublishGap !== autoPublishGap && 
          autoPublishGap >= AppConfig.realtime.MIN_AUTO_PUBLISH_GAP && (
          <ResetSaveButton 
            onReset={() => setAutoPublishGap(oriAutoPublishGap)}
            onSave={() => {
              setOriPublishGap(autoPublishGap);
              dispatch(ActionType.SET_AUTO_PUBLISH_GAP, autoPublishGap);
            }}
          />
        )}
      </div>

      <h3 className="mt-8 text-sm font-bold">Bảng điểm</h3>
      <div className="flex items-center mt-3">
        <div className="mr-4 text-sm">Sống dòng của bảng điểm</div>
        <NumberInput 
          min={1}
          max={100}
          value={leaderboardSize}
          onChange={v => setLeaderboardSize(v || 0)}
          className="w-24"
        />
        {oriLeaderboardSize !== leaderboardSize && leaderboardSize >= 1 && (
          <ResetSaveButton 
            onReset={() => setLeaderboardSize(oriLeaderboardSize)}
            onSave={() => {
              setOriLeaderboardSize(leaderboardSize);
              OperateService.setLeaderboardSize(currRoomId, leaderboardSize);
            }}
          />
        )}
      </div>
    </div>
  )
}