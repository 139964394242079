import { Badge } from '@mantine/core';

export enum EventCalendarStatus {
  IDLE = "IDLE",
  LIVE = "LIVE",
  END = "END"
};

export const EventCalendarStatusDisplay = {
  [EventCalendarStatus.IDLE]: <Badge color="dark">Chưa bắt đầu</Badge>,
  [EventCalendarStatus.LIVE]: <Badge color="red">Live</Badge>,
  [EventCalendarStatus.END]: <Badge color="green">Hoàn thành</Badge>
}