import { Redirect, Route, Switch } from "react-router";
import { ChangeLogListPage } from "./ChangeLogListPage";

export function ChangeLogPage() {
  return (
    <Switch>
      <Redirect exact from="/app/system/changelog" to="/app/system/changelog/list" />
      <Route path="/app/system/changelog/list" component={ChangeLogListPage} />
    </Switch>
  )
}