import { LoadingOverlay } from "@mantine/core";
import { useCallback, useMemo } from "react"
import { DropResult } from "react-beautiful-dnd";
import { BsQuestionSquare } from "react-icons/bs";
import { CgPoll } from "react-icons/cg";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { InteractionType } from "../../enums/InteractionType";
import { QuizGameEditorTab } from "../../enums/QuizGameEditorTab";
import { IQuizGameEditor } from "../../hooks/useQuizGameEditor";
import { RandomUtils } from "../../utils/RandomUtils";
import { AppSideListItem } from "../AppSideListItem";
import { AppVerticalDnD } from "../AppVerticalDnD";
import { QuizGameSideListItem } from "../quiz-game/QuizGameSideListItem";

interface ScenarioSideListProps {
  zIndex: number;
  interactions: InteractionDto[];
  selected?: InteractionDto | null;
  quizGameEditor: IQuizGameEditor;
  onDelete: (interaction: InteractionDto) => any;
  onSelect: (interaction: InteractionDto) => any;
  onReorder: (dropResult: DropResult) => any;
}

export function ScenarioSideList(props: ScenarioSideListProps) {
  const droppableId = useMemo(() => {
    return RandomUtils.uuid();
  }, []);

  const resolveName = useCallback((interaction: InteractionDto, idx: number, minimal?: boolean) => {
    switch (interaction.interactionType) {
      case InteractionType.QUIZ_GAME:
        return (
          <>
            <BsQuestionSquare className="mr-1 mb-1 inline" />
            {idx + 1}. {interaction.quizGame?.name || 'Bộ câu hỏi'}
            {minimal ? '' : ` (${interaction.quizGame?.totalQuiz || 0} câu)`}
          </>
        );
      case InteractionType.POLL:
        return (
          <>
            <CgPoll className="mr-1 mb-1 inline" />
            {idx + 1}. {interaction.poll?.title || 'Khảo sát'}
          </>
        );
    }
  }, []);

  return (
    <AppVerticalDnD<InteractionDto>
      droppableId={droppableId}
      onDragEnd={props.onReorder}
      items={props.interactions}
      itemId={interaction => String(interaction.id)}
      itemRenderer={(interaction, idx) => {
        const isQuizGame = interaction.interactionType === InteractionType.QUIZ_GAME;
        if (
          isQuizGame && 
          props.selected &&
          props.selected.id === interaction.id
        ) {
          const { quizGameEditor: editor } = props;
          return (
            <>
              <LoadingOverlay visible={(!!editor.fetchMQ && editor.fethMQResponse.loading)} />
              <QuizGameSideListItem
                title={resolveName(interaction, idx, true)}
                zIndex={props.zIndex}
                createButton={true}
                collapsable={true}
                menu={true}
                deletable={true}
                onDelete={() => props.onDelete(interaction)}
                mountedQuizzes={editor.mountedQuizzes}
                onDeleteMQ={editor.handleDeleteMQ}
                onSelectMQ={editor.handleSelectMQ}
                onReorderMQ={editor.handleReorderMQ}
                onCreate={() => editor.setTab(QuizGameEditorTab.MOUNT_QUIZ)}
                onSelectQuizGame={() => editor.setTab(QuizGameEditorTab.UPDATE_QUIZ_GAME)}
              />
            </>
          )
        }
        
        return (
          <AppSideListItem
            zIndex={props.zIndex}
            onClick={() => props.onSelect(interaction)}
            onCollapse={() => props.onSelect(interaction)}
            onDelete={() => props.onDelete(interaction)}
            menu={true}
            deletable={true}
            collapse={true}
            collapsable={isQuizGame}
          >
            {resolveName(interaction, idx)}
          </AppSideListItem>
        );
      }}
    />
  )
}