import { VscLinkExternal } from "react-icons/vsc";

interface AppPreviewWrapperProps {
  title?: any;
  children: any;
}

export function AppPreviewWrapper(props: AppPreviewWrapperProps) {
  return (
    <div className="rounded-xl px-2 pb-2 border bg-white">
      <div className="h-14 flex items-center border-b text-sm">
        {props.title}
        <div className="ml-auto pr-2">
          <VscLinkExternal className="transform rotate-180" />
        </div>
      </div>
      <div className="overflow-auto" style={{ maxHeight: '40vh' }}>
        {props.children}
      </div>
    </div>
  )
}