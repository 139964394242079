import { Button, TextInput } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useState } from "react";
import { LivestreamProfileDto } from "../../../dtos/operate/LivestreamProfileDto";
import { UploadFileRequestDto } from "../../../dtos/upload-file/UploadFileRequestDto";
import { ActionType } from "../../../enums/ActionType";
import { UploadFileFolder } from "../../../enums/UploadFileFolder";
import { useAppFormik } from "../../../hooks/useAppFormik";
import { useSession } from "../../../hooks/useSession";
import { RealtimeService } from "../../../services/RealtimeService";
import { UploadFileService } from "../../../services/UploadFileService";
import { useAppStore } from "../../../store/useAppStore";
import { FileUtils } from "../../../utils/FileUtils";
import { UploadAvatar } from "../../system/user/UploadAvatar";

interface IOperateLivestreamProfileFormProps {
  initialValues: LivestreamProfileDto;
}

export function OperateLivestreamProfileForm(props: IOperateLivestreamProfileFormProps) {
  const { initialValues } = props;
  const session = useSession();
  const { store, dispatch } = useAppStore();
  const { currRoomId } = store;
  const [file, setFile] = useState<any>();
  const [oriUsername, setOriUsername] = useState(initialValues.username);

  const submit = useCallback(async (dto: LivestreamProfileDto, actions: FormikHelpers<LivestreamProfileDto>) => {
    if (session.user && currRoomId) {
      if (file) {
        const ext = FileUtils.ext(file.name);
        const ufRequest = new UploadFileRequestDto();
        ufRequest.file = file;
        ufRequest.folder = UploadFileFolder.LIVESTREAM_AVATARS;
        ufRequest.filename = `${currRoomId}_${session.user.id}.${ext}`;
        const success = await UploadFileService.upload(ufRequest);

        if (success) {
          dto.avatar = UploadFileService.makeSrc(ufRequest);
          actions.setFieldValue('avatar', dto.avatar);
          setFile(null);
        }
      }

      RealtimeService.close(currRoomId);
      dispatch(ActionType.SET_LIVESTREAM_PROFILE, dto);
      setOriUsername(dto.username);
    }
    actions.setSubmitting(false);
  }, [session, file, dispatch, currRoomId]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  const upload = useCallback((file: any) => {
    setFile(file);
    return true;
  }, []);

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <div className="mb-3">
        <UploadAvatar autoUpload avatarSrc={formik.values.avatar} upload={upload} />
      </div>
      <TextInput
        required
        name="username"
        label="Tên hiển thị"
        placeholder="Nhập tên hiển thị..."
        className="w-96 mb-4"
        value={formik.values.username}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.username && formik.errors.username}
      />
      {(!!file || formik.values.username !== oriUsername) && (
        <Button type="submit" loading={formik.isSubmitting}>Áp dụng thay đổi</Button>
      )}
    </form>
  )
}