import { Avatar, Tab, TextInput } from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { AiFillPushpin, AiOutlineSend } from "react-icons/ai";
import { AppConfig } from "../../../config/AppConfig";
import { RealtimeCommentDto } from "../../../dtos/comment/RealtimeCommentDto";
import { EventType } from "../../../enums/EventType";
import { CommentService } from "../../../services/CommentService";
import { RealtimeService } from "../../../services/RealtimeService";
import { useAppStore } from "../../../store/useAppStore"
import { AppRoundedTabs } from "../../AppRoundedTabs";
import { OperateRoomNotExists } from "../OperateRoomNotExists";
import { CommentItem } from "./CommentItem";

const approx = require('approximate-number');

export function OperateComment() {
  const { store } = useAppStore();
  const { livestreamProfile } = store;

  const [tab, setTab] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalBlock, setTotalBlock] = useState(0);
  const [comments, setComments] = useState<RealtimeCommentDto[]>([]);
  const [pinComment, setPinComment] = useState<RealtimeCommentDto>();
  
  const inputRef = useRef<any>();
  const listRef = useRef<any>();
  const previousTabRef = useRef(0);
  
  const { currRoomId, isRoomOpened } = store;
  const isBlockTab = tab === 1;

  const scrollListToEnd = useCallback(() => {
    setTimeout(() => {
      if (listRef.current) {
        listRef.current.scrollTop = listRef.current.scrollHeight;
      }
    }, 0);
  }, []);

  useEffect(() => {
    if (comments.length > 0) {
      scrollListToEnd();
    }
  }, [comments, scrollListToEnd]);

  useEffect(() => {
    if (currRoomId) {
      return RealtimeService.subscribe(currRoomId, eventDto => {
        switch (eventDto.eventType) {
          case EventType.LAST_COMMENTS:
            setTotal(eventDto.payload.total);
            setComments(eventDto.payload.comments);
            break;
          case EventType.LAST_BLOCK_COMMENTS:
            setTotalBlock(eventDto.payload.total);
            setComments(eventDto.payload.comments);
            break;
          case EventType.COMMENT:
            setTotal(v => v + 1);
            if (!isBlockTab) {
              setComments(comments => {
                if (comments.length >= AppConfig.comment.BUFFER_SIZE) {
                  comments = comments.slice(comments.length - AppConfig.comment.BUFFER_SIZE);
                }
                return [...comments, eventDto.payload];
              });
            }
            break;
          case EventType.BLOCK_COMMENT:
            setTotal(v => v - 1);
            setTotalBlock(v => v + 1);
            if (isBlockTab) {
              CommentService.getLastBlockComments(currRoomId, AppConfig.comment.BUFFER_SIZE);
            } else {
              setComments(comments => comments.filter(cmt => cmt.commentId !== eventDto.payload.commentId));
            }
            break;
          case EventType.PIN_COMMENT:
            setPinComment(eventDto.payload);
            break;
          case EventType.UNPIN_COMMENT:
            setPinComment(undefined);
            break;
        }
      });
    }
  }, [currRoomId, scrollListToEnd, isBlockTab]);

  useEffect(() => {
    if (currRoomId) {
      if (tab !== previousTabRef.current) {
        if (tab === 1) {
          CommentService.getLastBlockComments(currRoomId, AppConfig.comment.BUFFER_SIZE);
        } else {
          CommentService.getLastComments(currRoomId, AppConfig.comment.BUFFER_SIZE);
        }
        previousTabRef.current = tab;
      }
    }
  }, [tab, currRoomId]);

  const sendComment = useCallback(() => {
    if (currRoomId) {
      const content = inputRef.current?.value;
      if (!!content) {
        CommentService.sendComment(currRoomId, content);
        inputRef.current.value = '';
      }
    }
  }, [currRoomId]);

  if (!currRoomId || !isRoomOpened) {
    return <OperateRoomNotExists />
  }

  return (
    <div className="px-4 h-full">
      <AppRoundedTabs grow active={tab} onTabChange={setTab}>
        <Tab label={`Bình luận (${approx(total)})`}></Tab>
        <Tab label={`Bình luận bị ẩn (${approx(totalBlock)})`}></Tab>
      </AppRoundedTabs>

      <div
        ref={listRef}
        className="overflow-auto my-4" 
        style={{ height: `calc(100% - ${pinComment ? '250' : '150'}px)` }}
      >
        {comments.map(comment => (
          <div key={comment.commentId} className="mb-1">
            <CommentItem comment={comment} isBlock={isBlockTab} isPin={false} />
          </div>
        ))}
      </div>

      {pinComment && (
        <div className="px-2 py-1 border rounded-lg border-blue-600 mb-4 bg-blue-50">
          <p className="text-sm flex items-center text-blue-600 pl-2">
            <AiFillPushpin className="mr-1" /> Bình luận đã ghim
          </p>
          <CommentItem comment={pinComment} isPin={true} />
        </div>
      )}

      <div className="flex items-end">
        <Avatar className="cursor-pointer mr-2" alt="Avatar" color="blue" radius="xl" src={livestreamProfile?.avatar} />
        <div className="w-full">
          <p className="text-sm mb-1 font-medium text-red-600">{livestreamProfile?.username}</p>
          <TextInput
            radius="xl"
            className="w-full"
            placeholder="Nhập bình luận..."
            rightSection={<AiOutlineSend className="cursor-pointer" onClick={sendComment} />}
            ref={inputRef}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                sendComment();
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}