export enum QuizMode {
  CONFETTI = 'CONFETTI',
  KAHOOT = 'KAHOOT',
  FOOTBALL = 'FOOTBALL'
}

export const QuizModeEn = {
  [QuizMode.CONFETTI]: "Confetti",
  [QuizMode.KAHOOT]: "Kahoot",
  [QuizMode.FOOTBALL]: "Football"
}