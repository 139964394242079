import React from "react";
import { Redirect, Route, RouteProps } from "react-router";
import { useSession } from "../hooks/useSession";
import { AppNotAllowed } from "./AppNotAllowed";

interface Props extends RouteProps {
  component: any;
  roles?: string[];
}

export const AppAuthedRoute: React.FC<Props> = ({
  component: Component,
  roles,
  ...rest
}) => {
  const session = useSession();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!session.user) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                search: '?redirect=' + props.location.pathname
              }}
            />
          );
        }

        const isValidRole = !roles || roles.includes(session.user.role as any);
        if (!isValidRole) {
          return (
            <AppNotAllowed />
          )
        }

        return <Component {...props} />;
      }}
    />
  );
};
