export interface IResponseCode {
  code: number;
  message: string;
}

export const ResponseCode = {
  OK: { code: 200, message: "Ok"},
  BAD_REQUEST: { code: 400, message: "Bad request"},
  NOT_FOUND: { code: 404, message: "Not found"},
  UNAUTHORIZED: { code: 401, message: "Unauthorized"},
  INTERNAL_ERROR: { code: 500, message: "Internal error"},
  JSON_RELATED_ERROR: { code: 501, message: "Json related error"},
  // Auth
  REFRESH_TOKEN_NOT_FOUND: { code: 1000, message: "Refresh token not found"},
  REFRESH_TOKEN_EXPIRED: { code: 1001, message: "Refresh token is expired"},
  // User
  USER_NOT_FOUND: { code: 2000, message: "User not found"},
  USER_DISABLED: { code: 2001, message: "User is disabled"},
  USERNAME_EXISTED: { code: 2002, message: "Username is existed"},
  // Poll
  POLL_NOT_FOUND: { code: 3000, message: "Poll not found"},
  POLL_DISABLED: { code: 3001, message: "Poll disabled"},
  POLL_PUBLISHED: { code: 3002, message: "Poll is already published"},
  POLL_PUBLISH_CONFLICTED: { code: 3003, message: "Try to publish new poll while another is processing"},
  // Quiz
  QUIZ_NOT_FOUND: { code: 4000, message: "Quiz not found"},
  QUIZ_DISABLED: { code: 4001, message: "Quiz is disabled"},
  QUIZ_PUBLISHED: { code: 4002, message: "Quiz is already published"},
  QUIZ_PUBLISH_CONFLICTED: { code: 4003, message: "Try to publish new quiz while another quiz is processing"},
  QUIZ_HAVE_NO_CORRECT_ANSWER: { code: 4004, message: "Quiz have no correct answer"},
  QUIZ_INVALID_CONFETTI_FORMAT: { code: 4005, message: "Confetti quiz must have only one question"},
  QUIZ_INVALID_MODE: { code: 4006, message: "Invalid quiz's mode"},
  // Project
  PROJECT_NOT_FOUND: { code: 5000, message: "Project not found"},
  PROJECT_DISABLED: { code: 5001, message: "Project is disabled"},
  // Scenario
  SCENARIO_NOT_FOUND: { code: 6000, message: "Scenario not found"},
  SCENARIO_DISABLED: { code: 6001, message: "Scenario is disabled"},
  SCENARIO_NOT_PUBLISHING: { code: 6002, message: "Scenario is not publishing"},
  SCENARIO_IS_PUBLISHING: { code: 6007, message: "Can't update scenario caused it's publishing"},
  SCENARIO_PUBLISH_CONFLICTED: { code: 6006, message: "Can't publish scenario caused it's conflicted with another"},
  // Quiz Game
  QUIZ_GAME_NOT_FOUND: { code: 7000, message: "Quiz game not found"},
  QUIZ_GAME_DISABLED: { code: 7001, message: "Quiz game is disabled"},
  QUIZ_GAME_IS_EMPTY: { code: 7003, message: "Quiz game is empty"},
  // Interaction
  INTERACTION_NOT_FOUND: { code: 8000, message: "Interaction not found"},
  INTERACTION_DISABLED: { code: 8001, message: "Interaction disabled"},
  INTERACTION_IS_PUBLISHING: { code: 8002, message: "Can't update interaction while it's publishing"},
  INTERACTION_NOT_PUBLISHING: { code: 8003, message: "Interaction is not publishing"},
  INTERACTION_PUBLISH_CONFLICTED: { code: 8004, message: "Can't publish interaction caused it's conflicted with another"},
  INTERACTION_INVALID_TYPE: { code: 8005, message: "Invalid interaction's type"},
  // Mounted Quiz
  MOUNTED_QUIZ_NOT_FOUND: { code: 9000, message: "Mounted quiz not found"},
  MOUNTED_QUIZ_DISABLED: { code: 9001, message: "Mounted quiz is disabled"},

  of(code: number) {
    return Object.values(this).find((v: any) => v.code === code) as (IResponseCode | null);
  }
}