import dayjs from "dayjs";
import { SelectModeVi } from "../../enums/SelectMode";
import { PollDto } from '../../dtos/poll/PollDto';

interface PollPreviewProps {
  poll: PollDto;
}

export function PollPreview(props: PollPreviewProps) {
  return (
    <div className="flex flex-wrap max-w-2xl">
      <div className="w-full text-sm font-bold">{props.poll.title}</div>
      <div className="w-full text-gray-500 text-sm mt-2 mb-4">
        Kiểu khảo sát: {SelectModeVi[props.poll.selectMode]}
      </div>
      {props.poll.pollOptions.map(ans => (
        <div className="w-1/2 pr-2 mb-2">
          <div className={"border rounded p-3 text-sm flex items-center"}>
            {ans.content}
          </div>
        </div>
      ))}
      <div className="w-full flex mt-4 text-xs">
        {/* <Divider my="sm" /> */}
        <div className="mr-4"><b>Cập nhật lúc:</b> {dayjs(props.poll.updateDt).format('DD/MM/YYYY HH:mm')}</div>
        <div><b>Tạo lúc:</b> {dayjs(props.poll.createDt).format('DD/MM/YYYY HH:mm')}</div>
      </div>
    </div>
  )
}