export enum LogActorType {
  OPERATOR = 'OPERATOR',
  PLAYER = 'PLAYER',
  SYSTEM = 'SYSTEM'
}

export const LogActorTypeDisplay = {
  [LogActorType.OPERATOR]: 'Vận hành viên',
  [LogActorType.PLAYER]: 'Người chơi',
  [LogActorType.SYSTEM]: 'Hệ thống'
}