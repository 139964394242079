export enum TargetType {
  QUIZ = "QUIZ",
  POLL = "POLL",
  QUIZ_GAME = "QUIZ_GAME"
}

export const TargetTypeDisplay = {
  [TargetType.QUIZ]: "Câu hỏi",
  [TargetType.POLL]: "Khảo sát",
  [TargetType.QUIZ_GAME]: "Quiz game"
}