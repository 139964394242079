import { Center, RingProgress, RingProgressProps, Text } from "@mantine/core";

interface AppRingCountDownProps extends Partial<RingProgressProps> {
  countdown?: number;
  percent?: number;
  textSize?: any;
}

export function AppRingCountDown(props: AppRingCountDownProps) {
  const { countdown, percent, textSize, ...restProps } = props;
  return (
    <RingProgress 
      {...restProps}
      style={{ transform: 'scaleX(-1)' }}
      sections={[{ 
        value: percent || 0, 
        color: 'blue'
      }]}
      label={(
        <Center>
          <Text style={{ transform: 'scaleX(-1)' }} size={textSize || "sm"} color="blue">{countdown || 0}</Text>
        </Center>
      )}
    />
  )
}