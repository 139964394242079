export enum LogTarget {
  PROJECT = 'PROJECT',
  SCENARIO = 'SCENARIO',
  POLL = 'POLL',
  QUIZ = 'QUIZ',
  QUIZ_GAME = 'QUIZ_GAME',
  MOUNTED_QUIZ = 'MOUNTED_QUIZ',
  INTERACTION = 'INTERACTION',
  USER = 'USER',
  APP = 'APP',
  SYSTEM_CONFIG = 'SYSTEM_CONFIG',
  ROOM = 'ROOM',
  CLIENT = 'CLIENT',
  FOOTBALL_GAME_LEADERBOARD = 'FOOTBALL_GAME_LEADERBOARD',
  EVENT_CALENDAR = 'EVENT_CALENDAR'
}

export const LogTargetDisplay = {
  [LogTarget.PROJECT]: 'Dự án',
  [LogTarget.SCENARIO]: 'Kịch bản',
  [LogTarget.POLL]: 'Khảo sát',
  [LogTarget.QUIZ]: 'Câu hỏi',
  [LogTarget.QUIZ_GAME]: 'Quiz game',
  [LogTarget.MOUNTED_QUIZ]: 'Mounted quiz',
  [LogTarget.INTERACTION]: 'Tương tác',
  [LogTarget.USER]: 'Tài khoản',
  [LogTarget.APP]: 'App tích hợp',
  [LogTarget.SYSTEM_CONFIG]: 'Cấu hình hệ thống',
  [LogTarget.ROOM]: 'Room',
  [LogTarget.CLIENT]: 'Client',
  [LogTarget.FOOTBALL_GAME_LEADERBOARD]: 'BXH Football Game',
  [LogTarget.EVENT_CALENDAR]: 'Lịch sự kiện'
}