import { Avatar, Button } from "@mantine/core";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { FileUtils } from "../../../utils/FileUtils";

interface IUploadAvatarProps {
  avatarSrc?: string | null;
  autoUpload?: boolean;
  upload: (file: any) => any;
  onFileChange?: (file: any) => any;
  onCancel?: () => any;
}

export function UploadAvatar(props: IUploadAvatarProps) {
  const { avatarSrc, upload, onFileChange, onCancel, autoUpload } = props;
  const [avatar, setAvatar] = useState<any>(avatarSrc);

  useEffect(() => {
    if (avatarSrc) {
      setAvatar(avatarSrc);
    }
  }, [avatarSrc]);

  const [edit, setEdit] = useState(false);
  const [saving, setSaving] = useState(false);
  const fileInputRef = useRef<any>(null);

  const save = useCallback(async () => {
    const el = fileInputRef.current;
    if (el.files) {
      setSaving(true);
      const success = await upload(el.files[0]);
      setSaving(false);

      if (success) {
        setEdit(false);
      }
    }
  }, [upload]);

  const handleFileChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    const el = e.target;
    if (el.files) {
      setAvatar(await FileUtils.makeUrl(el.files[0]));
      setEdit(true);
      onFileChange && onFileChange(el.files[0]);
      if (autoUpload) {
        await save();
      }
    }
  }, [onFileChange, save, autoUpload]);

  const cancel = useCallback(() => {
    setAvatar(avatarSrc);
    setEdit(false);
    onCancel && onCancel();
  }, [avatarSrc, onCancel]);

  return (
    <div className="flex items-center">
      <Avatar color="blue" size="lg" radius="xl" src={avatar} alt="Avatar" />
      {edit ? (
        <div className="flex items-center">
          <Button variant="outline" color="gray" className="mx-2" onClick={cancel} disabled={saving}>Huỷ bỏ</Button>
          <Button type="submit" onClick={save} loading={saving}>Lưu lại</Button>
        </div>
      ) : (
        <label htmlFor="avatar" className="ml-4 text-blue-400 rounded border border-blue-400 p-2 cursor-pointer text-sm text-center">
          Cập nhật Avatar
        </label>
      )}
      <input id="avatar" type="file" name="avatar" accept="image/*" className="hidden" onChange={handleFileChange} ref={fileInputRef} />
    </div>
  )
}