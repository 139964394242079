import { Image } from "@mantine/core";
import { Link } from "react-router-dom";
import blankCanvas from '../images/undraw_blank_canvas.svg';

export function NotFoundPage() {
  return (
    <div className="flex flex-col items-center justify-center mt-24">
      <div className="w-64">
        <Image src={blankCanvas} alt="Page not found" />
      </div>
      <p className="mt-8 text-xl text-red-400 font-bold">404 - Trang không tồn tại...</p>
      <p className="mt-2">
        <Link className="text-blue-400 hover:underline" to="/">Quay về trang chủ</Link>
      </p>
    </div>
  )
}