import { Button } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { QuizGameDto } from "../../dtos/quiz-game/QuizGameDto";
import { useCompKey } from "../../hooks/useCompKey";
import { QuizGameService } from "../../services/QuizGameService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { AppConfirmModal } from "../AppConfirmModal";
import { AppFixedModal } from "../AppFixedModal";
import { AppFlexFolders, AppFlexFoldersProps } from "../AppFlexFolders";
import { FlexListQueryDto } from "../AppFlexList";
import { QuizGameEditor } from "./QuizGameEditor";
import { QuizGamePreview } from "./QuizGamePreview";

interface QuizGameListProps {
  zIndex: number;
  folderOverrides?: Partial<AppFlexFoldersProps<QuizGameDto>>
  creatable?: boolean;
}

export function QuizGameList(props: QuizGameListProps) {
  const { zIndex, folderOverrides, creatable } = props;
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState<QuizGameDto | null>(null);
  const [del, setDel] = useState<QuizGameDto | null>(null);
  const compKey = useCompKey();

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return QuizGameService.query({
      ...queryDto,
      sorts: [
        { field: 'updateDt', descending: true }
      ],
      filters: {
        name: queryDto.searchTerm,
        disabled: false
      }
    })
  }, []);

  const deleteQuizGame = useCallback(async (quizGame: QuizGameDto) => {
    const success = await QuizGameService.toggle(quizGame.id);
    if (success) {
      NotificationUtils.success({ message: <>Xoá bộ câu hỏi <b>{quizGame.name} thành công!</b></> });
      setDel(null);
      compKey.renew();
    }
  }, [compKey]);

  const actions = useMemo(() => {
    if (!creatable) {
      return null; 
    }
    return (
      <Button variant="gradient" onClick={() => setCreate(true)} leftIcon={<HiPlusCircle />}>
        Thêm bộ câu hỏi
      </Button>
    );
  }, [creatable]);

  return (
    <>
      {(create || !!update) && (
        <AppFixedModal
          title={(create ? "Thêm" : "Cập nhật") + " bộ câu hỏi"}
          zIndex={zIndex}
          onClose={() => {
            setCreate(false);
            setUpdate(null);
            
            setTimeout(() => {
              compKey.renew();
            }, 0);
          }}
        >
          <QuizGameEditor template={true} zIndex={zIndex + 1} quizGame={update} />
        </AppFixedModal>
      )}

      <AppConfirmModal 
        opened={!!del} 
        onClose={() => setDel(null)}
        onConfirm={() => del && deleteQuizGame(del)}
      >
        Bạn có chắc muốn xoá bộ câu hỏi <b>{del?.name}</b> chứ?
      </AppConfirmModal>

      <AppFlexFolders
        zIndex={zIndex + 1}
        key={compKey.key}
        fetchData={fetchData as any}
        folderWrapperClassName="w-1/3 lg:w-1/4 mb-4 pr-4 text-sm"
        folderLabelRenderer={(quizGame: QuizGameDto) => (
          <div>{quizGame.name} <b>({quizGame.totalQuiz})</b></div>
        )}
        folderPreviewRenderer={(quizGame: QuizGameDto) => (
          <QuizGamePreview quizGame={quizGame} />
        )}
        actions={actions}
        onUpdate={setUpdate}
        onSelect={setUpdate}
        onDelete={setDel}
        {...folderOverrides}
      />
    </>
  )
}