import { Button, LoadingOverlay } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { HiPlusCircle } from "react-icons/hi";
import { ResponseCode } from "../../config/ResponseConfig";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { MountedQuizDto } from "../../dtos/mounted-quiz/MountedQuizDto";
import { QuizGameDto } from "../../dtos/quiz-game/QuizGameDto";
import { ScenarioDto } from "../../dtos/scenario/ScenarioDto";
import { InteractionType } from "../../enums/InteractionType";
import { QuizGameEditorTab } from "../../enums/QuizGameEditorTab";
import { ScenarioEditorTab } from "../../enums/ScenarioEditorTab";
import { useCompKey } from "../../hooks/useCompKey";
import { useQuery } from "../../hooks/useQuery";
import { useQuizGameEdtior } from "../../hooks/useQuizGameEditor";
import { InteractionService } from "../../services/InteractionService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { AppSideListItem } from "../AppSideListItem";
import { InteractionTypeSelectModal } from "../interaction/InteractionTypeSelectModal";
import { MountAndUpsertPollForm } from "../poll/MountAndUpsertPollForm";
import { MountPollForm } from "../poll/MountPollForm";
import { MountQuizGameForm } from "../quiz-game/MountQuizGameForm";
import { QuizGameEditorBody } from "../quiz-game/QuizGameEditor";
import { ScenarioSideList } from "./ScenarioSideList";
import { UpsertScenarioForm } from "./UpsertScenarioForm";

interface ScenarioEditorProps {
  zIndex: number;
  projectId?: number;
  scenario?: ScenarioDto | null;
  onUpdateScenarioSuccess?: (scenario: ScenarioDto) => any;
}

export function ScenarioEditor(props: ScenarioEditorProps) {
  const { onUpdateScenarioSuccess } = props;
  const [tab, setTab] = useState<ScenarioEditorTab>(ScenarioEditorTab.UPDATE_SCENARIO);
  const [scenario, setScenario] = useState<ScenarioDto | null | undefined>(props.scenario);
  const [interactions, setInteractions] = useState<InteractionDto[]>([]);
  const [selectedItr, setSelectedItr] = useState<InteractionDto | null>(null);
  const [sideListLoading, setSideListLoading] = useState(false);
  const [selectTypeModal, setSelectTypeModal] = useState(false);
  const updatePollFormKey = useCompKey();
  const nextNumOrder = interactions.length + 1;

  const scenarioId = scenario?.id;
  const fetchItr = useMemo(() => {
    return scenarioId ? () => InteractionService.find({ scenarioId }) : undefined;
  }, [scenarioId]);

  const fetchItrResponse = useQuery(fetchItr);
  useEffect(() => {
    if (!fetchItrResponse.loading) {
      setInteractions(fetchItrResponse.data || []);
    }
  }, [fetchItrResponse]);

  const handleMount = useCallback((itr: InteractionDto) => {
    setInteractions(itrList => [...itrList, itr]);
  }, []);

  const handleDelete = useCallback(async (itr: InteractionDto) => {
    const success = await InteractionService.toggle(itr.id);
    if (success) {
      NotificationUtils.success({ message: 'Xoá tương tác thành công' });
      setInteractions(itrList => itrList.filter(_itr => _itr.id !== itr.id));
      
      if (selectedItr?.id === itr.id) {
        setTab(ScenarioEditorTab.UPDATE_SCENARIO);
        setSelectedItr(null);
      }
    }
  }, [selectedItr]);

  const sideListInteractions = useMemo(() => {
    return interactions
      .filter(itr => 
        itr.interactionType === InteractionType.QUIZ_GAME || 
        itr.interactionType === InteractionType.POLL
      )
      .sort((itr1, itr2) => itr1.numOrder - itr2.numOrder); // Ascending
  }, [interactions]);

  const handleSideListReorder = useCallback(async (dropResult: DropResult) => {
    if (dropResult.destination) {
      const src = sideListInteractions[dropResult.source.index];
      const dest = sideListInteractions[dropResult.destination.index];
      if (src.id !== dest.id) {
        setSideListLoading(true);
        const requestDto = {
          updates: [
            { id: src.id, numOrder: dest.numOrder },
            { id: dest.id, numOrder: src.numOrder }
          ]
        };
    
        const success = await InteractionService.updateNumOrder(requestDto);
        if (success) {
          const srcNumOrder = src.numOrder;
          src.numOrder = dest.numOrder;
          dest.numOrder = srcNumOrder;
          setInteractions(prev => [...prev]);
        }
        setSideListLoading(false);
      }
    }
  }, [sideListInteractions]);

  const findIndex = useCallback((itr: InteractionDto) => {
    return interactions.findIndex(_itr => _itr.id === itr.id);
  }, [interactions]);

  const findByMQ = useCallback((mqId: number) => {
    return interactions.find(itr => 
      itr.interactionType === InteractionType.QUIZ &&
      itr.mountedQuizId === mqId
    );
  }, [interactions]);

  const findByQG = useCallback((quizGameId: number) => {
    return interactions.find(itr =>
      itr.interactionType === InteractionType.QUIZ_GAME &&
      itr.quizGameId === quizGameId
    );
  }, [interactions]);

  const quizGameEditor = useQuizGameEdtior({
    quizGame: useMemo(() => {
      return selectedItr?.quizGame;
    }, [selectedItr]),

    onDeleteMQ: useCallback(async (mq: MountedQuizDto) => {
      const itr = findByMQ(mq.id);
      if (!!itr) {
        await InteractionService.toggle(itr.id);
      }
    }, [findByMQ]),

    onMount: useCallback(async (mq: MountedQuizDto) => {
      if (scenario) {
        const response = await InteractionService.create({
          interactionType: InteractionType.QUIZ,
          mountedQuizId: mq.id,
          numOrder: nextNumOrder,
          scenarioId: scenario.id
        });

        if (response.code === ResponseCode.OK.code) {
          handleMount(response.body);
        }
      }
    }, [nextNumOrder, scenario, handleMount]),

    onUpsertQuizGame: useCallback(async (quizGame: QuizGameDto) => {
      if (scenario) {
        const itr = findByQG(quizGame.id);
        if (!!itr) {
          itr.quizGame = quizGame;
          setInteractions(prev => [...prev]);
        } else {
          const response = await InteractionService.create({
            interactionType: InteractionType.QUIZ_GAME,
            quizGameId: quizGame.id,
            numOrder: nextNumOrder,
            scenarioId: scenario.id
          });
  
          if (response.code === ResponseCode.OK.code) {
            handleMount(response.body);
          }
        }
      }
    }, [nextNumOrder, scenario, handleMount, findByQG]),

    onUpdateMQ: useCallback((mq: MountedQuizDto) => {
      const itr = findByMQ(mq.id);
      if (!!itr) {
        itr.mountedQuiz = mq;
        setInteractions(prev => [...prev]);
      }
    }, [findByMQ])
  });

  const handleSelect = useCallback((itr: InteractionDto) => {
    setSelectedItr(itr);
    switch (itr.interactionType) {
      case InteractionType.POLL: 
        setTab(ScenarioEditorTab.UPDATE_POLL);
        updatePollFormKey.renew();
        break;
      case InteractionType.QUIZ_GAME: 
        setTab(ScenarioEditorTab.UPDATE_QUIZ_GAME);
        quizGameEditor.setTab(QuizGameEditorTab.UPDATE_QUIZ_GAME);
        setTimeout(quizGameEditor.upsertQGFormKey.renew, 0);
        break;
    }
  }, [updatePollFormKey, quizGameEditor]);

  const handleSelectType = useCallback((type: InteractionType) => {
    setSelectTypeModal(false);
    setSelectedItr(null);
    switch (type) {
      case InteractionType.POLL: 
        setTab(ScenarioEditorTab.CREATE_POLL);
        break;
      case InteractionType.QUIZ_GAME: 
        setTab(ScenarioEditorTab.CREATE_QUIZ_GAME);
        setTimeout(quizGameEditor.upsertQGFormKey.renew, 0);
        break;
    }
  }, [quizGameEditor]);

  const handleUpdateScenario = useCallback(() => {
    setTab(ScenarioEditorTab.UPDATE_SCENARIO);
    setSelectedItr(null);
  }, []);

  const handleUpdatePoll = useCallback((itr: InteractionDto) => {
    const idx = findIndex(itr);
    setInteractions(prev => {
      prev[idx] = itr;
      return [...prev];
    });
  }, [findIndex]);

  const handleUpdateScenarioSuccess = useCallback((scenario: ScenarioDto) => {
    setScenario(scenario);
    onUpdateScenarioSuccess && onUpdateScenarioSuccess(scenario);
  }, [onUpdateScenarioSuccess]);

  const _sideListLoading = (fetchItr && fetchItrResponse.loading) || sideListLoading;
  
  return (
    <>
      <InteractionTypeSelectModal
        opened={selectTypeModal}
        zIndex={props.zIndex + 1} 
        onSelect={handleSelectType}
        onClose={() => setSelectTypeModal(false)}
      />
      <div className="flex" style={{ height: 'calc(100% - 40px)' }}>
        <div className="w-1/4 border-r h-full overflow-auto">
          <AppSideListItem zIndex={props.zIndex} onClick={handleUpdateScenario}>
            <b>{scenario?.name || 'Kịch bản'}</b>
          </AppSideListItem>
          <div className="relative">
            <LoadingOverlay visible={_sideListLoading} />
            <ScenarioSideList
              zIndex={props.zIndex}
              interactions={sideListInteractions}
              onDelete={handleDelete}
              onReorder={handleSideListReorder}
              onSelect={handleSelect}
              selected={selectedItr}
              quizGameEditor={quizGameEditor}
            />
          </div>
          {scenario && (
            <div className="p-4">
              <Button 
                fullWidth 
                disabled={_sideListLoading}
                variant="light" 
                leftIcon={<HiPlusCircle />}
                onClick={() => setSelectTypeModal(true)}
              >
                Thêm tương tác
              </Button>
            </div>
          )}
        </div>
        <div className="w-3/4 p-4 h-full overflow-auto">
          {tab === ScenarioEditorTab.UPDATE_SCENARIO && (
            <UpsertScenarioForm
              submitable={true}
              scenario={scenario}
              projectId={props.projectId}
              onSuccess={handleUpdateScenarioSuccess}
            />
          )}

          {scenario && (
            <>
              {tab === ScenarioEditorTab.CREATE_QUIZ_GAME && (
                <MountQuizGameForm 
                  template={false}
                  zIndex={props.zIndex}
                  numOrder={nextNumOrder}
                  editor={quizGameEditor}
                  scenarioId={scenario.id}
                  onMount={handleMount}
                />
              )}

              {tab === ScenarioEditorTab.CREATE_POLL && (
                <MountPollForm
                  numOrder={nextNumOrder}
                  onMount={handleMount}
                  scenarioId={scenario.id}
                  zIndex={props.zIndex}
                />
              )}

              {tab === ScenarioEditorTab.UPDATE_QUIZ_GAME && (
                <QuizGameEditorBody
                  template={false}
                  zIndex={props.zIndex}
                  editor={quizGameEditor}
                />
              )}

              {tab === ScenarioEditorTab.UPDATE_POLL && (
                <MountAndUpsertPollForm
                  key={updatePollFormKey.key}
                  interaction={selectedItr}
                  numOrder={nextNumOrder}
                  onSuccess={handleUpdatePoll}
                  scenarioId={scenario.id}
                  zIndex={props.zIndex}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}