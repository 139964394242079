import { Avatar } from "@mantine/core";
import { QuizLeaderboardDto } from "../dtos/quiz-game/QuizLeaderboardDto";
import { AppPreviewWrapper } from "./AppPreviewWrapper";

interface AppQuizLeaderboardPreviewProps {
  title: any;
  showScore?: boolean;
  leaderboard: QuizLeaderboardDto;
  onPageChange?: (page: number) => any;
}

export function AppQuizLeaderboardPreview(props: AppQuizLeaderboardPreviewProps) {
  const { page, pageSize } = props.leaderboard;
  return (
    <AppPreviewWrapper title={props.title}>
      <div className="p-2">
        {props.leaderboard.content.map((row, idx) => (
          <div key={idx} className="px-4 py-1 w-full rounded-lg border flex items-center text-xs mb-1">
            <div className="w-6">
              {row.rank || (page * pageSize + idx)}
            </div>
            <div className="flex items-center">
              <Avatar size="sm" radius="xl" src={row.clientInfo.avatar} color="blue" alt={row.clientInfo.name} />
              <div className="ml-2 font-medium">{row.clientInfo.name}</div>
            </div>
            {props.showScore && (
              <div className="ml-auto">{row.totalScore}</div>
            )}
          </div>
        ))}

        {/* <div className="mt-4 flex justify-center">
          <AppPagination
            total={total}
            current={page + 1}
            pageSize={pageSize}
            onChange={props.onPageChange}
            showLessItems={true}
          />
        </div> */}
      </div>
    </AppPreviewWrapper>
  )
}