import { ActionIcon, Select } from "@mantine/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RiEditBoxLine } from "react-icons/ri";
import { ScenarioDto } from "../../../dtos/scenario/ScenarioDto";
import { ActionType } from "../../../enums/ActionType";
import { PublishStatus } from "../../../enums/PublishStatus";
import { useCompKey } from "../../../hooks/useCompKey";
// import { EventService } from "../../../services/EventService";
import { OperateService } from "../../../services/OperateService";
import { ScenarioService } from "../../../services/ScenarioService";
import { useAppStore } from "../../../store/useAppStore";
import { NotificationUtils } from "../../../utils/NotificationUtils";
import { AppFixedModal } from "../../AppFixedModal";
import { OperateInteraction } from "../../interaction/OperateInteraction";
import { ScenarioEditor } from "../../scenario/ScenarioEditor";
import { ScenarioSelectModal } from "../../scenario/ScenarioSelectModal";

export function OperateScenario() {
  const { store, dispatch } = useAppStore();
  const { currScenario, currEvent } = store;

  const [select, setSelect] = useState(false);
  const [update, setUpdate] = useState(false);
  
  const operateInteractionCompKey = useCompKey();
  const scenarioSelectProps: any = useMemo(() => {
    if (currScenario) {
      return {
        data: [{ value: String(currScenario.id), label: currScenario.name }],
        value: String(currScenario.id)
      };
    }
    return { data: [] };
  }, [currScenario]);

  const handleSelectScenario = useCallback(() => {
    if (currScenario && currScenario.publishStatus === PublishStatus.PUBLISHING) {
      NotificationUtils.warn({ message: 'Vui lòng kết thúc kịch bản hiện tại trước khi bắt đầu kịch bản mới' });
    } else {
      setSelect(true);
    }
  }, [currScenario]);

  const handleUpdateScenarioSuccess = useCallback((scenario: ScenarioDto) => {
    dispatch(ActionType.UPDATE_SCENARIO, scenario);
  }, [dispatch]);

  useEffect(() => {
    const recoverScenario = async () => {
      let roomId = '';
      if (currEvent) {
        roomId = currEvent.roomId;
      } else {
        roomId = OperateService.getCurrRoomId();
      }

      if (roomId) {
        const scenarioId = OperateService.getCurrScenarioId(roomId);
        if (scenarioId) {
          const scenario = await ScenarioService.findById(scenarioId);
          dispatch(ActionType.NEW_SCENARIO, scenario);
        }
      }
    }

    recoverScenario();
  }, [dispatch, currEvent]);

  return (
    <>
      {update && currScenario && (
        <AppFixedModal
          zIndex={3}
          title={"Cập nhật kịch bản"}
          onClose={() => {
            operateInteractionCompKey.renew();
            setUpdate(false);
          }}
        >
          <ScenarioEditor zIndex={3} scenario={currScenario} onUpdateScenarioSuccess={handleUpdateScenarioSuccess} />
        </AppFixedModal>
      )}

      {select && (
        <ScenarioSelectModal 
          zIndex={3} 
          onClose={() => setSelect(false)}
          onSelect={(scenario) => {
            dispatch(ActionType.NEW_SCENARIO, scenario);
            setSelect(false);
          }}
        />
      )}

      <div className="px-4 pt-4" style={{ height: '85px' }}>
        <Select 
          readOnly
          disabled={currScenario && currScenario.publishStatus === PublishStatus.PUBLISHING}
          label={(
            <div className="flex items-center">
              <b>Kịch bản</b>
              {currScenario && (
                <ActionIcon color="blue" onClick={() => setUpdate(true)} className="ml-1">
                  <RiEditBoxLine />
                </ActionIcon>
              )}
            </div>
          )}
          placeholder="Chọn kịch bản"
          onFocus={handleSelectScenario}
          {...scenarioSelectProps}
        />
      </div>

      <div className="pt-2" style={{ height: 'calc(100% - 85px)' }}>
        <OperateInteraction key={operateInteractionCompKey.key} />
      </div>
    </>
  )
}