import { Button, PasswordInput } from "@mantine/core";
import { LockClosedIcon } from "@radix-ui/react-icons";
import { IsString } from "class-validator";
import { FormikHelpers } from "formik";
import { useCallback, useMemo } from "react";
import { Match } from "../../decorators/validations/MatchDecorator";
import { ChangePasswordDto } from "../../dtos/auth/ChangePasswordDto";
import { useAppFormik } from "../../hooks/useAppFormik";
import { useCompKey } from "../../hooks/useCompKey";
import { AuthService } from "../../services/AuthService";
import { NotificationUtils } from "../../utils/NotificationUtils";

class ChangePasswordWithConfirmDto extends ChangePasswordDto {
  @Match('newPassword', { message: 'Xác nhận mật khẩu mới không khớp' })
  @IsString({ message: 'Vui lòng xác nhận mật khẩu mới' })
  confirmPassword?: string;
}

export function ChangePasswordPage() {
  const compKey = useCompKey();
  const initialValues = useMemo(() => new ChangePasswordWithConfirmDto(), []);
  const submit = useCallback(async (values: ChangePasswordWithConfirmDto, actions: FormikHelpers<ChangePasswordWithConfirmDto>) => {
    const succes = await AuthService.changePassword(values);
    if (succes) {
      NotificationUtils.success({ title: "Đổi mật khẩu thành công", message: "Vui lòng đăng nhập lại để kiểm tra" });
      actions.resetForm();
      compKey.renew();
    }
    actions.setSubmitting(false);
  }, [compKey]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  return (
    <form 
      className="w-full md:w-1/3" 
      onSubmit={formik.handleSubmit}
      onReset={formik.handleReset}
      key={compKey.key}
    >
      <PasswordInput
        required
        label="Mật khẩu"
        name="password"
        placeholder="Mật khẩu..."
        className="mt-4"
        icon={<LockClosedIcon />}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.password}
        error={formik.touched.password && formik.errors.password}
      />

      <PasswordInput
        required
        label="Mật khẩu mới"
        name="newPassword"
        placeholder="Mật khẩu mới..."
        className="mt-4"
        icon={<LockClosedIcon />}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.newPassword}
        error={formik.touched.newPassword && formik.errors.newPassword}
      />

      <PasswordInput
        required
        label="Xác nhận mật khẩu mới"
        name="confirmPassword"
        placeholder="Xác nhận mật khẩu mới..."
        className="mt-4"
        icon={<LockClosedIcon />}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.confirmPassword}
        error={formik.touched.confirmPassword && formik.errors.confirmPassword}
      />

      <Button variant="gradient" type="submit" className="mt-4" loading={formik.isSubmitting}>
        Đổi mật khẩu
      </Button>
    </form>
  )
}