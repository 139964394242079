import { Button, Group, LoadingOverlay, Text } from "@mantine/core";
import { Dropzone, DropzoneStatus } from "@mantine/dropzone";
import { useCallback, useState } from "react";
import { AiOutlineUpload } from "react-icons/ai";
import { BiErrorCircle } from 'react-icons/bi';
import { AppConfig } from "../../config/AppConfig";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { UploadFileDisplay } from "./UploadFileDisplay";
import { UploadFileDto } from "../../dtos/upload-file/UploadFileDto";

interface UploadFileDropZoneProps {
  maxSize?: number;
  accept?: string[];
  onUpload?: (file: UploadFileDto) => any; 
  onClose?: () => any;
}

export function UploadFileDropZone(props: UploadFileDropZoneProps) {
  const maxSize = props.maxSize || AppConfig.uploadFile.MAX_SIZE;
  const { onClose, onUpload, accept } = props;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<UploadFileDto>();

  const handleDrop = useCallback(async (files: File[]) => {
    if (files.length > 0) {
      setLoading(true);
      setFile(await UploadFileDto.ofFile(files[0]));
      setLoading(false);
    }
  }, []);

  const handleReject = useCallback(() => {
    NotificationUtils.error({ message: 'File không hợp lệ, vui lòng chọn file khác' });
  }, []);

  const renderIcon = useCallback((status: DropzoneStatus) => {
    if (status.accepted) {
      return <AiOutlineUpload className="text-blue-400 text-6xl" />
    }
    if (status.rejected) {
      return <BiErrorCircle className="text-red-400 text-6xl" />
    }
    return <AiOutlineUpload className="text-6xl" />
  }, []);

  const upload = useCallback(async () => {
    if (file && onUpload) {
      setLoading(true);
      const success = await onUpload(file);
      if (success) {
        NotificationUtils.success({ message: 'Tải lên thành công' });
      }
      setLoading(false);
    }
  }, [file, onUpload]);

  const handleDelete = useCallback(() => {
    setFile(undefined);
  }, []);

  return (
    <div className="w-full relative">
      <div className="p-4 mb-16">
        {file ? (
          <div className="md:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5 ">
            <LoadingOverlay visible={!!file && loading} />
            <UploadFileDisplay onDelete={handleDelete} file={file} />
          </div>
        ) : (  
          <Dropzone loading={loading} onDrop={handleDrop} onReject={handleReject} maxSize={maxSize} accept={accept}>
            {status => (
              <Group position="center" spacing="xl" style={{ minHeight: 220, pointerEvents: 'none' }}>
                {renderIcon(status)}
                <div className="ml-4">
                  <Text size="xl" inline>Kéo thả, hoặc nhấn vào đây để tải lên</Text>
                  <Text size="sm" color="dimmed">Chỉ chấp nhận một <span className="text-blue-400">file</span> duy nhất, dung lượng không vượt quá <span className="text-blue-400">{maxSize / 1024 / 1024}MB</span></Text>
                </div>
              </Group>
            )}
          </Dropzone>
        )}
      </div>
      <div className="fixed left-0 right-0 bottom-0 mt-4 px-8 py-4 bg-gray-100 flex items-center justify-end">
        <Button className="mr-4" variant="outline" color="gray" onClick={onClose}>Huỷ bỏ</Button>
        <Button disabled={!file} loading={!!file && loading} onClick={upload} leftIcon={<AiOutlineUpload />}>
          Tải lên
        </Button>
      </div>
    </div>
  )
}