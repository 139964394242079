import { IsString, MaxLength, MinLength } from "class-validator";

export class UpdateProfileDto {
  @MaxLength(20, { message: "Tên người dùng chỉ cho phép tối đa 20 kí tự" })
  @MinLength(1, { message: "Tên người dùng phải có ít nhất 1 kí tự" })
  @IsString({ message: "Vui lòng nhập tên người dùng" })
  lastName: string;

  @MaxLength(50, { message: "Họ người dùng chỉ cho phép tối đa 50 kí tự" })
  @MinLength(1, { message: "Họ người dùng phải có ít nhất 1 kí tự" })
  @IsString({ message: "Vui lòng nhập họ người dùng" })
  firstName: string;
}