import { Avatar, Checkbox, Radio } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { SelectMode } from "../enums/SelectMode";
import { AppPreviewWrapper } from "./AppPreviewWrapper";

export interface AppInteractivePreviewProps {
  title: any;
  content: any;
  options: any[];
  selectMode: SelectMode;
  avatars?: string[] | null;
  selections?: number[] | null;
  corrects?: boolean[] | null;
  checked?: boolean[] | null;
}

export function AppInteractivePreview(props: AppInteractivePreviewProps) {
  const { selections, avatars } = props;

  const sum = useCallback((numbers: number[]) => {
    return numbers.reduce((a, b) => a + b, 0);
  }, []);

  const totalSelection = useMemo(() => {
    return selections ? sum(selections) : 0;
  }, [selections, sum]);

  return (
    <AppPreviewWrapper title={props.title}>
      <div className="text-center px-2 text-sm my-4">{props.content}</div>
      {props.options.map((opt, idx) => {
        const selection = props.selections ? props.selections[idx] : 0;
        const selectPercent = totalSelection > 0 ? Math.round(selection / totalSelection * 100) : 0;
        const correct = props.corrects ? props.corrects[idx] : false;
        return (
          <div 
            className={
              "border mt-1 flex items-center px-3 py-2 text-sm relative bg-white " 
              + (correct ? "border-green-400 text-green-700": "")
              // + (props.corrects && props.corrects[idx] ? "bg-green-400 border-green" : "bg-white")
            }
            style={{ borderRadius: '20px' }}
            key={idx}
          >
            {selectPercent > 0 && (
              <div 
                className={"transition-all absolute h-full top-0 left-0 rounded-2xl opacity-50 " + (correct ? "bg-green-400" : "bg-gray-200")}
                style={{ width: `${selectPercent}%` }} 
              />
            )}

            {props.selectMode === SelectMode.MULTIPLE ? (
              <div style={{ width: '56%', zIndex: 2 }} className="flex items-center">
                <Checkbox className="mr-2" checked={!!props.checked && props.checked[idx]} label={<span className="text-sm">{opt}</span>} />
              </div>
            ) : (
              !!props.checked ? (
                <Radio
                  value={opt}
                  checked={props.checked[idx]}
                  style={{ width: '56%', zIndex: 2 }} 
                  label={<span className="text-sm">{opt}</span>}
                />
              ) : (
                <div style={{ width: '56%', zIndex: 2 }}>{opt}</div>
              )
            )}
  
            <div className="flex items-center" style={{ width: '44%', zIndex: 2 }}>
              {avatars && (
                avatars.map((avatar, idx) => (
                  <Avatar src={avatar} alt="selection avatar" size="xs" key={idx} style={{ marginLeft: '-5px' }} />
                ))
              )}
              {selection > 0 && (
                <div className="ml-auto rounded-lg bg-red-500 text-white text-xs px-1">
                  {selection}
                </div>
              )}
            </div>
          </div>
        )
      })}
    </AppPreviewWrapper>
  )
}