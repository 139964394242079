import { useCallback, useEffect, useMemo, useState } from "react";

export interface IQueryResponse<T = any> {
  loading: boolean;
  error?: any | null;
  data?: T | null;
  reFetch: (options?: any) => Promise<void>,
  ready: boolean;
}

export function useQuery<T = any>(fetchData?: (options?: any) => Promise<T>): IQueryResponse<T> {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState<T | null>(null);

  const reFetch = useCallback(async (options?: any) => {
    setData(null);
    setError(null);
    setLoading(true);
    
    if (fetchData) {
      try {
        setData(await fetchData(options));
      } catch (err: any) {
        setError(err);
      }
    }
    
    setLoading(false);
  }, [fetchData]);

  useEffect(() => {
    reFetch();
  }, [reFetch]);

  return useMemo(
    () => ({
      loading,
      error,
      data,
      reFetch,
      ready: !loading && !error && data !== null && data !== undefined
    }),
    [loading, error, data, reFetch]
  );
}
