import { NumberInput, Select } from "@mantine/core";
import { useState } from "react";
import { AppDeviceFrame, deviceFrames } from "./AppDeviceFrame";
import { AiOutlineFullscreen, AiOutlineMobile } from 'react-icons/ai';

interface AppRichDeviceFrameProps {
  defaultDevice?: string;
  defaultScale?: number;
  children: any;
}

export function AppRichDeviceFrame(props: AppRichDeviceFrameProps) {
  const [device, setDevice] = useState<any>(props.defaultDevice || 'iPhone 5/SE');
  const [scale, setScale] = useState(props.defaultScale || 100);

  return (
    <div className="h-full">
      <div className="w-full mb-4 flex justify-center">
        <Select 
          required
          defaultValue="IPhone X"
          className="mr-2"
          data={Object.keys(deviceFrames).map(device => ({ value: device, label: device }))}
          value={device}
          onChange={setDevice}
          icon={<AiOutlineMobile />}
        />
        <NumberInput
          min={40}
          max={100}
          value={scale}
          onChange={v => setScale(v || 0)}
          placeholder="Scale..."
          icon={<AiOutlineFullscreen className="text-sm" />}
          required
        />
      </div>
      <div className="flex justify-center">
        <AppDeviceFrame device={device} scale={scale / 100}>
          {props.children}
        </AppDeviceFrame>
      </div>
    </div>
  )
}