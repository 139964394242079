import { Button, TextInput } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useMemo } from "react";
import { ResponseCode } from "../../../config/ResponseConfig";
import { SystemConfigDto } from "../../../dtos/system-config/SystemConfigDto";
import { UpsertSystemConfigDto } from "../../../dtos/system-config/UpsertSystemConfigDto";
import { useAppFormik } from "../../../hooks/useAppFormik";
import { SystemConfigService } from "../../../services/SystemConfigService";
import { NotificationUtils } from "../../../utils/NotificationUtils";

interface UpsertSystemConfigFormProps {
  onSuccess?: (systemConfig: SystemConfigDto) => any;
  systemConfig?: SystemConfigDto;
}

export function UpsertSystemConfigForm(props: UpsertSystemConfigFormProps) {
  const { systemConfig, onSuccess } = props;

  const initialValues = useMemo(() => {
    const upsertDto = new UpsertSystemConfigDto();
    if (systemConfig) {
      upsertDto.name = systemConfig.name;
      upsertDto.value = systemConfig.value;
    }
    return upsertDto;
  }, [systemConfig]);

  const submit = useCallback(async (values: UpsertSystemConfigDto, actions: FormikHelpers<UpsertSystemConfigDto>) => {
    const response = await SystemConfigService.upsert(values);
    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: 'Lưu lại cấu hình thành công' });
      onSuccess && onSuccess(response.body);
    }
  }, [onSuccess]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput 
        required
        readOnly
        label="Tên cấu hình"
        name="name"
        placeholder="Tên cấu hình..."
        className="mb-4"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={formik.touched.name && formik.errors.name}
      />

      <TextInput 
        required
        label="Giá trị"
        name="value"
        placeholder="Giá trị..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.value}
        error={formik.touched.value && formik.errors.value}
      />

      <Button fullWidth type="submit" className="mt-4" loading={formik.isSubmitting}>
        Lưu lại
      </Button>
    </form>
  )
}