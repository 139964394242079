import { Button } from "@mantine/core";
import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { HiPlusCircle } from "react-icons/hi";
import { ScenarioDto } from "../../dtos/scenario/ScenarioDto";
import { PublishStatus, PublishStatusIcon } from "../../enums/PublishStatus";
import { useCompKey } from "../../hooks/useCompKey";
import { ScenarioService } from "../../services/ScenarioService";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { AppConfirmModal } from "../AppConfirmModal";
import { AppFixedModal } from "../AppFixedModal";
import { AppFlexFolders, AppFlexFoldersProps } from "../AppFlexFolders";
import { FlexListQueryDto } from "../AppFlexList";
import { ScenarioEditor } from "./ScenarioEditor";

interface ScenarioListProps {
  projectId: number;
  zIndex: number;
  creatable?: boolean;
  folderOverrides?: Partial<AppFlexFoldersProps<ScenarioDto>>;
}

export const scenarioListFolderClassNames = {
  [PublishStatus.PUBLISHING]: 'border-red-400 bg-red-50',
  [PublishStatus.FINISHED]: 'cursor-not-allowed border-green-400 bg-green-50',
  [PublishStatus.IDLE]: ''
};

export function ScenarioList(props: ScenarioListProps) {
  const { projectId, creatable, folderOverrides, zIndex } = props;
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState<ScenarioDto | null>(null);
  const [del, setDel] = useState<ScenarioDto | null>(null);
  const compKey = useCompKey();

  const fetchData = useCallback((queryDto: FlexListQueryDto) => {
    return ScenarioService.query({
      ...queryDto,
      sorts: [
        { field: 'updateDt', descending: true }
      ],
      filters: {
        projectId,
        name: queryDto.searchTerm,
        disabled: false
      }
    })
  }, [projectId]);

  const deleteScenario = useCallback(async (scenario: ScenarioDto) => {
    const success = await ScenarioService.toggle(scenario.id);
    if (success) {
      NotificationUtils.success({ message: <>Xoá kịch bản <b>{scenario.name} thành công!</b></> });
      setDel(null);
      compKey.renew();
    }
  }, [compKey]);

  const actions = useMemo(() => {
    if (creatable) {
      return (
        <Button variant="gradient" onClick={() => setCreate(true)} leftIcon={<HiPlusCircle />}>
          Thêm kịch bản
        </Button>
      );
    }
    return null;
  }, [creatable]);

  const doUpdate = useCallback((s: ScenarioDto) => {
    if (s.publishStatus === PublishStatus.IDLE) {
      setUpdate(s);
    }
  }, []);

  return (
    <>
      {(create || !!update) && (
        <AppFixedModal
          title={(create ? "Thêm" : "Cập nhật") + " kịch bản"}
          zIndex={zIndex}
          onClose={() => {
            setCreate(false);
            setUpdate(null);

            setTimeout(() => {
              compKey.renew();
            }, 0);
          }}
        >
          <ScenarioEditor zIndex={zIndex + 1} scenario={update} projectId={projectId} />
        </AppFixedModal>
      )}

      <AppConfirmModal 
        opened={!!del} 
        onClose={() => setDel(null)}
        onConfirm={() => del && deleteScenario(del)}
      >
        Bạn có chắc muốn xoá kịch bản <b>{del?.name}</b> chứ?
      </AppConfirmModal>

      <AppFlexFolders<ScenarioDto>
        zIndex={zIndex + 1}
        key={compKey.key}
        fetchData={fetchData as any}
        folderWrapperClassName="w-1/4 mb-4 pr-4 text-sm"
        folderClassName={s => scenarioListFolderClassNames[s.publishStatus]}
        folderLabelRenderer={s => (
          <div className="flex items-center">
            {s.publishStatus !== PublishStatus.IDLE && (PublishStatusIcon as any)[s.publishStatus]} <div className="ml-2">{s.name}</div>
          </div>
        )}
        folderPreviewRenderer={s => (
          <div className="text-gray-500 text-xs">
            <div className="mb-1"><b>Cập nhật lúc:</b> {dayjs(s.updateDt).format('DD/MM/YYYY HH:mm')}</div>
            <div><b>Tạo lúc:</b> {dayjs(s.createDt).format('DD/MM/YYYY HH:mm')}</div>
          </div>
        )}
        folderMenu={s => s.publishStatus === PublishStatus.IDLE}
        actions={actions}
        onUpdate={doUpdate}
        onSelect={doUpdate}
        onDelete={setDel}
        {...folderOverrides}
      />
    </>
  )
}