export class AmsService {

  static rtmpUrl(roomId: string) {
    return `rtmp://${process.env.REACT_APP_AMS_IP}:1935/${process.env.REACT_APP_AMS_APP}/${roomId}`;
  }

  static webRtcUrl() {
    return `wss://${process.env.REACT_APP_AMS_HOST}/${process.env.REACT_APP_AMS_APP}/websocket`;
  }

  static webRtcPublishUrl(roomId: string) {
    return `https://${process.env.REACT_APP_AMS_HOST}/LiveApp?name=${roomId}`;
  }

  static playUrl(roomId: string) {
    return `https://${process.env.REACT_APP_AMS_HOST}/${process.env.REACT_APP_AMS_APP}/play.html?name=${roomId}&autoplay=true`;
  }
}