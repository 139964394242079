import { Tab, Tabs } from "@mantine/core";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { IQuizGameEditor } from "../../hooks/useQuizGameEditor";
import { MountQuizGameFromLibraryForm } from "./MountQuizGameFromLibrary";
import { QuizGameEditorBody } from "./QuizGameEditor";

interface MountQuizGameFormProps {
  zIndex: number;
  scenarioId: number;
  onMount: (itr: InteractionDto) => any;
  numOrder: number;
  editor: IQuizGameEditor;
  template: boolean;
}

export function MountQuizGameForm(props: MountQuizGameFormProps) {
  return (
    <Tabs>
      <Tab label="Thư viện">
        <div className="mt-4">
          <MountQuizGameFromLibraryForm
            zIndex={props.zIndex}
            scenarioId={props.scenarioId}
            onMount={props.onMount}
            numOrder={props.numOrder}
          />
        </div>
      </Tab>
      <Tab label="Tạo bộ câu hỏi mới">
        <div className="mt-4">
          <QuizGameEditorBody 
            template={props.template}
            zIndex={props.zIndex}
            editor={props.editor}
          />
        </div>
      </Tab>
    </Tabs>
  )
}