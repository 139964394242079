import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { QuizGameDetailPage } from "./QuizGameDetailPage";
import { QuizGameListPage } from "./QuizGameListPage";

export function QuizGamePage(props: RouteComponentProps) {
  return (
    <>
      <Switch>
        <Redirect exact from="/app/interactive/quiz-game" to="/app/interactive/quiz-game/list" />
        <Route path="/app/interactive/quiz-game/list" component={QuizGameListPage} />
        <Route path="/app/interactive/quiz-game/detail/:id" component={QuizGameDetailPage} />
      </Switch>
    </>
  )
}