import { useCallback, useState } from "react";
import { InteractionDto } from "../../dtos/interaction/InteractionDto";
import { ActionType } from "../../enums/ActionType";
import { PublishStatus } from "../../enums/PublishStatus";
import { useOperateDisables } from "../../hooks/useOperateDisables";
import { PollService } from "../../services/PollService";
import { useAppStore } from "../../store/useAppStore";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { BaseInteraction } from "./BaseInteraction";

interface PollInteractionProps {
  interaction: InteractionDto;
  onSelect?: () => any;
  numOrder?: number;
}

export function PollInteraction(props: PollInteractionProps) {
  const { interaction: itr } = props;
  const { store, dispatch } = useAppStore();
  const { clockTick, currPollItrId, currRoomId, isRoomOpened } = store;
  const operateDisables = useOperateDisables();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handlePublish = useCallback(async () => {
    setLoading(true);
    setError(false);
    const success = await PollService.publish(itr.id);
    if (success) {
      NotificationUtils.success({ message: 'Publish khảo sát thành công' });
      dispatch(ActionType.SET_CURR_POLL_ITR_ID, itr.id);
      dispatch(ActionType.UPDATE_ITR_NODE, {
        id: itr.id,
        publishStatus: PublishStatus.PUBLISHING
      });
    } else {
      setError(true);
    }
    setLoading(false);
  }, [itr, dispatch]);

  const handleDisable = useCallback(() => {
    operateDisables.toggleOperate(itr.id);
  }, [operateDisables, itr]);

  return (
    <BaseInteraction 
      menu={itr.publishStatus === PublishStatus.IDLE}
      allowDisable={!clockTick || currPollItrId !== itr.id}
      publishable={!clockTick && isRoomOpened && !!currRoomId}
      disabled={operateDisables.isOperateDisabled(itr.id)}
      publishing={itr.publishStatus === PublishStatus.PUBLISHING}
      completed={itr.publishStatus === PublishStatus.FINISHED}
      current={currPollItrId === itr.id}
      loading={loading}
      error={error}
      onClick={props.onSelect}
      onPublish={handlePublish}
      onDisable={handleDisable}
    >
      {props.numOrder || itr.numOrder}. {itr.poll?.title}
    </BaseInteraction>
  )
}