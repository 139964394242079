import { Divider } from "@mantine/core";
import { useCallback } from "react";
import { QuizGameBannerDto } from "../../dtos/quiz-game-banner/QuizGameBannerDto";
import { QuizGameDto } from "../../dtos/quiz-game/QuizGameDto";
import { QuizGameBannerTypeDisplay } from "../../enums/QuizGameBannerType";
import { ScreenMode } from "../../enums/ScreenMode";
import { useQuery } from "../../hooks/useQuery";
import { QuizGameBannerService } from "../../services/QuizGameBannerService";
import { AppLoading } from "../AppLoading";
import { QuizGameBannerInput } from "./QuizGameBannerInput";

interface IUpsertQuizGameBannerFormProps {
  quizGame: QuizGameDto;
}

export function UpsertQuizGameBannerForm(props: IUpsertQuizGameBannerFormProps) {
  const { quizGame } = props;
  const quizGameId = quizGame.id;
  
  const fetchBanners = useCallback(async () => {
    const banners = await QuizGameBannerService.findByQuizGameId(quizGameId);
    return banners.reduce((map: any, el: QuizGameBannerDto) => {
      map[`${el.type}_${el.screenMode}`] = Object.assign(new QuizGameBannerDto(), el);
      return map;
    }, {});
  }, [quizGameId]);

  const bannersResponse = useQuery(fetchBanners);

  if (bannersResponse.loading) {
    return <AppLoading />
  }

  return (
    <div>
      <label>Hình ảnh của bộ câu hỏi: <b>{quizGame.name}</b></label>
      {Object.entries(QuizGameBannerTypeDisplay).map(([bannerType, label]) =>
        <div key={bannerType} className="w-full mb-2">
          <Divider label={label} my="xs" />
          <div className="flex flex-wrap">
            <div className="w-full mb-2 lg:mb-0 lg:w-1/2 lg:pr-2">
              <QuizGameBannerInput 
                label="Chọn hình thường"
                quizGameId={quizGame.id}
                bannerType={bannerType as any}
                screenMode={ScreenMode.PORTRAIT}
                banner={bannersResponse.data[`${bannerType}_${ScreenMode.PORTRAIT}`]}
              />
            </div>

            <div className="w-full lg:w-1/2 lg:pl-2">
              <QuizGameBannerInput 
                label="Chọn hình fullscreen"
                quizGameId={quizGame.id}
                bannerType={bannerType as any}
                screenMode={ScreenMode.LANDSCAPE}
                banner={bannersResponse.data[`${bannerType}_${ScreenMode.LANDSCAPE}`]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}