import { CloseButton } from "@mantine/core";

interface AppFixedModalProps {
  zIndex: number;
  onClose: () => any;
  title: any;
  children: any;
}

export function AppFixedModal(props: AppFixedModalProps) {
  return (
    <div className="fixed inset-0" style={{ zIndex: props.zIndex }}>
      <div className="absolute inset-0 bg-gray-700 opacity-75" onClick={props.onClose}></div>
      <div className="rounded bg-white absolute bottom-8 top-8 left-8 right-8 overflow-auto">
        <div className="w-full bg-gray-100 font-medium pl-4 py-2">{props.title}</div>
        <div className="absolute top-2 right-4" style={{ zIndex: props.zIndex }}><CloseButton onClick={props.onClose} /></div>
        {props.children}
      </div>
    </div>
  )
}