import { Button, Divider, Textarea, TextInput } from "@mantine/core";
import { FormikHelpers } from "formik";
import { useCallback, useMemo, useState } from "react";
import { ResponseCode } from "../../../config/ResponseConfig";
import { AppDto } from "../../../dtos/app/AppDto";
import { UpsertAppDto } from "../../../dtos/app/UpsertAppDto";
import { useAppFormik } from "../../../hooks/useAppFormik";
import { AppService } from "../../../services/AppService";
import { NotificationUtils } from "../../../utils/NotificationUtils";

interface UpsertAppFormProps {
  onChange?: (app: AppDto) => any;
  app?: AppDto | null;
}

export function UpsertAppForm(props: UpsertAppFormProps) {
  const { onChange } = props;
  const [app, setApp] = useState(props.app);
  const [regenKeyPairLoading, setRegenKeyPairLoading] = useState(false);

  const initialValues = useMemo(() => {
    const upsertDto = new UpsertAppDto();
    if (app) {
      upsertDto.name = app.name;
    }
    return upsertDto;
  }, [app]);

  const submit = useCallback(async (values: UpsertAppDto, actions: FormikHelpers<UpsertAppDto>) => {
    let response;
    if (app) {
      response = await AppService.update(app.id, values);
    } else {
      const isNameExists = await AppService.checkName(values.name);
      if (isNameExists) {
        actions.setFieldError('name', 'Tên app đã tồn tại, vui lòng chọn tên khác');
        actions.setSubmitting(false);
        return;
      }

      response = await AppService.create(values);
    }

    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: 'Lưu lại app thành công' });
      setApp(app);
      onChange && onChange(response.body);
    }

    actions.setSubmitting(false);
  }, [onChange, app]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  const regenKeyPair = useCallback(async () => {
    if (app) {
      setRegenKeyPairLoading(true);
  
      const response = await AppService.regenKeyPair(app.id);
      if (response.code === ResponseCode.OK.code) {
        NotificationUtils.success({ message: 'Tạo cặp key mới thành công' });
        setApp(response.body);
      }
  
      setRegenKeyPairLoading(false);
    }
  }, [app]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput 
        required
        label="Tên app"
        name="name"
        placeholder="Tên app..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={formik.touched.name && formik.errors.name}
      />

      <Button fullWidth type="submit" className="mt-4" loading={formik.isSubmitting}>
        Lưu lại
      </Button>

      {app && (
        <>
          <Divider my="lg" />

          <Textarea maxRows={5} readOnly autosize label="Public Key" defaultValue={app.publicKey} />
          <Textarea maxRows={5} readOnly autosize label="Private Key" defaultValue={app.privateKey} />

          <Button fullWidth className="mt-4" loading={regenKeyPairLoading} onClick={regenKeyPair}>
            Tạo cặp key mới
          </Button>
        </>
      )}
    </form>
  )
}