import { Button, TextInput } from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import { FormikHelpers } from "formik";
import { useCallback, useMemo } from "react";
import { ResponseCode } from "../../../config/ResponseConfig";
import { EventCalendarDto } from "../../../dtos/event-calendar/EventCalendarDto";
import { UpsertEventCalendarFormDto } from "../../../dtos/event-calendar/UpsertEventCalenderFormDto";
import { UserRole } from "../../../enums/UserRole";
import { useAppFormik } from "../../../hooks/useAppFormik";
import { useApps } from "../../../hooks/useApps";
import { useSession } from "../../../hooks/useSession";
import { EventCalendarService } from "../../../services/EventCalendarService";
import { DtUtils } from "../../../utils/DtUtils";
import { NotificationUtils } from "../../../utils/NotificationUtils";
import { AppSelect } from "../../system/app/AppSelect";

interface UpsertEventCalendarFormProps {
  eventCalendar?: EventCalendarDto | null;
  onSuccess?: (e: EventCalendarDto) => any;
}

export function UpsertEventCalendarForm(props: UpsertEventCalendarFormProps) {
  const { eventCalendar, onSuccess } = props;
  const { user } = useSession();

  const now = useMemo(() => new Date(), []);
  const initialValues = useMemo(() => {
    const upsertDto = new UpsertEventCalendarFormDto();
    if (user.role !== UserRole.ADMIN) {
      upsertDto.appId = user.appId;
    }
    if (eventCalendar) {
      const startDt = new Date(eventCalendar.startDt);
      const endDt = new Date(eventCalendar.endDt);
      upsertDto.startDate = startDt;
      upsertDto.startTime = startDt;
      upsertDto.endDate = endDt;
      upsertDto.endTime = endDt;
      upsertDto.appId = eventCalendar.appId;
      upsertDto.name = eventCalendar.name;
    }
    upsertDto.startDate = upsertDto.startDate || now;
    upsertDto.startTime = upsertDto.startTime || now;
    upsertDto.endDate = upsertDto.endDate || now;
    upsertDto.endTime = upsertDto.endTime || now;
    return upsertDto;
  }, [user, eventCalendar, now]);

  const submit = useCallback(async (values: UpsertEventCalendarFormDto, actions: FormikHelpers<UpsertEventCalendarFormDto>) => {
    if (!values.checkDate()) {
      actions.setFieldError('endDate', 'Ngày kết thúc không hợp lệ');
      actions.setFieldError('endTime', 'Giờ kết thúc không hợp lệ');
      actions.setSubmitting(false);
      return;
    }

    let response;
    if (eventCalendar) {
      response = await EventCalendarService.update(eventCalendar.id, values.toUpsertDto());
    } else {
      response = await EventCalendarService.create(values.toUpsertDto());
    }

    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: 'Lưu lịch sự kiện thành công' });
      onSuccess && onSuccess(response.body);
    }

    actions.setSubmitting(false);
  }, [eventCalendar, onSuccess]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  const apps = useApps();

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextInput
        required
        label="Tên sự kiện"
        name="name"
        placeholder="Tên sự kiện..."
        className="mb-4"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={formik.touched.name && formik.errors.name}
      />

      {user.role === UserRole.ADMIN && apps.data && (
        <AppSelect 
          required
          className="mb-4"
          data={apps.data}
          loading={apps.loading}
          onChange={v => formik.setFieldValue('appId', Number(v))}
          onBlur={formik.handleBlur}
          defaultValue={String(formik.values.appId)}
          value={String(formik.values.appId)}
          error={formik.touched.appId && formik.errors.appId}
        />
      )}

      <div className="flex mb-4">
        <DatePicker
          required
          clearable={false}
          label="Ngày bắt đầu"
          name="startDate"
          placeholder="Ngày bắt đầu..."
          className="w-1/2 mr-1"
          zIndex={3}
          inputFormat="DD/MM/YYYY"
          labelFormat="DD/MM/YYYY"
          excludeDate={DtUtils.excludePastDate}
          onChange={d => formik.setFieldValue('startDate', d)}
          value={formik.values.startDate}
          error={formik.errors.startDate}
        />

        <TimeInput
          required
          label="Giờ bắt đầu"
          name="startTime"
          placeholder="Giờ bắt đầu..."
          className="w-1/2 ml-1"
          onChange={t => formik.setFieldValue('startTime', t)}
          value={formik.values.startTime}
          error={formik.errors.startTime}
        />
      </div>

      <div className="flex mb-4">
        <DatePicker
          required
          clearable={false}
          label="Ngày kết thúc"
          name="endDate"
          placeholder="Ngày kết thúc..."
          className="w-1/2 mr-1"
          zIndex={3}
          inputFormat="DD/MM/YYYY"
          labelFormat="DD/MM/YYYY"
          excludeDate={DtUtils.excludePastDate}
          onChange={d => formik.setFieldValue('endDate', d)}
          value={formik.values.endDate}
          error={formik.errors.endDate}
        />

        <TimeInput
          required
          label="Giờ kết thúc"
          name="endTime"
          placeholder="Giờ kết thúc..."
          className="w-1/2 ml-1"
          onChange={t => formik.setFieldValue('endTime', t)}
          value={formik.values.endTime}
          error={formik.errors.endTime}
        />
      </div>

      <Button fullWidth type="submit" loading={formik.isSubmitting}>
        Lưu lại
      </Button>
    </form>
  )
}