import { Redirect, Route, RouteComponentProps, Switch } from "react-router";
import { PollListPage } from "./PollListPage";

export function PollPage(props: RouteComponentProps) {
  return (
    <>
      <Switch>
        <Redirect exact from="/app/interactive/poll" to="/app/interactive/poll/list" />
        <Route path="/app/interactive/poll/list" component={PollListPage} />
      </Switch>
    </>
  )
}