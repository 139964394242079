import { AppConfig } from "../config/AppConfig";
import { InteractionDto } from "../dtos/interaction/InteractionDto";
import { LivestreamProfileDto } from "../dtos/operate/LivestreamProfileDto";
import { PublishStatus } from "../enums/PublishStatus";
import { StorageUtils } from "../utils/StorageUtils";
import { RoomService } from "./RoomService";
import { SystemConfigService } from "./SystemConfigService";

export class OperateService {

  private static readonly storage = new StorageUtils(localStorage);
  private static readonly CURR_ROOM_ID_KEY = `curr_room_id`;
  private static readonly CURR_EVENT_ID_KEY = (roomId: string) => `${roomId}_curr_event_id`;
  private static readonly AUTO_PUBLISH_GAP_KEY = (roomId: string) => `${roomId}_auto_publish_gap`;
  private static readonly CURR_SCENARIO_ID_KEY = (roomId: string) => `${roomId}_curr_scenario_id`;
  private static readonly LEADERBOARD_SIZE_KEY = (roomId: string) => `${roomId}_leaderboard_size`;
  private static readonly OPERATE_DISABLES_KEY = (roomId: string) => `${roomId}_operate_disables`;
  private static readonly LIVESTREAM_PROFILE_KEY = (roomId: string, userId: any) => `${roomId}_livestream_profile_${userId}`;

  static getCurrRoomId() {
    return this.storage.get(this.CURR_ROOM_ID_KEY);
  }

  static setCurrRoomId(roomId: string) {
    if (roomId) {
      this.storage.set(this.CURR_ROOM_ID_KEY, roomId);
    }
  }

  static removeCurrRoomId() {
    this.storage.remove(this.CURR_ROOM_ID_KEY);
  }

  static getCurrEventId(roomId: string) {
    return this.storage.get(this.CURR_EVENT_ID_KEY(roomId));
  }

  static setCurrEventId(roomId: string, eventId: number) {
    if (roomId && eventId) {
      this.storage.set(this.CURR_EVENT_ID_KEY(roomId), ''+eventId);
    }
  }

  static removeCurrEventId(roomId: string) {
    this.storage.remove(this.CURR_EVENT_ID_KEY(roomId));
  }

  static getLivestreamProfile(roomId: string, userId: string): LivestreamProfileDto {
    const key = this.LIVESTREAM_PROFILE_KEY(roomId, userId);
    return this.storage.getJSON(key);
  }

  static setLivestreamProfile(roomId: string, userId: number, dto: LivestreamProfileDto) {
    const key = this.LIVESTREAM_PROFILE_KEY(roomId, userId);
    this.storage.setJSON(key, dto);
  }

  static getAutoPublishGap(roomId: string) {
    return Number(this.storage.get(this.AUTO_PUBLISH_GAP_KEY(roomId)) || 10);
  }

  static setAutoPublishGap(roomId: string, gap: number) {
    this.storage.set(this.AUTO_PUBLISH_GAP_KEY(roomId), String(gap));
  }

  static getCurrScenarioId(roomId: string) {
    const key = this.CURR_SCENARIO_ID_KEY(roomId);
    return Number(this.storage.get(key) || 0);
  }

  static removeCurrScenarioId(roomId: string) {
    const key = this.CURR_SCENARIO_ID_KEY(roomId);
    this.storage.remove(key);
  }

  static setCurrScenarioId(roomId: string, id: number) {
    if (roomId && id) {
      this.storage.set(this.CURR_SCENARIO_ID_KEY(roomId), String(id));
    }
  }

  static async getMaxConnection(roomId?: string) {
    if (roomId) {
      const roomInfo = await RoomService.getRoomInfo(roomId);
      return roomInfo.maxConnection;
    }
    
    const systemConfig = await SystemConfigService.findByName('Max connection');
    return Number(systemConfig.value || 0);
  }

  static setMaxConnection(roomId: string, maxConnection: number) {
    return RoomService.updateMaxConnection(roomId, maxConnection);
  }

  static getLeaderboardSize(roomId: string) {
    const size = Number(this.storage.get(this.LEADERBOARD_SIZE_KEY(roomId)));
    return size && !isNaN(size) && size > 0 ? size : AppConfig.paging.LEADERBOARD_PAGE_SIZE;
  }

  static setLeaderboardSize(roomId: string, size: number) {
    if (roomId && size) {
      this.storage.set(this.LEADERBOARD_SIZE_KEY(roomId), String(size));
    }
  }

  static setOperateDisables(roomId: string, operateDisables: any) {
    this.storage.setJSON(this.OPERATE_DISABLES_KEY(roomId), operateDisables);
  }

  static getOperateDisables(roomId: string) {
    return this.storage.getJSON(this.OPERATE_DISABLES_KEY(roomId)) || {};
  }

  static clearOperateDisables(roomId: string) {
    this.storage.remove(this.OPERATE_DISABLES_KEY(roomId));
  }

  static cleanRoom(roomId: string) {
    this.storage.clearByPrefix(roomId);
    if (roomId === this.getCurrRoomId()) {
      this.removeCurrRoomId();
    }
  }

  static findNextQuiz(roomId: string, itrQuizzes: InteractionDto[], currItrQuiz?: InteractionDto) {
    let nextItrQuiz: InteractionDto | undefined;
    const operateDisables = this.getOperateDisables(roomId);

    if (currItrQuiz) {
      let idx = itrQuizzes.findIndex(itrQuiz => {
        return itrQuiz.numOrder === currItrQuiz.numOrder + 1;
      });

      if (idx >= 0) {
        while (
          idx < itrQuizzes.length 
          && (
            !itrQuizzes[idx] 
            || itrQuizzes[idx].publishStatus !== PublishStatus.IDLE
            || operateDisables[itrQuizzes[idx].id]
          )
        ) {
          idx++;
        }

        if (idx < itrQuizzes.length) {
          nextItrQuiz = itrQuizzes[idx];
        }
      }
    }

    if (!nextItrQuiz) {
      nextItrQuiz = itrQuizzes?.find(itrQuiz => {
        return itrQuiz.publishStatus === PublishStatus.IDLE && !operateDisables[itrQuiz.id];
      });
    }
    
    return nextItrQuiz;
  }
}