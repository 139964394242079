import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { OperateService } from "../../services/OperateService";

export function reduceAutoPublishGap(state: AppGlobalStateDto, autoPublishGap: number): AppGlobalStateDto {
  if (!!state.currRoomId) {
    OperateService.setAutoPublishGap(state.currRoomId, autoPublishGap);
  }
  
  return {
    ...state,
    autoPublishGap
  };
}