import { IsNotEmpty, IsNumber, IsOptional, IsString, Max, MaxLength, Min } from "class-validator";
import { IsNotBlank } from "../../decorators/validations/IsNotBlankDecorator";
import { SelectMode } from "../../enums/SelectMode";

export class UpsertQuizAnswerDto {
  id?: number;

  @MaxLength(100, { message: 'Nội dung câu trả lời chỉ cho phép tối đa 100 kí tự' })
  @IsNotBlank({ message: 'Nội dung câu trả lời không hợp lệ' })
  @IsNotEmpty({ message: 'Vui lòng nhập nội dung câu trả lời' })
  @IsString({ message: 'Vui lòng nhập nội dung câu trả lời' })
  content: string;
  correct: boolean = false;  
}

export class UpsertQuizDto {
  @Max(600, { message: 'Thời gian trả lời phải nhỏ hơn hoặc bằng 10 phút' })
  @Min(3, { message: 'Thời gian trả lời phải lớn hơn hoặc bằng 3 giây' })
  @IsNumber({}, { message: 'Thời gian trả lời là bắt buộc' })
  duration: number;

  @Min(0, { message: 'Delay phải lớn hơn hoặc bằng 0' })
  @Max(600, { message: 'Delay không được quá 600' })
  @IsNumber({}, { message: 'Vui lòng nhập delay' })
  delay: number = 0;

  @MaxLength(255, { message: 'Nội dung câu hỏi chỉ cho phép tối đa 255 kí tự' })
  @IsNotBlank({ message: 'Nội dung câu hỏi không hợp lệ' })
  @IsNotEmpty({ message: 'Vui lòng nhập nội dung câu hỏi' })
  @IsString({ message: 'Vui lòng nhập nội dung câu hỏi' })
  content: string;
  
  @IsString({ message: 'Vui lòng chọn kiểu câu hỏi' })
  selectMode: SelectMode;

  @IsOptional()
  answers: UpsertQuizAnswerDto[];

  @IsOptional()
  template?: boolean;
}