import { Button, TextInput } from "@mantine/core";
import { EnvelopeClosedIcon } from "@radix-ui/react-icons";
import { FormikHelpers } from "formik";
import { useCallback, useMemo } from "react";
import { UploadAvatar } from "../../components/system/user/UploadAvatar";
import { ResponseCode } from "../../config/ResponseConfig";
import { UpdateProfileDto } from "../../dtos/user/UpdateProfileDto";
import { ActionType } from "../../enums/ActionType";
import { useAppFormik } from "../../hooks/useAppFormik";
import { useSession } from "../../hooks/useSession";
import { UserService } from "../../services/UserService";
import { useAppStore } from "../../store/useAppStore";
import { NotificationUtils } from "../../utils/NotificationUtils";
import { S3Utils } from "../../utils/S3Utils";

export function ProfilePage() {
  const { dispatch } = useAppStore();
  const session = useSession();
  const user = session.user;

  const initialValues = useMemo(() => {
    const updateDto = new UpdateProfileDto();
    if (user) {
      updateDto.firstName = user.firstName;
      updateDto.lastName = user.lastName;
    }
    return updateDto;
  }, [user]);

  const submit = useCallback(async (values: UpdateProfileDto, actions: FormikHelpers<UpdateProfileDto>) => {
    const success = await UserService.updateProfile(values);
    if (success) {
      NotificationUtils.success({ message: 'Cập nhật thông tin thành công' });
      dispatch(ActionType.SET_SESSION, { ...session, user: { ...user, ...values } });
    }
    actions.setSubmitting(false);
  }, [dispatch, session, user]);

  const formik = useAppFormik({
    initialValues,
    onSubmit: submit
  });

  const uploadAvatar = useCallback(async (file: any) => {
    const response = await UserService.updateAvatar(file);
    if (response.code === ResponseCode.OK.code) {
      NotificationUtils.success({ message: 'Cập nhật avatar thành công' });
      dispatch(ActionType.SET_SESSION, { ...session, user: { ...user, avatar: response.body } });
      return true;
    }
    return false;
  }, [session, dispatch, user]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <UploadAvatar avatarSrc={S3Utils.toS3Url(user.avatar)} upload={uploadAvatar} />
      </div>

      <TextInput
        required
        readOnly
        label="Email"
        name="email"
        className="w-1/3 mb-4 cursor-not-allowed"
        placeholder="Email..."
        defaultValue={user?.username}
        icon={<EnvelopeClosedIcon />}
      />
      <TextInput
        required
        label="Họ người dùng"
        name="firstName"
        className="w-1/3 mb-4"
        placeholder="Nhập họ người dùng..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.firstName}
        error={formik.touched.firstName && formik.errors.firstName}
      />
      <TextInput
        required
        label="Tên người dùng"
        name="lastName"
        className="w-1/3 mb-4"
        placeholder="Nhập tên người dùng..."
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.lastName}
        error={formik.touched.lastName && formik.errors.lastName}
      />
      <Button variant="gradient" type="submit" loading={formik.isSubmitting}>
        Cập nhật
      </Button>
    </form>
  )
}