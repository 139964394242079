import { Apis } from "../config/ApiConfig";
import { QueryQuizGameLeaderboardDto, QuizGameLeaderboardFilters } from "../dtos/quiz-game-leaderboard/QueryQuizGameLeaderboardDto";
import { HttpUtils } from "../utils/HttpUtils";
import { AuthService } from "./AuthService";
import qs from 'querystring';

export class QuizGameLeaderboardService {

  static async query(queryDto: QueryQuizGameLeaderboardDto) {
    return (await HttpUtils.post(Apis.quizGameLeaderboard.query, queryDto)).body;
  }

  static exportExcel(filters: QuizGameLeaderboardFilters) {
    window.open(Apis.quizGameLeaderboard.exportExcel + "?" + qs.stringify({
      quizGameId: filters.quizGameId,
      atk: AuthService.accessToken
    }));
  }
}