import { MountedQuizDto } from "../../dtos/mounted-quiz/MountedQuizDto";
import { DropResult } from "react-beautiful-dnd";
import { AppVerticalDnD } from "../AppVerticalDnD";
import { Menu, MenuItem } from "@mantine/core";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useMemo } from "react";
import { RandomUtils } from "../../utils/RandomUtils";

interface MountedQuizVerticalDnDProps {
  zIndex: number;
  mountedQuizzes: MountedQuizDto[];
  onDelete: (mq: MountedQuizDto) => any;
  onSelect: (mq: MountedQuizDto) => any;
  onReorder: (dropResult: DropResult) => any;
}

export function MountedQuizVerticalDnD(props: MountedQuizVerticalDnDProps) {
  const droppableId = useMemo(() => {
    return RandomUtils.uuid();
  }, []);

  return (
    <AppVerticalDnD<MountedQuizDto>
      droppableId={droppableId}
      onDragEnd={props.onReorder}
      items={props.mountedQuizzes}
      itemId={mq => String(mq.id)}
      itemRenderer={(mq, idx) => (
        <div 
          className="flex items-start relative text-sm mb-2 border rounded-lg p-2 bg-white hover:border-blue-400 hover:text-blue-400"
          onClick={(e) => {
            if (!!props.onSelect) {
              e.preventDefault();
              props.onSelect(mq);
            }
          }}
        >
          <div className="mr-2">
            {idx + 1}. {mq.quiz?.content}
          </div>
          <div className="ml-auto">
            <Menu 
              trigger="hover"
              placement="center"
              zIndex={props.zIndex + 1}
              delay={100}
              control={<span><HiOutlineDotsVertical /></span>}
              controlRefProp="ref"
            >
              <MenuItem
                color="red"
                icon={<RiDeleteBin6Line />}
                onClick={(evt: any) => {
                  evt.stopPropagation();
                  props.onDelete && props.onDelete(mq);
                }}
              >
                Xoá
              </MenuItem>
            </Menu>
          </div>
        </div>
      )}
    />
  )
}