import { QuizGameBannerType } from "../../enums/QuizGameBannerType";
import { ScreenMode } from "../../enums/ScreenMode";
import { UploadFileFolder } from "../../enums/UploadFileFolder";
import { FileUtils } from "../../utils/FileUtils";
import { UploadFileDto } from "../upload-file/UploadFileDto";

export class QuizGameBannerDto {
  id: number;
  type: QuizGameBannerType;
  mediaSrc: string;
  screenMode: ScreenMode;
  quizGameId: number;
  disabled: boolean;
  width: number;
  height: number;
  size: number;
  _uploadFile?: UploadFileDto;

  get uploadFile() {
    if (this._uploadFile) {
      return this._uploadFile;
    }

    this._uploadFile = new UploadFileDto();
    this._uploadFile.ext = FileUtils.ext(this.mediaSrc);
    this._uploadFile.folder = UploadFileFolder.QUIZ_GAME_BANNERS;
    this._uploadFile.src = this.mediaSrc;
    this._uploadFile.width = this.width;
    this._uploadFile.height = this.height;
    this._uploadFile.size = this.size;
    this._uploadFile.mime = 'image';

    return this._uploadFile;
  }
}