import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { EventDto } from "../dtos/realtime/EventDto";
import { EventState } from "../enums/EventState";
import { HttpUtils } from "../utils/HttpUtils";

export class EventService {

  static async findById(id: number) {
    return ((await HttpUtils.get<EventDto>(Apis.event.findById(id))) as any).data;
  }

  static async updateState(id: number, state: EventState) {
    return (await HttpUtils.put(Apis.event.updateState(id), { state })).code === ResponseCode.OK.code;
  }
}