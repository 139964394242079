import { Button, Modal } from "@mantine/core";
import { InteractionType } from "../../enums/InteractionType";
import { CgPoll } from 'react-icons/cg';
import { BsQuestionSquare } from 'react-icons/bs';

interface InteractionTypeSelectModalProps {
  opened: boolean;
  zIndex: number;
  onClose: () => void;
  onSelect: (type: InteractionType) => void;
}

export function InteractionTypeSelectModal(props: InteractionTypeSelectModalProps) {
  return (
    <Modal
      zIndex={props.zIndex}
      opened={props.opened}
      onClose={props.onClose}
      title="Thêm tương tác"
    >
      <Button 
        fullWidth 
        variant="gradient" 
        size="md" 
        className="mb-4"
        leftIcon={<BsQuestionSquare />}
        onClick={() => props.onSelect(InteractionType.QUIZ_GAME)}
      >
        Thêm bộ câu hỏi
      </Button>
      <Button 
        fullWidth 
        variant="gradient" 
        size="md"
        leftIcon={<CgPoll />}
        onClick={() => props.onSelect(InteractionType.POLL)}
      >
        Thêm khảo sát
      </Button>
    </Modal>
  )
}