export enum ActionType {
  SET_SESSION,
  SET_SB_COLLAPSE,
  NEW_SCENARIO,
  UPDATE_SCENARIO,
  REMOVE_SCENARIO,
  SET_ITR_NODES,
  SET_CURR_QUIZ_GAME_ITR_ID,
  SET_CURR_QUIZ_ITR_ID,
  SET_CURR_POLL_ITR_ID,
  UPDATE_ITR_NODE,
  SET_CLOCK_TICK,
  SET_AUTO_PUBLISH,
  SET_AUTO_PUBLISH_COUNTDOWN,
  SET_AUTO_PUBLISH_GAP,
  SET_AUTO_END_QUIZ_GAME,
  SET_OPERATE_DISALES,
  SET_CONNECTION_STATUS,
  SET_CURR_EVENT,
  SET_CURR_HTVGO_LIVESTREAM,
  SET_LIVESTREAM_PROFILE,
  SET_CURR_ROOM_ID,
  SET_IS_ROOM_OPENED,
  CLOSE_ROOM,
  SET_STATE
}