import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { UpdateNumOrderRequestDto } from "../dtos/common/UpdateNumOrderDto";
import { CreateMountedQuizDto } from "../dtos/mounted-quiz/CreateMountedQuizDto";
import { ModifyMountedQuizDto } from "../dtos/mounted-quiz/ModifyMountedQuizDto";
import { MountedQuizDto } from "../dtos/mounted-quiz/MountedQuizDto";
import { HttpUtils } from "../utils/HttpUtils";

export class MountedQuizService {

  private static readonly PREV_UPSERT_PARAMS = "prev_upsert_params";
  
  static get prevUpsertParams(): ModifyMountedQuizDto | null {
    const raw = localStorage.getItem(this.PREV_UPSERT_PARAMS);
    return raw ? JSON.parse(raw) : null;
  }

  static set prevUpsertParams(modifyDto: ModifyMountedQuizDto | null) {
    localStorage.setItem(this.PREV_UPSERT_PARAMS, JSON.stringify(modifyDto));
  }

  static async findByQuizGameId(quizGameId: number) {
    return (await HttpUtils.get<MountedQuizDto[]>(Apis.mountedQuiz.findByQuizGameId, { quizGameId })).body;
  }

  static create(createDto: CreateMountedQuizDto) {
    return HttpUtils.post<MountedQuizDto>(Apis.mountedQuiz.create, createDto);
  }

  static update(id: number, modifyDto: ModifyMountedQuizDto) {
    return HttpUtils.put<MountedQuizDto>(Apis.mountedQuiz.update(id), modifyDto);
  }

  static async updateNumOrder(requestDto: UpdateNumOrderRequestDto) {
    return (await HttpUtils.put(Apis.mountedQuiz.updateNumOrder, requestDto)).code === ResponseCode.OK.code;
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.mountedQuiz.toggle(id))).code === ResponseCode.OK.code;
  }
}