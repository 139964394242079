import { Button, Divider, Modal } from '@mantine/core';
import { useCallback, useState } from "react";

interface AppConfirmModalProps {
  opened: boolean;
  onConfirm: () => any;
  onClose: () => void;
  children: any;
}

export function AppConfirmModal(props: AppConfirmModalProps) {
  const { onConfirm } = props;
  const [loading, setLoading] = useState(false);

  const handleConfirm = useCallback(async () => {
    if (onConfirm) {
      setLoading(true);
      await onConfirm();
      setLoading(false);
    }
  }, [onConfirm]);

  return (
    <Modal title="Xác nhận" {...props}>
      <Divider my="lg" />
      {props.children}
      <Divider my="lg" />
      <div className="flex items-center">
        <div className="ml-auto">
          <Button className="mr-2" variant="outline" color="gray" onClick={props.onClose}>Huỷ bỏ</Button>
          <Button onClick={handleConfirm} loading={loading}>Đồng ý</Button>
        </div>
      </div>
    </Modal>
  )
}