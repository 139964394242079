import { Apis } from "../config/ApiConfig";
import { ResponseCode } from "../config/ResponseConfig";
import { UpsertProjectDto } from "../dtos/project/UpsertProjectDto";
import { ProjectDto } from "../dtos/project/ProjectDto";
import { QueryProjectDto } from "../dtos/project/QueryProjectDto";
import { HttpUtils } from "../utils/HttpUtils";
import { PageDto } from "../dtos/common/PageDto";

export class ProjectService {

  static async query(queryDto: QueryProjectDto): Promise<PageDto<ProjectDto>> {
    return (await HttpUtils.post(Apis.project.query, queryDto)).body;
  }

  static async checkName(name: string) {
    return (await HttpUtils.get(Apis.project.checkName, { name })).body;
  }

  static create(createDto: UpsertProjectDto) {
    return HttpUtils.post(Apis.project.create, createDto);
  }

  static update(id: number, updateDto: UpsertProjectDto) {
    return HttpUtils.put(Apis.project.update(id), updateDto);
  }

  static async toggle(id: number) {
    return (await HttpUtils.post(Apis.project.toggle(id))).code === ResponseCode.OK.code;
  }
}