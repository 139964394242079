import { Redirect, Route, Switch } from "react-router";
import { PollPage } from "./poll/PollPage";
import { ProjectPage } from "./project/ProjectPage";
import { QuizGamePage } from "./quiz-game/QuizGamePage";
import { QuizPage } from "./quiz/QuizPage";
import { ScenarioPage } from "./scenario/ScenarioPage";

export function InteractivePage() {
  return (
    <div className="p-5">
      <Switch>
        <Redirect exact from="/app/interactive" to="/app/interactive/project" />
        <Route path="/app/interactive/project" component={ProjectPage} />
        <Route path="/app/interactive/scenario/:id" component={ScenarioPage} />
        <Route path="/app/interactive/quiz-game" component={QuizGamePage} />
        <Route path="/app/interactive/quiz" component={QuizPage} />
        <Route path="/app/interactive/poll" component={PollPage} />
      </Switch>
    </div>
  )
}