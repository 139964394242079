import { Redirect, Route, Switch } from "react-router";
import { EventCalendarDetailPage } from "./EventCalendarDetailPage";
import { EventCalendarListPage } from "./EventCalendarListPage";

export function EventCalendarPage() {
  return (
    <Switch>
      <Redirect exact from="/app/livestream/event-calendar" to="/app/livestream/event-calendar/list" />
      <Route path="/app/livestream/event-calendar/list" component={EventCalendarListPage} />
      <Route path="/app/livestream/event-calendar/detail/:id" component={EventCalendarDetailPage} />
    </Switch>
  )
}