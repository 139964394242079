import { AppBreadcrumbs } from "../../../components/AppBreadCrumbs";
import { PollList } from "../../../components/poll/PollList";

const breadcrumbs = [
  { label: 'Tương tác', link: '/app/interactive' },
  { label: 'Khảo sát', link: '/app/interactive/poll/list' }
]

export function PollListPage() {
  return (
    <>
      <AppBreadcrumbs items={breadcrumbs} /> 
      <PollList zIndex={3} creatable={true} />
    </>
  )
}