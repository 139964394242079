import { Tab, Tabs } from "@mantine/core";
import { MountedQuizDto } from "../../dtos/mounted-quiz/MountedQuizDto";
import { QuizMode } from "../../enums/QuizMode";
import { MountAndUpsertQuizForm } from "./MountAndUpsertQuizForm";
import { MountQuizFromLibraryForm } from "./MountQuizFromLibraryForm";

interface MountQuizFormProps {
  zIndex: number;
  quizGameId: number;
  quizMode: QuizMode;
  onMount: (mq: MountedQuizDto, newQuiz?: boolean) => any;
  numOrder: number;
  template: boolean;
}

export function MountQuizForm(props: MountQuizFormProps) {
  return (
    <Tabs>
      <Tab label="Thư viện">
        <div className="mt-4">
          <MountQuizFromLibraryForm {...props} />
        </div>
      </Tab>
      <Tab label="Tạo câu hỏi mới">
        <div className="mt-4">
          <MountAndUpsertQuizForm
            template={props.template}
            zIndex={props.zIndex}
            quizGameId={props.quizGameId}
            quizMode={props.quizMode}
            onSuccess={props.onMount}
            numOrder={props.numOrder}
          />
        </div>
      </Tab>
    </Tabs>
  )
}