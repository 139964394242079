import { Modal } from "@mantine/core";
import { QuizDto } from "../../dtos/quiz/QuizDto";
import { UpdateQuizCorrectAnswersForm } from "./UpdateQuizCorrectAnswersForm";

interface UpdateQuizCorrectAnswersModalProps {
  opened: boolean;
  onClose: () => any;
  quizGameId: number;
  quizzes: QuizDto[];
  onSuccess?: (correctAnswerIds: number[]) => any;
}

export function UpdateQuizCorrectAnswersModal(props: UpdateQuizCorrectAnswersModalProps) {
  return (
    <Modal opened={props.opened} title="Cập nhật đáp án" onClose={props.onClose} size="50%" overflow="inside">
      <div className="p-2">
        <UpdateQuizCorrectAnswersForm quizGameId={props.quizGameId} quizzes={props.quizzes} onSuccess={props.onSuccess} />
      </div>
    </Modal>
  )
}