import { Table } from '@mantine/core';
import { useMemo } from 'react';
import ReactJson from 'react-json-view';
import ReactDiffViewer from 'react-diff-viewer';
import { FormatUtils } from '../../utils/FormatUtils';
import { LogActionDisplay } from '../../enums/LogAction';
import { LogTargetDisplay } from '../../enums/LogTarget';
import { LogTypeDisplay } from '../../enums/LogType';
import { LogActorTypeDisplay } from '../../enums/LogActorType';

interface DynamicLogDetailProps {
  log: any;
}

const columns: any = {
  '@timestamp': {
    title: 'Thời gian',
    render: (v: any) => FormatUtils.dt(v)
  },
  action: {
    title: 'Hành dộng',
    render: (v: any) => (LogActionDisplay as any)[v]
  },
  target_type: {
    title: 'Đối tượng',
    render: (v: any) => (LogTargetDisplay as any)[v]
  },
  target_id: {
    title: 'ID Đối tượng',
    render: (v: any) => v
  },
  log_type: {
    title: 'Loại log',
    render: (v: any) => (LogTypeDisplay as any)[v]
  },
  actor_id: {
    title: 'Tác nhân',
    render: (v: any) => v
  },
  actor_type: {
    title: 'Loại tác nhân',
    render: (v: any) => (LogActorTypeDisplay as any)[v]
  },
  room_id: {
    title: 'Room ID',
    render: (v: any) => v
  },
  app_id: {
    title: 'App ID',
    render: (v: any) => v
  }
}

export function DynamicLogDetail(props: DynamicLogDetailProps) {
  const propsLog = props.log;
  const log = useMemo(() => ({ ...propsLog }), [propsLog]);

  const rows = useMemo(() => {
    const rows = Object.keys(columns).map(field => {
      const col = columns[field];
      const content = log[field];
      delete log[field];
      return { title: col.title, content: col.render(content) };
    });

    if (log.new_data || log.old_data) {
      rows.push({
        title: 'Thay đổi',
        content: (
          <ReactDiffViewer 
            oldValue={JSON.stringify(log.old_data)}
            newValue={JSON.stringify(log.new_data)}
            showDiffOnly
            splitView={false}
          />
        )
      });

      delete log.old_data;
      delete log.new_data;
    }

    rows.push({
      title: 'Thông tin khác',
      content: <ReactJson collapsed src={log} />
    })

    return rows;
  }, [log]);

  return (
    <Table striped>
      <thead>
        <tr>
          <th>Tên</th>
          <th>Giá trị</th>
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (
          <tr>
            <td className="w-32">{row.title}</td>
            <td>{row.content}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}