import { IsNotEmpty, IsString, MaxLength } from "class-validator";
import { IsNotBlank } from "../../decorators/validations/IsNotBlankDecorator";

export class UpsertScenarioDto {
  @MaxLength(50, { message: 'Tên kịch bản chỉ cho phép tối đa 50 kí tự' })
  @IsNotBlank({ message: 'Tên kịch bản không hợp lệ' })
  @IsNotEmpty({ message: 'Vui lòng nhập tên kịch bản' })
  @IsString({ message: 'Vui lòng nhập tên kịch bản' })
  name: string;
  projectId?: number;
}