import { AppGlobalStateDto } from "../../dtos/store/AppGlobalStateDto";
import { OperateService } from "../../services/OperateService";
import { clearScenarioState } from "./clearScenarioState";

export function reduceCloseRoom(state: AppGlobalStateDto): AppGlobalStateDto {
  if (state.currRoomId) {
    OperateService.cleanRoom(state.currRoomId);
  }
  
  return {
    ...clearScenarioState(state),
    currRoomId: undefined,
    livestreamProfile: undefined,
    isRoomOpened: false,
    operateDisables: {}
  }
}