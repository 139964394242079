import { FileUtils } from "../../utils/FileUtils";
import path from 'path';

export class UploadFileDto {
  id: number;
  ext: string;
  mime: string;
  filename: string;
  displayName: string;
  folder: string;
  src?: string;
  size: number;
  userId: number;
  minioObject: string;
  width: number;
  height: number;
  createDt: Date;
  updateDt: Date;
  file?: File;

  static async ofFile(file: File) {
    const dto = new UploadFileDto();
    dto.mime = file.type;
    dto.filename = file.name;
    dto.ext = path.extname(file.name).replace('.', '');
    dto.displayName = path.basename(file.name);
    dto.size = file.size;
    dto.file = file;
    dto.src = FileUtils.makeUrl(file);
    
    const { width, height } = await FileUtils.getWidthHeight(dto.src);
    dto.width = width;
    dto.height = height;
    return dto;
  }
}