export enum QuizGameBannerType {
  START_GAME = 'START_GAME',
  COUNTDOWN = 'COUNTDOWN',
  WAIT_GAME_RESULT = 'WAIT_GAME_RESULT',
  GAME_RESULT_WIN = 'GAME_RESULT_WIN',
  GAME_RESULT_LOSE = 'GAME_RESULT_LOSE',
  GAME_RESULT = 'GAME_RESULT',
  END_GAME = 'END_GAME',
  JOIN_LATE = 'JOIN_LATE'
}

export const QuizGameBannerTypeDisplay = {
  [QuizGameBannerType.START_GAME]: 'Hình bắt đầu game',
  [QuizGameBannerType.COUNTDOWN]: 'Hình countdown',
  [QuizGameBannerType.WAIT_GAME_RESULT]: 'Hình đợi kết quả game (game dự đoán)',
  [QuizGameBannerType.GAME_RESULT_WIN]: 'Hình kết quả game khi người chơi chiến thắng',
  [QuizGameBannerType.GAME_RESULT_LOSE]: 'Hình kết quả game khi người chơi thua cuộc',
  [QuizGameBannerType.GAME_RESULT]: 'Hình kết quả game',
  [QuizGameBannerType.END_GAME]: 'Hình kết thúc game',
  [QuizGameBannerType.JOIN_LATE]: 'Hình hiển thị khi người chơi vào trễ (khi game đã bắt đầu)'
}