import { Apis } from "../config/ApiConfig";
import { AppConfig } from "../config/AppConfig";
import { EventDto } from "../dtos/realtime/EventDto";
import { OpenConnectionInfoDto } from "../dtos/realtime/OpenConnectionInfoDto";
import { EventType } from "../enums/EventType";
import { NotificationUtils } from "../utils/NotificationUtils";
import { RSAUtils } from "../utils/RSAUtils";

type Subscriber = (eventDto: EventDto) => any;

type SubscriberMap = {
  [key: string]: Subscriber[]
}

type WebSocketMap = {
  [key: string]: WebSocket
}

export class RealtimeService {

  private static subscribers: SubscriberMap = {};
  private static websockets: WebSocketMap = {};
  private static forceCloses: any = {};

  static getWs(roomId: string) {
    return this.websockets[roomId];
  }

  static subscribe(roomId: string, subscriber: Subscriber) {
    if (!this.subscribers[roomId]) {
      this.subscribers[roomId] = [];
    }

    this.subscribers[roomId].push(subscriber);
    return () => {
      this.subscribers[roomId] = this.subscribers[roomId].filter(s => s !== subscriber);
    };
  }

  static broadcast(roomId: string, eventDto: EventDto) {
    const subs = this.subscribers[roomId];
    subs && subs.forEach(sub => sub(eventDto));
  }

  static clear(roomId: string) {
    // delete this.subscribers[roomId];
    delete this.websockets[roomId];
  }

  static open(dto: OpenConnectionInfoDto) {
    const roomId = dto.roomId;
    const forceNew = dto.forceNew;

    if (this.websockets[roomId] && !forceNew) {
      return this.websockets[roomId];
    }
    
    RSAUtils.setPublicKey(AppConfig.realtime.PUBLIC_KEY);

    const json = JSON.stringify({
      id: AppConfig.realtime.ID_PREFIX + dto.id,
      name: dto.name,
      operator: true
    });

    const token = RSAUtils.encrypt(json);
    const appId = AppConfig.realtime.APP_ID;
    const env: any = process.env.REACT_APP_APP_ENV;
    const wsApi = (Apis.realtime as any)[env];

    const connect = (retryCount: number = 0) => {
      let connStr = `${wsApi}?roomId=${roomId}&appId=${appId}&token=${encodeURIComponent(token)}`;
      if (!!dto.avatar) {
        connStr += `&avatar=${encodeURIComponent(dto.avatar)}`;
      }

      const ws = new WebSocket(connStr);
      this.broadcast(roomId, new EventDto(EventType.CONNECTING));

      ws.onopen = () => {
        if (retryCount > 0) {
          NotificationUtils.success({
            // id: 'realtime-server',
            message: 'Kết nối lại realtime server thành công!'
          });
        }
        this.broadcast(roomId, new EventDto(EventType.CONNECTED));
      }

      ws.onmessage = (e: MessageEvent<any>) => {
        const eventDto = EventDto.parse(e.data);
        switch (eventDto.eventType) {
          case EventType.CLIENT_CONFLICT: 
            NotificationUtils.warn({ message: 'Bạn vừa kết nối ở một thiết bị khác, nên kết nối hiện tại sẽ kết thúc' });
            this.close(roomId);
            break;
          default:
            this.broadcast(roomId, eventDto);
        }
      }

      ws.onerror = (e: any) => {
        console.log('[WS] Error:', e);
        ws.close();
      }
  
      ws.onclose = () => {
        if (!!this.forceCloses[roomId]) {
          return;
        }

        NotificationUtils.error({ 
          // id: 'realtime-server',
          message: 'Mất kết nối tới realtime server, đang thử kết nối lại...',
          loading: true,
          autoClose: 3000
        });

        setTimeout(() => {
          if (retryCount + 1 >= AppConfig.realtime.MAX_RETRY) {
            NotificationUtils.error({
              // id: 'realtime-server',
              message: 'Không thể kết nối lại realtime server, vui lòng tải lại trang và thử lại'
            });
            this.broadcast(roomId, new EventDto(EventType.DISCONNECTED));
          } else {
            connect(retryCount + 1);
          }
        }, 3000);
      }
      
      this.websockets[roomId] = ws;
    }

    connect();
  }

  static close(roomId: string) {
    const ws = this.websockets[roomId];
    if (ws && ws.readyState !== WebSocket.CLOSED && ws.readyState !== WebSocket.CLOSING) {
      this.forceCloses[roomId] = true;
      this.broadcast(roomId, new EventDto(EventType.DISCONNECTED));

      ws.close();
      this.clear(roomId);
    }
  }

  static paginate(roomId: string, eventType: EventType, page: number, pageSize: number) {
    const ws = this.websockets[roomId];
    if (ws) {
      const eventDto = new EventDto(eventType, { page, pageSize });
      ws.send(eventDto.toString());
    }
  }

  static paginateQuizLeaderboard(roomId: string, page: number, pageSize: number) {
    return this.paginate(roomId, EventType.PAGINATE_QUIZ_LEADERBOARD, page, pageSize);
  }

  static paginateClients(roomId: string, page: number, pageSize: number) {
    return this.paginate(roomId, EventType.PAGINATE_CLIENTS, page, pageSize);
  }
}